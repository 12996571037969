<div class="clear"></div>
<section class="banner">
	<div class="container py-4 d-flex justify-content-between align-items-center">
		<h1 class="mb-0">{{this.userUtilsService.selectedEnvironment?.Name}} Public Procurement Ordinance</h1>
		<div class="input-group" style="max-width:300px;">
			<input type="text" class="form-control" [(ngModel)]="searchTerm" id="search" placeholder="search...">
				<div class="input-group-append" id="button-addon4">
				  <button class="btn btn-outline-secondary" type="button" (click)="ClearSearch()"><em class="fas fa-backspace fa-fw"></em></button>
				</div>
		</div>
	</div>
</section>
<!--ngx-highlight-words
	[textToHighlight]="contextText"
	[searchWords]="[searchTerm]"
	highlightClassName="bg-Searchable">
</ngx-highlight-words-->
<div class="clear py-3"></div>
<div #content>
	<section class="container">
		<div class="d-flex justify-content-between align-items-center">
			<h3 class="text-black">{{this.userUtilsService.selectedEnvironment?.Letter}}PPRA Ordinance 2002</h3>
			<p class="mb-0">
				<a href="./assets/docs/ordinance2020.pdf" target="_blank" class="text-black font-weight-medium mr-3 cursor-pointer" (click)="getPDF()">
					<i class="far fa-file-pdf text-danger mr-1 cursor-pointer"></i> Download PDF
				</a>
				<a class="text-black font-weight-medium cursor-pointer" (click)="print()">
					<i class="fas fa-print text-primary mr-1 cursor-pointer"></i> Print
				</a>
			</p>
		</div>	
		<h5 class="text-primary font-weight-semi-bold">An Ordinance</h5>

		<p>
			<ngx-highlight-words
				[textToHighlight]="'to provide for the establishment of Public Procurement Regulatory Authority for regulating public procurement of goods, services, works and disposal of public assets in the public sector;'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
			</ngx-highlight-words>
		</p>
		<p>
			<ngx-highlight-words
				[textToHighlight]="'WHEREAS it is expedient to provide for the establishment of a Public Procurement Regulatory Authority for regulating procurement of goods, services, works and disposal of public assets in the public sector and for matters connected therewith or ancillary thereto;'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
			</ngx-highlight-words>
		</p>	
		<p>
			<ngx-highlight-words
				[textToHighlight]="'AND WHEREAS the President is satisfied that circumstances exist which render it necessary to take immediate action;'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
			</ngx-highlight-words>
		</p>	
		<p>
			<ngx-highlight-words
				[textToHighlight]="'NOW, THEREFORE, in pursuance of the Proclamation of Emergency of the fourteenth day of October, 1999, and the Provisional Constitution Order No.1 of 1999, read with the Provisional Constitution (Amendment) Order No.9 of 1999, and in exercise of all powers enabling him in that behalf, the President of the Islamic Republic of Pakistan is pleased to make and promulgate the following Ordinance:-'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
			</ngx-highlight-words>
		</p>
		<h6 class="text-black">
			<ngx-highlight-words [textToHighlight]="'CHAPTER  I.-PRELIMINARY'" [searchWords]="[searchTerm]" highlightClassName="bg-Searchable"></ngx-highlight-words>
		</h6>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">1-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Short title, extent and commencement.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'This Ordinance may be called the Public Procurement Regulatory Authority Ordinance, 2020 (As amended upto July 7, 2020).'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'It extends to the whole of Pakistan.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(3)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'It shall come into force at once.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">2-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Definitions'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'In this Ordinance, unless there is anything repugnant in the subject or context,-'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(a)</strong>
					<div class="media-body">
						"Authority" 
						<ngx-highlight-words
							[textToHighlight]="'means the Public Procurement Regulatory Authority established under section 3;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(b)</strong>
					<div class="media-body">
						"Board" 
						<ngx-highlight-words
							[textToHighlight]="'means the Board constituted under section 6;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(c)</strong>
					<div class="media-body">
						"Chairperson" 
						<ngx-highlight-words
							[textToHighlight]="'means the Chairperson of the Board;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black">(ca)</strong>
					<div class="media-body">
						"disposal" 
						<ngx-highlight-words
							[textToHighlight]="'means auction, rental, lease, donations, destruction and recycling of public assets or any combination thereof;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(d)</strong>
					<div class="media-body">
						"Fund" 
						<ngx-highlight-words
							[textToHighlight]="'means the fund established under section 9;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(e)</strong>
					<div class="media-body">
						"goods" 
						<ngx-highlight-words
							[textToHighlight]="'means articles and objects of every kind and description including raw materials, products, equipment, machinery, spares, scraps, waste material and commodities in any form and includes all type of assets such as immoveable property, physical objects in any form or matter, intangible assets, goodwill, intellectual property and proprietary right, as well as service incidental thereto if the value of these services does not exceed the value of such goods;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>"
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(f)</strong>
					<div class="media-body">
						"Managing Director" 
						<ngx-highlight-words
							[textToHighlight]="'means the Managing Director appointed under section 8;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(g)</strong>
					<div class="media-body">
						"member" 
						<ngx-highlight-words
							[textToHighlight]="'means a member of the Authority;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(h)</strong>
					<div class="media-body">
						"misprocurement" 
						<ngx-highlight-words
							[textToHighlight]="'means public procurement in contravention of any provision of this Ordinance, any rules, regulations, orders or instructions made there under or any other law in respect of, or relating to, public procurement;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(i)</strong>
					<div class="media-body">
						"prescribed" 
						<ngx-highlight-words
							[textToHighlight]="'means prescribed by rules made under this Ordinance;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(j)</strong>
					<div class="media-body">
						"procuring agency" 
						<ngx-highlight-words
							[textToHighlight]="'means-'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>						
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">(i)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'any Ministry, Division, Department or any Office of the Federal Government;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>"
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">(ii)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'any authority, corporation, body or organization established by or under a Federal law or which is owned or controlled by the Federal Government;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>"
							</div>
						</div>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black">(ja)</strong>
					<div class="media-body">
						"public asset" 
						<ngx-highlight-words
							[textToHighlight]="'means all types of goods owned and possessed by the procuring agency;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(k)</strong>
					<div class="media-body">
						"Public Fund" 
						<ngx-highlight-words
							[textToHighlight]="'means the Federal Consolidated Fund and the Public Account of the Federation and includes funds of enterprises which are owned or controlled by the Federal Government;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(l)</strong>
					<div class="media-body">
						"public procurement" 
						<ngx-highlight-words
							[textToHighlight]="'means acquisition of goods, services or construction of any works financed wholly or partly out of the public fund and includes disposal of public assets and commercial transactions between procuring agency and private party, in terms of which the private party is allowed to-'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(i)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'perform a procuring agencys assigned functions, including operations and management, on its behalf;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(ii)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'assume the use of public asset; or'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(iii)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="' receive a benefit either from budget or revenue of the Federal Government or from fees or charges to be collected by the private party for performing the procuring agencys function or any combination thereof;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(m)</strong>
					<div class="media-body">
						"regulations" 
						<ngx-highlight-words
							[textToHighlight]="'means regulations made under this Ordinance;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(n)</strong>
					<div class="media-body">
						"rules" 
						<ngx-highlight-words
							[textToHighlight]="'means rules made under this Ordinance;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(o)</strong>
					<div class="media-body">
						"service" 
						<ngx-highlight-words
							[textToHighlight]="'means any object of procurement other than goods or works; and'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(p)</strong>
					<div class="media-body">
						"works" 
						<ngx-highlight-words
							[textToHighlight]="'means any construction work consisting of erection, assembly, repair, renovation or demolition of a building or structure or part thereof, such as site preparation, excavation, installation of equipment or materials and decoration, finishing and includes incidental services such as drilling, mapping, satellite photography, seismic investigations and similar activities, if the value of those services does not exceed that of the works themselves.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<h6 class="text-black">
			<ngx-highlight-words [textToHighlight]="'CHAPTER II.-ESTABLISHMENT OF AUTHORITY'" [searchWords]="[searchTerm]" highlightClassName="bg-Searchable"></ngx-highlight-words>
		</h6>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">3-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Establishment of Authority.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'There is hereby established an Authority to be called the Public Procurement Regulatory Authority for carrying out the purposes of this Ordinance.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Authority shall be a body corporate, having perpetual succession and a common seal, with powers subject to the provisions of this Ordinance, to acquire and hold property, both moveable and immovable, and, sue and be sued by the name assigned to it by sub-section (1).'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(3)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The headquarters of the authority shall be at Islamabad and it may establish its offices at such other place or places in Pakistan as it may consider appropriate.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">4-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Power of the Federal Government to issue directives'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'The Federal Government may, as and when it considers necessary, issue directives to the Authority on matters of policy, and such directives shall be binding on the Authority.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">5-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Functions and powers of the Authority.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'Subject to other provisions of this Ordinance, the authority may take such measures and exercise such powers as may be necessary for improving governance,  management, transparency,  accountability and quality of public procurement of goods, services and works in the public sector.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'Without prejudice to the generality of the powers conferred by sub-section (1), the Authority may'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>						
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">(a)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'monitor application of the laws, rules, regulations, policies and procedures in respect of, or relating to, procurement;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">(b)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'monitor the implementation of and evaluate laws, rules, regulations, policies and procedures in respect of, or relating to, inspection or quality of goods, services and works and recommend reformulation thereof or revisions therein as it deems necessary;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">(c)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'recommend to the Federal Government revisions in or formulation of new laws, rules and policies in respect of or related to public procurement;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">d</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'make regulations and lay down codes of ethics and procedures for public procurement, inspection or quality of goods, services and works;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">e</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'monitor public procurement practices and make recommendations to improve governance, transparency, accountability and quality of public procurement;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">f</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'monitor overall performance of procuring agencies and make recommendations for improvements in their institutional set up;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">g</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'provide and coordinate assistance to procuring agencies for developing and improving their institutional framework and public procurement activities;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">h</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'submit reports to the Government in respect of public procurement activities of procuring agencies;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">i</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'call any functionary of procuring agencies to provide assistance in its functions and call for any information from such agencies in pursuance of its objectives and functions; and'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">j</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'perform any other function assigned to it by the Federal Government or that is incidental or consequential to any of the aforesaid functions.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<h6 class="text-black">
			<ngx-highlight-words [textToHighlight]="'CHAPTER III.-MANAGEMENT AND ADMINISTRATION OF THE AUTHORITY'" [searchWords]="[searchTerm]" highlightClassName="bg-Searchable"></ngx-highlight-words>
		</h6>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">6-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Board'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'General directions and administration of the Authority and its affairs shall vest in a Board which may exercise all powers, perform all functions and do all acts and things which may be exercised, performed or done by the Authority.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Board shall consist of the following Members, namely:-'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>						
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">(1)</strong>
							<div class="media-body">
								<div class="d-flex justify-content-between w-50">									
									<ngx-highlight-words
										[textToHighlight]="'Secretary, Finance Division'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
									<strong class="text-black">
										<ngx-highlight-words
											[textToHighlight]="'Chairperson'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</strong>
								</div>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">(2)</strong>
							<div class="media-body">
								<div class="d-flex justify-content-between w-50">									
									<ngx-highlight-words
										[textToHighlight]="'Secretary, Ministry of Industries and Production'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
									<strong class="text-black">
										<ngx-highlight-words
											[textToHighlight]="'Member'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</strong>
								</div>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">(3)</strong>
							<div class="media-body">
								<div class="d-flex justify-content-between w-50">									
									<ngx-highlight-words
										[textToHighlight]="'Secretary, Defence Production Division'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
									<strong class="text-black">
										<ngx-highlight-words
											[textToHighlight]="'Member'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</strong>
								</div>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">(4)</strong>
							<div class="media-body">
								<div class="d-flex justify-content-between w-50">									
									<ngx-highlight-words
										[textToHighlight]="'Secretary Ministry of Water and Power'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
									<strong class="text-black">
										<ngx-highlight-words
											[textToHighlight]="'Member'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</strong>
								</div>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">(5)</strong>
							<div class="media-body">
								<div class="d-flex justify-content-between w-50">									
									<ngx-highlight-words
										[textToHighlight]="'Secretary, Ministry of Housing and works'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
									<strong class="text-black">
										<ngx-highlight-words
											[textToHighlight]="'Member'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</strong>
								</div>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">(6)</strong>
							<div class="media-body">
								<div class="d-flex justify-content-between w-50">									
									<ngx-highlight-words
										[textToHighlight]="'Secretary, Ministry of Communications'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
									<strong class="text-black">
										<ngx-highlight-words
											[textToHighlight]="'Member'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</strong>
								</div>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">(7)</strong>
							<div class="media-body">
								<div class="d-flex justify-content-between w-50">									
									<ngx-highlight-words
										[textToHighlight]="'Three Members from private sector to be nominated by the Federal Government'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
									<strong class="text-black">
										<ngx-highlight-words
											[textToHighlight]="'Member'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</strong>
								</div>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-2">(7)</strong>
							<div class="media-body">
								<div class="d-flex justify-content-between w-50">									
									<ngx-highlight-words
										[textToHighlight]="'Managing Director'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
									<strong class="text-black">
										<ngx-highlight-words
											[textToHighlight]="'Member'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(3)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Managing Director shall act as Secretary to the Board.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(4)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'A member appointed from the private sector shall hold office for a period of three years and shall be entitled to such terms and conditions as the Federal Government may determine.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(5)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'A  member from the private sector may, by writing in his hand addressed to the Federal Government, resign his office.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(6)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'A casual vacancy in the office of a private sector member shall be filled by appointment of another member from the private sector for the residue of the terms of his predecessor.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">7-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Meeting of the Board'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="' The meeting of the Board shall be presided over by the Chairperson or, in his absence the member elected by the members, shall preside at the meeting of the Board.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'Five members shall constitute a quorum for a meeting of the Board requiring a decision by the Board.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(3)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The meeting of the Board shall be held at such times, places and in such manner as may be prescribed by regulation.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(4)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The members shall have reasonable notice of the time and place of the meeting and matters on which a decision by the Board shall be taken in such meeting.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(5)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The decision of the Board shall be taken by the majority of its members present and, in case of a tie, the member presiding a meeting shall have a casting vote.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(6)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'All orders, determination and decision of the Board shall be taken in writing and shall be signed by the Managing Director.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">8-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Managing Director'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Federal Government shall select and appoint a whole-time Managing Director to serve as member of the Board and shall be responsible for day to day administration of the Authority for such period and on such terms and conditions as the Federal Government may, determine.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Managing Director shall be appointed for three years and he shall be eligible for re-appointment but his total tenure shall in no case exceed six years.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(3)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'No person shall be appointed or continue as Managing Director, if he-'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(a)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'has been convicted of an offence involving moral turpitude;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(b)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'has been removed from service for misconduct;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(c)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'has been adjudicated as insolvent;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(d)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'is incapable of discharging his duties by reasons of physical or mental incapacity and has been so declared by a Medical Board appointed by the Federal Government; or'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(e)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'fail to disclose any conflict of interest at or within the time provided for such disclosure by or under this Ordinance or contravene any of the provisions of this Ordinance.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(4)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Managing Director may, at any time, resign his office by writing under his hand addressed to the Federal Government.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(5)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Managing Director shall be paid such salary and allowances as the Federal Government may determine but his salary and allowances shall not be varied to his disadvantage during his term of office.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(6)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Managing Director shall not, during the term of his office, engage himself to any other service, business, vocation or employment nor shall he before the expiration of one year thereof enter into employment or accept any advisory or consult relationship with any person engaged in public procurement activity: Provided that where the Managing Director is a Government servant there shall be no such restrictions on his employment after he has retired or transferred from the post of Managing Director and where he is from private sector he shall not have any direct or indirect financial interest or have any connection with any company engaged in public procurement activity for so long as he holds office and for a period of one year thereafter.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(7)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Managing Director shall have power and responsibility to--'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(a)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'exercise administrative control over the personnel of the Authority;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(b)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'exercise, in respect of the Authority, such other management, administrative and financial powers as deemed appropriate by the Authority;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(c)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'submit the annual budget proposals of the Authority to the Board;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(d)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'prepare the annual report of the Authority for the Board and the Federal Government;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(e)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'exercise such powers as the Board may delegate to him; and'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(f)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'act on behalf of the Authority, in any emergency, subject to the obligation to report such action to the Board at its next meeting and to seek the Boardâ€™s ratification of any action so taken.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<h6 class="text-black">
			<ngx-highlight-words [textToHighlight]="'CHAPTER IV.-FINANCIAL PROVISIONS'" [searchWords]="[searchTerm]" highlightClassName="bg-Searchable"></ngx-highlight-words>
		</h6>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">9-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Fund'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'There is hereby established a Fund to be known as the Public Procurement Regulatory Authority Fund which shall vest in the Authority and shall be utilized by the Authority to meet the charges in connection with its functions under this Ordinance.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'To the credit of the Public Procurement Authority, a fund shall be placed comprising-'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(a)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'such sums as the Federal Government may, from time to time,  allocate to it in the annual budget;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(b)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'grants;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(c)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'income from investment by the Authority; and'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(d)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'all other sums or properties which may in any manner become payable to, or vest in, the Authority in respect of any matter.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(3)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Authority, while performing its functions and exercising its powers under the Ordinance, shall exercise highest sense of prudence as far as expenditures are concerned.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">10-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Expenditure to be charged on the Fund'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Fund shall be expended for the purpose of'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(a)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'paying any expenditure lawfully incurred by the Authority, relating to remuneration of  its members, employees, advisers, and consultants of the Authority, including provident fund contributions, superannuating allowances or gratuities, legal fees and costs as well as other fees and costs;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(b)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'paying any other expenses, costs or expenditure properly incurred or accepted by the Authority in the performance of its functions or the exercise of its powers under this Ordinance;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(c)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'purchasing or hiring equipment, machinery and any other work and undertakings in the performance of its functions or the exercise of its powers under this Ordinance;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(d)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'repaying any financial accommodation received; and'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">
							<strong class="font-weight-medium text-black mr-1">(e)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'generally, paying any expenses for carrying into effect the provisions of this Ordinance.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">11-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Power to obtain finances and receive grants'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'The Authority may, from time to time and with the approval of the Federal Government, accept grants from entities both domestic and international, including multilateral agencies for meeting any of its obligations or performing of any of its functions.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">12-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Investment'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'The Authority may invest its surplus funds in accordance with the instructions of the Federal Government.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">13-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Budget and accounts'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'The Authority shall cause its accounts to be maintained properly and in respect of each financial year submit for approval of the Federal Government by such date and in such form as may be specified by the Federal Government a statement showing the estimated receipts and current expenditure and the sums to be required from the Government during the next financial year.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">14-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Maintenance of accounts'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'The Authority shall cause proper accounts to be kept and shall after the end of each financial year cause to be prepared for that financial year a statement of accounts of the Authority which shall include a balance sheet and an account of receipt and expenditure.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">15-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Audit'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'The accounts of the Authority, shall be audited every year by the Auditor-General of Pakistan.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<h6 class="text-black">
			<ngx-highlight-words [textToHighlight]="'CHAPTER V.-REGULATORY AND OTHER  PROVISIONS.'" [searchWords]="[searchTerm]" highlightClassName="bg-Searchable"></ngx-highlight-words>
		</h6>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">16-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Information'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Authority may call for any information required by it for carrying out the purposes of this Ordinance, from any person or any institution in public procurement activities and any such person or institution shall provide the required information called by the Authority.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Authority shall furnish to the Federal Government such information with respect to the policies and procedures it is pursuing or proposes to pursue in the performance of any of its functions under this Ordinance as the Federal Government may, from time to time, require.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">17-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Annual report'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'Within one hundred and twenty days from the end of each financial year, the Authority shall cause a report to be prepared on its activities including inquiries and investigations made by the Authority under this Ordinance during that financial year and release to the public after it has been seen by the Cabinet.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<h6 class="text-black">
			<ngx-highlight-words [textToHighlight]="'CHAPTER VI.-MISCELLANEOUS'" [searchWords]="[searchTerm]" highlightClassName="bg-Searchable"></ngx-highlight-words>
		</h6>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">18-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Appointment of officers and staff, etc.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Authority may, from time to time and within its resources, appoint such officers, servants, advisers, consultants and experts as it may consider necessary for performance of its functions.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Authority shall by regulations prescribe the procedure for appointment of its officers, servants, advisers, consultants and experts and the terms and conditions of their service.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">19-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Members, officers, etc. to be public servants'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'The Chairperson, members, Director-General, officers, servants, advisers, consultants and experts of the Authority shall, when acting or purporting to act in pursuance of any of the provisions of this Ordinance or the rules and regulations made thereunder, be deemed to be public servants within the meaning of section 21 of the Pakistan Penal Code (Act XLV of 1860).'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">20-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Delegation'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'The Authority may, by such conditions and limitations as it may deem fit to impose, delegate any of its functions or powers to the Managing Director, or one or more members or any of its officers except the power to-'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(a)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'approve audited accounts;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(b)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'recommend exemption under section 21; and'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(c)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'make or repeal regulation made under this Ordinance.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-1">20A-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Mechanism for grievance redressal'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'The procuring agency shall, by rules and regulations made under this Ordinance, have a mechanism for grievance redressal of bidder during procurement'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">21-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Power to exempt'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'The Authority may, for reasons to be recorded in writing, recommend to the Federal Government that the procurement of an object or class of objects in the national interest be exempted from the operation of this Ordinance or any rule or regulation made thereunder or any other law regulating public procurement and the Federal Government on such recommendations shall exempt the aforesaid objects or class of objects from the operation of the laws and rules and regulations made thereunder.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-1">21A-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Ordinance not to apply to certain bodies corporate etc.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'Notwithstanding anything contained in this Ordinance the provisions thereof shall not apply to a body corporate, company, institution undertaking or establishment specified in the Schedule to this Ordinance, which has been privatized pursuant to the Privatization Commission Ordinance, 2000 (LII of 2000).'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Federal Government may, by notification in the official Gazette, amend the Schedule so as to add any entry thereto, modify or omit any entry therein.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">22-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Validity of proceedings'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'No act or proceedings of the Authority or the Board, shall be invalid by reason only of the existence of a vacancy in, or defect  in the constitution of, the  Authority or the Board.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">23-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Indemnity'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'No suit, prosecution, or other legal proceedings shall lie against the Authority, the Board, the Chairperson or any member, officer, servants, advisers or consultants of the Authority in respect of anything in good faith done or intended to be done under this Ordinance or the rules and regulations made thereunder.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">24-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Common seal'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">1</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Authority shall have a common seal and such seal shall be kept by the Managing Director or such other person as the Chairperson may authorise.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">
					<strong class="font-weight-medium text-black mr-2">2</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The seal shall be authenticated in the same manner as may be prescribed by regulation and any document purported to be sealed with the seal so authenticated shall be receivable as evidence of the particular stated in the document.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">25-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Winding up'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'No provision of law relating to winding up of bodies corporate shall apply to the Authority and the Authority shall not be wound up except by the orders of the Federal Government in the same manner as the Federal Government may direct.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">
			<strong class="font-weight-semi-bold text-black mr-3">26-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Power of the Federal Government to make rules'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'The Federal Government may, by notification in the official Gazette, make rules for carrying out the purposes of this Ordinance.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">27-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Power of the Authority to make regulations'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'The Authority may make regulations, not inconsistent with the provisions of this Ordinance and the rules made thereunder, for carrying out the purposes of this Ordinance.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<h5 class="text-right Roboto">
			<ngx-highlight-words
				[textToHighlight]="'PRESIDENT OF PAKISTAN'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
			</ngx-highlight-words>
		</h5>
		<p>
			<strong>
				<ngx-highlight-words
					[textToHighlight]="'THE SCHEDULE '"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
				<br>[See Section 21A]
			</strong>
		</p>
		<p>
			<ngx-highlight-words
				[textToHighlight]="'1. The Pakistan Telecommunication Company Limited.'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
			</ngx-highlight-words>
		</p>

		<!--ol>
			<li>
				
				<ol type="1">
					<li class="mb-1">
						<ngx-highlight-words
							[textToHighlight]="'This Ordinance may be called the Public Procurement Regulatory Authority Ordinance, 2020 (As amended upto July 7, 2020).'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</li>
					<li class="mb-1">
						<ngx-highlight-words
							[textToHighlight]="'It extends to the whole of Pakistan.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</li>
					<li class="mb-1">
						<ngx-highlight-words
							[textToHighlight]="'It shall come into force at once.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</li>
				</ol>
			</li>
			<li><strong class="font-weight-semi-bold text-black">Definitions.-</strong>
				<p><ngx-highlight-words
					[textToHighlight]="'In this Ordinance, unless there is anything repugnant in the subject or context,-'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words></p>
				<ol type="a">
					<li class="mb-1">"Authority"<ngx-highlight-words
						[textToHighlight]="' means the Public Procurement Regulatory Authority established under section 3;'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</li>
					<li class="mb-1">"Board"<ngx-highlight-words
						[textToHighlight]="' means the Board constituted under section 6;'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</li>
					<li class="mb-1">"Chairperson" <ngx-highlight-words
						[textToHighlight]="'means the Chairperson of the Board;'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words><br>(ca) "disposal" <ngx-highlight-words
						[textToHighlight]="'means auction, rental, lease, donations, destruction and recycling of public assets or any combination thereof;'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</li>
					<li class="mb-1">"Fund"<ngx-highlight-words
						[textToHighlight]="' means the fund established under section 9;'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</li>
					<li class="mb-1">"goods"<ngx-highlight-words
						[textToHighlight]="' means articles and objects of every kind and description including raw materials, products, equipment, machinery, spares, scraps, waste material and commodities in any form and includes all type of assets such as immoveable property, physical objects in any form or matter, intangible assets, goodwill, intellectual property and proprietary right, as well as service incidental thereto if the value of these services does not exceed the value of such goods;'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</li>
					<li class="mb-1">"Managing Director"<ngx-highlight-words
						[textToHighlight]="' means the Managing Director appointed under section 8;'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</li>
					<li class="mb-1">"member" <ngx-highlight-words
						[textToHighlight]="'means a member of the Authority;'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</li>
					<li class="mb-1">
						<p>"misprocurement" <ngx-highlight-words
							[textToHighlight]="'means public procurement in contravention of any provision of this Ordinance, any rules, regulations, orders or instructions made there under or any other law in respect of, or relating to, public procurement;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
							</ngx-highlight-words></p>
						<ol type="i">
							<li class="mb-1">"prescribed" <ngx-highlight-words
								[textToHighlight]="'means prescribed by rules made under this Ordinance;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</li>
							<li class="mb-1">"procuring agency" means-
								<ol type="i">
									<li class="mb-1"><ngx-highlight-words
										[textToHighlight]="'any Ministry, Division, Department or any Office of the Federal Government;'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</li>									
								</ol>
							</li>
							<li class="mb-1">"public asset" <ngx-highlight-words
								[textToHighlight]="'means all types of goods owned and possessed by the procuring agency;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</li>						
						</ol>
					</li>
					<li class="mb-1">
						<ngx-highlight-words
						[textToHighlight]="'any authority, corporation, body or organization established by or under a Federal law or which is owned or controlled by the Federal Government;'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words><br>
						<ngx-highlight-words
						[textToHighlight]="'(ja) public asset means all types of goods owned and possessed by the procuring agency;'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</li>					
					<li class="mb-1">
						<p>"Public Fund" <ngx-highlight-words
							[textToHighlight]="'means the Federal Consolidated Fund and the Public Account of the Federation and includes funds of enterprises which are owned or controlled by the Federal Government;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
							</ngx-highlight-words></p>
						<ol type="i">
							<li class="mb-1">"public procurement"<ngx-highlight-words
								[textToHighlight]="' means acquisition of goods, services or construction of any works financed wholly or partly out of the public fund and includes disposal of public assets and commercial transactions between procuring agency and private party, in terms of which the private party is allowed to-'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="' perform a procuring agencys assigned functions, including operations and management, on its behalf;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="' assume the use of public asset; or'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="' receive a benefit either from budget or revenue of the Federal Government or from fees or charges to be collected by the private party for performing the procuring agencys function or any combination thereof;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
						</ol>
					</li>
					<li class="mb-1">"regulations" <ngx-highlight-words
						[textToHighlight]="'means regulations made under this Ordinance;'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1">"rules" <ngx-highlight-words
						[textToHighlight]="' means rules made under this Ordinance;'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1">"service" <ngx-highlight-words
						[textToHighlight]="'means any object of procurement other than goods or works; and'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1">"works" 
						<ngx-highlight-words
						[textToHighlight]="' means any construction work consisting of erection, assembly, repair, renovation or demolition of a building or structure or part thereof, such as site preparation, excavation, installation of equipment or materials and decoration, finishing and includes incidental services such as drilling, mapping, satellite photography, seismic investigations and similar activities, if the value of those services does not exceed that of the works themselves.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
				</ol>			
			</li>
		</ol>

		<h6 class="text-black">
			<ngx-highlight-words
			[textToHighlight]="'CHAPTER II.-ESTABLISHMENT OF AUTHORITY'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words></h6>
		<ol>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Establishment of Authority.'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<ol type="1">
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'There is hereby established an Authority to be called the Public Procurement Regulatory Authority for carrying out the purposes of this Ordinance.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Authority shall be a body corporate, having perpetual succession and a common seal, with powers subject to the provisions of this Ordinance, to acquire and hold property, both moveable and immovable, and, sue and be sued by the name assigned to it by sub-section (1).'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The headquarters of the authority shall be at Islamabad and it may establish its offices at such other place or places in Pakistan as it may consider appropriate.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
				</ol>
			</li>
			<li>
				<strong class="font-weight-semi-bold text-black"><ngx-highlight-words
					[textToHighlight]="'Power of the Federal Government to issue directives'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></strong>
				<p><ngx-highlight-words
					[textToHighlight]="'The Federal Government may, as and when it considers necessary, issue directives to the Authority on matters of policy, and such directives shall be binding on the Authority.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Functions and powers of the Authority.-'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<ol type="1">
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'Subject to other provisions of this Ordinance, the authority may take such measures and exercise such powers as may be necessary for improving governance,  management, transparency,  accountability and quality of public procurement of goods, services and works in the public sector.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1">
						<p><ngx-highlight-words
							[textToHighlight]="'Without prejudice to the generality of the powers conferred by sub-section (1), the Authority may'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
							</ngx-highlight-words></p>
						<ol type="a">
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'monitor application of the laws, rules, regulations, policies and procedures in respect of, or relating to, procurement;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'monitor the implementation of and evaluate laws, rules, regulations, policies and procedures in respect of, or relating to, inspection or quality of goods, services and works and recommend reformulation thereof or revisions therein as it deems necessary;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'recommend to the Federal Government revisions in or formulation of new laws, rules and policies in respect of or related to public procurement;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'make regulations and lay down codes of ethics and procedures for public procurement, inspection or quality of goods, services and works;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'monitor public procurement practices and make recommendations to improve governance, transparency, accountability and quality of public procurement;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'monitor overall performance of procuring agencies and make recommendations for improvements in their institutional set up;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'provide and coordinate assistance to procuring agencies for developing and improving their institutional framework and public procurement activities;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'submit reports to the Government in respect of public procurement activities of procuring agencies;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'call any functionary of procuring agencies to provide assistance in its functions and call for any information from such agencies in pursuance of its objectives and functions; and'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'perform any other function assigned to it by the Federal Government or that is incidental or consequential to any of the aforesaid functions.'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
						</ol>
					</li>
				</ol>
			</li>
		</ol>

		<h6 class="text-black"><ngx-highlight-words
			[textToHighlight]="'CHAPTER III.-MANAGEMENT AND ADMINISTRATION OF THE AUTHORITY'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words></h6>
		<ol>
			<li><strong class="font-weight-semi-bold text-black">Board</strong>
				<ol type="1">
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'General directions and administration of the Authority and its affairs shall vest in a Board which may exercise all powers, perform all functions and do all acts and things which may be exercised, performed or done by the Authority.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1">
						<ngx-highlight-words
			[textToHighlight]="'The Board shall consist of the following Members, namely:-'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words>
						<ol type="a">
							<li class="mb-1">
								<div class="d-flex justify-content-between w-50">
									<ngx-highlight-words
			[textToHighlight]="'Secretary, Finance Division'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words>
									<strong class="font-weight-semi-bold text-black">Chairperson</strong>
								</div>
							</li>
							<li class="mb-1">
								<div class="d-flex justify-content-between w-50">
									<ngx-highlight-words
			[textToHighlight]="'Secretary, Ministry of Industries and Production'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words>
									<strong class="font-weight-semi-bold text-black">Member</strong>
								</div>
							</li>
							<li class="mb-1">
								<div class="d-flex justify-content-between w-50">
									<ngx-highlight-words
			[textToHighlight]="'Secretary, Defence Production Division'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words>
									<strong class="font-weight-semi-bold text-black">Member</strong>
								</div>
							</li>
							<li class="mb-1">
								<div class="d-flex justify-content-between w-50">
									<ngx-highlight-words
			[textToHighlight]="'Secretary Ministry of Water and Power'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words>
									<strong class="font-weight-semi-bold text-black">Member</strong>
								</div>
							</li>
							<li class="mb-1">
								<div class="d-flex justify-content-between w-50">
									<ngx-highlight-words
			[textToHighlight]="'Secretary, Ministry of Housing and works'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words>
									<strong class="font-weight-semi-bold text-black">Member</strong>
								</div>
							</li>
							<li class="mb-1">
								<div class="d-flex justify-content-between w-50">
									<ngx-highlight-words
			[textToHighlight]="'Secretary, Ministry of Communications'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words>
									<strong class="font-weight-semi-bold text-black">Member</strong>
								</div>
							</li>
							<li class="mb-1">
								<div class="d-flex justify-content-between w-50">
									<ngx-highlight-words
			[textToHighlight]="'Three Members from private sector to be nominated by the Federal Government'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words>
									<strong class="font-weight-semi-bold text-black">Member</strong>
								</div>
							</li>
							<li class="mb-1">
								<div class="d-flex justify-content-between w-50">
									<ngx-highlight-words
			[textToHighlight]="'Member'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words>Managing Director
									<strong class="font-weight-semi-bold text-black"></strong>
								</div>
							</li>
						</ol>
					</li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Managing Director shall act as Secretary to the Board.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'A member appointed from the private sector shall hold office for a period of three years and shall be entitled to such terms and conditions as the Federal Government may determine.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'A  member from the private sector may, by writing in his hand addressed to the Federal Government, resign his office.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'A casual vacancy in the office of a private sector member shall be filled by appointment of another member from the private sector for the residue of the terms of his predecessor.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
				</ol>
			</li>
			<li>
				<strong class="font-weight-semi-bold text-black">Meeting of the Board</strong>
				<ol type="1">
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The meeting of the Board shall be presided over by the Chairperson or, in his absence the member elected by the members, shall preside at the meeting of the Board.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'Five members shall constitute a quorum for a meeting of the Board requiring a decision by the Board.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The meeting of the Board shall be held at such times, places and in such manner as may be prescribed by regulation.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The members shall have reasonable notice of the time and place of the meeting and matters on which a decision by the Board shall be taken in such meeting.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The decision of the Board shall be taken by the majority of its members present and, in case of a tie, the member presiding a meeting shall have a casting vote.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'All orders, determination and decision of the Board shall be taken in writing and shall be signed by the Managing Director.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
				</ol>
			</li>
			<li>
				<strong class="font-weight-semi-bold text-black">Managing Director</strong>
				<ol type="1">
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Federal Government shall select and appoint a whole-time Managing Director to serve as member of the Board and shall be responsible for day to day administration of the Authority for such period and on such terms and conditions as the Federal Government may, determine.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Managing Director shall be appointed for three years and he shall be eligible for re-appointment but his total tenure shall in no case exceed six years.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'No person shall be appointed or continue as Managing Director, if he-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<ol type="a">
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'has been convicted of an offence involving moral turpitude;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'has been removed from service for misconduct;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'has been adjudicated as insolvent;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'is incapable of discharging his duties by reasons of physical or mental incapacity and has been so declared by a Medical Board appointed by the Federal Government; or'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'fail to disclose any conflict of interest at or within the time provided for such disclosure by or under this Ordinance or contravene any of the provisions of this Ordinance.'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
						</ol>
					</li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Managing Director may, at any time, resign his office by writing under his hand addressed to the Federal Government.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Managing Director shall be paid such salary and allowances as the Federal Government may determine but his salary and allowances shall not be varied to his disadvantage during his term of office.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Managing Director shall not, during the term of his office, engage himself to any other service, business, vocation or employment nor shall he before the expiration of one year thereof enter into employment or accept any advisory or consult relationship with any person engaged in public procurement activity: Provided that where the Managing Director is a Government servant there shall be no such restrictions on his employment after he has retired or transferred from the post of Managing Director and where he is from private sector he shall not have any direct or indirect financial interest or have any connection with any company engaged in public procurement activity for so long as he holds office and for a period of one year thereafter.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Managing Director shall have power and responsibility to--'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<ol type="a">
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'has been convicted of an offence involving moral turpitude;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'exercise administrative control over the personnel of the Authority;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'exercise, in respect of the Authority, such other management, administrative and financial powers as deemed appropriate by the Authority;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'submit the annual budget proposals of the Authority to the Board;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'prepare the annual report of the Authority for the Board and the Federal Government;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'exercise such powers as the Board may delegate to him; and'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'act on behalf of the Authority, in any emergency, subject to the obligation to report such action to the Board at its next meeting and to seek the Boardâ€™s ratification of any action so taken.'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
						</ol>
					</li>
				</ol>
			</li>
		</ol>

		<h6 class="text-black"><ngx-highlight-words
			[textToHighlight]="'CHAPTER IV.-FINANCIAL PROVISIONS'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words></h6>
		<ol>
			<li><strong class="font-weight-semi-bold text-black">Fund</strong>
				<ol type="1">
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'There is hereby established a Fund to be known as the Public Procurement Regulatory Authority Fund which shall vest in the Authority and shall be utilized by the Authority to meet the charges in connection with its functions under this Ordinance.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'To the credit of the Public Procurement Authority, a fund shall be placed comprising-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<ol type="a">
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'such sums as the Federal Government may, from time to time,  allocate to it in the annual budget;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'grants;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'income from investment by the Authority; and'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'all other sums or properties which may in any manner become payable to, or vest in, the Authority in respect of any matter.'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
						</ol>
					</li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Authority, while performing its functions and exercising its powers under the Ordinance, shall exercise highest sense of prudence as far as expenditures are concerned.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
				</ol>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Expenditure to be charged on the Fund'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<ol type="1">
					<li class="mb-1">
						<p><ngx-highlight-words
							[textToHighlight]="'The Fund shall be expended for the purpose of'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
							</ngx-highlight-words></p>
						<ol type="a">
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'paying any expenditure lawfully incurred by the Authority, relating to remuneration of  its members, employees, advisers, and consultants of the Authority, including provident fund contributions, superannuating allowances or gratuities, legal fees and costs as well as other fees and costs;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'paying any other expenses, costs or expenditure properly incurred or accepted by the Authority in the performance of its functions or the exercise of its powers under this Ordinance;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'purchasing or hiring equipment, machinery and any other work and undertakings in the performance of its functions or the exercise of its powers under this Ordinance;'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'repaying any financial accommodation received; and'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
							<li class="mb-1"><ngx-highlight-words
								[textToHighlight]="'generally, paying any expenses for carrying into effect the provisions of this Ordinance.'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></li>
						</ol>
					</li>
				</ol>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Power to obtain finances and receive grants'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<p><ngx-highlight-words
					[textToHighlight]="'The Authority may, from time to time and with the approval of the Federal Government, accept grants from entities both domestic and international, including multilateral agencies for meeting any of its obligations or performing of any of its functions.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Investment'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<p><ngx-highlight-words
					[textToHighlight]="'The Authority may invest its surplus funds in accordance with the instructions of the Federal Government.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Budget and accounts'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<p><ngx-highlight-words
					[textToHighlight]="'The Authority shall cause its accounts to be maintained properly and in respect of each financial year submit for approval of the Federal Government by such date and in such form as may be specified by the Federal Government a statement showing the estimated receipts and current expenditure and the sums to be required from the Government during the next financial year.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Maintenance of accounts'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<p><ngx-highlight-words
					[textToHighlight]="'The Authority shall cause proper accounts to be kept and shall after the end of each financial year cause to be prepared for that financial year a statement of accounts of the Authority which shall include a balance sheet and an account of receipt and expenditure.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
			<li><strong class="font-weight-semi-bold text-black">Audit</strong>
				<p><ngx-highlight-words
					[textToHighlight]="'The accounts of the Authority, shall be audited every year by the Auditor-General of Pakistan.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
		</ol>

		<h6 class="text-black"><ngx-highlight-words
			[textToHighlight]="'CHAPTER V.-REGULATORY AND OTHER  PROVISIONS.'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words></h6>
		<ol>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Information'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<ol type="1">
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Authority may call for any information required by it for carrying out the purposes of this Ordinance, from any person or any institution in public procurement activities and any such person or institution shall provide the required information called by the Authority.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Authority shall furnish to the Federal Government such information with respect to the policies and procedures it is pursuing or proposes to pursue in the performance of any of its functions under this Ordinance as the Federal Government may, from time to time, require.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
				</ol>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Annual report'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<p><ngx-highlight-words
					[textToHighlight]="'Within one hundred and twenty days from the end of each financial year, the Authority shall cause a report to be prepared on its activities including inquiries and investigations made by the Authority under this Ordinance during that financial year and release to the public after it has been seen by the Cabinet.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
		</ol>

		<h6 class="text-black"><ngx-highlight-words
			[textToHighlight]="'CHAPTER VI.-MISCELLANEOUS'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words></h6>
		<ol>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Appointment of officers and staff, etc.'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<ol type="1">
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Authority may, from time to time and within its resources, appoint such officers, servants, advisers, consultants and experts as it may consider necessary for performance of its functions.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Authority shall by regulations prescribe the procedure for appointment of its officers, servants, advisers, consultants and experts and the terms and conditions of their service.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
				</ol>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Members, officers, etc. to be public servants'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<p><ngx-highlight-words
					[textToHighlight]="'The Chairperson, members, Director-General, officers, servants, advisers, consultants and experts of the Authority shall, when acting or purporting to act in pursuance of any of the provisions of this Ordinance or the rules and regulations made thereunder, be deemed to be public servants within the meaning of section 21 of the Pakistan Penal Code (Act XLV of 1860).'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Delegation'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<p><ngx-highlight-words
					[textToHighlight]="'The Authority may, by such conditions and limitations as it may deem fit to impose, delegate any of its functions or powers to the Managing Director, or one or more members or any of its officers except the power to-'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
				<ol type="a">
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'approve audited accounts;'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'recommend exemption under section 21; and'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'make or repeal regulation made under this Ordinance.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
				</ol>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Mechanism for grievance redressal'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<p><ngx-highlight-words
					[textToHighlight]="'The procuring agency shall, by rules and regulations made under this Ordinance, have a mechanism for grievance redressal of bidder during procurement'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Power to exempt'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<p><ngx-highlight-words
					[textToHighlight]="'The Authority may, for reasons to be recorded in writing, recommend to the Federal Government that the procurement of an object or class of objects in the national interest be exempted from the operation of this Ordinance or any rule or regulation made thereunder or any other law regulating public procurement and the Federal Government on such recommendations shall exempt the aforesaid objects or class of objects from the operation of the laws and rules and regulations made thereunder.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Ordinance not to apply to certain bodies corporate etc.-'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<ol type="1">
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'Notwithstanding anything contained in this Ordinance the provisions thereof shall not apply to a body corporate, company, institution undertaking or establishment specified in the Schedule to this Ordinance, which has been privatized pursuant to the Privatization Commission Ordinance, 2000 (LII of 2000).'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Federal Government may, by notification in the official Gazette, amend the Schedule so as to add any entry thereto, modify or omit any entry therein.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
				</ol>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Validity of proceedings'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<p><ngx-highlight-words
					[textToHighlight]="'No act or proceedings of the Authority or the Board, shall be invalid by reason only of the existence of a vacancy in, or defect  in the constitution of, the  Authority or the Board.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Indemnity'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<p><ngx-highlight-words
					[textToHighlight]="'No suit, prosecution, or other legal proceedings shall lie against the Authority, the Board, the Chairperson or any member, officer, servants, advisers or consultants of the Authority in respect of anything in good faith done or intended to be done under this Ordinance or the rules and regulations made thereunder.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Common seal'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<ol type="1">
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The Authority shall have a common seal and such seal shall be kept by the Managing Director or such other person as the Chairperson may authorise.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words></li>
					<li class="mb-1"><ngx-highlight-words
						[textToHighlight]="'The seal shall be authenticated in the same manner as may be prescribed by regulation and any document purported to be sealed with the seal so authenticated shall be receivable as evidence of the particular stated in the document.'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
						</ngx-highlight-words> </li>
				</ol>
			</li>
			<li><strong class="font-weight-semi-bold text-black">Winding up</strong>
				<p><ngx-highlight-words
					[textToHighlight]="'No provision of law relating to winding up of bodies corporate shall apply to the Authority and the Authority shall not be wound up except by the orders of the Federal Government in the same manner as the Federal Government may direct.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Power of the Federal Government to make rules'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<p><ngx-highlight-words
					[textToHighlight]="'The Federal Government may, by notification in the official Gazette, make rules for carrying out the purposes of this Ordinance.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
			<li><strong class="font-weight-semi-bold text-black"><ngx-highlight-words
				[textToHighlight]="'Power of the Authority to make regulations'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
				<p><ngx-highlight-words
					[textToHighlight]="'The Authority may make regulations, not inconsistent with the provisions of this Ordinance and the rules made thereunder, for carrying out the purposes of this Ordinance.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
			</li>
		</ol-->
		
	</section>
</div>
<div class="clear py-3"></div>