import { Component, OnInit } from '@angular/core';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';

@Component({
  selector: 'app-blacklististed-supplier',
  templateUrl: './blacklististed-supplier.component.html',
  styleUrls: ['./blacklististed-supplier.component.scss']
})
export class BlacklististedSupplierComponent implements OnInit {

  blackListedSuppliers:any;
  constructor(private portalService:PublicPortalService) { }

  ngOnInit(): void {
    this.getAllBlackListedSuppliers();
  }
  getAllBlackListedSuppliers(){
    this.portalService.getAllBlackListedSuppliers()
    .subscribe(baseResponse => {
      if (baseResponse != null) {
        if (baseResponse.success != null) {
          if (baseResponse.success) {
                this.blackListedSuppliers = baseResponse?.data  
              
          }
        }

      }

    })
  }
}
