<div class="clear"></div>
<section class="banner">
	<div class="container py-4 d-flex justify-content-between align-items-center">
		<h1 class="mb-0">Public Procurement Regulations</h1>
		<div class="input-group" style="max-width:300px;">
			<input type="text" class="form-control" [(ngModel)]="searchTerm" id="search" placeholder="search...">
			<div class="input-group-append" id="button-addon4">
				<button class="btn btn-outline-secondary" type="button" (click)="ClearSearch()"><em class="fas fa-backspace fa-fw"></em></button>
			</div>
		</div>
	</div>
</section>
<div class="clear py-3"></div>
<section class="container">
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/sro1717.pdf" target="_blank" class="d-flex w-100 align-items-center justify-content-between">
            <span>
				<i class="far fa-hand-point-right text-primary mr-2"></i>
				<ngx-highlight-words
					[textToHighlight]="'Regulations for Procedure of filing and disposal of Review Petition under Rule 19(3), 2021'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</span>
		</a>
    </h6>
	<h6 class="well font-weight-medium">
        <a data-toggle="collapse" href="#reg2008" role="button" aria-expanded="false" aria-controls="reg2008" class="d-flex w-100 align-items-center justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i>
				<ngx-highlight-words
				[textToHighlight]="'Regulation 2008'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></span>
			<i class="fas fa-arrow-down fa-fw text-black-50"></i>
        </a>
    </h6>
	<div class="clearfix collapse" id="reg2008">
		<div class="d-flex justify-content-between">
			<h3 class="text-black">
				<ngx-highlight-words
					[textToHighlight]="'Regulation 2008'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</h3>
			<p>
				<a href="./assets/docs/regulation2008.pdf" target="_blank" class="btn btn-sm btn-outline-primary cursor-pointer mr-2">
					<i class="far fa-file-pdf mr-1"></i> Regulation 2008
				</a>
				<button type="button" class="btn btn-sm btn-outline-secondary">
					<i class="fas fa-print mr-1"></i> Print
				</button>
			</p>
		</div>
		<p class="Roboto text-black-50">
			<ngx-highlight-words
				[textToHighlight]="'Islamabad, the 11th July, 2008'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
			</ngx-highlight-words>
		</p>		
		<h5 class="text-primary font-weight-semi-bold">
			<ngx-highlight-words
			[textToHighlight]="'Notification'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words>
		</h5>
		<p>
			<strong>
				<ngx-highlight-words
					[textToHighlight]="'S. R. O. 805(I)/2008.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</strong> 
			<ngx-highlight-words
				[textToHighlight]="'In exercise of the powers conferred by section 27 of the Public Procurement Regulatory Authority Ordinance, 2002 (XXII of 2002), the Authority is pleased to make the following regulations, namely;'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
			</ngx-highlight-words>-
		</p>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">1-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Short title and commencement.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'These regulations may be called the Public Procurement Regulations, 2008.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'They shall come into force at once.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">2-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Definitions.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'In these regulations, unless there is any thing repugnant in the subject of context, -'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(a)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'Ordinanc means the Public Procurement Regulatory Authority Ordinance, 2002 (XXII of 2002); and'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(b)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'rules means the Public Procurement Rules, 2004. '"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The expression used but not defined herein shall have the same meaning as are assigned to them in Ordinance and rules.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">3-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Bidding documents.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'A Procuring agency when engaged in procurement of works, shall use the standard form of bidding documents prescribed by the '"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
				<a href="https://ppra.org.pk/biddingeng2.asp" target="_blank" class="font-weight-semi-bold text-primary">
					<ngx-highlight-words
						[textToHighlight]="'Pakistan Engineering Council constituted under the Pakistan Engineering Council Act, 1975 (V of 1976).'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</a>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">4-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Record to be kept.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'A Procuring agency shall keep the following record of the procurement proceedings for at least five years from the date of completion of procurement of contract or rejection of all bids under rules 33 of the rules, namely:-'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(a)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'a brief description of the goods or works to be procured or of the procurement need for which the procuring agency requested proposal or offers;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(b)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'the names and addresses of suppliers or contractors that submitted bids, proposals, offers or quotations and name and address of supplier or contractor with whom the procurement contract is entered into and the contract price;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(c)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'the names and address of suppliers or contractor who were pre-qualified or selected and invited to submit bids or technical proposals;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(d)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'information related to the qualifications or disqualifications of suppliers or contractors who have submitted bids, proposals, offers or quotations;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(e)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'the price or the basis for determining the price and a summary of the other terms and conditions of each bid, proposal, offer or quotation and procurement contract stipulated by the procuring agency;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(f)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'evaluation report prepared under rule 35 of the rules, alongwith any reservation and preference under rule 24 ibid;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(g)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'in case of rejection of bids pursuant to rule 33 of the rules, its complete record;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(h)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="' in case of any other method of procurement except open compatative bidding which does not culminate in procurement contract, a statement to that effect and the reasons thereof; and'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(i)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'a summary of any requests for clarification of the pre-qualification or solicitation documents, the response thereto, as well as a summary of any modification to those documents.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">5-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Obtaining the record.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'After acceptance of the bids or, as the case may be, termination of the procurement proceedings without resulting in a contract, any person may, on request, obtain the records referred to in clauses (a) and (c) of regulation 4.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'After acceptance of the bids or, as the case may be, termination of the procurement proceedings without resulting in a contract any person who submitted bids proposals, offers or quotations or applied for pre-qualification may obtain, on request, the records referred to in clauses (b), (d), (e), (f) and (g) of regulation 4.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(3)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The record referred to in regulation 4 may also be made available within a reasonable time to the  Auditor General of Pakistan or any authorized officer of the Authority or the Federal Government.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">6-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'No liability to suppliers etc.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'A procuring agency shall not be liable to suppliers or contractors for damages owing solely to a failure of keeping record of the procurement proceedings in accordance with these regulations.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<p class="text-primary font-weight-semi-bold d-flex justify-content-between">
			<span>[<ngx-highlight-words
				[textToHighlight]="'F. No. 2/1/2008/PPRA-RA.III.'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
			</ngx-highlight-words>]</span>
			<strong class="text-black">
				<ngx-highlight-words
					[textToHighlight]="'MUHAMMAD FAROOQ,'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words><br><ngx-highlight-words
					[textToHighlight]="'Section Officer (RA-III).'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</strong>
		</p>
	</div>

	<h6 class="well font-weight-medium">
        <a data-toggle="collapse" href="#reg2009" role="button" aria-expanded="false" aria-controls="reg2009" class="d-flex w-100 align-items-center justify-content-between">
            <span>
				<i class="far fa-hand-point-right text-primary mr-2"></i>
				<ngx-highlight-words
					[textToHighlight]="'Regulation 2009'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</span>
			<i class="fas fa-arrow-down fa-fw text-black-50"></i>
        </a>
    </h6>
	<div class="clearfix collapse" id="reg2009">
		<div class="d-flex justify-content-between">
			<h3 class="text-black">
				<ngx-highlight-words
					[textToHighlight]="'Regulation 2009'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</h3>
			<p>
				<a href="./assets/docs/regulation2009.pdf" target="_blank" class="btn btn-sm btn-outline-primary cursor-pointer mr-2">
					<i class="far fa-file-pdf mr-1"></i> Regulation 2009
				</a>
			</p>
		</div>

		<p class="Roboto text-black-50">
			<ngx-highlight-words
			[textToHighlight]="'Islamabad, the July 9, 2009'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words></p>
		
		<h5 class="text-primary font-weight-semi-bold">Notification</h5>
		<p><strong>S.R.O.1170(1)/2009.</strong> 
			<ngx-highlight-words
			[textToHighlight]="' In exercise of the powers conferred by section 27 of the Public Procurement Regulatory Authority Ordinance, 2002 (XXII of 2002), the Public Procurement Regulatory Authority is pleased to make the following regulations, namely: -'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words>
		</p>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">1-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Short title and commencement.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The regulations may be called the Public Procurement Regulations, 2009.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'They shall come into force at once.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">2-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Posting of contract awards on PPRA`s Website.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'All procuring agencies whether within or outside Pakistan shall post Contract Awards over fifty million rupees on PPRA`s website on the proformas as set out in'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
				<a href="./assets/docs/elvform50.doc" target="_blank" class="font-weight-semi-bold text-primary">
					<ngx-highlight-words
						[textToHighlight]="'Annexure-I and Annexure-II'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</a><br><br>
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Provided '"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong> 
				<ngx-highlight-words
					[textToHighlight]="'that where any information is related to the award of a contract is of proprietary nature or where the procuring agency is convinced that such disclosure of information shall be against the public interest, it can withhold only such information from uploading on PPRAâ€²s website subject to the prior approval of the Public Procurement Regulatory Authority.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<p class="text-primary font-weight-semi-bold d-flex justify-content-between">
			<span>[<ngx-highlight-words
				[textToHighlight]="'F. No. 2/1/2008/PPRA-RA.III.'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
			</ngx-highlight-words>]</span>
			<strong class="text-black">
				<ngx-highlight-words
					[textToHighlight]="'MUHAMMAD FAROOQ,'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words><br>
				<ngx-highlight-words
					[textToHighlight]="'Section Officer (RA-III).'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</strong>
		</p>
	</div>

	<h6 class="well font-weight-medium">
        <a  data-toggle="collapse" href="#reg2010" role="button" aria-expanded="false" aria-controls="reg2010" class="d-flex w-100 align-items-center justify-content-between">
            <span>
				<i class="far fa-hand-point-right text-primary mr-2"></i> 
				<ngx-highlight-words
					[textToHighlight]="'Regulation 2010 for Procurement of Consultancy Services'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</span>
			<i class="fas fa-arrow-down fa-fw text-black-50"></i>
        </a>
    </h6>
	<div class="clearfix collapse" id="reg2010">
		<div class="d-flex justify-content-between">
			<h3 class="text-black">
				<ngx-highlight-words
					[textToHighlight]="'Regulation 2010'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</h3>
			<p>
				<a href="./assets/docs/srvreg.pdf" target="_blank" class="btn btn-sm btn-outline-primary cursor-pointer mr-2">
					<i class="far fa-file-pdf mr-1"></i> Regulation, 2010  for Procurement of Consultancy Services
				</a>
			</p>
		</div>
	
		<h5 class="text-primary font-weight-semi-bold">
			<ngx-highlight-words
				[textToHighlight]="'Notification'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
			</ngx-highlight-words>
		</h5>
		<p><strong><ngx-highlight-words
			[textToHighlight]="'S.R.O. 1077(I)/2010. -- '"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words></strong> 
			<ngx-highlight-words
			[textToHighlight]="'In exercise of powers conferred by section 27 of the Public Procurement Regulatory Authority Ordinance, 2002 (XXII of 2002), the Public Procurement Regulatory  Authority is pleased to make the following regulations, namely:-'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words>
		</p>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">1-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Short title and commencement.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'These regulations may be called the Procurement of Consultancy Services Regulations,  2010.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'They shall come into force at once.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">2-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Definitions.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'In these regulations, unless there is anything repugnant in the subject or context:-'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words><br>
						<ngx-highlight-words
							[textToHighlight]="'Provided that any procuring agency desirous of using quality based selection as a method of procurement shall record its reasons and justifications in writing for resorting to this method and shall place the same on record; and'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">(ii)</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Procedure for Selection under the quality based selection ---'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(a)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'a request for expression of interest as laid down under regulation 5 is advertised to invite interested applicants or firms to contest;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(b)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'a request for proposals shall be prepared and sent to short- listed consultants selected following the laid down criteria;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(c)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'the evaluation of proposals shall be carried out in two stages in the following manner, namely:-'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(i)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'the technical proposals shall be evaluated and the procuring agency may discuss technical details, if it may deem necessary;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(ii)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'the financial proposals of technically responsive or highest ranked proposal only shall be opened in the presence of the applicants or their representatives who may wish to attend the opening session; and'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(iii)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'highest ranked proposal is accepted, if it suits to the procuring agency in all respects.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">B-</strong>
			<div class="media-body">
				(i)
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Quality and Cost Based Selection.---'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'This method will be used were high quality is the prime consideration while cost is a secondary consideration;'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(ii)</strong>
					<div class="media-body font-weight-medium">
						<ngx-highlight-words
							[textToHighlight]="'Procedure for Selection under the quality and cost based Selection.---'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(a)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'a request for expression of Interest as laid down in               regulation 5 is advertised to invite interested applicants or      firms to contest;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(b)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'a request for proposals shall be prepared and sent to short-listed consultants selected following the laid down criteria; and;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(c)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'the evaluation of proposals shall be carried out in two stages in      the following manner, namely:-'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
								<div class="media">			
									<strong class="font-weight-medium text-black mr-2">(i)</strong>
									<div class="media-body">
										<ngx-highlight-words
											[textToHighlight]="'the technical proposals shall be evaluated and the procuring agency may discuss technical details, if it may deem necessary;'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</div>
								</div>
								<div class="media">			
									<strong class="font-weight-medium text-black mr-2">(ii)</strong>
									<div class="media-body">
										<ngx-highlight-words
											[textToHighlight]="'the financial proposals of technically responsive proposals shall   be opened in the presence of the applicants or their representatives who may wish to attend the opening session; and'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</div>
								</div>
								<div class="media">			
									<strong class="font-weight-medium text-black mr-2">(iii)</strong>
									<div class="media-body">
										<ngx-highlight-words
											[textToHighlight]="'a combined evaluation of technical and financial proposals shall follow and the applicant with the winning proposal will be accepted.'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">C-</strong>
			<div class="media-body">
				(i)
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Least cost.- '"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<ngx-highlight-words
					[textToHighlight]="'This method will only be used for assignments of standard or routine nature, where well established practices and  standards exist;'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(ii)</strong>
					<div class="media-body font-weight-medium">
						<ngx-highlight-words
							[textToHighlight]="'Procedures for selection under least cost selection.---'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(a)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'following evaluation of technical proposals and opening of  financial proposals, the applicant offering the lowest evaluated        price shall be selected for discussions on technical issues if deemed necessary; and'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(b)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'under this method proposals passing the technical score threshold shall then be treated equally and evaluated on the basis of cost only;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">D-</strong>
			<div class="media-body">
				(i)
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Single source or direct selection.-- '"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<ngx-highlight-words
					[textToHighlight]="'This method will be used only in exceptional cases, where it provides clear advantage over competition in following cases (only), namely:-'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(a)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'for tasks which are natural continuation of previous assignment and where continuity of technical services is unavoidable;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(b)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'repeat orders as provided under sub-clause (iv) of clause (c) of rule 42 of Public Procurement Rules, 2004;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(c)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'in cases of emergency as provided under sub-clause (v) of clause (c) of rule 42 of Public Procurement Rules, 2004; and'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(d)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'where only one consultant is qualified or has experience of exceptional worth with reference to clause (c) or (d) of rule 42 of the Public Procurement Rules, 2004;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(ii)</strong>
					<div class="media-body">				
						<strong class="font-weight-semi-bold text-black">
							<ngx-highlight-words
								[textToHighlight]="'Procedures for selection under single source selection ---'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
							</ngx-highlight-words>
						</strong>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(a)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'the justification for single source selection method shall be examined in the context of the overall interests of the procuring                agency to ensure economy and efficiency and provide equal opportunity to all eligible consultants, therefore, the decision to use single source selection method shall be approved in writing by the Principal Accounting Officer, concerned on recommendation by a Committee; and'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(b)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'the request for proposals or TOR, as the case may be, shall be issued to the selected consultant and the selected consultant shall be  requested to submit a technical and financial proposal upon the receipt of which discussions shall be held between the proposal evaluation committee and the selected consultant and all aspects of its  proposal, whether technical or financial, shall be discussed  together in order to reach an agreement /contract etc; and'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">E-</strong>
			<div class="media-body">
				(i)
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Fixed budget.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<ngx-highlight-words
					[textToHighlight]="'This method shall be used only when the assignment is simple, can be precisely defined and when the budget is fixed. The request for proposals shall indicate the available budget. Proposals that exceed the indicated budget shall be rejected. The ranking shall be based only on evaluation of technical proposals of the qualified bidders.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(ii)</strong>
					<div class="media-body">
						<strong class="font-weight-semi-bold text-black">
							<ngx-highlight-words
								[textToHighlight]="'Procedures for selection under fixed budget.---- '"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
							</ngx-highlight-words>
						</strong>
						<ngx-highlight-words
							[textToHighlight]="'The Procedures to be followed for the selection under fixed budget method shall be the                 same as for the   quality and cost based method, with the following                 exceptions, namely;'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(a)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'the request for proposals shall indicate the available budget                             including taxes and ask the applicants to provide, in separate                           envelopes, their best technical and financial proposals within                     the budget;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>							
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(b)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="' the terms of reference shall be as complete as possible to make sure that the budget is sufficient for the consultants to perform the expected tasks;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(c)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'the request for proposals shall require the applicants to provide breakdowns of their costs for the different activities with a provision that applicants who decline to provide the breakdown may risk the rejection of their proposals; and'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(d)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'the request for proposals shall state that, following opening of proposals, all proposals that exceed the indicated budget shall be rejected and the applicant who has submitted the highest ranked technical proposal among the rest shall be selected and invited to discuss further details for concluding the contract.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">4-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Criteria for eligibility of consultants.- '"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<ngx-highlight-words
					[textToHighlight]="'The procuring agency shall not hire a consultant for an assignment in which there is possibility of conflict of interest. If a consultant has been engaged by the procuring agency to provide goods or works for a project, it shall be disqualified from providing consulting services for the same project. similarly, a consultant should not be hired for any assignment which by its nature, may be in conflict with another assignment of that consultant.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">5-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Expression of interest.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'A request for expression of interest shall be advertised by giving applicants at least fifteen calendar days for national competition and thirty calendar days for international competition to submit their interest to provide consultancy services.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The expression of interest shall contain at least the following           information, namely:-'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">a)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'the name and address of procuring agency;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">b)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'an appropriate description of the assignment providing scope of the intellectual and professional services required;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">c)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'deadline and place of the submission of the expression of interest; and'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">d)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'evaluation criteria required to be followed.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">6-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Criteria for short-listing of consultants.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'Whenever short listing is deemed necessary, the procuring agency shall predetermine a criteria for short listing. Except for single source, there will normally be a minimum of three consultants in the shortlist, but there is no upper limit for number of candidates to be short listed. However, if less than three candidates apply, their proposals may be considered on merit.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The procuring agency while engaged in short listing of consultants will take into consideration the following factors, namely:-'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(i)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'qualification;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(ii)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'experience;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(iii)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'any other factor that a procuring agency may deem relevant, not inconsistent with these regulations or Public Procurement Rules, 2004.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>				
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(3)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'All applicants shall be informed whether or not they have been short listed.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">7-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Criteria for prequalification of consultants.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'Whether short listing is done or not, the procuring agency may engage in prequalification of consultants in case of complex assignments.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The procuring agency while engaged in prequalification of consultants will take into consideration the following factors, namely:-'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(i)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'qualification;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(ii)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'general experience;'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(iii)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'specific experience'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(iv)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'past performance; and'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(v)</strong>
							<div class="media-body">
								<ngx-highlight-words
									[textToHighlight]="'any other factor that a procuring agency may deem relevant, not inconsistent with these regulations or Public Procurement Rules, 2004.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">8-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Request for proposals.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The procuring agency shall use a request for proposals for seeking proposals from the consultants whether short listed or prequalified or not which shall include the following, namely:-'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(a)</strong>
							<div class="media-body">
								<strong class="font-weight-medium text-black">
									<ngx-highlight-words
										[textToHighlight]="'Letter of Invitation.-'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
								</strong>
								<ngx-highlight-words
									[textToHighlight]="'The letter of invitation shall mention the name and address of the procuring agency and shall state the intention of the procuring agency to enter into a contract for provision of consulting services.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(b)</strong>
							<div class="media-body">
								<strong class="font-weight-medium text-black">
									<ngx-highlight-words
										[textToHighlight]="'Instruction to consultants.-'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
								</strong>
								<ngx-highlight-words
									[textToHighlight]="'The instructions to consultants shall contain all necessary information that would help them prepare responsive proposals and shall bring as much transparency as possible to the selection system.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(c)</strong>
							<div class="media-body">
								<strong class="font-weight-medium text-black">
									<ngx-highlight-words
										[textToHighlight]="'Terms of reference.-'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
								</strong>
								<ngx-highlight-words
									[textToHighlight]="'Terms of reference shall unambiguously define           the objectives, goals and scope of the assignment besides          conditions of contract. Terms of reference shall list the services        and surveys necessary to carry out the assignment and               expected outputs.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(d)</strong>
							<div class="media-body">
								<strong class="font-weight-medium text-black">
									<ngx-highlight-words
										[textToHighlight]="'Evaluation criteria.-'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
								</strong>
								<ngx-highlight-words
									[textToHighlight]="'Except as otherwise provided, the      evaluation of proposals shall be carried out giving due consideration to quality and cost.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(e)</strong>
							<div class="media-body">
								<strong class="font-weight-medium text-black">
									<ngx-highlight-words
										[textToHighlight]="'Type of contract.-'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
								</strong>
								<ngx-highlight-words
									[textToHighlight]="'The procuring agency, depending on the              circumstances, may use one of the following types of contract,               namely:-'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
								<div class="media">			
									<strong class="font-weight-medium text-black mr-2">(i)</strong>
									<div class="media-body">
										<ngx-highlight-words
											[textToHighlight]="' lump sum contract will be used mainly for assignments    in which the content, duration of the services and the required output are unambiguously defined;'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</div>
								</div>
								<div class="media">			
									<strong class="font-weight-medium text-black mr-2">(ii)</strong>
									<div class="media-body">
										<ngx-highlight-words
											[textToHighlight]="'time based contract will be used when it is difficult to         define the scope and the length of services;'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</div>
								</div>
								<div class="media">			
									<strong class="font-weight-medium text-black mr-2">(iii)</strong>
									<div class="media-body">
										<ngx-highlight-words
											[textToHighlight]="'hourly or daily rates will be used for small projects,                             especially when the assignment is for less than a month;            and'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</div>
								</div>
								<div class="media">			
									<strong class="font-weight-medium text-black mr-2">(iv)</strong>
									<div class="media-body">
										<ngx-highlight-words
											[textToHighlight]="' any other contract based on combination of the above and                including out of pocket expenses, where required.'"
											[searchWords]="[searchTerm]"
											highlightClassName="bg-Searchable">
										</ngx-highlight-words>
									</div>
								</div>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(f)</strong>
							<div class="media-body">
								<strong class="font-weight-medium text-black">
									<ngx-highlight-words
										[textToHighlight]="'Proposed contract format.-'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
								</strong>
								<ngx-highlight-words
									[textToHighlight]="'The procuring agencies should               propose a draft specimen contract to be entered into with the        successful bidder.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
						<div class="media">			
							<strong class="font-weight-medium text-black mr-2">(g)</strong>
							<div class="media-body">
								<strong class="font-weight-medium text-black">
									<ngx-highlight-words
										[textToHighlight]="'Special Provisions.-'"
										[searchWords]="[searchTerm]"
										highlightClassName="bg-Searchable">
									</ngx-highlight-words>
								</strong>
								<ngx-highlight-words
									[textToHighlight]="'The procuring agency may specify any other requirement related to the assignment or contract etc. where required.'"
									[searchWords]="[searchTerm]"
									highlightClassName="bg-Searchable">
								</ngx-highlight-words>
							</div>
						</div>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The procuring agency will invite the prospective consultants to submit their technical and financial proposals in separately sealed envelopes. The procuring agency shall give deadline for submission of proposals.  Consultants shall be given adequate time to prepare their proposals which shall be in accordance with response time stipulated under rule 13 of the Public Procurement Rules, 2004.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">9-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Selection Committee.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The procuring agency shall appoint Committee for short listing and selection of consultant. The Committee shall comprise of at least three competent relevant persons to evaluate the consultants as per evaluation criteria. In case of non-availability of in-house expertise the procuring agency may engage outside appropriate sources.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Committee shall advise on short listing and prequalification of consultants.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(3)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'Except for single source selection, the Committee shall evaluate the request for proposals in accordance with clause (b) of rule 36 of the Public Procurement Rules, 2004.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(4)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The bidder whose technical and financial proposal is the most advantageous shall be ranked highest and his bid accepted: Provided that adjustments in the main days of various level of professionals etc. may be negotiated, as per requirement if deemed necessary, without any price or rate negotiations.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">10-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Extent of negotiations or discussions.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'The Committee of the procuring agency may negotiate with the highest ranked bidder regarding methodology, work plan, staffing and special conditions of the contract. The Committee shall not permit substitution of key staff, unless both parties agree that undue delay in selection process makes such substitution unavoidable. In case of failure of negotiations with highest ranked bidder, the Committee may invite the next ranked bidder for such negotiations or discussions. Negotiations are to be carried out by committee and its minutes shall be recorded. Negotiations by a single person Committee shall never be allowed:'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words><br>
				<ngx-highlight-words
					[textToHighlight]="'Provided that negotiations shall not seek changes in the rates quoted by the bidder in accordance with restriction imposed on financial negotiations under Rule 40 of the Public Procurement Rules, 2004.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">11-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Professional liability of consultants.-'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The consultant selected and awarded a contract shall be liable for consequence of errors or omissions on its part. The extent of liability of the consultant should be incorporated in the contract and in no case should it be less than remunerations excluding the out of pocket expenses, nor should the liability exceed twice the remunerations.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The procuring agency may demand insurance on part of the consultant to cover its liability under sub-regulation (I) and necessary costs shall be borne by the consultant which shall be reimbursed by the procuring agency as out of pocket expenses by the consultant.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(3)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The Consultant shall be held liable for all losses or damages suffered by the procuring agency on account of any misconduct and unsatisfactory performance by the Consultant in performing the consulting services.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">12-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Inconsistency'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong><br>
				<ngx-highlight-words
					[textToHighlight]="'The Public Procurement Rules, 2004 and these regulations are to be taken as mutually explanatory of one another but in case of inconsistency, the Public Procurement Rules, 2004 shall take precedence over these regulations.'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</div>
		</div>
		<div class="clear py-3"></div>

		<p class="text-primary font-weight-bold d-flex justify-content-between">
			[F. No.2/1/2009/PPRA-RA.III.]
			<strong class="text-black">
				<ngx-highlight-words
				[textToHighlight]="'MUHAMMAD FAROOQ,'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words><br>
				<ngx-highlight-words
				[textToHighlight]="'Section Officer (RA-III).'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></strong>
		</p>
	</div>

	<h6 class="well font-weight-medium">
        <a  data-toggle="collapse" href="#reg2011" role="button" aria-expanded="false" aria-controls="reg2011" class="d-flex w-100 align-items-center justify-content-between">
            <span>
				<i class="far fa-hand-point-right text-primary mr-2"></i>
				<ngx-highlight-words
					[textToHighlight]="'Regulation 2011'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words></span>
			<i class="fas fa-arrow-down fa-fw text-black-50"></i>
        </a>
    </h6>
	<div class="clearfix collapse" id="reg2011">
		<div class="d-flex justify-content-between">
			<h3 class="text-black">
				<ngx-highlight-words
					[textToHighlight]="'Regulation 2011'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</h3>
			<p>
				<a href="./assets/docs/reg2011-amd.pdf" target="_blank" class="btn btn-sm btn-outline-primary cursor-pointer mr-2">
					<i class="far fa-file-pdf mr-1"></i> Regulation, 2011(Amended-2019)
				</a>
			</p>
		</div>

		<p class="Roboto text-black-50"><ngx-highlight-words
			[textToHighlight]="'Islamabad, the 28th February, 2019'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words></p>
		<h5 class="text-primary font-weight-semi-bold">
			<ngx-highlight-words
				[textToHighlight]="'Notification'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></h5>
		<p><strong><ngx-highlight-words
			[textToHighlight]="'S.R.O. 270(1)/2019 â€“'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words></strong><ngx-highlight-words
			[textToHighlight]="' In exercise of powers conferred by Section 27 of the Public Procurement Regulatory Authority Ordinance, 2002 (No. XXII of 2002), the Public Procurement Authority is pleased to make the following regulation, namely:-'"
			[searchWords]="[searchTerm]"
			highlightClassName="bg-Searchable">
			</ngx-highlight-words>
		</p>

		<div class="media">			
			<strong class="font-weight-semi-bold text-black mr-3">1-</strong>
			<div class="media-body">
				<strong class="font-weight-semi-bold text-black">
					<ngx-highlight-words
						[textToHighlight]="'Short title and commencement. -'"
						[searchWords]="[searchTerm]"
						highlightClassName="bg-Searchable">
					</ngx-highlight-words>
				</strong>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(1)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'The regulations may be called the Public Procurement Regulations, 2011 amended on 7th February 2019.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(2)</strong>
					<div class="media-body">
						<ngx-highlight-words
							[textToHighlight]="'They shall come into force at once'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
				<div class="media">			
					<strong class="font-weight-medium text-black mr-2">(3)</strong>
					<div class="media-body">
						<strong class="font-weight-semi-bold text-black">
							<ngx-highlight-words
								[textToHighlight]="'International and inter-governmental commitments of Federal Government:-'"
								[searchWords]="[searchTerm]"
								highlightClassName="bg-Searchable">
							</ngx-highlight-words>
						</strong><br>
						<ngx-highlight-words
							[textToHighlight]="'Whenever a sponsoring Ministry/Division is of the view that prospective procurement are required to be made by invoking rule-5 of public Procurement Rules, 2004, it shall bring a case to the ECC after undertaking due consultations with the stakeholder Ministries/Divisions/Departments etc. as prescribed in the Rules of Business, 1973. The ECC shall consider such case(s) and authorize, or advise otherwise the sponsoring Ministry/Division whether to proceed or not; in terms of rule-5 of the Public Procurement Rules, 2004:'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words><br>
						<ngx-highlight-words
							[textToHighlight]="'Provided that approval of the ECC shall not be required in case of commitment of the Federal Government arising out of arrangements with international financial institutions or State(s), where finances are provided by them.'"
							[searchWords]="[searchTerm]"
							highlightClassName="bg-Searchable">
						</ngx-highlight-words>
					</div>
				</div>
			</div>
		</div>
		<div class="clear py-2"></div>
		<p class="text-primary font-weight-bold d-flex justify-content-between">
			<span>[<ngx-highlight-words
				[textToHighlight]="'No. 2/1/2010-RA-III/PPRA.'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words>]</span>
			<strong class="text-black">
				<ngx-highlight-words
				[textToHighlight]="'MALIK MOHAMMAD USMAN.'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words>
				<ngx-highlight-words
				[textToHighlight]="'Section officer (RA-III)'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</strong>
		</p>
	</div>
	
	<h6 class="well font-weight-medium">
        <a data-toggle="collapse" href="#reg2015" role="button" aria-expanded="false" aria-controls="reg2015" class="d-flex w-100 align-items-center justify-content-between">
            <span>
				<i class="far fa-hand-point-right text-primary mr-2"></i>
				<ngx-highlight-words
					[textToHighlight]="' Regulation 2015 (Eligible Bidders Tax Compliance)'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</span>
			<i class="fas fa-arrow-down fa-fw text-black-50"></i>
        </a>
    </h6>
	<div class="clearfix collapse text-center mt-2 mb-3" id="reg2015"><i class="far fa-file-pdf text-danger mr-1 cursor-pointer"></i><a href="./assets/docs/reg2015.pdf" target="_blank" class="font-weight-semi-bold text-primary">Regulation, 2015  (Eligible Bidders Tax Compliance)</a></div>
	
	<h6 class="well font-weight-medium">
        <a href="./assets/docs/regppraamd.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span>
				<i class="far fa-hand-point-right text-primary mr-2"></i>
				<ngx-highlight-words
					[textToHighlight]="' Regulation on Transaction of Business Through the Board Meeting of Public Procurement Regulatory'"
					[searchWords]="[searchTerm]"
					highlightClassName="bg-Searchable">
				</ngx-highlight-words>
			</span>
        </a>
    </h6>

	<h6 class="well font-weight-medium">
        <a href="./assets/docs/regppraamd.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i><ngx-highlight-words
				[textToHighlight]="' Authority (S.R.O.15(I)/2021 and 1224(I)/2021)'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></span>
        </a>
    </h6>

	<h6 class="well font-weight-medium">
        <a href="./assets/docs/sro1717.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i><ngx-highlight-words
				[textToHighlight]="' Regulations for Procedure of filing and disposal of Review Petition under Rule 19(3), 2021 and Redressal of Grievances Regulations (S.R.O.90(I)/2022).'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></span>
        </a>
    </h6>

	<h6 class="well font-weight-medium">
        <a href="./assets/docs/sro370.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i><ngx-highlight-words
				[textToHighlight]="' Regulations for National Standard Procurement Documents (S.R.O.370(I)/2022).'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></span>
        </a>
    </h6>

	<h6 class="well font-weight-medium">
        <a href="./assets/docs/sro591.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i>
				<ngx-highlight-words
				[textToHighlight]="' Regulations for Manner of Advertisement (S.R.O 591(I)/2022).'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></span>
        </a>
    </h6>

	<h6 class="well font-weight-medium">
        <a href="./assets/docs/sro592.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i>
				<ngx-highlight-words
				[textToHighlight]="' Regulations for Declaration of Beneficial Owners (S.R.O 592(I)/2022).'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></span>
        </a>
    </h6>

	<h6 class="well font-weight-medium">
        <a href="./assets/docs/epronotif1.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i>
				<ngx-highlight-words
				[textToHighlight]="' E-Pak Procurement Regulation (S.R.O 296(I)/2023).'"
				[searchWords]="[searchTerm]"
				highlightClassName="bg-Searchable">
				</ngx-highlight-words></span>
        </a>
    </h6>

	</section>
<div class="clear py-3"></div>