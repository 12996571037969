import { Component, OnInit } from '@angular/core';
import { listType } from 'src/app/core/enumerations/enum-ListType';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { payRequestModel } from 'src/app/core/models/payRequestModel';
import { ActiveBlackListedRequestModel } from 'src/app/core/models/active-black-listed-request-model.model';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import { saveAs } from 'file-saver';
import b64toBlob from 'b64-to-blob';

@Component({
  selector: 'app-pepra-active-black-listed-firms',
  templateUrl: './pepra-active-black-listed-firms.component.html',
  styleUrls: ['./pepra-active-black-listed-firms.component.scss']
})
export class PepraActiveBlackListedFirmsComponent implements OnInit {

  //payRequestModel: ActiveBlackListedRequestModel;
  paginationModel: PaginationModel;
  payRequestModel: payRequestModel;
  loggedInUser: any;
  selectedSort: any;
  Records: any;
  totalRecords: any;
  totalPages: number;
  pages = new Array();
  PageNo: number = 0;
  downloadFile=new  downloadfile();
  PN: number;
  ID: number = 0;
  Sector: any;
  DropdownRecords: any
  selectedValue: any
  isSelected: boolean;
  selectedStateID: any;
  ClosingDate: any;
  TenderNumber: any;

  constructor(public userUtilsService:UserUtilService, private service: PublicPortalService, private portalService:PublicPortalService,) { }

  ngOnInit(): void {
    this.paginationModel = new PaginationModel();
    this.payRequestModel = new payRequestModel();
    this.PageNo = Number(this.paginationModel.pageNumber);
    this.GetPayDetailList()
  }
  GetPayDetailList() {

    this.payRequestModel.Type = listType.ActiveBlackListed;
    this.payRequestModel.ID = 0;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              let data = (baseResponse.data as any);
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }
              this.Records = data?.records;
            }
          }
        }
      })
  }
  changePage(pageNumber) {

    this.paginationModel.pageNumber = pageNumber;
    this.PageNo = Number(pageNumber);
    this.PN = this.PageNo;
    this.GetPayDetailList();
  }
  previousPage() {
    if (Number(this.paginationModel.pageNumber) > 1) {
      this.changePage(Number(this.paginationModel.pageNumber) - 1);
    }
  }
  nextPage() {
    if (Number(this.paginationModel.pageNumber) < this.totalPages) {
      this.changePage(Number(this.paginationModel.pageNumber) + 1);
    }
  }
  
  selectedPage() {
    if(this.PN==null || this.PN==0){
      this.PN=1
    }
    this.paginationModel.pageNumber = String(this.PN);
    this.PageNo = this.PN;
    this.GetPayDetailList();
  }

  onEnterKeyPressed(event: KeyboardEvent): void {
    // Check if the key pressed is Enter

    if (event.key === 'Enter') {
      // Call your function here
      this.selectedPage()
    }
  }
  sort(orderByColumnName, orderBy){
    this.paginationModel.orderBy = orderBy;
    this.payRequestModel.pagination.orderByColumnName = orderByColumnName;
    this.paginationModel.orderByColumnName = orderByColumnName
    this.GetPayDetailList()  
  }
}
