import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
@Component({
  selector: 'app-evaluation-report-fppra',
  templateUrl: './evaluation-report-fppra.component.html',
  styleUrls: ['./evaluation-report-fppra.component.scss']
})
export class EvaluationReportFppraComponent implements OnInit {
 biddingProcessInfo
    data: any;
    mostAdvantigiousBidder: string;
    reportRecord:any;
    finalEvaluationReportForm: FormGroup;
  constructor(
    private PublicPortalService: PublicPortalService,
    private toasterService: ToastrService,
    private userUtilsService : UserUtilService,
  ) { }
  isVisible=false;
  itemwiseTableData:any;
  supplierRecord:any;
  lowestBidderList:any;
  @Output() pdfData = new EventEmitter<any>();
  @Input() viewAble:boolean;
  @Input() publishedDocumentID:number;
  @Input() fromEContract:boolean=false;
  @Input() FinalEvaluation:boolean=false;
  @Input() reportData:any;
  @Input() supplierID;
  @Input() hasProcess:boolean = true;
  @Input() lotNo:number = 1;
  evaluationHeading :string;
  ngOnInit() {
    this.biddingProcessInfo = JSON.parse(sessionStorage.getItem("BasicInfo"));

    this.data=this.reportData.tableData;
    this.lowestBidderList=this.supplierID===0?
    this.reportData.tableData.filter(x=>x.loiResponse==='Accepted'):
    this.reportData.tableData.filter(x=>x.loiResponse==='Accepted' && x.supplierID===this.supplierID);
    this.mostAdvantigiousBidder=this.data.length>0?this.data[0].mostAdvantigiousBidder:"";
    this.reportRecord=this.reportData.finalEvaluationRecord;
    this.itemwiseTableData=this.reportData.itemsRecord;
    this.evaluationHeading=this.FinalEvaluation?"FINAL":"Technical";
    this.getAll(this.publishedDocumentID)
}
  getAll(docId:number) {
    this.PublicPortalService.geteFinalEvaluationReport(docId,this.FinalEvaluation,this.supplierID).subscribe((baseResponse: any) => {
      if (baseResponse.success) {
        this.data=baseResponse.data.tableData;
        this.mostAdvantigiousBidder=this.data.length>0?this.data[0].mostAdvantigiousBidder:"";
        this.reportRecord=baseResponse.data.finalEvaluationRecord;
        this.setFormsValues(this.reportRecord)
      } else {
        if (baseResponse.errors.length > 0) {
          for (var i = 0; i < baseResponse.errors.length; i++) {
            this.toasterService.error(baseResponse.errors[i].errorMessage);
          }
          return;
        }
      }
    })
  }

  isCenter: boolean = false;
  generatePDF() {
    // if(this.data[0]?.loiResponse=='Rejected' && this.FinalEvaluation){
    //     this.toasterService.error("Supplier didn't responded the LOI Yet");
    //     return;
    // }
    if(!this.hasProcess){
      this.hasProcess = true;
      const element = document.getElementById('pdfContent');

      const options = {
          margin: [20, 20, 20, 20],};
          var report= html2pdf()
          .set(options)
          .from(element);
            const pdfBlob = new Blob([report.output('blob')], { type: 'application/pdf' });
            const pdfFile = new File([pdfBlob], 'FinalEvaluationReport.pdf', { type: 'application/pdf' });


            const fileList = [pdfFile] as any;
            fileList[0] = pdfFile;
          this.pdfData.emit(fileList);
    }
  }
  downloadReport(){
    this.isCenter = true;
    const element = document.getElementById('pdfContent');
    const options = {
        margin: [20, 20, 20, 20],
        format:'A4'
    };
        var report= html2pdf()
        .set(options)
        .from(element).save("FinalEvaluationReport.pdf").then(() => {
            this.isCenter = false;
        });

  }
  setFormsValues(obj:any){

    if(obj !=null){
      let formObj={
        pPRARefNo:obj.ppraRefNo,
        tenderInquiryNo:obj.tenderInquiryNo,
        detailsForBidEvaluation:obj.detailsBidEvaluation
    }
    this.finalEvaluationReportForm.setValue(formObj)
    }
  }

}
