<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>PPRA Query Reply</h1>
	</div>
</section>
<div class="clear py-4"></div>
<section class="container">
    <div class="row">
        <div class="col-lg-4 m-auto text-center">
            <img src="./assets/images/ud.png" class="img-center">
        </div>
    </div>
</section>
<div class="clear py-3"></div>