import { BaseRequestModel } from "./BaseModel/BaseRequestModel";
import { PaginationModel } from "./pagination-model";

export class GetPayRequestModel extends BaseRequestModel{
    Type:string;
    ID:number=0;
    City?:string;
    TenderNumber?:string;
    TenderWorth?:string;
    Sector?:string;
    Organization?:string;
    TypeID?:string;
    Date:Date;
    pagination:PaginationModel;
}