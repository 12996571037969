import { Component, OnInit } from '@angular/core';
import b64toBlob from 'b64-to-blob';
import { BaseRequestModel } from 'src/app/core/models/BaseModel/BaseRequestModel';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import { saveAs } from 'file-saver';
import { GetPayRequestModel } from 'src/app/core/models/GetPayRequestModel';
import { listType } from 'src/app/core/enumerations/enum-ListType';
import { PaginationModel } from 'src/app/core/models/pagination-model';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  allSupplierCount: any;
  payRequestModel: GetPayRequestModel;
  paginationModel: PaginationModel;
  NonConsultingServicesCount: any;
  GoodsCount: any;
  WorksCount: any;
  ConsultingServicesCount: any;
  AllCount: any;
  PortalNewsAndAnnouncements: any;
  downloadFile: downloadfile;
  byteImage: any
  FileGUID: any;
  AttachmentId: any;
  currentDate=new Date()
  byteImage1: string;
  listByteImage: any[];
  arrayList: any[] = [];
  UrlLink: any;
  totalPages: number;
  totalRecords: any;
  pages: any[];
  Records: any;
  constructor(public userUtilsService:UserUtilService,
    public portalService:PublicPortalService) {}

  ngOnInit(): void {
    this.listByteImage=[]
    this.arrayList=[]
    this.downloadFile = new downloadfile();
    this.paginationModel = new PaginationModel();
    this.payRequestModel = new GetPayRequestModel();
    this.GetPayDetailList()
    this.getSuppliersDashboardCount()
    this.GetPortalNewsAndAnnouncements()
  }
  GetPayDetailList() {

    this.payRequestModel.Type = listType.TendersCount;
    this.payRequestModel.ID = 0;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.portalService.GetPayDetailListTenderEIOPQ(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              let data = (baseResponse.data as any);
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }              
              this.Records = data?.records;
            }
          }
        }
      })
  }
  getSuppliersDashboardCount(){
    this.portalService.GetPPRRASupplierCount()
    .subscribe(baseResponse => {
      if (baseResponse != null) {
        if (baseResponse.success != null) {
          if (baseResponse.success) {
                this.allSupplierCount = baseResponse?.data                
                this.AllCount = this.allSupplierCount[0].AllCount              
                this.NonConsultingServicesCount = this.allSupplierCount[0].NonConsultingServicesCount              
                this.GoodsCount = this.allSupplierCount[0].GoodsCount              
                this.WorksCount = this.allSupplierCount[0].WorksCount              
                this.ConsultingServicesCount = this.allSupplierCount[0].ConsultingServicesCount              
          }
        }

      }

    })
  }
  
  GetPortalNewsAndAnnouncements(){
    this.portalService.GetPortalNewsAndAnnouncements()
    .subscribe(baseResponse => {
      if (baseResponse != null) {
        if (baseResponse.success != null) {
          if (baseResponse.success) {
                this.PortalNewsAndAnnouncements = baseResponse?.data
                for (let i = 0; i < this.PortalNewsAndAnnouncements.length; i++) {
                  this.FileGUID = this.PortalNewsAndAnnouncements[i].FileGuid;
                  this.AttachmentId = this.PortalNewsAndAnnouncements[i].AttachmentId;
                  this.UrlLink = this.PortalNewsAndAnnouncements[i].UrlLink;
                  this.getAttachmentByID(i,this.AttachmentId,this.FileGUID,this.UrlLink)
                       
                }
              //   this.PortalNewsAndAnnouncements.forEach(item => {
              //     this.FileGUID = item.FileGuid;
              //     this.AttachmentId = item.AttachmentId;
              //     this.getAttachmentByID(this.AttachmentId, this.FileGUID);
              // });
                                   
          }
        }

      }

    })
  }
  getAttachmentByID(i,Id: any,Guid:any,Url:any) {
    if(Id == 0 || Id==null){
      return;
    }
    this.downloadFile.ID = Id;
    this.downloadFile.idsList = Guid;
    this.portalService.DownloadFileByGuid(this.downloadFile).subscribe(baseResponse => {
      if (baseResponse.success && baseResponse.data != null) {
        var Bytes=''
        Bytes = baseResponse.data['bytes'];
        var BytesData="data:image/png;base64,"+ Bytes;
        this.arrayList.push({Url , BytesData });
      }
    });
  }
}
