
<diV class="grid my-3" >
    <div class="mx-1">
     <button pButton class="btn btn-sm btn-outline-primary"  (click)="generatePDF()"><i class="fas fa-download mr-1"></i>Generate PDF</button>
    </div>

 </diV>
<div id="pdfContent" style="padding: 10px;">
    <h3 class="reportHeading my-3" style="text-align: center;">
        SINDH PUBLIC PROCUREMENT REGULATORY AUTHORITY
    </h3>
    <div class="bordr my-3">
      <h4 class="my-1">
        CONTRACT EVALUATION FORM
      </h4>
    </div>

    <h4 class="reportHeading my-3">
        TO BE FILLED IN BY ALL PROCURING AGENCIES FOR PUBLIC CONTRACTS OF WORKS, SERVICES & GOODS WORTH 50 MILLION (PKR) OR ABOVE
    </h4>

    <div style="margin-top: 5%;">
        <ol class="custom-list grid">
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6">NAME OF THE ORGANIZATION / DEPTT. </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.precuringAgency}}</span>
                </div>
            </li>

            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6">PROVINCIAL / LOCAL GOVT./ OTHER </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.fundingType}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6">TITLE OF CONTRACT</span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.contractName}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6">TENDER NUMBER </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.tenderNumber}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6">BRIEF DESCRIPTION OF CONTRACT </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.needOfProcurement}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> FORUM THAT APPROVED THE SCHEME </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.forum}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> TENDER ESTIMATED VALUE </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.contractAndPrice}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> ENGINEER’S ESTIMATE </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.contractAndPrice}}</span>
                    (For civil works only)
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> ESTIMATED COMPLETION PERIOD (AS PER CONTRACT) </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">------------------------</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> TENDER OPENED ON (DATE & TIME) </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">------------------------</span>
                </div>
            </li>

            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> NUMBER OF TENDER DOCUMENTS SOLD </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.documentSold}}</span>
                    (Attach list of buyers)
                </div>
            </li>

            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> NUMBER OF BIDS RECEIVED </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.totalBidders}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6">NUMBER OF BIDDERS PRESENT AT THE TIME OF OPENING OF </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.totalBidders}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6">BID EVALUATION REPORT </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">------------------------</span>
                    (Enclose a copy)
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> NAME AND ADDRESS OF THE SUCCESSFUL BIDDER </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.nameAndAddressOfBidder}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> CONTRACT AWARD PRICE</span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.contractAndPrice}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6">RANKING OF SUCCESSFUL BIDDER IN EVALUATION REPORT(i.e. 1st, 2nd, 3rd EVALUATION BID).</span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">1st</span>
                </div>
            </li>
            <li class="w-full my-3">
                <div class="grid w-full">
                    <span class="md:col-6"> METHOD OF PROCUREMENT USED : - (Tick one)</span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">------------------------</span>
                </div>
                <ol type="a" start="a">
                    <li class="w-full">
                        <div class="grid w-full">
                         <span class="md:col-6"> SINGLE STAGE – ONE ENVELOPE PROCEDURE  :</span>
                         <span  class="md:col-6" style="border-bottom:#000 1px;"><input *ngIf="data?.method=='SSSE'" type="checkbox" name="ssse"  checked></span>
                        </div>
                    </li>
                    <li class="w-full">
                        <div class="grid w-full">
                         <span class="md:col-6"> SINGLE STAGE – TWO ENVELOPE PROCEDURE  :</span>
                         <span  class="md:col-6" style="border-bottom:#000 1px;"><input *ngIf="data?.method=='SSTE'" type="checkbox" name="sste" checked></span>
                        </div>
                    </li>
                    <li class="w-full">
                        <div class="grid w-full">
                         <span class="md:col-6"> TWO STAGE BIDDING PROCEDURE  :</span>
                         <span  class="md:col-6" style="border-bottom:#000 1px;"><input *ngIf="data?.method=='TSBP'" type="checkbox" name="tsbp" checked></span>
                        </div>
                    </li>
                    <li class="w-full">
                        <div class="grid w-full">
                         <span class="md:col-6"> TWO STAGE – TWO ENVELOPE BIDDING PROCEDURE : </span>
                         <span  class="md:col-6" style="border-bottom:#000 1px;"><input *ngIf="data?.method=='TSTE'" type="checkbox" name="tste" checked></span>
                        </div>
                    </li>
                </ol>
                  <div>
                     -  PLEASE SPECIFY IF ANY OTHER METHOD OF PROCUREMENT WAS ADOPTED i.e. EMERGENCY, DIRECT CONTRACTING / NEGOTIATION ETC. WITH BRIEF REASONS:
                  </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> APPROVING AUTHORITY FOR AWARD OF CONTRACT</span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.precuringAgency}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> WHETHER THE PROCUREMENT WAS INCLUDED IN ANNUAL PROCUREMENT PLAN?</span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.procurementIncluded}}
                    </span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> ADVERTISEMENT :</span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.advertisement}}</span>
                </div>
                <ol type="i">
                    <li class="w-full">
                        <div class="grid w-full">
                            <span class="md:col-6"> SPPRA Website </span>
                            <span  class="md:col-6" style="border-bottom:#000 1px;">----------------------- </span>
                            (If yes, give date and SPPRA Identification No.)
                        </div>
                     </li>
                    <li class="w-full">
                        <div class="grid w-full">
                            <span class="md:col-6"> News Papers </span>
                            <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.NewsPaper}} </span>
                            (If yes, give names of newspapers and dates)
                        </div>
                     </li>
                </ol>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> NATURE OF CONTRACT </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.natureOfContract}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> WHETHER QUALIFICATION CRITERIA WAS INCLUDED IN BIDDING / TENDER DOCUMENTS? </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">------------------------</span>
                    (If yes, enclose a copy)
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6">  WHETHER BID EVALUATION CRITERIA WAS INCLUDED IN BIDDING / TENDER DOCUMENTS?  </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">---------------------------------</span>
                    (If yes, enclose a copy)
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> WHETHER APPROVAL OF COMPETENT AUTHORITY WAS OBTAINED FOR USING A METHOD OTHER THAN OPEN COMPETITIVE BIDDING? </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">----------------------------------</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6">  WAS BID SECURITY OBTAINED FROM ALL THE BIDDERS?</span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.BidSecurityObtained}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> WHETHER THE SUCCESSFUL BID WAS LOWEST EVALUATED BID / BEST EVALUATED BID (in case of Consultancies) </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.lowestSuccessfulBidder}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> WHETHER THE SUCCESSFUL BIDDER WAS TECHNICALLY COMPLIANT? </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.technicallyCompliantSuccessfulBidder}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> WHETHER NAMES OF THE BIDDERS AND THEIR QUOTED PRICES WERE READ OUT AT THE TIME OF OPENING OF BIDS? </span>
                    <span  class="md:col-6" style="border-bottom:#000 1px;">{{data?.priceReadOutLoud}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> WHETHER EVALUATION REPORT GIVEN TO BIDDERS BEFORE THE AWARD OF CONTRACT?  </span>
                    <span class="md:col-6" style="border-bottom:#000 1px;">{{data?.evaluationReportGiven}}</span>
                    (Attach copy of the bid evaluation report)
                </div>
            </li>



            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> ANY COMPLAINTS RECEIVED   </span>
                    <span class="md:col-6" style="border-bottom:#000 1px;">{{data?.complaints}}</span>
                    (If yes, result thereof)
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> ANY DEVIATION FROM SPECIFICATIONS GIVEN IN THE TENDER NOTICE / DOCUMENTS   </span>
                    <span class="md:col-6" style="border-bottom:#000 1px;">---------------------------------</span>
                    (If yes, give details)
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6">WAS THE EXTENSION MADE IN RESPONSE TIME?  </span>
                    <span class="md:col-6" style="border-bottom:#000 1px;">{{data?.extension}}</span>
                    (If yes, give detailed reasons.)
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6">DEVIATION FROM QUALIFICATION CRITERIA  </span>
                    <span class="md:col-6" style="border-bottom:#000 1px;">---------------------------------</span>
                    (If yes, give detailed reasons.)
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> WAS IT ASSURED BY THE PROCURING AGENCY THAT THE SELECTED FIRM IS NOT BLACK LISTED? </span>
                    <span class="md:col-6" style="border-bottom:#000 1px;">{{data?.blackList}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6"> WAS A VISIT MADE BY ANY OFFICER/OFFICIAL OF THE PROCURING AGENCY TO THE SUPPLIER’S PREMISES IN CONNECTION WITH THE PROCUREMENT? IF SO, DETAILS TO BE ASCERTAINED REGARDING FINANCING OF VISIT, IF ABROAD:  </span>
                    <span class="md:col-6" style="border-bottom:#000 1px;">{{data?.visit}}</span>
                    (If yes, enclose a copy)
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6">WERE PROPER SAFEGUARDS PROVIDED ON MOBILIZATION ADVANCE PAYMENT IN THE CONTRACT (BANK GUARANTEE ETC.)?</span>
                    <span class="md:col-6" style="border-bottom:#000 1px;">{{data?.safeGaurd}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-6">  SPECIAL CONDITIONS, IF ANY   </span>
                    <span class="md:col-6" style="border-bottom:#000 1px;">{{data?.specialCondition}}</span>
                    (If yes, give Brief Description)
                </div>
            </li>
        </ol>
    </div>

    <div style="margin-top: 7%;">
        <strong>Signature & Official Stamp of</strong><br/>
        <p style=" margin-left: 20px;"><strong>Authorized Officer <span>------------------------</span></strong></p>
    </div>
    <h4 class="reportHeading1">
       FOR OFFICE USE ONLY
    </h4>


    <div class="address">
        <p class="reportHeading2">SPPRA, Block. No.8, Sindh Secretariat No.4-A, Court Road, Karachi</p>
        <p class="heading">Tele: 021-9205356; 021-9205369 && Fax: 021-9206291</p>
    </div>
</div>
