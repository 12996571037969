<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>Download</h1>
	</div>
</section>
<div class="clear py-3"></div>
<section class="container">
    <h3 class="pb-3">PPRA Documents</h3>
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/ddcom.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Feedback/Comments on Draft Disposal of Public Asset Regulations, 2023</span>
        </a>
    </h6>
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/epronotif.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> E-Pak-Procurement Regulations 2023.</span>
        </a>
    </h6>

    <h6 class="well font-weight-medium">
        <a href="./assets/docs/rule23.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Instruction Regarding Publication and Timely Provision Of Bidding Documents to the Prospective Bidders (Rule-23).</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/rule47.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Instruction Regarding Violation of Rule 43 and 45 of PPRA with Respect to Payment to the Contractors and Suppliers.</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/sbdins.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Instructions Regarding Use of Standard Bidding Documents.</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/sro700.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> PPRA National Standard Bidding Documents (Therapeutic Goods -Pharmaceuticals)(S.R.O 700(I)/2022).</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/sro370.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> PPRA National Standard Bidding Documents (General Goods, Consultancy Services and Closed Framework Agreements) (S.R.O 370(I)/2022).</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="https://ppra.org.pk/sbd.asp" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> PPRA National Standard Bidding Documents.</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/sro591.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Regulations for Manner of Advertisement (S.R.O 591(I)/2022).</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/sro592.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Regulations for Declaration of Beneficial Owners (S.R.O 592(I)/2022)</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/mousecp.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Memorandum of Understanding (MOU) Between Securities and Exchange Commission of Pakistan (SECP) and Public Procurement Regulatory Authority (PPRA)</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/grc.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> General Instruction for Constitution of Grievance Redressal Committee(s) in terms of Rule 48(1) of Public Procurement Rules, 2004</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/generalins.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> General Instruction Regarding Procurement of Furniture and Fixture</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/sro1717.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Regulations for Procedure of filing and disposal of Review Petition under Rule 19(3), 2021 and Redressal of Grievances Regulations 2021</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="#" data-toggle="modal" data-target="#sbd" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> PPRA Standard Bidding Documents for Procurement of General Goods, Closed Frame Work Agreements and Consultancy Services</span>
        </a>
    </h6>
    <!-- Modal -->
    <div class="modal fade" id="sbd" tabindex="-1" role="dialog" aria-labelledby="sbdLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="font-weight-semi-bold">PPRA STANDARD BIDDING DOCUMENTS (SBDs)</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p class="font-weight-medium">In pursuance of Section 27 of PPRA Ordinance, 2002 read with Rule 23 of Public Procurement Rules, 2004, Public Procurement Regulatory Authority has been developed  following Standard Bidding Documents (SBDs).</p>
                    <h6 class="font-weight-semi-bold"><a href="./assets/docs/sro700.pdf" target="_blank">PPRA National Standard Bidding Document (Therapeutic Goods -Pharmaceuticals) (S.R.O 700(I)/2022).</a></h6>
                    <h6 class="font-weight-semi-bold"><a href="./assets/docs/sro370.pdf" target="_blank">PPRA National Standard Bidding Documents (General Goods, Consultancy Services and Closed Framework Agreements) (S.R.O 370(I)/2022).</a></h6>
                    <ul class="pt-2">
                        <li><a href="./assets/docs/sbdgoods.pdf" target="_blank">National Standard Bidding Document for Procurement of  General Goods [PDF]</a>; <a href="./assets/docs/sbdgoods.doc">[MS Word]</a></li>
                        <li><a href="./assets/docs/sbdrfp.pdf" target="_blank">National Request for Proposal Document for Procurement of Consultancy Services [PDF]</a>; <a href="./assets/docs/sbdrfp.doc">[MS Word]</a></li>
                        <li><a href="./assets/docs/sbdcf.pdf" target="_blank">National Standard Procurement Document for Procurement of Good through Closed Framework Agreements [PDF]</a>; <a href="./assets/docs/sbdcf.doc">[MS Word]</a></li>
                        <li><a href="./assets/docs/sbdtg.pdf" target="_blank">National Standard Bidding Document for Procurement of Therapeutic Goods (Pharmaceuticals) [PDF]</a>; <a href="./assets/docs/sbdtg.doc">[MS Word]</a></li>
                        <li><a href="./assets/docs/sbdinfo.pdf" target="_blank">National Standard Bidding Document for Procurement of Information System (Supply and Installation) [PDF]</a>; <a href="./assets/docs/sbdinfo.doc">[MS Word]</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/mdcontract.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Publication of Contract Awards on PPRA's Website Without Any Reference to the Amount of Contract.</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/drap.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Procurement of Drugs which are Registered and Approved by Drug Regulatory Authority of Pakistan (DRAP).</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/general1.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> General Clarification Regarding Award of Contract to Second Most Advantageous Bidder</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/regppraamd.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Regulation on Transaction of Business Through the Board Meeting of Public Procurement Regulatory Authority</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/exp26.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Explanation of Rule 26 Regarding Extension of Bid Validity Period</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/exp-r2.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Explanation of Rule 2(1)(h) Regarding Most Advantageous Bid</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/sopexp1.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Standard Operating Procedures for Exemption Cases Under Section 21 of the PPRA Ordinance, 2002</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/bl1.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Blacklisting / Debarment by International Organizations</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/tenderfee.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Tender, Evaluation Results, Procurement Contract and training fee notification</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="h./assets/docs/complaintfee.jpg" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Complaint Handling and Review Mechanism fee notification</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/bl2.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Determining of Modalities to invoke the clause-77.1 of the particular conditions of the contract prescribed in PEC bidding documents for civil works</span>
        </a>
    </h6>
    
    <h3>Standard Bidding Documents SBDs for Works</h3>
    <p class="pb-2">PPRA has allowed use of SBDs of the Pakistan Engineering Council for "works" only vide <a [routerLink]="['/documents/regulations']">PP Regulations 2008 SRO No.805(I)/2008.</a> Following SBDs of PEC shall be used for works.</p>
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/pec/civilwork.doc" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Standard Form of Bidding Documents (Civil Works)</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/pec/em.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Standard Form of Tender Documents for Procurement of Works(E&M)</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/pec/SmallerContracts.doc" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Standard Form of Tender Documents for Procurement of Works (For Smaller Contracts)</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/pec/ConsultancyServices-TimeBasedAssigments.doc" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Standard Form of Contract for Engineering Consultancy Services (For Large Projects) Time Based Assignments</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/pec/ConsultancyServices-LumpSumAssignment.doc" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Standard Form of Contract for Engineering Consultancy Services (For Large Projects) Lump Sum Assignments</span>
        </a>
    </h6>
    
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/pec/ConsultancyServicesForSmallerProjects.doc" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Standard Form of Contract for Engineering Consultancy Services (For Smaller Projects)</span>
        </a>
    </h6>

    <h3 class="pb-3">Standard Formats/Sample</h3>
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/s1.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> To be used for Goods, Works and Non-consulting Services</span>
        </a>
    </h6>

    <h6 class="well font-weight-medium">
        <a href="./assets/docs/s2.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> To be used for Request for Expression of Interest (EOI)</span>
        </a>
    </h6>

    <h6 class="well font-weight-medium">
        <a href="./assets/docs/s3.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> To be used for Proposals for Prequalification</span>
        </a>
    </h6>

    <h3 class="pb-3">Forms</h3>
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/elvform50.doc" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> To be used for all public contracts of works, services goods worth Rs.50 m or mores</span>
        </a>
    </h6>

    <h6 class="well font-weight-medium">
        <a href="./assets/docs/elvform.doc" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> To be used for all public contracts of works, services & goods worth one (1) billion or more</span>
        </a>
    </h6>

    <h6 class="well font-weight-medium">
        <a href="./assets/docs/certificates.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Form integrity pact for all contract of Rs. 10 million or more</span>
        </a>
    </h6>


    <h3 class="pb-3">Sindh PPRA Documents</h3>
    <h6 class="well font-weight-medium">
        <a href="http://www.pprasindh.gov.pk/downloads.php" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Sindh Public Procurement Rules, Regulations, Standard bidding documents</span>
        </a>
    </h6>

    <h3 class="pb-3">Punjab PPRA Documents</h3>
    <h6 class="well font-weight-medium">
        <a href="https://ppra.punjab.gov.pk/system/files/PUNJAB%20PROCUREMENT%20REGULATORY%20AUTHORITY%20%28AMENDMENT%29%20ORDINANCE%202015.doc_2.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Punjab Public Procurement Act</span>
        </a>
    </h6>

    <h6 class="well font-weight-medium">
        <a href="https://ppra.punjab.gov.pk/system/files/Final%20Notified%20PPR-2014%20%28ammended%20upto%2006.01.2016%29_0.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Punjab Public Procurement Rules</span>
        </a>
    </h6>

    <h3 class="pb-3">Khyber Pakhtunkhwa (KP) PPRA Documents</h3>
    <h6 class="well font-weight-medium">
        <a href="http://www.kppra.gov.pk/index.php/act/show" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> KP Public Procurement Act</span>
        </a>
    </h6>

    <h6 class="well font-weight-medium">
        <a href="http://www.kppra.gov.pk/index.php/rules/show" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> KP Public Procurement Rules</span>
        </a>
    </h6> 
    
    <h3 class="pb-3">SRO/Ordinance</h3>
    <h6 class="well font-weight-medium">
        <a href="./assets/docs/sro827.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> S.R.O 827(I)/2001 (import of engineering goods)</span>
        </a>
    </h6>

    <h6 class="well font-weight-medium">
        <a href="./assets/docs/sro660.pdf" target="_blank" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> S.R.O 660(I)/2002 (Amendments in Import of engineering goods)</span>
        </a>
    </h6>

    <h6 class="well font-weight-medium">
        <a href="./assets/docs/freedom_of_information_notification.doc" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> S.R.O. 514(1)/2004 (Freedom of information rules, 2004)</span>
        </a>
    </h6>

    <h6 class="well font-weight-medium">
        <a href="./assets/docs/public_investment.doc" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> The Public Investment (Financial Safeguard), Ordinance 1960</span>
        </a>
    </h6>

    <h6 class="well font-weight-medium">
        <a href="./assets/docs/escalationofpricesofsteel.doc" class="d-flex w-100 justify-content-between">
            <span><i class="far fa-hand-point-right text-primary mr-2"></i> Escalation in Prices of Steel-Planning and Development Division</span>
        </a>
    </h6>
</section>
<div class="clear py-3"></div>