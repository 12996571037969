import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingsComponent } from './components/trainings/trainings.component';
import { PublicationsComponent } from './components/publications/publications.component';
import { PolicyGuidelinesComponent } from './components/policy-guidelines/policy-guidelines.component';
import { RouterModule } from '@angular/router';
import { TendersComponent } from '../tenders/tenders/tenders.component';
import { EpadTraningsComponent } from './components/epad-tranings/epad-tranings.component';
import { ProcurementCodeComponent } from './components/procurement-code/procurement-code.component';
import { OnlineQueryComponent } from './components/online-query/online-query.component';
import { QueryReplyComponent } from './components/query-reply/query-reply.component';
import { FAQsComponent } from './components/faqs/faqs.component';
import { BoardComponent } from './components/board/board.component';
import { RegisterSuppliersComponent } from './components/register-suppliers/register-suppliers.component';
import { DownloadsComponent } from './components/downloads/downloads.component';
import { ActiveBlackListedFirmsComponent } from './components/active-black-listed-firms/active-black-listed-firms.component';
import { ArchiveDeListedBlackListedFirmsComponent } from './components/archive-de-listed-black-listed-firms/archive-de-listed-black-listed-firms.component';
import { ProcurementPlanComponent } from './components/procurement-plan/procurement-plan.component';
import { EvaluationResultsComponent } from './components/evaluation-results/evaluation-results.component';
import { ProcurementContractsComponent } from './components/procurement-contracts/procurement-contracts.component';
import { GrievancesRedressalCommitteesComponent } from './components/grievances-redressal-committees/grievances-redressal-committees.component';
import { LawActsComponent } from './components/law-acts/law-acts.component';
import { TenderGuidelinesComponent } from './components/tender-guidelines/tender-guidelines.component';
import { FormsModule } from '@angular/forms';
import { PepraActiveBlackListedFirmsComponent } from './components/pepra-active-black-listed-firms/pepra-active-black-listed-firms.component';
import { SharedModule } from '../shared/shared.module';
import { TendersModule } from '../tenders/tenders.module';




@NgModule({
  declarations: [
    TrainingsComponent,
    PublicationsComponent,
    PolicyGuidelinesComponent,
    EpadTraningsComponent,
    ProcurementCodeComponent,
    OnlineQueryComponent,
    QueryReplyComponent,
    FAQsComponent,
    BoardComponent,
    RegisterSuppliersComponent,
    DownloadsComponent,
    ActiveBlackListedFirmsComponent,
    ArchiveDeListedBlackListedFirmsComponent,
    PepraActiveBlackListedFirmsComponent,
    ProcurementPlanComponent,
    EvaluationResultsComponent,
    ProcurementContractsComponent,
    GrievancesRedressalCommitteesComponent,
    LawActsComponent,
    TenderGuidelinesComponent,
    
  ],
  imports: [
    FormsModule,
    TendersModule,
    RouterModule.forChild([
      {
        path: 'other/training',
        component: TrainingsComponent,
      },
      {
        path: 'other/epad-trainings',
        component: EpadTraningsComponent,
      },
      {
        path: 'other/policy-guidelines',
        component: PolicyGuidelinesComponent,
      },
      {
        path: 'other/publications',
        component: PublicationsComponent,
      },
      {
        path: 'other/procurement-code',
        component: ProcurementCodeComponent,
      },
      {
        path: 'other/online-query',
        component: OnlineQueryComponent,
      },
      {
        path: 'other/online-reply',
        component: QueryReplyComponent,
      },
      {
        path: 'other/FAQ',
        component: FAQsComponent,
      },
      {
        path: 'other/board',
        component: BoardComponent,
      },
      {
        path: 'other/register-suppliers',
        component: RegisterSuppliersComponent,
      },
      {
        path: 'other/downloads',
        component: DownloadsComponent,
      },
      {
        path: 'other/active-black-listed-firms',
        component: ActiveBlackListedFirmsComponent,
      },
      {
        path: 'other/pepra-active-black-listed-firms',
        component: PepraActiveBlackListedFirmsComponent,
      },
      {
        path: 'other/active-de-listed-black-listed-firms',
        component: ArchiveDeListedBlackListedFirmsComponent,
      },
      {
        path: 'other/procurement-plan',
        component: ProcurementPlanComponent,
      },
      {
        path: 'other/evaluation-results',
        component: EvaluationResultsComponent,
      },
      {
        path: 'other/procurement-contracts',
        component: ProcurementContractsComponent,
      },
      {
        path: 'other/grievances-redressal-committees',
        component: GrievancesRedressalCommitteesComponent,
      },
      {
        path: 'other/law-acts',
        component: LawActsComponent,
      },
      {
        path: 'other/tender-guidelines',
        component: TenderGuidelinesComponent,
      },
    ]),
    CommonModule,
  ]
})
export class OthersModule { }
