import { AfterViewInit, Component, OnInit } from '@angular/core';
import b64toBlob from 'b64-to-blob';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { GetAllPublishedDocumentDetailsByPidRequestModel, GetClosingTenderRequestModel, GetPublishedDocumentInfoRequestModel, TenderFilter } from 'src/app/core/models/tender-filter.model';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { BaseRequestModel } from 'src/app/core/models/BaseModel/BaseRequestModel';
import { ProcurementActivityReportRequestmodel } from 'src/app/core/models/ProcurementActivityReportRequestmodel';
@Component({
  selector: 'app-tenders',
  templateUrl: './tenders.component.html',
  styleUrls: ['./tenders.component.scss']
})
export class TendersComponent implements OnInit, AfterViewInit {
  paginationModel: PaginationModel;
  tR_DocumentTemplateID:number;
  procurementPlanDetailID:number;
  publishedDocumentID: number;
  wholePkg:boolean=true;
  lotNo:number=1;
  loggedInUser: any;
  publicTenders: any;
  searchFilter: TenderFilter;
  selectedSort: any;
  procurementplanDetailID: any;
  downloadFile: downloadfile;
  publicationDetails: any;
  documentsDetails: any;
  getAllTendersRequestModel: GetPublishedDocumentInfoRequestModel;
  getDepartmentRequest: BaseRequestModel;
  getClosingTenderRequestModel: GetClosingTenderRequestModel;
  getAllPublishedDocumentDetailsByPidRequestModel: GetAllPublishedDocumentDetailsByPidRequestModel;
  GetProcurementActivityReportRequestmodel: ProcurementActivityReportRequestmodel;
  isViolation: boolean = false;
  isColor: 'text-success';
  WorthRecord = [
    { id: 2, name: 'Worth More Than 50 Million' },
    { id: 1, name: 'Worth Less Than 50 Million' }
  ];
  activityStatus = [
    { id: 1, name: 'In-Progress' },
    { id: 2, name: 'Completed' },
    { id: 3, name: 'Cancelled' }
  ];
  departmentNames: any;
  selectedValue: any;
  departmentNameValue: any;
  activityStatusValue: any;
  TotalRecords: any;
  SortText = 'Tenders having closing date within next 40 days are displayed'
  OCDC: any;
  selectedStatusValue: any;
  selectedWorthValue: any;
  selectedWorthID: number;
  viewMoreLength: boolean = true;
  VeiwReport: boolean;
  VeiwReportPAPublished: boolean;
  finalEvaluation:boolean=false;
  eContractViewPDF: boolean;
  userId: any;
  publishedDocumentIDDownload: number;
  supplierID:any
  PageNo: number = 0;
  PN: number;
  totalPages: number;
  pages = new Array();
  constructor(
    private portalService: PublicPortalService,
    private route: Router

  ) {
  }

  ngOnInit(): void {
    this.downloadFile = new downloadfile();
    this.paginationModel = new PaginationModel();
    this.searchFilter = new TenderFilter();
    this.PageNo = Number(this.paginationModel.pageNumber);
    this.getAllPublishedDocumentDetailsByPidRequestModel = new GetAllPublishedDocumentDetailsByPidRequestModel();
    this.GetProcurementActivityReportRequestmodel = new ProcurementActivityReportRequestmodel();
    //this.getRulesAndRegulatoins();
  }
  ngAfterViewInit() {
    this.clear();
    this.getAllPublicTenders();
    this.GetDepartmentname();
  }
  getStatusClass(statusName: string): string {
    switch (statusName) {
      case 'In-Progress':
        return 'text-info';
      case 'Completed':
        return 'text-success';
      case 'Pending':
        return 'text-warning';
      case 'Cancelled':
        return 'text-danger';
      default:
        return 'text-info';
    }
  }
  getStatusClassPIPE(statusName: string): string {
    switch (statusName) {
      case 'In-Progress':
        return 'side_pipe bg-info';
      case 'Completed':
        return 'side_pipe bg-success';
      case 'Pending':
        return 'side_pipe bg-warning';
      case 'Cancelled':
        return 'side_pipe bg-danger';
      default:
        return 'side_pipe bg-info';
    }
  }
  getAllPublicTenders() {
    this.getAllTendersRequestModel = new GetPublishedDocumentInfoRequestModel();
    this.getAllTendersRequestModel.pagination = this.paginationModel;
    this.getAllTendersRequestModel.pagination.orderByColumnName = this.searchFilter?.sortOrder;
    this.getAllTendersRequestModel.filter = this.searchFilter;
    if (this.departmentNameValue != null) {
      this.selectedValue = this.departmentNameValue == null ? null : this.departmentNames.filter(x => x.departmentName == this.departmentNameValue)[0].id;
      this.getAllTendersRequestModel.filter.departmentName = this.selectedValue.toString()
    } else {
      this.getAllTendersRequestModel.filter.departmentName = null
    }
    if (this.selectedWorthValue != null) {
      this.selectedWorthID = this.selectedWorthValue == null ? null : this.WorthRecord.filter(x => x.name == this.selectedWorthValue)[0].id;
      this.getAllTendersRequestModel.filter.selectedWorth = this.selectedWorthID.toString()
    } else {
      this.getAllTendersRequestModel.filter.selectedWorth = null
    }
    this.getAllTendersRequestModel.filter.activityStatus = this.activityStatusValue || null
    this.portalService.getAllPublicTenders(this.getAllTendersRequestModel)
      .subscribe(baseResponse => {

        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              let publicTenders = (baseResponse.data as any);     
              this.publicTenders = publicTenders?.records;
              this.totalPages = publicTenders?.totalPages;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }
              if (this.publicTenders.length==0) {
                this.TotalRecords = 0
              }
              else {
                this.TotalRecords = this.publicTenders[0].totalRecords;
              }
              if (this.publicTenders.length < 10) {
                this.viewMoreLength = false
              }
              else {
                this.viewMoreLength = true
              }
            }
          }
        }
      })
  }
  GetDepartmentname() {
    this.getDepartmentRequest = new BaseRequestModel();
    this.portalService.GetDepartmentname(this.getDepartmentRequest)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              let departments = (baseResponse.data as any);
              this.departmentNames = departments;
            }
          }
        }
      })
  }


  viewMore() {
    if (this.publicTenders != null && this.publicTenders.length >= 10) {
      let pageSize = + this.paginationModel.pageSize
      pageSize = pageSize + 5;
      this.paginationModel.pageSize += 5;
      this.viewMoreLength = true
      this.getAllPublicTenders();
    }
    else {
      this.viewMoreLength = false
      return;
    }

  }
  search() {
    this.getAllPublicTenders();
  }

  searchRulesAndRegulations() {
    this.getRulesAndRegulatoins();
  }
  reset() {
    location.reload()
  }
  clear() {
    //location.reload()
    this.searchFilter.sortOrder = "";
    this.searchFilter.activityStatus = "";
    this.searchFilter.keywords = "";
    this.searchFilter.tenderNo = "";
    this.searchFilter.departmentName = "";
    this.searchFilter.dateOfAdvertisement = null;
    this.searchFilter.closingDate = null;
    this.searchFilter.selectedWorth = "";
    this.paginationModel.orderByColumnName = '';
    this.paginationModel.orderBy = '';
    this.getAllPublicTenders();
    this.GetDepartmentname();

  }
  sortChange() {
    if (this.searchFilter?.sortOrder == 'ClosingDate') {
      this.SortText = 'Tenders having closing date within next 40 days are displayed'
    } else if (this.searchFilter?.sortOrder == 'PublishedDate') {
      this.SortText = 'Tenders Published in previous 40 days are displayed'
    }
    this.getAllTendersRequestModel.pagination.orderByColumnName = this.searchFilter?.sortOrder;
    //this.getAllTendersRequestModel.pagination.orderBy = 'desc';
    this.getAllPublicTenders();
  }
  downloadDocuments(position: string, id: any) {
    this.VeiwReport=false
    this.getAllPublishedDocumentDetailsByPidRequestModel.Id = id;
    this.getAllPublishedDocumentDetailsByPidRequestModel.loggedInUserID = 1;
    this.getAllPublishedDocumentDetailsByPidRequestModel.loggedInUserOfficeID = 1
    this.getAllPublishedDocumentDetailbyPdId();
  }
  downloadDocumentsESubmission(Id: any, Guid: any) {
        this.VeiwReport=true
  }
  ReportHeader:any
  downloadPAPublishedDocumentsESubmission(Id: any,supplierID:any,ReportType:any,NoOfLot:any) {
    this.publishedDocumentIDDownload = Id;
    localStorage.setItem("publishedDocumentID",JSON.stringify(this.publishedDocumentIDDownload));
    this.lotNo=NoOfLot;
    this.publishedDocumentID=Id;
    this.supplierID=supplierID
    if(ReportType=='Award of Contract- Contract'){
      this.ReportHeader="Award of Contract"
      this.eContractViewPDF=true
    }else if(ReportType=='Final Evaluation- Final Evaluation Report'){
      this.ReportHeader="Final Evaluation"
       this.VeiwReportPAPublished=true
    }else if(ReportType=='Technical Evaluation- Technical Evaluation Report'){
      this.ReportHeader="Technical Evaluation"
      this.VeiwReportPAPublished=true
    }
        
  }
  getAllPublishedDocumentDetailbyPdId() {
    this.portalService.getAllPublishedDocumentDetailbyPdId(this.getAllPublishedDocumentDetailsByPidRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              this.documentsDetails = baseResponse.data;
              this.wholePkg=this.documentsDetails[0].wholePkg
              this.lotNo=this.documentsDetails[0].noOfLot
              this.tR_DocumentTemplateID=this.documentsDetails[0].tR_DocumentTemplateID
              this.procurementplanDetailID=this.documentsDetails[0].procurementPlansDetailID
              this.publishedDocumentID=this.documentsDetails[0].tr_PublishedDocumentID
            }
          }
        }
      })
  }
  getAttachmentByID(Id: any, Guid: any) {
    if (Id == 0 || Id == null) {
      return;
    }
    this.downloadFile.ID = Id;
    this.downloadFile.idsList = Guid;

    this.portalService.DownloadFileByGuid(this.downloadFile).subscribe(baseResponse => {
      if (baseResponse.success && baseResponse.data != null) {
        var Bytes = baseResponse.data['bytes'];
        var ContentType = baseResponse.data['contentType'];
        var FileName = baseResponse.data['fileName'];

        const blob = b64toBlob(Bytes, ContentType);
        saveAs(blob, FileName);
      }
    });
  }
  getPublications(position: string, model: any, id: any) {
    this.VeiwReportPAPublished=false
    this.eContractViewPDF=false
    this.getAllPublishedDocumentDetailsByPidRequestModel.Id = id;
    this.getAllPublishedDocumentDetailsByPidRequestModel.SupplierID = 1;
    this.getAllPublishedDocumentDetailsByPidRequestModel.procurementPlansDetailID =model?.procurementPlansDetailID;
    this.getAllPublishedDocumentDetailsByPidRequestModel.loggedInUserID = 1;
    this.getAllPublishedDocumentDetailsByPidRequestModel.loggedInUserOfficeID = 1;
    this.getAllPublishedDocumentDetailbyPdIdPublication();
  }
  getAllOCDC(procurementPlanDetailID: any, publishedDocumentID: any) {
    this.GetProcurementActivityReportRequestmodel.procurementPlanDetailID = procurementPlanDetailID
    this.GetProcurementActivityReportRequestmodel.publishedDocumentID = publishedDocumentID
    this.GetProcurementActivityReportRequestmodel.loggedInUserID = 1;
    this.GetProcurementActivityReportRequestmodel.loggedInUserOfficeID = 1;

    this.getrocurementactivityreportwebportal();
  }
  // getViolations(id) {
  //   this.route.navigateByUrl("/documents/publicvoilations")
  // }
  getViolations(id) {
    sessionStorage.setItem("procurementPlanDetailID", id)
    this.route.navigateByUrl("/documents/publicvoilations");
  }

  getAllPublishedDocumentDetailbyPdIdPublication() {
    this.portalService.getAllPublishedDocumentDetailbyPdIdPublication(this.getAllPublishedDocumentDetailsByPidRequestModel)
      .subscribe(baseResponse => {

        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              this.publicationDetails = baseResponse.data;
            }
          }
        }
      })
  }
  getrocurementactivityreportwebportal() {

    this.portalService.Getprocurementactivityreportwebportal(this.GetProcurementActivityReportRequestmodel)
      .subscribe(baseResponse => {

        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              this.OCDC = baseResponse.data;
            }
          }
        }
      })
  }
  getRulesAndRegulatoins() {
    this.getAllTendersRequestModel = new GetPublishedDocumentInfoRequestModel();
    this.getAllTendersRequestModel.pagination = this.paginationModel;
    this.getAllTendersRequestModel.pagination.orderByColumnName = this.searchFilter?.sortOrder;
    this.getAllTendersRequestModel.pagination.orderBy = "ASC";
    this.getAllTendersRequestModel.filter = this.searchFilter;
    this.portalService.getRulesAndRegulatoins(this.getAllTendersRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              let publicTenders = (baseResponse.data as any);
              this.publicTenders = publicTenders?.records;
            }
          }
        }
      })
  }
  viewMoreRulesAndRegulations() {
    if (this.publicTenders != null && this.publicTenders.length >= 10) {
      let pageSize = + this.paginationModel.pageSize
      pageSize = pageSize + 5;
      this.paginationModel.pageSize += 5;
      this.getRulesAndRegulatoins();
    }
    else {
      return;
    }
  }

  changePage(pageNumber) {
    this.paginationModel.pageNumber = pageNumber;
    this.PageNo = Number(pageNumber);
    this.PN = this.PageNo;
    //this.onSelectedValue()
    // if (this.payRequestModel.Type==listType.TendersHistory && this.ID==null){
    //   this.GetPayDetailList();
    // }
    // else if (this.payRequestModel.Type==listType.TendersWorthHistory ){
    //   this.onSelectedValue();
    // }else if (this.payRequestModel.Type==listType.TendersHistory){
    //   this.GetPayDetailListByID(this.ID);
    // }else if (this.payRequestModel.Type==listType.SectorHistory){
    //   this.GetPayDetailListByIDAndSector(this.ID);
    // }else if (this.payRequestModel.Type==listType.OganizationWiseHistoryFilter){
    //   this.GetPayDetailListByIDAndSector(this.ID);
    // }else if (this.payRequestModel.Type==listType.SectorWiseHistoryFilter){
    //   this.GetPayDetailListByID(this.ID);
    // }
  }

  nextPage() {
    if (Number(this.paginationModel.pageNumber) < this.totalPages) {
      this.changePage(Number(this.paginationModel.pageNumber) + 1);
      this.getAllPublicTenders()
    }
  }

  previousPage() {
    if (Number(this.paginationModel.pageNumber) > 1) {
      this.changePage(Number(this.paginationModel.pageNumber) - 1);
      this.getAllPublicTenders()
    }
  }

  selectedPage() {
    if(this.PN==null || this.PN==0){
      this.PN=1
    }
    this.paginationModel.pageNumber = String(this.PN);
    this.PageNo = this.PN;
    this.getAllPublicTenders()
    // if (this.payRequestModel.Type==listType.TendersHistory && this.ID==null){
    //   this.GetPayDetailList();
    // }
    // else if (this.payRequestModel.Type==listType.TendersWorthHistory ){
    //   this.onSelectedValue();
    // }else if (this.payRequestModel.Type==listType.TendersHistory){
    //   this.GetPayDetailListByID(this.ID);
    // }else if (this.payRequestModel.Type==listType.SectorHistory){
    //   this.GetPayDetailListByIDAndSector(this.ID);
    // }else if (this.payRequestModel.Type==listType.OganizationWiseHistoryFilter){
    //   this.GetPayDetailListByIDAndSector(this.ID);
    // }else if (this.payRequestModel.Type==listType.SectorWiseHistoryFilter){
    //   this.GetPayDetailListByID(this.ID);
    // }
  }

  onEnterKeyPressed(event: KeyboardEvent): void {
    // Check if the key pressed is Enter

    if (event.key === 'Enter') {
      // Call your function here
      this.selectedPage()
    }
  }
}
