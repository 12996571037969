export enum listType{
    Plans="Plans",
    plansbyid="plansbyid",
    PlansDropDown="PlansDropDown",
    Evaluations="Evaluations",
    Grievance="Grievance",
    GrievanceByID="GrievanceByID",
    GrievanceDropdown="GrievanceDropdown",
    Contracts="Contracts",
    BOI="BOI",
    ActiveTenders="Tenders",
    TendersCount="TendersCount",
    EOITenders="EOITenders",
    PQTenders="PQTenders",
    RFPTenders="RFPTenders",
    SADTenders="SADTenders",
    TendersDropDown="TendersDropDown",
    Sector="Sector",
    SectorDropDownSum="SectorDropDownSum",
    SectorDropDown="SectorDropDown",
    SectorHistoryDropDown="SectorHistoryDropDown",
    State="State",
    SectorHistory="SectorHistory",
    TendersHistory="TendersHistory",
    TendersHistoryDropsownRecord="TendersHistoryDropsownRecord",
    EPADSSuppliersByIndustry="EPADSSuppliersByIndustry",
    EPADSSuppliers="EPADSSuppliers",
    EPADSBussinessTypes="EPADSBussinessTypes",
    EPADSCities="EPADSCities",
    TendersWorth="TendersWorth",
    TendersWorthHistory="TendersWorthHistory",
    EPADSAllSuppliers="EPADSAllSuppliers",
    Delisted="Delisted",
    ActiveBlackListed="ActiveBlackListed",
    SectorWiseFilter="SectorWiseFilter",
    OganizationWiseFilter="OganizationWiseFilter",
    OganizationWiseHistoryFilter="OganizationWiseHistoryFilter",
    SectorWiseHistoryFilter="SectorWiseHistoryFilter",
}