<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>{{this.userUtilsService.selectedEnvironment?.Letter}}PPRA Publications</h1>
	</div>
</section>
<div class="clear p-4"></div>
<section class="container">
	<div class="row">
		<div class="col-md-6">
			<h3 class="font-weight-semi-bold">Year 2016</h3>
			<div class="well bg-light">
				<h6 class="font-weight-medium py-1">
					<a href="https://www.ppra.org.pk/doc/gazette/newsoct-dec-16.pdf" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter October-December, 2016</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="https://www.ppra.org.pk/doc/gazette/newsjuly-sep-16.pdf" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter July-September, 2016</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="https://www.ppra.org.pk/doc/gazette/newsapril-june-16.pdf" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter April-June, 2016</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="https://www.ppra.org.pk/doc/gazette/newsjan-mar-16.pdf" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter January-March, 2016</a>
				</h6>
			</div>
		</div>
		<div class="col-md-6">
			<h3 class="font-weight-semi-bold">Year 2015</h3>
			<div class="well bg-light">
				<h6 class="font-weight-medium py-1">
					<a href="https://www.ppra.org.pk/doc/gazette/newsoct-dec-15.pdf" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter October-December, 2015</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="https://www.ppra.org.pk/doc/gazette/newsjuly-sep-15.pdf" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter July-September, 2015</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="https://www.ppra.org.pk/doc/gazette/newsapril-june-15.pdf" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter April-June, 2015</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="https://www.ppra.org.pk/doc/gazette/newsjan-mar-15.pdf" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter January-March, 2015</a>
				</h6>
			</div>
		</div>
		<div class="col-md-6">
			<h3 class="font-weight-semi-bold">Year 2014</h3>
			<div class="well bg-light">
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter Oct-December, 2014</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter July-September, 2014</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter April-June, 2014</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter January-March (Special Edition), 2014</a>
				</h6>
			</div>
		</div>
		<div class="col-md-6">
			<h3 class="font-weight-semi-bold">Year 2013</h3>
			<div class="well bg-light">
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter October-December, 2013</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter July-September, 2013</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter April-June, 2013</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter Jan-March, 2013</a>
				</h6>
			</div>
		</div>
		<div class="col-md-6">
			<h3 class="font-weight-semi-bold">Year 2012</h3>
			<div class="well bg-light">
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter October-December, 2012</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter July-September, 2012</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter April-June, 2012</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter Jan-March, 2012</a>
				</h6>
			</div>
		</div>
		<div class="col-md-6">
			<h3 class="font-weight-semi-bold">Year 2011</h3>
			<div class="well bg-light">
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter October-December, 2011</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter July-September, 2011</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter April-June, 2011</a>
				</h6>
				<h6 class="font-weight-medium py-1">
					<a href="#" target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA News Letter Jan-March, 2011</a>
				</h6>
			</div>
		</div>
	</div>	
</section>
<div class="clear p-4"></div>