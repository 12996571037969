<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>Contact us</h1>
	</div>
</section>
<div class="class py-3"></div>
<section class="container">
	<div class="d-flex justify-content-between">
		<h4 class="font-weight-semi-bold">Contact us</h4>
		<h5 class="text-black font-weight-medium">EPADS UAN: <a href="tel:051111137237" class="text-primary font-weight-bold">051-111-137-237</a></h5>
	</div>
	<p>It is to inform that all the correspondence through following email addresses with PPRA officers be considered as an official correspondence, Any official correspondence with PPRA employees on his personal email address shall not be considered as official correspondence.</p>
	<div class="well bg-light">
		<h5 class="font-weight-medium">Mr. Maqbool Ahmad Gondal</h5>
		<p class="text-black-50 font-weight-medium Roboto">Managing Director</p>
		<p class="my-2">
			<span class="text-nowrap">
				<i class="fas fa-phone text-success mr-1"></i> 051-9224824
			</span><br>
			<span class="text-nowrap">
				<i class="fas fa-fax text-success mr-1"></i> 051-9224823
			</span>							
		</p>
		<p class="mb-0">
			<i class="far fa-envelope text-success mr-1"></i> <a href="mailto:mdppra@ppra.org.pk">mdppra@ppra.org.pk</a>
		</p>
	</div>
	<div class="well pb-0">
		<h5 class="card-title">LEGAL WING</h5>
		<hr>
		<div class="row">
			<div class="col-md-4 border-right">
				<h6 class="font-weight-medium">Dr. M. Aslam Waseem</h6>
				<p class="text-black-50 font-weight-medium Roboto">Director General (Legal)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9245982<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:aslamwaseem@ppra.org.pk">aslamwaseem@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4 border-right">
				<h6 class="font-weight-medium">Mr. Abdul Nabi</h6>
				<p class="text-black-50 font-weight-medium Roboto">Director (Legal)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9203542<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:abdulnabi@ppra.org.pk">abdulnabi@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4">
				<h6 class="font-weight-medium">Mr. Muhammad Khurshid</h6>
				<p class="text-black-50 font-weight-medium Roboto">Deputy Director (Legal)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9216995<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:khurshid@ppra.org.pk">khurshid@ppra.org.pk</a>
				</p>
			</div>
		</div>
	</div>
	<div class="well pb-0">
		<h5 class="card-title">MONITORING & EVALUATION WING</h5>
		<hr>
		<div class="row">
			<div class="col-md-4 border-right border-bottom">
				<h6 class="font-weight-medium">Dr. Asim Jaleel</h6>
				<p class="text-black-50 font-weight-medium Roboto">Director (M & E)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9211879<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:asimjaleel@ppra.org.pk">asimjaleel@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4 border-right border-bottom">
				<h6 class="font-weight-medium">Mr. M. Ibrahim R Khan</h6>
				<p class="text-black-50 font-weight-medium Roboto">Deputy Director (M & E)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9211881<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:ibrahimkhan@ppra.org.pk">ibrahimkhan@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4 border-bottom">
				<h6 class="font-weight-medium">Ms. Saima Mueed</h6>
				<p class="text-black-50 font-weight-medium Roboto">Deputy Director (M & E)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9211882<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:saima@ppra.org.pk">saima@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4 border-right">
				<h6 class="font-weight-medium mt-3">Mr. Saleh Izhar</h6>
				<p class="text-black-50 font-weight-medium Roboto">Deputy Director (M & E)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9211883<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:salehizhar@ppra.org.pk">salehizhar@ppra.org.pk</a>
				</p>
			</div>
		</div>
	</div>
	<div class="well pb-0">
		<h5 class="card-title">IT & RESEARCH WING</h5>
		<hr>
		<div class="row">
			<div class="col-md-4 border-right border-bottom">
				<h6 class="font-weight-medium">Mr. Ateeq Haider Baig</h6>
				<p class="text-black-50 font-weight-medium Roboto">Director General (IT & R)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9245634<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:ateeq@ppra.org.pk">ateeq@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4 border-right border-bottom">
				<h6 class="font-weight-medium">Mr. Rizwan Mehmood</h6>
				<p class="text-black-50 font-weight-medium Roboto">Director (MIS)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9205728<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:rizwan@ppra.org.pk">rizwan@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4 border-bottom">
				<h6 class="font-weight-medium">Mr. M. Aasim Bashir</h6>
				<p class="text-black-50 font-weight-medium Roboto">Deputy Director (IT)</p>
				<p>
					<!--i class="fas fa-phone text-success mr-1"></i> 051-9211882<br-->
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:aasim@ppra.org.pk">aasim@ppra.org.pk</a>
				</p>
			</div>

			<div class="col-md-4 border-right">
				<h6 class="font-weight-medium mt-3">Mr. Shafiq Ahmed Nonari</h6>
				<p class="text-black-50 font-weight-medium Roboto">Deputy Director (Research)</p>
				<p>
					<!---i class="fas fa-phone text-success mr-1"></i> 051-9245634<br-->
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:shafiq@ppra.org.pk">shafiq@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4 border-right">
				<h6 class="font-weight-medium mt-3">Syed Mohsin Hassan</h6>
				<p class="text-black-50 font-weight-medium Roboto">Asst Director (WD)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9205726<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:mohsin@ppra.org.pk">mohsin@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4">
				<h6 class="font-weight-medium mt-3">Mr. Sheheryar Masood</h6>
				<p class="text-black-50 font-weight-medium Roboto">Asst Director (IT)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9205726<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:sheheryar@ppra.org.pk">sheheryar@ppra.org.pk</a>
				</p>
			</div>
		</div>
	</div>
	<div class="well pb-0">
		<h5 class="card-title">FINANCE WING</h5>
		<hr>
		<div class="row">
			<div class="col-md-4 border-right border-bottom">
				<h6 class="font-weight-medium">Mr. Ali Asghar Bhutto</h6>
				<p class="text-black-50 font-weight-medium Roboto">Director (Finance)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9211880<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:aliasghar@ppra.org.pk">aliasghar@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4 border-right border-bottom">
				<h6 class="font-weight-medium">Mr. Yasir Shamim Khan</h6>
				<p class="text-black-50 font-weight-medium Roboto">Director (Internal Audit & Planning)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9216994<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:yasir@ppra.org.pk">yasir@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4 border-bottom">
				<h6 class="font-weight-medium">Ms. Asma Shaheen</h6>
				<p class="text-black-50 font-weight-medium Roboto">Deputy Director (Finance)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9201851<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:asma@ppra.org.pk">asma@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4 border-right">
				<h6 class="font-weight-medium mt-3">Mr. Faraz Alvi</h6>
				<p class="text-black-50 font-weight-medium Roboto">Deputy Director (Accounts)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9203541<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:faraz@ppra.org.pk">faraz@ppra.org.pk</a>
				</p>
			</div>
		</div>
	</div>
	<div class="well pb-0">
		<h5 class="card-title">HR WING</h5>
		<hr>
		<div class="row">
			<div class="col-md-4 border-right border-bottom">
				<h6 class="font-weight-medium">Mr. Farrukh Bashir</h6>
				<p class="text-black-50 font-weight-medium Roboto">Director General (HR)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9224822<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:farrukh@ppra.org.pk">farrukh@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4 border-right border-bottom">
				<h6 class="font-weight-medium">Dr. Faiza Nayyer</h6>
				<p class="text-black-50 font-weight-medium Roboto">Director (HR)</p>
				<p>
					<!---i class="fas fa-phone text-success mr-1"></i> 051-9216994<br--->
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:faiza@ppra.org.pk">faiza@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4 border-bottom">
				<h6 class="font-weight-medium">Mr. Ali Temoor</h6>
				<p class="text-black-50 font-weight-medium Roboto">Director (Coord & Estab)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9204462<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:alitemoor@ppra.org.pk">alitemoor@ppra.org.pk</a>
				</p>
			</div>

			<div class="col-md-4 border-right">
				<h6 class="font-weight-medium mt-3">Mr. Sagheer Ahmed</h6>
				<p class="text-black-50 font-weight-medium Roboto">Deputy Director (Training)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9245635<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:sagheer@ppra.org.pk">sagheer@ppra.org.pk</a>
				</p>
			</div>
			<div class="col-md-4 border-right">
				<h6 class="font-weight-medium mt-3">Syed Jahangir Rizvi</h6>
				<p class="text-black-50 font-weight-medium Roboto">Asst Director (Training)</p>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9245635<br>
					<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:jahangir@ppra.org.pk">jahangir@ppra.org.pk</a>
				</p>
			</div>
		</div>
	</div>
	<div class="card">
		<div class="card-header">
			<h5 class="card-title mb-0 font-weight-medium">For tender advertisements uploading fee/invoice/bill contact at <a href="tel:0519203541" class="text-primary font-weight-bold">051-9203541</a></h5>
		</div>
		<div class="card-body">
			<p>To advertise your tenders/procurement advertisements at PPRA website, Email at <a href="mailto:info@ppra.org.pk">info@ppra.org.pk</a> with attachment, or send by post at Following Address:</p>
			<div class="row">
				<div class="col-lg-3">
					<h6 class="font-weight-medium">Mr. Rizwan Mehmood</h6>
					<p class="text-black-50 font-weight-medium Roboto">Director (MIS)</p>
				</div>
				<div class="col-lg-6">
					<div class="media">
						<i class="fas fa-map-marker-alt text-success mt-2 mr-3"></i> 
						<div class="media-body">
						  Public Procurement Regulatory Authority,<br>1st Floor FBC Building Near State Bank,<br>Sector G-5/2, Islamabad-Pakistan
						</div>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="media">
						<i class="fas fa-phone text-success mt-2 mr-3"></i> 
						<div class="media-body">
						  92-51-920-57-28<br>92-51-920-57-26<br>92-51-921-91-49
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.4408223337373!2d73.0939720752114!3d33.72341543502259!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfc07c7d907aa5%3A0xccce2da1a9319462!2sPublic%20Procurement%20Regulatory%20Authority!5e0!3m2!1sen!2s!4v1709213250129!5m2!1sen!2s" width="100%" height="500" class="bg-white border p-1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
	<p class="mt-3">Send mail to <a href="mailto:info@ppra.org.pk">info@ppra.org.pk</a> with questions or comments about this web site.</p>
	<!--div class="row">
		<div class="col-lg-4">
			<div class="bg-light h-100 p-4">
				<h5 class="font-weight-semi-bold text-primary">Important Notice for Procuring Agencies</h5>
				<p>For timely uploading of tenders notices on PPRA website;</p>
				<ol type="i">
					<li class="mb-2">
						<p>Send tender notices to PPRA at least two working days prior to its publication in newspaper.</p>
						<strong class="text-black">OR</strong>
					</li>
					<li class="mb-1">
						Obtain your own User ID/Password for direct uploading on PPRA website. <a href="mailto:info@ppra.org.pk">info@ppra.org.pk</a>
					</li>
				</ol>
				<div class="clear py-1"></div>
				<hr>
				<div class="clear py-1"></div>
				<h5 class="font-weight-semi-bold text-black">Contact for  Queries/Support</h5>
				<h6>Tender Uploading</h6>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9205728, 26
					<br>
					<i class="fas fa-fax text-success mr-2"></i> 051-9219149
					<br>
					<i class="far fa-envelope text-success mr-2"></i>
					<a href="mailto:rizwan@ppra.org.pk">rizwan@ppra.org.pk</a><br>
					<a href="mailto:info@ppra.org.pk" class="ml-4">info@ppra.org.pk</a>					
				</p>
				<h6>Tender Fee Payments/Accounts & Finance</h6>
				<p>
					<i class="fas fa-phone text-success mr-1"></i> 051-9203541
					<br>
					<i class="fas fa-fax text-success mr-2"></i> 051-9224823
					<br>
					<i class="far fa-envelope text-success mr-2"></i>
					<a href="mailto:faraz@ppra.org.pk">faraz@ppra.org.pk</a>					
				</p>
				<a href="http://www.anti-fraudhotline.com/" target="_blank"><img src="../assets/images/usaid.jpg" class="img-center mt-2" title="Anti-Fraud"></a>
				<div class="text-center my-4">
					<a href="../assets/docs/pnotice.pdf" target="_blank"><img src="../assets/images/pnotice.jpg" class="img-center"></a>
					<div class="clear py-3"></div>
					<a href="../assets/images/rate.png" target="_blank"><img src="../assets/images/ad-rate.gif" class="img-center"></a>
				</div>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.4408223337373!2d73.0939720752114!3d33.72341543502259!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfc07c7d907aa5%3A0xccce2da1a9319462!2sPublic%20Procurement%20Regulatory%20Authority!5e0!3m2!1sen!2s!4v1709213250129!5m2!1sen!2s" width="100%" height="500" class="bg-white border p-1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
			</div>
		</div>
		<div class="col-lg-8 py-4">
			<div class="d-flex justify-content-between">
				<h4 class="font-weight-semi-bold">Contact us</h4>
				<h5 class="text-black font-weight-medium">EPADS UAN: <a href="tel:051111137237" class="text-primary font-weight-bold">051-111-137-237</a></h5>
			</div>
			<p>It is to inform that all the correspondence through following email addresses with PPRA officers be considered as an official correspondence, Any official correspondence with PPRA employees on his personal email address shall not be considered as official correspondence.</p>
			<div class="well">
				<div class="row">
					<div class="col-4 col-sm-4">
						<img src="../assets/images/DGgondal.png" class="img-thumbnail">
						<p class="my-2">
							<span class="text-nowrap">
								<i class="fas fa-phone text-success mr-1"></i> 051-9224824
							</span>
							<span class="text-nowrap">
								<i class="fas fa-fax text-success ml-2 mr-1"></i> 051-9224823
							</span>							
						</p>
						<p class="mb-0">
							<i class="far fa-envelope text-success mr-1"></i> <a href="mailto:mdppra@ppra.org.pk">mdppra@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-8 col-sm-8">
						<h5 class="font-weight-medium">Mr. Maqbool Ahmad Gondal</h5>
						<p class="text-black-50 font-weight-medium Roboto">Managing Director</p>
						<p>Mr. Maqbool Ahmad Gondal is Managing Director of Public Procurement Regulatory Authority (PPRA) w.e.f 18-11-2021. He is an officer of Audit and Accounts Services having 30 years of experience of Public Sector Accounting, Auditing, Management and Tax Administration. His academic includes Master of Accounting and Finance from University of Manchester (UK); MBA Finance from National University of Modern Language (NUML); and Msc / M.Phil Physics from Quaid-e-Azam University with distinction of Chancellor's Gold Medal. He is also member of a number of national and international Accounting Bodies including Certified Fraud Examiner (CFE) from USA. He is lifetime member of Pakistan Institute of Public Financial Accountant (PIPFA).</p>
					</div>
				</div>
			</div>
			<div class="well pb-0">
				<h5 class="card-title">LEGAL WING</h5>
				<hr>
				<div class="row">
					<div class="col-md-4 border-right">
						<h6 class="font-weight-medium">Dr. M. Aslam Waseem</h6>
						<p class="text-black-50 font-weight-medium Roboto">Director General (Legal)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9245982<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:aslamwaseem@ppra.org.pk">aslamwaseem@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4 border-right">
						<h6 class="font-weight-medium">Mr. Abdul Nabi</h6>
						<p class="text-black-50 font-weight-medium Roboto">Director (Legal)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9203542<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:abdulnabi@ppra.org.pk">abdulnabi@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4">
						<h6 class="font-weight-medium">Mr. Muhammad Khurshid</h6>
						<p class="text-black-50 font-weight-medium Roboto">Deputy Director (Legal)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9216995<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:khurshid@ppra.org.pk">khurshid@ppra.org.pk</a>
						</p>
					</div>
				</div>
			</div>
			<div class="well pb-0">
				<h5 class="card-title">MONITORING & EVALUATION WING</h5>
				<hr>
				<div class="row">
					<div class="col-md-4 border-right border-bottom">
						<h6 class="font-weight-medium">Dr. Asim Jaleel</h6>
						<p class="text-black-50 font-weight-medium Roboto">Director (M & E)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9211879<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:asimjaleel@ppra.org.pk">asimjaleel@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4 border-right border-bottom">
						<h6 class="font-weight-medium">Mr. M. Ibrahim R Khan</h6>
						<p class="text-black-50 font-weight-medium Roboto">Deputy Director (M & E)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9211881<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:ibrahimkhan@ppra.org.pk">ibrahimkhan@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4 border-bottom">
						<h6 class="font-weight-medium">Ms. Saima Mueed</h6>
						<p class="text-black-50 font-weight-medium Roboto">Deputy Director (M & E)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9211882<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:saima@ppra.org.pk">saima@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4 border-right">
						<h6 class="font-weight-medium mt-3">Mr. Saleh Izhar</h6>
						<p class="text-black-50 font-weight-medium Roboto">Deputy Director (M & E)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9211883<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:salehizhar@ppra.org.pk">salehizhar@ppra.org.pk</a>
						</p>
					</div>
				</div>
			</div>
			<div class="well pb-0">
				<h5 class="card-title">IT & RESEARCH WING</h5>
				<hr>
				<div class="row">
					<div class="col-md-4 border-right border-bottom">
						<h6 class="font-weight-medium">Mr. Ateeq Haider Baig</h6>
						<p class="text-black-50 font-weight-medium Roboto">Director General (IT & R)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9245634<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:ateeq@ppra.org.pk">ateeq@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4 border-right border-bottom">
						<h6 class="font-weight-medium">Mr. Rizwan Mehmood</h6>
						<p class="text-black-50 font-weight-medium Roboto">Director (MIS)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9205728<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:rizwan@ppra.org.pk">rizwan@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4 border-bottom">
						<h6 class="font-weight-medium">Mr. M. Aasim Bashir</h6>
						<p class="text-black-50 font-weight-medium Roboto">Deputy Director (IT)</p>
						<p>

							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:aasim@ppra.org.pk">aasim@ppra.org.pk</a>
						</p>
					</div>

					<div class="col-md-4 border-right">
						<h6 class="font-weight-medium mt-3">Mr. Shafiq Ahmed Nonari</h6>
						<p class="text-black-50 font-weight-medium Roboto">Deputy Director (Research)</p>
						<p>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:shafiq@ppra.org.pk">shafiq@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4 border-right">
						<h6 class="font-weight-medium mt-3">Syed Mohsin Hassan</h6>
						<p class="text-black-50 font-weight-medium Roboto">Asst Director (WD)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9205726<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:mohsin@ppra.org.pk">mohsin@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4">
						<h6 class="font-weight-medium mt-3">Mr. Sheheryar Masood</h6>
						<p class="text-black-50 font-weight-medium Roboto">Asst Director (IT)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9205726<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:sheheryar@ppra.org.pk">sheheryar@ppra.org.pk</a>
						</p>
					</div>
				</div>
			</div>
			<div class="well pb-0">
				<h5 class="card-title">FINANCE WING</h5>
				<hr>
				<div class="row">
					<div class="col-md-4 border-right border-bottom">
						<h6 class="font-weight-medium">Mr. Ali Asghar Bhutto</h6>
						<p class="text-black-50 font-weight-medium Roboto">Director (Finance)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9211880<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:aliasghar@ppra.org.pk">aliasghar@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4 border-right border-bottom">
						<h6 class="font-weight-medium">Mr. Yasir Shamim Khan</h6>
						<p class="text-black-50 font-weight-medium Roboto">Director (Internal Audit & Planning)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9216994<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:yasir@ppra.org.pk">yasir@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4 border-bottom">
						<h6 class="font-weight-medium">Ms. Asma Shaheen</h6>
						<p class="text-black-50 font-weight-medium Roboto">Deputy Director (Finance)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9201851<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:asma@ppra.org.pk">asma@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4 border-right">
						<h6 class="font-weight-medium mt-3">Mr. Faraz Alvi</h6>
						<p class="text-black-50 font-weight-medium Roboto">Deputy Director (Accounts)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9203541<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:faraz@ppra.org.pk">faraz@ppra.org.pk</a>
						</p>
					</div>
				</div>
			</div>
			<div class="well pb-0">
				<h5 class="card-title">HR WING</h5>
				<hr>
				<div class="row">
					<div class="col-md-4 border-right border-bottom">
						<h6 class="font-weight-medium">Mr. Farrukh Bashir</h6>
						<p class="text-black-50 font-weight-medium Roboto">Director General (HR)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9224822<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:farrukh@ppra.org.pk">farrukh@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4 border-right border-bottom">
						<h6 class="font-weight-medium">Dr. Faiza Nayyer</h6>
						<p class="text-black-50 font-weight-medium Roboto">Director (HR)</p>
						<p>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:faiza@ppra.org.pk">faiza@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4 border-bottom">
						<h6 class="font-weight-medium">Mr. Ali Temoor</h6>
						<p class="text-black-50 font-weight-medium Roboto">Director (Coord & Estab)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9204462<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:alitemoor@ppra.org.pk">alitemoor@ppra.org.pk</a>
						</p>
					</div>

					<div class="col-md-4 border-right">
						<h6 class="font-weight-medium mt-3">Mr. Sagheer Ahmed</h6>
						<p class="text-black-50 font-weight-medium Roboto">Deputy Director (Training)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9245635<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:sagheer@ppra.org.pk">sagheer@ppra.org.pk</a>
						</p>
					</div>
					<div class="col-md-4 border-right">
						<h6 class="font-weight-medium mt-3">Syed Jahangir Rizvi</h6>
						<p class="text-black-50 font-weight-medium Roboto">Asst Director (Training)</p>
						<p>
							<i class="fas fa-phone text-success mr-1"></i> 051-9245635<br>
							<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:jahangir@ppra.org.pk">jahangir@ppra.org.pk</a>
						</p>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="card-header">
					<h5 class="card-title mb-0 font-weight-medium">For tender advertisements uploading fee/invoice/bill contact at <a href="tel:0519203541" class="text-primary font-weight-bold">051-9203541</a></h5>
				</div>
				<div class="card-body">
					<p>To advertise your tenders/procurement advertisements at PPRA website, Email at <a href="mailto:info@ppra.org.pk">info@ppra.org.pk</a> with attachment, or send by post at Following Address:</p>
					<div class="row">
						<div class="col-lg-3">
							<h6 class="font-weight-medium">Mr. Rizwan Mehmood</h6>
							<p class="text-black-50 font-weight-medium Roboto">Director (MIS)</p>
						</div>
						<div class="col-lg-6">
							<div class="media">
								<i class="fas fa-map-marker-alt text-success mt-2 mr-3"></i> 
								<div class="media-body">
								  Public Procurement Regulatory Authority,<br>1st Floor FBC Building Near State Bank,<br>Sector G-5/2, Islamabad-Pakistan
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="media">
								<i class="fas fa-phone text-success mt-2 mr-3"></i> 
								<div class="media-body">
								  92-51-920-57-28<br>92-51-920-57-26<br>92-51-921-91-49
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div-->
</section>
<div class="class py-3"></div>