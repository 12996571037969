<div class="clear"></div>
<section class="banner">
  <div class="container py-4">
    <h1>Delisted Archive Blacklisted/Debarred Firms-Pakistan</h1>
  </div>
</section>
<div class="clear py-3"></div>
<section class="container">
  <h6 class="text-black font-weight-semi-bold">The following firms which were temporarily blacklisted/debarred by the
    procuring agencies, Now firms have been removed from the active list of blacklisted/debarred firms.</h6>
  <div class="clear py-2"></div>

  <p class="font-weight-bold">Total Delisted Archive Blacklisted/Debarred Firms <strong>({{totalRecords}})</strong></p>

  <div class="table-responsive">
    <table class="table border table-striped">
      <thead class="table-success">
        <tr>
          <th width="75">Sr. No</th>
          <th width="75">
            <div class="sortTh text-nowrap">
              PPRA No.
              <span class="sorted_Icons">
                  <a (click)="sort('PPRA_No','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                  <a (click)="sort('PPRA_No','asc')"><img src="../assets/images/arrow_down.gif"></a>
              </span>
            </div>
          </th>
          <th>
            <div class="sortTh">
              Name of Procurement Agency
              <span class="sorted_Icons">
                  <a (click)="sort('NameofProcurementAgency','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                  <a (click)="sort('NameofProcurementAgency','asc')"><img src="../assets/images/arrow_down.gif"></a>
              </span>
            </div>
          </th>
          <th>
            <div class="sortTh">
              Firm/Company Name
              <span class="sorted_Icons">
                  <a (click)="sort('Firm_CompanyName','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                  <a (click)="sort('Firm_CompanyName','asc')"><img src="../assets/images/arrow_down.gif"></a>
              </span>
            </div>
          </th>
          <th>Address</th>
          <th>Firm/Company Owner Details</th>
          <th>
            <div class="sortTh text-nowrap">
              Date From
              <span class="sorted_Icons">
                  <a (click)="sort('DateFrom','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                  <a (click)="sort('DateFrom','asc')"><img src="../assets/images/arrow_down.gif"></a>
              </span>
            </div>
          </th>
          <th>
            <div class="sortTh text-nowrap">
              Date To
              <span class="sorted_Icons">
                  <a (click)="sort('DateTo','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                  <a (click)="sort('DateTo','asc')"><img src="../assets/images/arrow_down.gif"></a>
              </span>
            </div>
          </th>
          <th>Reasons</th>
          <th>Type of Blacklisting</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <tr *ngFor="let info of Records; let i = index">
          <td>
            <strong>{{i+1}}</strong><br>
          </td>
          <td>{{info.PPRA_No}}</td>
          <td>{{info.NameofProcurementAgency}}</td>
          <td>{{info.Firm_CompanyName}}</td>
          <td>{{info.Address}}</td>
          <td>{{info.Firm_CompanyOwnerDetails}}</td>
          <td>{{info.DateFrom |date}}</td>
          <td>{{info.DateTo|date}}</td>
          <td>{{info.Reasons}}</td>
          <td>{{info.BlackListingDuration}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="clear clearfix py-1"></div>
  <!-- <p class="font-weight-bold">Total Active Tenders ({{totalRecords}})</p>
      <p class="arial">
          <small class="font_12">10 Records Per Page</small><br>
          <a (click)="changePage(pageNumber)"  *ngFor="let pageNumber of pages"    [ngClass]="{'active': pageNumber==this.paginationModel.pageNumber}"  class="font-weight-bold" >{{pageNumber}}&nbsp;</a> 
        
      </p> -->
  <small class="font_12">10 Records Per Page</small>
  <small class="font_12"> | Total Pages<strong> {{this.totalPages}}</strong></small>
  <nav aria-label="..." class="arial-pagination">
    <div class="d-flex">
      <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="this.PageNo === 1">
          <a class="page-link" (click)="previousPage()">Previous</a>
        </li>
        <li class="page-item" *ngFor="let pageNumber of pages">
          <a class="page-link" (click)="changePage(pageNumber)"
            [class.active]="pageNumber === this.PageNo">{{pageNumber}}</a>
        </li>
        <li class="page-item" [class.disabled]="this.PageNo=== this.totalPages">
          <a class="page-link" (click)="nextPage()">Next</a>
        </li>
      </ul>
      <div class="input-group input-group-sm ml-4" style="max-width: 115px;">
        <input type="number" class="form-control" placeholder="Page No." (keyup)="onEnterKeyPressed($event)"
          [(ngModel)]="this.PN" aria-label="Enter your text" aria-describedby="button-addon">
        <div class="input-group-append input-group-sm">
          <button class="btn btn-outline-secondary" (click)="selectedPage()" type="button" id="button-addon">Go</button>
        </div>
      </div>
    </div>
  </nav>
  <div class="clear py-3"></div>

  <h5 class="font-weight-semi-bold">Related Links:</h5>
  <ul>
    <li class="mb-2"><a target="_blank" href="http://www.pprasindh.gov.pk/blacklistfirms.php"
        class="font-weight-bold">Sindh PPRA Blacklisted Firms.</a></li>
    <li class="mb-2"><a target="_blank" href="https://eproc.punjab.gov.pk/blacklist.aspx" class="font-weight-bold">PPRA
        Punjab Blacklisted Firms.</a></li>
    <li class="mb-2"><a target="_blank" href="http://www.kppra.gov.pk/kppra/" class="font-weight-bold">PPRA Kyber
        Pakhtunkhwa Blacklisted Firms.</a></li>
    <li class="mb-2"><a target="_blank"
        href="https://www.worldbank.org/en/projects-operations/procurement/debarred-firms"
        class="font-weight-bold">World Bank Debarred Firms.</a></li>
    <li class="mb-2"><a target="_blank" href="https://lnadbg4.adb.org/oga0009p.nsf/sancALLPublic?OpenView&count=999"
        class="font-weight-bold">Asian Development Bank Debarred Firms.</a></li>
  </ul>
</section>
<div class="clear py-3"></div>