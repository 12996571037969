<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>Redressal of Grievances</h1>
	</div>
</section>
<div class="clear py-3"></div>
<section class="container">
    <p class="font-weight-semi-bold text-success">Public Contracts of Works, Services & Goods (Public Procurement Rule-47, 2004 and Public Procurement Regulations, 2009) .</p>
    <p><a href="./assets/docs/elvform50.doc" class="text-primary">Click to download Performa (PP Regulation-2009)</a></p>
    <p><a href="./assets/docs/mdcontract.pdf" target="_blank" class="text-primary">Click to download Performa (PP Rule-47).</a></p>
    <div class="clear py-2"></div>

    <div class="table-responsive">
        <table class="table border table-striped">
            <thead class="table-success">
                <th class="text-primary text-nowrap">Evaluation No</th>
                <th class="text-primary text-nowrap">Evaluation Result Title</th>
                <th class="text-primary text-center">Download</th>
                <th class="text-primary text-nowrap">Uploaded Date</th>
            </thead>
            <tbody>
                <tr>
                    <tr *ngFor="let tenders of publicTenders">
                        <th class="text-nowrap">{{tenders?.tenderNumbers}}</th>
                        <td>
                            <strong>{{tenders?.name}}</strong><br>
                        </td>
                        <td class="text-center">
                            <a (click)="getAttachmentByGUID(tenders?.documentId,tenders?.documentGUID)"   class="btn btn-rounded alert-primary m-1 btn-sm shadow-none font-weight-medium py-2 px-3"><img src="../assets/images/download_icon.gif"></a>
                        </td>
                        <td>{{tenders?.uploadedDate | date}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="clear clearfix py-1"></div>
        <p class="font-weight-bold">Total Active Tenders ({{totalRecords}})</p>
        <p class="arial">
            <small class="font_12">10 Records Per Page</small><br>
            <a (click)="changePage(pageNumber)"  *ngFor="let pageNumber of pages"    [ngClass]="{'active': pageNumber==this.paginationModel.pageNumber}"  class="font-weight-bold" >{{pageNumber}}&nbsp;</a> 
          
        </p>
</section>
<div class="clear py-3"></div>
