<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>Frequently Asked Questions</h1>
	</div>
</section>
<div class="clear py-4"></div>
<section class="container">
    <div class="accordion" id="FAQs">
        <div class="card mb-3">
            <div class="card-header p-0" id="headingOne">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Q1: What is the minimum response time for procurement advertisements?
                    </button>
                </h4>
            </div>      
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#FAQs">
                <div class="card-body">
                    <p>In terms of Rule 13 of the Public Procurement Rules 2004 the minimum response time shall not be less than fifteen (15) days for national competitive bidding and thirty (30) days for international competitive bedding. The Procuring Agencies can increase the response time depending upon nature of procurement.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingTwo">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Q2: How can procuring agency calculate response time? 
                    </button>
                </h4>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#FAQs">
                <div class="card-body">
                    <p>The response time shall be calculated from the date of first publication of the advertisement in newspaper or posting on the {{this.userUtilsService.selectedEnvironment?.Letter}}PPRAâ€™s website. In case if the advertisement is mandatory to advertise in both print and {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA website in terms of Rule 12 of Public Procurement Rules, 2004 the response time shall be calculated from the day of first publication in the newspaper.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingThree">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Q3: Can procuring agency fix amount for bid security?
                    </button>
                </h4>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#FAQs">
                <div class="card-body">
                    <p>The procuring agency may require the bidders to furnish a fixed amount of bid security not exceeding five percent of the estimated value of procurement determined by the procuring agency. Provided that in case where the procuring agency does not require the bid security, the bidder shall submit bid securing declaration on the format prescribed by the Authority in Standard Procurement Documents.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingFour">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Q4: Can procuring agency reject bids without assigning any reason?
                    </button>
                </h4>
            </div>
            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#FAQs">
                <div class="card-body">
                    <p>In terms of Rule 33(1) of the Public Procurement Rules 2004 the procuring agency may reject all bids or proposals at any time prior to the acceptance of a bid or proposal. The procuring agency shall immediately give notice of rejection of bid(s) to all bidders. On request by the bidders, the Procuring Agency shall intimate the reasons of rejection of bids but will incur no liability on this account nor is required to justify the rejection of bids.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingFive">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        Q5: What course of action will be adopted by the procuring agency when all the bids prices substantially exceed the cost estimated/market value?
                    </button>
                </h4>
            </div>
            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#FAQs">
                <div class="card-body">
                    <p>The procuring agency is allowed to cancel all the bids prior to acceptance as provided under Rule-33 of the Public Procurement Rules, 2004 and invoke Rule 34 of the said Rules for re-bidding.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingSix">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        Q6: Whether the procuring agency can enter into negotiations with the bidders for the reduction in the prices or to call for new bids?
                    </button>
                </h4>
            </div>
            <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#FAQs">
                <div class="card-body">
                    <p>Negotiations with the bidder having submitted the lowest evaluated bid or with any other bidder are not allowed as provided under Rule 40 of Public Procurement Rules, 2004.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingSeven">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        Q7: Whether negotiation if allowed to be made with all the bidders or only with the lowest evaluated bidders?
                    </button>
                </h4>
            </div>
            <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#FAQs">
                <div class="card-body">
                    <p>Negotiations with the bidder having submitted the lowest evaluated bid or with any other bidder are not allowed as provided under Rule 40 of Public Procurement Rules, 2004.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingEight">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        Q8: Many procuring agencies are signing Memorandum Of Understanding (MOU) with the Transparency International Pakistan empowering the latter to scrutinize contracts to be signed by them. Is this practice not an overlap of {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA mandate by a Civil Society Organization and is it in line with {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA Ordinance 2002 and PP rules 2004?
                    </button>
                </h4>
            </div>
            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#FAQs">
                <div class="card-body">
                    <p>Only {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA has been mandated by its Ordinanace-2002 to monitor and regulate the application of law and rules relating to public procurement of all goods, services and works. {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA does not indulge in line clearance functions nor this function is covered by law or has been outsourced by {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA to any Civil Society Organization in /outside the country. Public sector organizations entering into MOU entailing line clearance function do so at their own risk and cost.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingNine">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                        Q9: How to Upload Tender Documents at {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA's Web site?
                    </button>
                </h4>
            </div>
            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#FAQs">
                <div class="card-body">
                    <ol>
                        <li class="mb-2"><strong>By Online</strong> User ID and Password Can be provided on request at email address <a href="mailto:info@pprasindh.org.pk">info@pprasindh.org.pk</a> for online tender submission.</li>
                        <li class="mb-2"><strong>By Post</strong> Soft copies of Tender documents can be sent by  Post/Courier to "IT section Public Procurement Regulatory Authority, 1st Floor FBC Building G5/2 Islamabad"</li>
                        <li class="mb-2"><strong>E-mail</strong> You can send your tender documents as attachment with file extension "DOC" MS Word File, "JPG" (Image File), "PDF" (Acrobat Reader File) at email address <a href="mailto:info@pprasindh.org.pk">info@pprasindh.org.pk</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingTen">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                        Q10: It is not clear whether all annual requirements if known would have to be advertised in advance on PPRA's website or just the main items.
                    </button>
                </h4>
            </div>
            <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#FAQs">
                <div class="card-body">
                    <p>Indicative requirements of annual procurement should be advertised under Rule 9, at macro level, on {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA's website as well as on the website of the organization concerned for advance information of prospective bidders. Detailed advertisement for processing the procurements should subsequently be made, as required from time to time, in accordance with Rule 12.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingElev">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseElev" aria-expanded="false" aria-controls="collapseElev">
                        Q11: If a tender for procurement of goods over five hundred thousand rupees and up to the limit of three million rupees is launched on Authority's website, would there be any compulsion on the procuring agency to advertise it in the print media also.
                    </button>
                </h4>
            </div>
            <div id="collapseElev" class="collapse" aria-labelledby="headingElev" data-parent="#FAQs">
                <div class="card-body">
                    <p>If a tender for procurement of goods services and works costing  over rupees five hundred thousand and up to the limit of three million rupees has been launched on Authority's website, it is not mandatory to put the advertisement on print media.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingTwel">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseTwel" aria-expanded="false" aria-controls="collapseTwel">
                        Q12:
                        <p>If only one tender/bid is received in response to a tender notice advertised in both or one of the media (Authority's website/ print media), should the single tender be accepted or re-advertised the tender.</p>
                        If no tender/ bid is received against a requirement, what method of procurement is recommended to be adopted (Re-advertisement or Direct Contracting)
                    </button>
                </h4>
            </div>
            <div id="collapseTwel" class="collapse" aria-labelledby="headingTwel" data-parent="#FAQs">
                <div class="card-body">
                    <h6>Rule-38B.    Single responsive bid in goods, works and services.-</h6>
                    <ol>
                        <li>The procuring agency shall consider single bid in goods, works and services if it-</li>
                        <ol type="I">
                            <li>meets the evaluation criteria, ensures compliance of specifications and other terms &   conditions expressed in advertisement or bid solicitation documents;</li>
                            <li>is not in conflict with any provision of the Ordinance;</li>
                            <li>conforms to the technical specifications;</li>
                            <li>has financial conformance in terms of rate reasonability:</li>
                        </ol>
                        <p>Provided that except unsolicited proposal, in case of pre-qualification proceedings single bid shall not be entertained.</p>
                        <li> The procuring agency shall make a decision with due diligence and in compliance with general principles of procurement like economy, efficiency and value for money.</li>
                    </ol>
                    <p>Re-advertisement would be a preferred option. Direct contracting could also be used provided it meets the prescribed condition for direct contracting.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingThir">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseThir" aria-expanded="false" aria-controls="collapseThir">
                        Q13: Under the Rule if preference was allowed to a domestic or national supplier/contractor, magnitude of price preference to be accorded should be mentioned. However, a clarification whether the magnitude of price preference should be in figure or the percentage, needs to be obtained.
                    </button>
                </h4>
            </div>
            <div id="collapseThir" class="collapse" aria-labelledby="headingThir" data-parent="#FAQs">
                <div class="card-body">
                    <p>Preference to domestic or national suppliers or contractors should be in accordance with policies of the Federal Government. The magnitude of preference, presently in vogue-but for goods of domestic value addition, has been specified in Ministry of commerce <a target="_blank" href="https://www.{{this.userUtilsService.selectedEnvironment?.Letter}}PPRA.org.pk/doc/commerce.pdf">S.R.O 827(1)2001 dated 3rd December, 2001</a> as amended on 28.09.2002. This S.R.O can be downloaded from {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA's website.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingFourteen">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                        Q14:
                        <ul type="a">
                            <li>It has been observed that financial limits for procurement under sub rules (a) & (b) are not sufficient. These limits need to be reviewed.</li>
                            <li>Moreover, under sub rule c(iv) a procuring agency may engage in direct contracting for repeat orders not exceeding fifteen percent of original procurement. It is not clear how this rule could be applied in case of procurement of spare parts/equipment.</li>
                        </ul>
                    </button>
                </h4>
            </div>
            <div id="collapseFourteen" class="collapse" aria-labelledby="headingFourteen" data-parent="#FAQs">
                <div class="card-body">
                    <p>Procurement of spare parts has been dealt with separately under Rule 42(c)(i) which reads as "A" procuring agency shall only engage in direct contracting if the following conditions exist, namely:-
                        The procurement concerns the acquisition of spare parts or supplementary service from original manufacturer or supplier:Provided that the same are not available from alternative sources;
                        In case of procurement of spare parts/equipment through open competitive bidding when spare parts of required specifications are available from multiple sources, repeat orders not exceeding fifteen percent of the original procurement of the spare parts/equipment may be placed under rule 42(c)(iv)</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingFifteen">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                        Q15: Which type of record is to be maintained for audit.
                    </button>
                </h4>
            </div>
            <div id="collapseFifteen" class="collapse" aria-labelledby="headingFifteen" data-parent="#FAQs">
                <div class="card-body">
                    <p>Following documents may be kept for maintenance record of procurement by the procuring agencies for requirements of audit.</p>
                    <ul type="1">
                        <li>Detail with regard to the originating of demand. It explains the nature and quantity of the items to be produced. It should be supported by justification report.</li>
                        <li>Purchase requisition may be maintained. It shows specification as well as quantity of the items to be produced. It also contains the name/ names of the consignee, to whom the material is to be delivered.<br>
                            <h6 class="text-primary font-weight-medium mt-3">Tender documents/ file</h6>
                        </li>
                        <li>It includes consolidation of tender documents, preparation of tabulation statements, analysis of sales, comparative statement, evaluation of tender, documents by the technical committee, approval of the competent authority.</li>
                        <li>Purchase order file be maintained. It includes the documentary detail of the quantity ordered, rate accepted and delivery period, inspection of material, chemical analysis, acceptance of material on basis of suitability report from the consignee and finally, delivery of material to the ultimate consignee.</li>
                        <li>Material receipt note be maintained, it shows description and quantity of material accepted. It is an authority for payment.</li>
                        <li>Contractor bills:-These show detail of material supplied and must always be accompanied by original copy of material, Receipt Note and original sales Tax Invoice.</li>
                        <li>Budget file containing appropriation for the purchases.</li>
                        <li>File relating to sanction/ approval of competent authority to make the purchase and make payments and related documents, necessary for audit including need assessment, actual quantities demand, difference if any, from items purchased and those required along with justifications.</li>
                        <li>Record of goods, services and works to be planned/ procured in a year before publishing advertisements.</li>
                        <li>Record of spare parts/ equipments or services procured from the original manufacturer or record of goods, services and works actually procured in a particular year.</li>
                        <li>Copies of tenders/ quotations invited to make the purchases along with specifications.</li>
                        <li>In case of limited tenders, sealed quotation, comparatives statements and invitation record.</li>
                        <li>Record showing the particulars of bidders who purchased tender documents, deposited bids and participated in the tendering process.</li>
                        <li>Record of bidders who deposited bid security to the procuring agency.</li>
                        <li>Pre-qualification record of suppliers/ contractors.</li>
                        <li>Technical & Financial bid evaluation reports of each type of procurement.</li>
                        <li>Newspapers in which advertisements published against each procurement.</li>
                        <li>Record of cash deposit receipt, received from the bidders.</li>
                        <li>Stock Register containing the entries of purchase and specification of items purchased.</li>
                        <li>Register showing details of items procured services made as well as work done.</li>
                        <li>The record pertaining to requisitions made by the department as well as approval of the competent authority for said procurements.</li>
                        <li>Record of black-listed suppliers/ contractors.</li>
                        <li>Purchase committee should also keep a record of evaluation of input cost of consumable stores on the basis of project usage in addition to the cost of the equipment while evaluating the bids.</li>
                        <li>Delivery of challans of suppliers/ contractors.</li>
                        <li>Instructions to bidders.</li>
                        <li>Proceedings of committees.</li>
                        <li>Final approval of competent authority for procurement from a particular bidder at specific rate.</li>
                        <li>Acceptance letter to successful bidders.</li>
                        <li>Bank guarantees.</li>
                        <li>In case of imported goods, import invoices, inventories, indents, letter of credit and shipping documents.</li>
                        <li>Issue indents/ acknowledgements.</li>
                        <li>Pre-qualification record of suppliers and contractors.</li>
                        <li>Inspection rates/ laboratory tests and analysis reports of samples provided by suppliers.</li>
                        <li>Tender opening committee should record their proceedings and if feel necessary negotiation be carried out with the different suppliers/ contractors.</li>
                        <li>Delegation of financial powers.</li>
                        <li>Codal requirements.</li>
                        <li>Contractor profile.</li>
                        <li>Firms registered with sales tax department.</li>
                        <li>Test reports.</li>
                        <li>All latest instruction/ direction/ discretion/ rules/ regulations for procurement.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingSixteen">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                        Q16: Whether procuring agencies can prescribe a condition that the bidders involved in litigation over procurement matters with them or other Government departments are not allowed to participate in their procurement proceedings?
                    </button>
                </h4>
            </div>
            <div id="collapseSixteen" class="collapse" aria-labelledby="headingSixteen" data-parent="#FAQs">
                <div class="card-body">
                    <p>Article 4 of the Constitution, 1973 of the Islamic Republic of Pakistan provides the right to an individual to be dealt with in accordance with law etc. Article 8 of the Constitution, 1973 envisages that the law inconsistent with or derogation of fundamental rights to be avoided. Article 199 (2) of the Constitution emphasises upon that subject to the constitution, the right to move a high court for the enforcement of any of the fundamental right conferred by Chapter 1 part II shall not be abridged.</p>
                    <p>In terms of rule 48(5) of Public Procurement Rules, 2004 any bidder not satisfied with the decision of the redressal grievances committee of the procuring agency can lodge an appeal in the relevant court of jurisdiction.</p>
                    <p>After coming into force of the procurement contract, disputes between the parties to the contract shall be settled through arbitration as per rule 49 (1) of Public Procurement Rules, 2004. Moreover, rule 49 (2) of the Rules provides that procuring agency shall provide the method of arbitration in the procurement contract not inconsistent with the law of the Government. As such, a condition to restrict the bidders who have/had filed writ petition(s) in the courts would not be appropriate.  However, a procuring agency may ask for the information relating to the court cases of the bidders and may assess its impact, if any, on the proposed procurement, keeping in view the principles of fairness, transparency and equality.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingSevteen">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseSevteen" aria-expanded="false" aria-controls="collapseSevteen">
                        Q17: Can any member of general public be allowed besides the bidders or their representatives to attend the opening of bids under rule 28 of Public Procurement Rules, 2004?
                    </button>
                </h4>
            </div>
            <div id="collapseSevteen" class="collapse" aria-labelledby="headingSevteen" data-parent="#FAQs">
                <div class="card-body">
                    <p>Rule 28 of Public Procurement Rules, 2004 states that all bids shall be opened publicly in the presence of the bidders or their representatives who may choose to be present, at the time and place announced prior to the bidding. As the general public is defined as bidders or their representatives, the procuring agency can restrict the attendance accordingly.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingEighteen">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                        Q18: Whether public sector enterprises are exempted from submission of bid security or performance guarantee in open bidding of procuring agencies or otherwise?
                    </button>
                </h4>
            </div>
            <div id="collapseEighteen" class="collapse" aria-labelledby="headingEighteen" data-parent="#FAQs">
                <div class="card-body">
                    <p>Rule 25 of the Public Procurement Rules, 2004 provides that procuring agencies may require the bidders to furnish bid security not exceeding 5% of the bid price. The rule is applicable irrespective of the fact whether bidder is from public sector or private sector. However, method of submission of bid security is not given in rule 25, which needs to be elaborated in the bidding documents. It is upto the procuring agency to decide method of submission of bid security in an appropriate manner acceptable to the procuring agency. The form(s) in which bid security is required / acceptable should be clearly mentioned in the bidding documents.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingNinteen">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseNinteen" aria-expanded="false" aria-controls="collapseNinteen">
                        Q19: What is meant by local original manufacturer of motor vehicles?
                    </button>
                </h4>
            </div>
            <div id="collapseNinteen" class="collapse" aria-labelledby="headingNinteen" data-parent="#FAQs">
                <div class="card-body">
                    <p>In terms of Rule 42(c)(vii) of Public Procurement Rules, 2004 a procuring agency shall engage in direct contracting only if purchase of motor vehicle is made from local original manufacturers or their authorized agents at manufacturerâ€™s price. The matter of definition of local original manufacturer has been discussed with the Ministry of Industries and Production. It has been clarified by the Ministry that those cars whose Complete Knock Down (CKD) kits have been imported and assembled in Pakistan and are also utilizing local content (in-house or vendorized) irrespective of level of localization and also pay all value added local taxes and availing concessionary SROs should be considered as locally manufactured cars. (In all such processes assembling is the final process of production.) However, those cars which have been imported in a Completely Built Unit (CBU) condition cannot be considered as locally manufactured cars.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingTwinty">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseTwinty" aria-expanded="false" aria-controls="collapseTwinty">
                        Q20: Whether {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA has the powers to settle the audit paras made on mis-procurements / violations of Public Procurement Rules, 2004 and regulations in accordance with {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA Ordinance, 2002 or otherwise?
                    </button>
                </h4>
            </div>
            <div id="collapseTwinty" class="collapse" aria-labelledby="headingTwinty" data-parent="#FAQs">
                <div class="card-body">
                    <p>PPRA has not been delegated any powers to regularize the mis-procurement or settle the audit paras of any procuring agency under {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA Ordinance, 2002 or in terms of Public Procurement Rules, 2004 and relevant regulations made thereunder.</p>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header p-0" id="headingTwintyOne">
                <h4 class="mb-0">
                    <button class="btn btn-link btn-block text-left text-primary font-weight-semi-bold text_20" type="button" data-toggle="collapse" data-target="#collapseTwintyOne" aria-expanded="false" aria-controls="collapseTwintyOne">
                        Q21: Whether the procuring agencies can procure goods / commodities / works / services etc. on the rates fixed by the procuring agency itself?
                    </button>
                </h4>
            </div>
            <div id="collapseTwintyOne" class="collapse" aria-labelledby="headingTwintyOne" data-parent="#FAQs">
                <div class="card-body">
                    <p>Rule 42 (c) (vi) of Public Procurement Rules, 2004 clarifies that a procuring agency shall engage in direct contracting when the prices of goods, services or works are fixed by the Government or any other authority, agency or body duly authorized by the Government on its behalf. This provision will be applicable on those prices on which that item is available on uniform rates in the market and holding of competition for such procurements may not bring any value for money to the procuring agency.</p>
                </div>
            </div>
        </div>
    </div>
    <p>Send mail to <a href="mailto:info@pprasindh.org.pk">info@pprasindh.org.pk</a> with questions or comments about this web site.</p>
</section>
<div class="clear py-3"></div>