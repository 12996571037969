import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import b64toBlob from 'b64-to-blob';
import * as html2pdf from 'html2pdf.js';
import { DocumentTemplateSection } from 'src/app/core/models/DocumenttemplateSection';
import { SBDReportRequestModel } from 'src/app/core/models/SBDReportRequestModel';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';

@Component({
  selector: 'app-standard-bidding-document',
  templateUrl: './standard-bidding-document.component.html',
  styleUrls: ['./standard-bidding-document.component.scss']
})
export class StandardBiddingDocumentComponent implements OnInit {
  lstSession: any[] = [];
  tableData:any[];
  @Input() documentTemplateID:number;
  @Input() wholePkg:boolean;
  @Input() lotNo:number;
  @Input() procurementPlanDetailID:number;
  @Input() publishedDocument:number;
  sbdReportRequestModel:SBDReportRequestModel;
  displayHeaders:string[]=[];
  officeDetail:string;
  showHideScore:boolean;
  noOfLots:any[];
  noOfLotsValue:number=1;
  constructor(
      private PublicPortalService: PublicPortalService,
      //private datePipe: DatePipe
      ) { }

  ngOnInit(): void {
   // this.lstSession = this.tableData;
   if (localStorage.getItem("OfficeDetail") !== null) {
      this.officeDetail = String(localStorage.getItem("OfficeDetail"));
  }
  this.showHideScore=this.officeDetail==="Punjab-PPRA-Dev"?false:true;

  if(!this.wholePkg){
     // this.noOfLots=[];
      // for(let a=1;a<=this.lotNo;a++){
      //     let obj={number:a,id:a};
      //     this.noOfLots.push(obj);
      // }
      this.PublicPortalService.getNoOfLots(this.procurementPlanDetailID,this.publishedDocument)
      .subscribe((response: any) => {
        if (response.success) {
          this.noOfLots = response.data;
          this.noOfLotsValue=this.noOfLots[0].lotNumber;
        }
      })
  }
      this.getAllSections();
  }
  
  downloadReport(){
    this.sbdReportRequestModel=new SBDReportRequestModel()
    this.sbdReportRequestModel.documentTemplateID=this.documentTemplateID;
    this.sbdReportRequestModel.isTransectionalData=true;
    this.PublicPortalService.genReport(this.sbdReportRequestModel).subscribe((response
      : any) => {
      if (response != null) {
      var ContentType = "application/pdf";
      const blob = b64toBlob(response.data.fileContents, ContentType);
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL, "_blank");
      }
      });
      }
      
  
  getAllSections() {
      this.lstSession = [];
      let documentTemplateSection = new DocumentTemplateSection();
      documentTemplateSection.documentTemplateID = this.documentTemplateID;
      documentTemplateSection.isTransectionalData = true;
      documentTemplateSection.noOfLotsValue=this.noOfLotsValue;
      this.PublicPortalService.getSection(documentTemplateSection)
        .subscribe((baseResponse: any) => {
          if (baseResponse != null) {
            if (baseResponse.success != null) {
              if (baseResponse.success) {

                let obj = baseResponse.data.find((x: any) => x.documentTemplateID === this.documentTemplateID);

                if (baseResponse.data.length > 0) {
                  baseResponse.data.forEach((el: any) => {

                    if (el.documentTemplateID === this.documentTemplateID) {
                      this.lstSession.push(el);
                    }

                  })
                   this.tableData = this.lstSession

                  this.lstSession = this.lstSession.map((el) => {
                    return {
                      lov_DocumentTemplateSectionTypeID: el.lov_DocumentTemplateSectionTypeID,
                      name: el.name,
                      sectionNumber: el.sectionNumber,
                      isBidSection: el.lov_DocumentTemplateSectionTypeID === 284 ? true : false,
                      isEvaluationSection: el.lov_DocumentTemplateSectionTypeID === 308 ||el.lov_DocumentTemplateSectionTypeID === 283 || el.lov_DocumentTemplateSectionTypeID === 307 ? true : false,
                      isDynamicTableSection:el.lov_DocumentTemplateSectionTypeID === 303 ? true : false,
                      isEligibilitySection: el.lov_DocumentTemplateSectionTypeID === 306 ? true : false,
                      isCheckListSection: el.lov_DocumentTemplateSectionTypeID === 309 ? true : false,
                      isTextSection: this.checkID(el.lov_DocumentTemplateSectionTypeID),
                      template: el.template,
                      sectionID: el.sectionID,
                      sectionDetails: el.sectionDetails,
                      sectionDynamicTable:el.sectionDynamicTable,
                      tableHTML: '',
                      sectionEvaluations: el.sectionEvaluations,
                      dynamicTableInfo: el.dynamicTableInfo,

                      sectionEligibility: el.sectionEligibility,
                      sectionCheckList: el.sectionCheckList,
                      evaluationTableHTML: '',
                      dynamicTableHTML: '',
                      eligibilityHTML: '',
                      checkListHTML: '',
                      sectionFields:el.sectionFields,
                      isScore:el.isScore
                    }
                  })
                  this.lstSession.forEach((el) => {
                    if (el.sectionDetails.length > 0) {

                      el.sectionDetails.forEach((ell) => {
                        el.tableHTML = el.tableHTML + `
                          <tr>
                          <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${ell.isMandatory}</td>
                          <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${ell.bidSectionName}</td>
                          <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${ell.clause}</td>
                          <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${ell.itbNumber}</td>
                          <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${ell.instruction}</td>
                         <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${ell.description}</td>
                         </tr>
                          `
                      })
                    }

                    if (el.sectionEvaluations.length > 0) {
                      el.sectionEvaluations.forEach((ele) => {
                        el.evaluationTableHTML = el.evaluationTableHTML + `
                              <tr>
                              <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${ele.criterionTitle}</td>
                              <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${'Yes/No'}</td>
                              <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${ele.weightage}</td>
                              <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${ele.score}</td>
                             </tr>
                              `;
                      })
                    }
                    if (el.sectionEligibility.length > 0) {
                      el.sectionEligibility.forEach((elegibility) => {
                        el.eligibilityHTML = el.eligibilityHTML + `
                              <tr>
                              <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${elegibility.name}</td>
                              <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${elegibility.response}</td>
                             </tr>
                              `;
                      })
                    }
                    if (el.sectionCheckList.length > 0) {
                      el.sectionCheckList.forEach((checkList) => {
                        el.checkListHTML = el.checkListHTML + `
                              <tr>
                              <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${checkList.name}</td>
                              <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${checkList.response}</td>
                             </tr>
                              `;
                      })
                    }
                    if(el.lov_DocumentTemplateSectionTypeID==303){
                      el.sectionDetails=el.sectionDynamicTable.sectionDynamicTable[0];
                      el.sectionDynamicTable.sectionDynamicTable.forEach((eld) => {
                        this.displayHeaders= Object.keys(eld)

                        if(el.dynamicTableInfo.length>0){
                          el.dynamicTableInfo.forEach((elb) => {
                            if(eld.TrnSyncID.toString().toUpperCase() == elb.trnSyncId ){
                                 eld.subTableInfo = []
                                 eld.subTableInfo.push(elb.subTable)
                            }
                          })
                        }
                          el.dynamicTableHTML = el.dynamicTableHTML + `
                          <tr>
                          <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${eld.columnName}</td>
                          <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${eld.dataType}</td>
                          <td bordercolor="#dee2e6" style="padding:0.5rem 1rem; font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"; border:1px solid #dee2e6;">${eld.type}</td>
                         </tr>
                          `;
                      })
                  }
                    if(el.sectionFields.length > 0){
                      el.sectionFields.forEach((ele) => {
                          if(el.template.includes(ele.mappingTag) && (ele.value != null || ele.value != '')){
                          // let tempValue  = ele.dataType==='DateTime'?this.datePipe.transform(ele.value,'dd/MM/yyyy'): ele.value;
                          //     el.template = el.template.replaceAll(ele.mappingTag, tempValue);
                          }
                      })
                  }
                  })
                }
              }
            }

          }

        })
    }
    checkID(i: number) {
      if (i === 283 || i === 284)
        return false;
      else
        return true;
    }
}


