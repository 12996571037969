<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>Infirmities/Violations</h1>
	</div>
</section>
<div class="clear py-4"></div>
<section class="container">
    <div class="table-responsive">
        <table class="table border table-striped">
            <thead class="table-success">
                <tr>
                    <th>Procurement Title</th>
                    <th>Violations</th>
                    <th>Violation Attachment</th>
                    <th>Violation Created Date</th>
                    <th>Violation Created By</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of publicVoilations">
                    <td class="text-wrap text-break">{{item?.procurementTitle}}</td>
                    <td class="text-wrap text-break">{{item?.voilation}}</td>
                    <td *ngIf="item?.voilationAttachmentID>0">
                    <a (click)="getAttachmentByGUID(item?.voilationAttachmentID,item?.voilationAttachmentGUID)" class="btn btn-sm btn-primary text-white rounded-pill">
                        <i class="fas fa-download mr-1"></i> Download
                    </a>
                    </td>
                    <td>{{item?.voilationAttachmentName}}</td>
                    <td>{{item?.voilationCreatedDate}}</td>
                    <td>{{item?.createdByName}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- <nav aria-label="...">
        <ul class="pagination justify-content-center">
            <li class="page-item disabled"><a class="page-link">Previous</a></li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item active" aria-current="page"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item"><a class="page-link" href="#">4</a></li>
            <li class="page-item"><a class="page-link" href="#">5</a></li>
            <li class="page-item"><a class="page-link" href="#">Next</a></li>
        </ul>
    </nav> -->
</section>
<div class="clear py-3"></div>