import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { GetPPRAOrganizationNameRequestModel } from 'src/app/core/models/tender-filter.model';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';

@Component({
  selector: 'app-ppra-plans',
  templateUrl: './ppra-plans.component.html',
  styleUrls: ['./ppra-plans.component.scss']
})
export class PpraPlansComponent implements OnInit, OnDestroy{
  getPPRAPlanByOrganizationRequestModel:GetPPRAOrganizationNameRequestModel;
  paginationModel: PaginationModel;
  organisationPlans:any;
  currentDate = new Date();
  totalRecords:any;
  totalPages:number;
  pages= new Array();
  constructor(private userUtil:UserUtilService,private portalService:PublicPortalService ) { 
    this.paginationModel = new PaginationModel();
    this.userUtil.isPPRAPage = true;
    this.getPPRAPlanByOrganizationRequestModel = new GetPPRAOrganizationNameRequestModel();
  }

  ngOnInit(): void {
    this.getPPRAPlanByOrganization();
  }
  ngOnDestroy(): void {
    this.userUtil.isPPRAPage = false;
  }
  getPPRAPlanByOrganization(){
  
    this.getPPRAPlanByOrganizationRequestModel = new GetPPRAOrganizationNameRequestModel();
    this.getPPRAPlanByOrganizationRequestModel.pagination = this.paginationModel;
    this.getPPRAPlanByOrganizationRequestModel.pagination.orderByColumnName = null;
    this.getPPRAPlanByOrganizationRequestModel.pagination.orderBy = "desc";
    this.portalService.getPPRAPlanByOrganization(this.getPPRAPlanByOrganizationRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
                 let records = (baseResponse.data as any);
                 this.organisationPlans = (baseResponse.data as any)?.records;
                
                 this.totalRecords = records?.totalRecords;
                 this.totalPages = records?.totalPages;
                  this.pages = new Array();
                    for (let i = 1; i <= this.totalPages; i++) {
                      this.pages.push(i);
                    }
            }
          }
        }
      })
  }
  changePage(pageNumber){
    this.paginationModel.pageNumber = pageNumber;
    this.getPPRAPlanByOrganization();
  }
}
