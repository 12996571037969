<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>
			<img src="../assets/images/ppra.png" width="50" class="mr-1">
			National Institute of Procurement <small class="font-weight-normal">(NIP)</small>
		</h1>
	</div>
</section>
<section class="container">
	<div class="row">
		<div class="col-md-4 col-sm-6">
			<div class="bg-light h-100 p-4">
				<div class="nav flex-column list-group" id="training-tab" role="tablist" aria-orientation="vertical">
					<a class="nav-link list-group-item list-group-item-action active" id="home-tab"
						data-toggle="pill" href="#home" role="tab" aria-controls="home"
						aria-selected="true">Home</a>
					<a class="nav-link list-group-item list-group-item-action" id="picture-gallery-tab"
						data-toggle="pill" href="#picture-gallery" role="tab" aria-controls="picture-gallery"
						aria-selected="false">Picture Gallery</a>
					<a class="nav-link list-group-item list-group-item-action" id="training-outlines-tab"
						data-toggle="pill" href="#training-outlines" role="tab" aria-controls="training-outlines"
						aria-selected="false">Training outlines</a>
					<a class="nav-link list-group-item list-group-item-action" id="resourse-persons-tab"
						data-toggle="pill" href="#resourse-persons" role="tab" aria-controls="resourse-persons"
						aria-selected="false">Resourse Persons</a>
					<a class="nav-link list-group-item list-group-item-action" id="training-schedule-tab"
						data-toggle="pill" href="#training-schedule" role="tab" aria-controls="training-schedule"
						aria-selected="false">Training Schedule</a>
					<a class="nav-link list-group-item list-group-item-action" id="NIP-alumni-tab" data-toggle="pill"
						href="#NIP-alumni" role="tab" aria-controls="NIP-alumni" aria-selected="false">NIP Alumni</a>
					<a class="nav-link list-group-item list-group-item-action" id="international-training-links-tab"
						data-toggle="pill" href="#international-training-links" role="tab"
						aria-controls="international-training-links" aria-selected="false">International Training
						Links</a>
					<a class="nav-link list-group-item list-group-item-action" id="contact-tab" data-toggle="pill"
						href="#contact" role="tab" aria-controls="contact" aria-selected="false">Contact Us</a>
				</div>

			</div>
		</div>
		<div class="col-md-8 col-sm-7 py-4">
			<div class="tab-content" id="training-tabContent">
				<!---home--->
				<div class="tab-pane fade show active" id="home" role="tabpanel"
					aria-labelledby="home-tab">
					<h3 class="pb-2">Introduction</h3>
					<p>Training of government officials in
						{{this.userUtilsService.selectedEnvironment?.Name}} public procurement was started by
						{{this.userUtilsService.selectedEnvironment?.Name}} Public Procurement Regulatory Authority
						({{this.userUtilsService.selectedEnvironment?.Name}} PPRA) under a 3 years World Bank Capacity
						Building Project in 2006. These training activities were formalized through the establishment of
						National Institute of Procurement in 2009.</p>
					<p>This Institute has been equipped with modern facilities and can accommodate around 20
						participants at a time. Regular interactive training programs on Public Procurement Rules and
						Procedures are organized on fortnightly basis.
						{{this.userUtilsService.selectedEnvironment?.Name}} PPRA conducts training courses and practical
						sessions for capacity building of public sector as well as private sector executives and
						officials. The focus of training programs is dissemination of Public Procurement Rules 2004 and
						procedures pertaining to modern business of procurement. NIP, within its legal framework in the
						form of {{this.userUtilsService.selectedEnvironment?.Name}} PPRA Ordinance 2002,</p>
					<p>Public Procurement Rules 2004 and the {{this.userUtilsService.selectedEnvironment?.Name}} PPRA
						Regulations, will play a pivotal role in the achievement of excellence in governmental and
						institutional procurement through education, certification and professional development of
						procurement professionals.</p>
					<img src="../assets/images/down.png" class="img-center">
				</div>

				<!---Picture Gallery--->
				<div class="tab-pane fade" id="picture-gallery" role="tabpanel" aria-labelledby="picture-gallery-tab">
					<h3 class="pb-3">National Institute of Procurement Pictures Gallery</h3>

				</div>

				<!---Training Outlines--->
				<div class="tab-pane fade" id="training-outlines" role="tabpanel"
					aria-labelledby="training-outlines-tab">
					<h3 class="pb-3">Training Outlines</h3>
					<div class="row">
						<div class="col-sm-6 pb-4">
							<div class="card mb-2 h-100">
								<img src="../assets/images/lecture-01.jpg" class="card-header p-0">
								<div class="card-body">
									<div class="d-flex justify-content-between align-items-center mb-3">
										<strong class="font-weight-medium">Lecture -01</strong>
										<span class="badge btn-rounded alert-success btn-100 py-2">90 min</span>
									</div>
									<h6 class="text-primary font-weight-semi-bold">Introduction to
										{{this.userUtilsService.selectedEnvironment?.Letter}}PPRA and Public Procurement
										Rules 2004 (Rules 1 through 14)</h6>
									<h6 class="font-weight-medium">Lecture contents:</h6>
									<ol class="pl-3" type="a">
										<li class="mb-1">Need for procurement reforms</li>
										<li class="mb-1">Introduction to PPRA Ordinance 2002</li>
										<li class="mb-1">Definitions given in the Public Procurement Rules 2004</li>
										<li class="mb-1">Scope and applicability of Public Procurement Rules 2004</li>
										<li class="mb-1">Principles of procurements</li>
										<li class="mb-1">International and inter-governmental commitments of the Federal Government</li>
										<li class="mb-1">Language of procurement communications and documentation</li>
										<li class="mb-1">Integrity pact</li>
										<li class="mb-1">Procurement planning</li>
										<li class="mb-1">Limitation on splitting or regrouping of proposed procurement</li>
										<li class="mb-1">Specifications</li>
										<li class="mb-1">Approval mechanism</li>
										<li class="mb-1">Methods of advertisement</li>
										<li class="mb-1">Response time for receipt of bids and proposals</li>
										<li class="mb-1">Exceptions to procurement advertisements</li>
									</ol>
								</div>
							</div>
						</div>
						<div class="col-sm-6 pb-4">
							<div class="card mb-2 h-100">
								<img src="../assets/images/lecture-02.jpg" class="card-header p-0">
								<div class="card-body">
									<div class="d-flex justify-content-between align-items-center mb-3">
										<strong class="font-weight-medium">Lecture -02</strong>
										<span class="badge btn-rounded alert-success btn-100 py-2">60 min</span>
									</div>
									<h6 class="text-primary font-weight-semi-bold">Procurement Planning Workshop</h6>
									<h6 class="font-weight-medium">Lecture contents:</h6>
									<p>To provide participants opportunity of hands-on-experience by attempting to
										prepare annual procurement plans for their organizations, on the
										{{this.userUtilsService.selectedEnvironment?.Name}} PPRA prescribed proforma.
									</p>
								</div>
							</div>
						</div>
						<div class="col-sm-6 pb-4">
							<div class="card mb-2 h-100">
								<img src="../assets/images/lecture-03.jpg" class="card-header p-0">
								<div class="card-body">
									<div class="d-flex justify-content-between align-items-center mb-3">
										<strong class="font-weight-medium">Lecture -03</strong>
										<span class="badge btn-rounded alert-success btn-100 py-2">90 min</span>
									</div>
									<h6 class="text-primary font-weight-semi-bold">Public Procurement Rules 2004 (Rules
										15 Through 37)</h6>
									<h6 class="font-weight-medium">Lecture contents:</h6>
									<ol class="pl-3" type="a">
										<li class="mb-1">Need for procurement reforms</li>
										<li class="mb-1">Introduction to PPRA Ordinance 2002</li>
										<li class="mb-1">Definitions given in the Public Procurement Rules 2004</li>
										<li class="mb-1">Scope and applicability of Public Procurement Rules 2004</li>
										<li class="mb-1">Principles of procurements</li>
										<li class="mb-1">International and inter-governmental commitments of the Federal Government</li>
										<li class="mb-1">Language of procurement communications and documentation</li>
										<li class="mb-1">Integrity pact</li>
										<li class="mb-1">Procurement planning</li>
										<li class="mb-1">Limitation on splitting or regrouping of proposed procurement</li>
										<li class="mb-1">Specifications</li>
										<li class="mb-1">Approval mechanism</li>
										<li class="mb-1">Methods of advertisement</li>
										<li class="mb-1">Response time for receipt of bids and proposals</li>
										<li class="mb-1">Exceptions to procurement advertisements</li>
									</ol>
								</div>
							</div>
						</div>
						<div class="col-sm-6 pb-4">
							<div class="card mb-2 h-100">
								<img src="../assets/images/lecture-04.jpg" class="card-header p-0">
								<div class="card-body">
									<div class="d-flex justify-content-between align-items-center mb-3">
										<strong class="font-weight-medium">Lecture -04</strong>
										<span class="badge btn-rounded alert-success btn-100 py-2">90 min</span>
									</div>
									<h6 class="text-primary font-weight-semi-bold">Public Procurement Rules 2004 Part
										Three (Rules 38 Through 51)</h6>
									<h6 class="font-weight-medium">Lecture contents:</h6>
									<ol class="pl-3" type="a">
										<li class="mb-1">Acceptance of bids</li>
										<li class="mb-1">Performance guarantee</li>
										<li class="mb-1">Limitation on negotiations</li>
										<li class="mb-1">Confidentiality</li>
										<li class="mb-1">Alternative methods of procurements</li>
										<li class="mb-1">On account payments</li>
										<li class="mb-1">Entry into force of the procurement contract</li>
										<li class="mb-1">Closing of contract</li>
										<li class="mb-1">Record of procurement proceedings</li>
										<li class="mb-1">Public access and transparency</li>
										<li class="mb-1">Redressal of grievances by the procuring agency</li>
										<li class="mb-1">Arbitration</li>
										<li class="mb-1">Mis-procurement</li>
										<li class="mb-1">Overriding effect</li>
									</ol>
								</div>
							</div>
						</div>
						<div class="col-sm-6 pb-4">
							<div class="card mb-2 h-100">
								<img src="../assets/images/lecture-01.jpg" class="card-header p-0">
								<div class="card-body">
									<div class="d-flex justify-content-between align-items-center mb-3">
										<strong class="font-weight-medium">Lecture -05</strong>
										<span class="badge btn-rounded alert-success btn-100 py-2">60 min</span>
									</div>
									<h6 class="text-primary font-weight-semi-bold">Public Precurement Regulations</h6>
									<h6 class="font-weight-medium">Lecture contents:</h6>
									<ol class="pl-3">
										<li class="mb-1">Understand the legal framework governing the public procurement in Pakistan, including the PPRA Ordinance 2002, the Public Procurement Rules 2004 and the PPRA Regulations;</li>
										<li class="mb-1">Comply with the relevant Rules while making procurement;</li>
										<li class="mb-1">Get exposure to real life case studies;</li>
										<li class="mb-1">Get experiential knowledge through involvement in workshops.</li>
									</ol>
								</div>
							</div>
						</div>
						<div class="col-sm-6 pb-4">
							<div class="card mb-2 h-100">
								<img src="../assets/images/lecture-02.jpg" class="card-header p-0">
								<div class="card-body">
									<div class="d-flex justify-content-between align-items-center mb-3">
										<strong class="font-weight-medium">Lecture -06</strong>
										<span class="badge btn-rounded alert-success btn-100 py-2">60 min</span>
									</div>
									<h6 class="text-primary font-weight-semi-bold">Bidding Documents Workshop</h6>
									<h6 class="font-weight-medium">Lecture contents:</h6>
									<p>To provide participants opportunity of hands-on-experience by attempting to prepare bidding documents for an object or class of objects.</p>
								</div>
							</div>
						</div>
						<div class="col-sm-6 pb-4">
							<div class="card mb-2 h-100">
								<img src="../assets/images/lecture-03.jpg" class="card-header p-0">
								<div class="card-body">
									<div class="d-flex justify-content-between align-items-center mb-3">
										<strong class="font-weight-medium">Lecture -07</strong>
										<span class="badge btn-rounded alert-success btn-100 py-2">60 min</span>
									</div>
									<h6 class="text-primary font-weight-semi-bold">Case Study</h6>
									<h6 class="font-weight-medium">Lecture contents:</h6>
									<ol class="pl-3" type="a">
										<li class="mb-1">Rule position on mandatory uploading of certain procurement documents such as annual procurement plans, procurement/tender notices, evaluation reports and contracts</li>
										<li class="mb-1">Overview of PPRA website</li>
									</ol>
								</div>
							</div>
						</div>
						<div class="col-sm-6 pb-4">
							<div class="card mb-2 h-100">
								<img src="../assets/images/lecture-04.jpg" class="card-header p-0">
								<div class="card-body">
									<div class="d-flex justify-content-between align-items-center mb-3">
										<strong class="font-weight-medium">Lecture -08</strong>
										<span class="badge btn-rounded alert-success btn-100 py-2">60 min</span>
									</div>
									<h6 class="text-primary font-weight-semi-bold">Case Study</h6>
									<h6 class="font-weight-medium">Lecture contents:</h6>
									<p>Participants will be given real life case study in compliance with the procurement legal framework.</p>
								</div>
							</div>
						</div>
						<div class="col-sm-6 pb-4">
							<div class="card mb-2 h-100">
								<img src="../assets/images/lecture-01.jpg" class="card-header p-0">
								<div class="card-body">
									<div class="d-flex justify-content-between align-items-center mb-3">
										<strong class="font-weight-medium">Lecture -09</strong>
										<span class="badge btn-rounded alert-success btn-100 py-2">60 min</span>
									</div>
									<h6 class="text-primary font-weight-semi-bold">Bid Evulation Workshop</h6>
									<h6 class="font-weight-medium">Lecture contents:</h6>
									<p>To provide participants opportunity of hands-on-experience by attempting to prepare bid evaluation report.</p>
								</div>
							</div>
						</div>
						<div class="col-sm-6 pb-4">
							<div class="card mb-2 h-100">
								<img src="../assets/images/lecture-02.jpg" class="card-header p-0">
								<div class="card-body">
									<div class="d-flex justify-content-between align-items-center mb-3">
										<strong class="font-weight-medium">Lecture -10</strong>
									</div>
									<h6 class="text-primary font-weight-semi-bold">Concluding Remarks</h6>
									<h6 class="font-weight-medium">Lecture contents:</h6>
									<p>Concluding remarks at the end of the workshop by MD PPRA.</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!---Resourse Persons--->
				<div class="tab-pane fade" id="resourse-persons" role="tabpanel" aria-labelledby="resourse-persons-tab">
					<h3 class="pb-3">Resourse Persons</h3>
				</div>

				<!---Training Schedule--->
				<div class="tab-pane fade" id="training-schedule" role="tabpanel"
					aria-labelledby="training-schedule-tab">
					<h3 class="pb-3">Training Schedule</h3>
					<h5 class="Roboto font-weight-semi-bold">Registration:</h5>
					<p>{{this.userUtilsService.selectedEnvironment?.Name}} Public Procurement Regulatory Authority
						({{this.userUtilsService.selectedEnvironment?.Name}} PPRA) invites nominations of suitable
						officers for the training Porgrammes on {{this.userUtilsService.selectedEnvironment?.Name}}
						Public Procurement Rules and Procedures scheduled usually every month.</p>
					<div class="clear p-2"></div>
					<div class="row justify-content-center">
						<div class="col-sm-5">
							<div class="card h-100">
								<h6 class="card-header bg-primary text-white text-center font-weight-medium">
									Training Program for Procuring Agencies
								</h6>
								<div class="card-body text-center">
									<p class="font-weight-semi-bold text-black">Level-I</p>
									<p>Basic Procurement Management (2-Days)</p>
									<h6 class="font-weight-semi-bold text-success">Rs. 10,000/Person</h6>
									<hr>
									<p class="font-weight-semi-bold text-black">Level-II</p>
									<p>Advanced Procurement Management (3-Days)</p>
									<h6 class="font-weight-semi-bold text-success">Rs. 15,000/Person</h6>
									<hr>
									<p class="font-weight-semi-bold text-black">Level-III</p>
									<p>Customized Advanced Training Program as per the requirements of Procuring
										Agencies (3-Days)</p>
									<h6 class="font-weight-semi-bold text-success">Rs. 20,000/Person</h6>
								</div>
							</div>
						</div>
						<div class="col-sm-5">
							<div class="card h-100">
								<h6 class="card-header bg-success text-white text-center font-weight-medium">
									Training Program for Suppliers<br>&nbsp;
								</h6>
								<div class="card-body text-center">
									<p class="font-weight-semi-bold text-black">Level-I</p>
									<p>Basic of Procurement Regulatory Framework (2-Days)</p>
									<h6 class="font-weight-semi-bold text-success">Rs. 10,000/Person</h6>
									<hr>
									<p class="font-weight-semi-bold text-black">Level-II</p>
									<p>Advanced Course for Competitive Participation and Contract Execution (3-Days)</p>
									<h6 class="font-weight-semi-bold text-success">Rs. 25,000/Person</h6>
								</div>
							</div>
						</div>
					</div>

					<div class="clear p-3"></div>

					<p>Nomination of officers must reach {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA at
						least 7 days prior to commencement of the course. Nominations are accepted on first come first
						serve basis as the number of seats is limited.</p>
					<p>Total training time per day (including 30 minutes tea break and one hour prayers/lunch break) is
						seven hours, starting from 9 a.m. to 4 p.m. On the first day the participants will be required
						to reach by 8:30 a.m. for registration and pre-test. The nominee should report to PPRA for
						participation in course only after confirmation of his/her registration by PPRA.</p>
					<h5 class="font-weight-semi-bold pt-3 pb-2">Course objectives:</h5>
					<p>Participants are able to:</p>
					<p class="pl-5">
						<span class="text-success font-weight-semi-bold">a)</span> Understand the legal framework
						governing the public procurement in Pakistan, including the
						{{this.userUtilsService.selectedEnvironment?.Name}} PPRA Ordinance 2002, the Public Procurement
						Rules 2004 and the PPRA Regulations;<br>
						<span class="text-success font-weight-semi-bold">b)</span> Comply with the relevant Rules while
						making procurement;<br>
						<span class="text-success font-weight-semi-bold">c)</span> Get exposure to real life case
						studies;<br>
						<span class="text-success font-weight-semi-bold">d)</span> Get experiential knowledge through
						involvement in workshops.
					</p>
				</div>

				<!---NIP Alumni--->
				<div class="tab-pane fade" id="NIP-alumni" role="tabpanel" aria-labelledby="NIP-alumni-tab">
					<h3 class="pb-3">NIP Alumni</h3>
					<div class="well">
						<div class="row">
							<h6 class="col-12 col-sm-6 col-md-4 mb-0 pt-2 font-weight-medium">
								<a href="#" data-toggle="modal" data-target="#y2006">
									<i class="far fa-hand-point-right text-primary mr-2"></i> Year 2006</a>
								<hr>
							</h6>
							<!-- Modal Year 2006 -->
							<div class="modal fade" id="y2006" tabindex="-1" aria-labelledby="y2006Label" aria-hidden="true">
								<div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
									<div class="modal-content">
										<div class="modal-header">
											<h5 class="modal-title font-weight-semi-bold" id="y2006Label">Capacity Building Training Programme on “Public Procurement Rules & Procedures”</h5>
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body">
											<h6 class="text-center">November 2006 to December 2006</h6>
											<div class="table-responsive border">
												<table class="table table-striped">
													<thead class="table-success">
														<tr>
															<th scope="col" width="50" class="font-weight-semi-bold text-black">Sr.</th>
															<th scope="col" class="font-weight-semi-bold text-black">Name & Designation</th>
															<th scope="col" class="font-weight-semi-bold text-black">Ministry / Department</th>
															<th scope="col" class="font-weight-semi-bold text-black">Training held at</th>
															<th scope="col" class="font-weight-semi-bold text-black">Duration</th>
															<th scope="col" class="font-weight-semi-bold text-black">Contact</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>1</td>
															<td>Mr. Abdul Latif Leghari<br>Deputy Secretary</td>
															<td>M/o Finance,<br>Islamabad</td>
															<td>National Institute of Banking and Finance, Islamabad</td>
															<td>06 – 07. Nov, 2006</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9209176<br><i class="fas fa-fax text-success mr-1"></i>  9211354</td>
														</tr>
														<tr>
															<td>2.</td>
															<td>Mr. Ghulam Anbia<br>Director</td>
															<td>M/o Finance<br>Controller General of Accounts (CGA) , Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9219275<br><i class="fas fa-fax text-success mr-1"></i> 92192755<br><i class="far fa-envelope text-success mr-1"></i> <a href="mailto:ghulamanbia@yahoo.com">ghulamanbia@yahoo.com</a></td>
														</tr>
														<tr>
															<td>3</td>
															<td>Mr. M. Ismail<br>Procurement Specialist</td>
															<td>M/o Finance<br>Auditor General of Pakistan, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9224034<br><i class="fas fa-fax text-success mr-1"></i> 9224044<br><i class="far fa-envelope text-success mr-1"></i> <a href="mailto:iizee55@yahoo.com">iizee55@yahoo.com</a></td>
														</tr>
														<tr>
															<td>4.</td>
															<td>Mr. Umar dad Afridi<br>Section Officer</td>
															<td>M/o Commerce, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9205745<br><i class="fas fa-fax text-success mr-1"></i> 9205241<br><i class="far fa-envelope text-success mr-1"></i> <a href="mailto:Umardad@yahoo.com">Umardad@yahoo.com</a></td>
														</tr>
														<tr>
															<td>5.</td>
															<td>Mr. Ejaz Asad Rasool<br>DeputySecretary</td>
															<td>Ministry of Education, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 92...5</td>
														</tr>
														<tr>
															<td>6.</td>
															<td>Mr. Muhammad Asmat Malik<br>Deputy Secretary</td>
															<td>Election Commission of Pakistan, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9204244<br><i class="fas fa-fax text-success mr-1"></i> 9205300</td>
														</tr>
														<tr>
															<td>7.</td>
															<td>Mr. Nadir Shah<br>Director</td>
															<td>Establishment Division, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9252308<br><i class="fas fa-fax text-success mr-1"></i> 9252363</td>
														</tr>
														<tr>
															<td>8.</td>
															<td>Mr. M. Aziz Khan<br>Deputy Secretary</td>
															<td>M / o Industries &amp; Production, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9202846</td>
														</tr>
														<tr>
															<td>9.</td>
															<td>Mr. Shah din Sheikh<br>Superintendent Engineer</td>
															<td>Ministry of Housing &amp; Works, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9260845</td>
														</tr>
														<tr>
															<td>10.</td>
															<td>Mr. Amjad Shamim<br>Manager</td>
															<td>-do-</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9215981-83<br><i class="fas fa-fax text-success mr-1"></i> 9215985<br><i class="fas fa-envelope text-success mr-1"></i> <a href="mailto:mjad@npo.gov.pk">mjad@npo.gov.pk</a></td>
														</tr>
														<tr>
															<td>11.</td>
															<td>Mr. Zulfiqar Ali Nasir<br>Section Officer</td>
															<td>M/o Petroleum &amp; Natural Resources, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9213286<br><i class="fas fa-fax text-success mr-1"></i> 9201803</td>
														</tr>
														<tr>
															<td>12.</td>
															<td>Mr. Tariq Mahmood Javaid, Section Officer</td>
															<td>-do-</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9208233<br><i class="far fa-envelope text-success mr-1"></i> <a href="mailto:Tariqmahmood_786@hotmail.com">Tariqmahmood_786@hotmail.com</a></td>
														</tr>
														<tr>
															<td>13.</td>
															<td>Mr. Abdul Sattar Khan<br>Procurement Specialist</td>
															<td>Central Board of Revenue, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9206788<br><i class="far fa-envelope text-success mr-1"></i> <a href="mailto:Sattar.khan@cbr.gov.pk">Sattar.khan@cbr.gov.pk</a></td>
														</tr>
														<tr>
															<td>14.</td>
															<td>Hafiz Shahid Abbas<br>Second Secretary</td>
															<td>-do-</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9207973<br><i class="fas fa-fax text-success mr-1"></i> 9203298<br><i class="far fa-envelope text-success mr-1"></i> <a href="mailto:hafiz.shahid@cbr.gov.pk">hafiz.shahid@cbr.gov.pk</a></td>
														</tr>
														<tr>
															<td>15.</td>
															<td>Mr. Hamid Mahmood Rana<br>Section Officer</td>
															<td>M/o Science &amp; Technology, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9205302<br><i class="fas fa-fax text-success mr-1"></i> 9201050</td>
														</tr>
														<tr>
															<td>16.</td>
															<td>Mrs. Nazia Khalid<br>Deputy Secretary</td>
															<td>M/o Social Welfare &amp; Special Education, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9263276<br><i class="fas fa-fax text-success mr-1"></i> 9263272</td>
														</tr>
														<tr>
															<td>17.</td>
															<td>Mr. Ather Sajjid Khan<br>Section Officer</td>
															<td>Minority Affairs Division, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9202273<br><i class="fas fa-fax text-success mr-1"></i> 9218773</td>
														</tr>
														<tr>
															<td>18.</td>
															<td>Mr. Muhammad Aggan Dhari<br>Section Officer</td>
															<td>National Assembly Secretariat, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9207770</td>
														</tr>
														<tr>
															<td>19.</td>
															<td>Mr. Muhammad Asghar Malik<br>Deputy Secretary</td>
															<td>M/o Textile, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9217236</td>
														</tr>
														<tr>
															<td>20.</td>
															<td>Mr. Arif Mahmood<br>Chief Meteorologist</td>
															<td>Defence Division, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9250368<br><i class="fas fa-fax text-success mr-1"></i> 92503668<br><i class="far fa-envelope text-success mr-1"></i> <a href="mailto:arifmahmood1954@hotmail.com">Arifmahmood1954@hotmail.com</a></td>
														</tr>
														<tr>
															<td>21.</td>
															<td>Mr. Naeem Shah<br>Director</td>
															<td>Defence Division, Islamabad</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 021-9261404<br><i class="fas fa-fax text-success mr-1"></i> 021-9261405<br><i class="far fa-envelope text-success mr-1"></i> <a href="mailto:anaeemshah1956@yahoo.com">naeemshah1956@yahoo.com</a></td>
														</tr>
														<tr>
															<td>22.</td>
															<td>Mr. Abdul Hamid Chaudhry<br>Deputy Secretary (Admn)</td>
															<td>M/o Finance</td>
															<td>National Institute of Banking and Finance, Islamabad</td>
															<td>04 – 05. Dec, 2006</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9209164</td>
														</tr>
														<tr>
															<td>23.</td>
															<td>Mr. Kamran Khattak<br>Assistant Director</td>
															<td>Central Directorate of National Savings</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9206169 ...-9502685</td>
														</tr>
														<tr>
															<td>24.</td>
															<td>Mr. Azmat Hussain Khan<br>Section Officer (Admn-II)</td>
															<td>Cabinet Division</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9201455</td>
														</tr>
														<tr>
															<td>25.</td>
															<td>Mr. Mazher Yasin Watto<br>Deputy Director</td>
															<td>M/o Foreign Affairs</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9207917</td>
														</tr>
														<tr>
															<td>26.</td>
															<td>Mr. Waheed Ahmed<br>Internal Auditor</td>
															<td>-do-</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9202309</td>
														</tr>
														<tr>
															<td>27.</td>
															<td>Dr. Imdad Ali Ghangro<br>Section Officer (Admn/Gen)</td>
															<td>M/o Narcotics Control</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 92145826</td>
														</tr>
														<tr>
															<td>28.</td>
															<td>Mr. Zahid Ullah Shah<br>Section Officer (Admn/Gen)</td>
															<td>M/o Parliamentary Affairs</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9213508</td>
														</tr>
														<tr>
															<td>29.</td>
															<td>Malik Khalid Mahmood<br>Deputy Director</td>
															<td>Pakistan Science Foundation</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9215064</td>
														</tr>
														<tr>
															<td>30.</td>
															<td>Mr. Shakeel Ahmed <br>Senior Accounts Officer</td>
															<td>Pakistan Council for Science and Technology</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9222637</td>
														</tr>
														<tr>
															<td>31.</td>
															<td>Mr. Abdul Naeem<br>Admin Officer</td>
															<td>National Institute of Electronics</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9258501-4</td>
														</tr>
														<tr>
															<td>32.</td>
															<td>Mr. Muhammad Ameen<br>Assistant Director (Accounts)</td>
															<td>Wafaqi Mohtasib Secretariat</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9202172</td>
														</tr>
														<tr>
															<td>33.</td>
															<td>Mr. Muhammad Khalid<br>Section Officer (G)</td>
															<td>M/o Water &amp; Power</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9252313</td>
														</tr>
														<tr>
															<td>34.</td>
															<td>Mrs. Saira Furqan<br>Manager</td>
															<td>M/o Women Development</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9257631 ...-5098221</td>
														</tr>
														<tr>
															<td>35.</td>
															<td>Mr. Falak Sher Malik<br>Section Officer</td>
															<td>Election Commission of Pakistan</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9203300 ...-9898229</td>
														</tr>
														<tr>
															<td>36.</td>
															<td>Mr. Abdul Qaddus<br>Deputy Director</td>
															<td>M/o Interior</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9261241</td>
														</tr>
														<tr>
															<td>37.</td>
															<td>Mr. Sajjad Ahmed<br>Section Officer (GA)</td>
															<td>Overseas Pakistani Division</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9252585</td>
														</tr>
														<tr>
															<td>38.</td>
															<td>Mr. Jan Muhammad<br>Deputy Director</td>
															<td>Pakistan Metrological Department</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 925... ...-5512317</td>
														</tr>
														<tr>
															<td>39.</td>
															<td>Mr.
															Sana-ullah Sial 
															<br>Section
															Officer (G) 
															</td>
															<td>
															M/o Local Govt &amp; 
															Rural Development 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9204383 
															</td>
														</tr>
														<tr>
															<td>40.</td>
															<td>Mr.
															Munir Ahmed Alvi 
															<br>Accounts
															Officer 
															</td>
															<td>
															Board of Investment 
															</td>
															<td>National Institute of Banking and Finance, Islamabad</td>
															<td>27 – 28. Dec, 2006</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9201604 
															</td>
														</tr>
														<tr>
															<td>41.</td>
															<td>Mr.
															M. Faisal Khokhar 
															<br>Dy.
															Director 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9221826 
															</td>
														</tr>
														<tr>
															<td>42.</td>
															<td>Agha
															Gul Hameed 
															<br>Dy.
															G.M 
															</td>
															<td>
															Industries &amp; Production Division 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 
															5584157 
															</td>
														</tr>
														<tr>
															<td>43.</td>
															<td>Mr.
															S. Zia-ul-Hassan 
															<br>Manager 
															</td>
															<td>
															State Engineering Corp. 
															Industries &amp; Production Division 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9208848 
															</td>
														</tr>
														<tr>
															<td>44.</td>
															<td>Mr.
															Haidder Zaman Khattak 
															<br>Dy.
															Director 
															</td>
															<td>
															Pakistan Science Foundation 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9201976 
															</td>
														</tr>
														<tr>
															<td>45.</td>
															<td>Mr.
															Arif Karim 
															<br>S.O 
															</td>
															<td>
															Ministry of Textile Industry 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9203626 
															</td>
														</tr>
														<tr>
															<td>46.</td>
															<td>Mr.
															Muhammad Ishaque Mahar, D.S 
															</td>
															<td>
															Overseas Pakistanis Division 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9252566 
															</td>
														</tr>
														<tr>
															<td>47.</td>
															<td>Mr.
															Azmat Hayat Khan 
															<br>Director 
															</td>
															<td>
															Defence Division 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9250598 
															</td>
														</tr>
														<tr>
															<td>48.</td>
															<td>Mr.
															Shoukat Ali Awan 
															<br>C.M 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 
															042-9200208 
															</td>
														</tr>
														<tr>
															<td>49.</td>
															<td>Mr.
															Shoukat Ali Qasim 
															<br>Meteorologist 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 
															...-2401846 
															</td>
														</tr>
														<tr>
															<td>50.</td>
															<td>Mr.
															Shams-ul-Haq 
															<br>S.O 
															</td>
															<td>
															Ministry of Science &amp; Technology 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 
															...-4245267 
															</td>
														</tr>
														<tr>
															<td>51.</td>
															<td>Major
															Khurshid Ahmed Khan 
															<br>Deputy
															Director 
															</td>
															<td>
															Defence Division (ASF) 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 
															...-3764457 
															</td>
														</tr>
														<tr>
															<td>52.</td>
															<td>Syed
															Javed Hassan Rizvi 
															<br>S.
															Supply Officer 
															</td>
															<td>
															Defence Division (CAA) 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 
															...-3665760 
														</td>
														</tr>
														<tr>
															<td>53.</td>
															<td>Mr.
															Faqir Khan 
															<br>Supply
															Officer 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td>-do-</td>
														<td>
														...-8967199 
														</td>
														</tr>
														<tr>
															<td>54.</td>
															<td>Mr.
															Wahab-ud-din 
															<br>Director 
															</td>
															<td>
															Ministry of Petroleum &amp; Natural Resources 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9211579 
															</td>
														</tr>
														<tr>
															<td>55.</td>
															<td>Mr.
															Muhammad Tayyab 
															<br>Instructor 
															</td>
															<td>
															National Institute of Administration (NIPA) 
															</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> ...-9118174 
															</td>
														</tr>
														<tr>
															<td>56.</td>
															<td>Ms. Ghayoor Sultana<br>Deputy Secretary (Admn)</td>
															<td>Ministry of Housing &amp; Works</td>
															<td>-do-</td>
															<td>-do-</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9217155</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div class="modal-footer">
											<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
										</div>
									</div>
								</div>
							</div>
  
							<h6 class="col-12 col-sm-6 col-md-4 mb-0 pt-2 font-weight-medium">
								<a href="#" data-toggle="modal" data-target="#y2007">
									<i class="far fa-hand-point-right text-primary mr-2"></i> Year 2007</a>
								<hr>
							</h6>
							<!-- Modal Year 2007 -->
							<div class="modal fade" id="y2007" tabindex="-1" aria-labelledby="y2007" aria-hidden="true">
								<div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
									<div class="modal-content">
										<div class="modal-header">
											<h5 class="modal-title font-weight-semi-bold" id="y2007">Jan 2007 to December 2007</h5>
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body">
											<div class="table-responsive border">
												<table class="table table-striped">
													<tbody>
														<tr>
															<td>1.</td>
															<td>Ms. Raana Ahsan<br>Director (PP)</td>
															<td>Board of Investment</td>
															<td>Pakistan Engineering Council<br>(PEC), Islamabad</td>
															<td class="text-nowrap">23 – 24. Jan, 2007</td>
															<td><i class="fas fa-phone text-success mr-1"></i> 9219790</td>
													   </tr>
													   <tr>
														<td>2.</td>
														<td>Mr.
														Aamir Sultan<br>Deputy
														Director</td>
														<td>-do-</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9203259</td>
													   </tr>
													   <tr>
														<td>3.</td>
														<td>Mir
														Afzal Khan,<br>Section
														Officer (Gen)</td>
														<td>Planning &amp; Development Division</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9207716</td>
													   </tr>
													   <tr>
														<td>4.</td>
														<td>Mr.
														Zulfiqar Ali,<br>Manager
														Finance</td>
														<td>-do-</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9211742</td>
													   </tr>
													   <tr>
														<td>5.</td>
														<td>Mr.
														Zafar Iqbal Khan,<br>Deputy
														Director</td>
														<td>-do-</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9208684</td>
													   </tr>
													   <tr>
														<td>6.</td>
														<td>Lt.
														Col. Muhammad Akram Bhatti, TI(M)<br>Deputy
														Secretary (Admn)</td>
														<td>States and Frontier Regions Division</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9205176</td>
													   </tr>
													   <tr>
														<td>7.</td>
														<td>Mr.
														Zulfiqar Ali Khan<br>Section
														Officer</td>
														<td>Ministry of Sports</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9210130</td>
													   </tr>
													   <tr>
														<td>8.</td>
														<td>Mr.
														Ejaz Latif Khokhar<br>Deputy
														Manager</td>
														<td>-do-</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9252157</td>
													   </tr>
													   <tr>
														<td>9.</td>
														<td>Mr.
														Hamid Khalil<br>Director</td>
														<td>Ministry of Population</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9201416</td>
													   </tr>
													   <tr>
														<td>10.</td>
														<td>Mr.
														Muhammad Abu<br>Tahir
														Khan, ADG</td>
														<td>Department of Communications Security</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9201113 (Ext) 106</td>
													   </tr>
													   <tr>
														<td>11.</td>
														<td>Mr.
														Muhammad Usman<br>Section
														Officer</td>
														<td>Labour and Manpower Division</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9202588</td>
													   </tr>
													   <tr>
														<td>12.</td>
														<td>Mr.
														Salahuddin<br>Section
														Officer</td>
														<td>Law, Justice &amp; Human Rights Division</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9208522</td>
													   </tr>
													   <tr>
														<td>13.</td>
														<td>Mr.
														Aziz Bahadar<br>Section
														Officer</td>
														<td>Election Commission of Pakistan</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9204343</td>
													   </tr>
													   <tr>
														<td>14.</td>
														<td>Mr.
														Abdul Hameed<br>Assistant
														Election Commissioner</td>
														<td>Election Commission of Pakistan</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> ...-4306675</td>
													   </tr>
													   <tr>
														<td>15.</td>
														<td>Mr.
														Jamil-ur-Rehman<br>Manager
														L.P.O</td>
														<td>Survey of PakistanSurveyor General’s Office</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9290211...-5584797</td>
													   </tr>
													   <tr>
														<td>16.</td>
														<td>Mr.
														Shafiq-ur-Rehman<br>Section
														Officer (G)</td>
														<td>Establishment Division</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> ...-5131962</td>
													   </tr>
													   <tr>
														<td>17.</td>
														<td>Mr.
														Qasim Zafar<br>Deputy
														Director (Est)</td>
														<td>(Pakistan Bait-ul-Mal)</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9250463</td>
													   </tr>
													   <tr>
														<td>18.</td>
														<td>Mr.
														Qazi Zafar Iqbal<br>Deputy
														Director (Admn)</td>
														<td>(Pakistan Bait-ul-Mal)</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9250469</td>
													   </tr>
													   <tr>
														<td>19.</td>
														<td>Mr.
														SharjeelAbbasi<br>System
														Administrator (MIS)</td>
														<td>Pakistan National Shipping Corporation (PNSC)</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 021-9204030</td>
													   </tr>
													   <tr>
														<td>20.</td>
														<td>Mr.
														Asad Ullah<br>Assistant
														Manager (Stores)</td>
														<td>Pakistan National Shipping Corporation </td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 021-2313708</td>
													   </tr>
													   <tr>
														<td>21.</td>
														<td>Mr.
														Tajamal Hussain<br>Section
														Officer (General)</td>
														<td>Ministry of Housing &amp; Works</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9209288</td>
													   </tr>
													  </tbody>
												</table>
											</div>
										</div>
										<div class="modal-footer">
											<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
										</div>
									</div>
								</div>
							</div>

							<h6 class="col-12 col-sm-6 col-md-4 mb-0 pt-2 font-weight-medium">
								<a href="#" data-toggle="modal" data-target="#y2008">
									<i class="far fa-hand-point-right text-primary mr-2"></i> Year 2008</a>
								<hr>
							</h6>
							<!-- Modal Year 2008 -->
							<div class="modal fade" id="y2008" tabindex="-1" aria-labelledby="y2008" aria-hidden="true">
								<div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
									<div class="modal-content">
										<div class="modal-header">
											<h5 class="modal-title font-weight-semi-bold" id="y2008">Jan 2008 to Dec, 2008</h5>
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body">
											<div class="table-responsive border">
												<table class="table table-striped">
													<tbody>
														<tr>
														<td>1.</td>
														<td>Mr. Sahibzada,<br>Section Officer (F&amp;A)</td>
														<td>Ministry of Housing and Works, Islamabad</td>
														<td>PPRA Training Centre, Blue Area, Islamabad</td>
														<td>14-15. January, 2008</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9204340 ...-5010488</td>
													   </tr>
													   <tr>
														<td>2.</td>
														<td>Mr. Muhammad Adnan,<br>Accounts Officer</td>
														<td>M/o Food Agriculture &amp; Livestock, Islamabad</td>
														<td>-do-</td>
														<td></td>
														<td><i class="fas fa-phone text-success mr-1"></i> 2829141-117 0304-9656161</td>
													   </tr>
													   <tr>
														<td>3.</td>
														<td>Mr. Zille Husnain,<br>Research Officer (ASPL-II)</td>
														<td>-do-</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 051-9206516 ...-6103802</td>
													   </tr>
													   <tr>
														<td>4.</td>
														<td>Mr. Sikandar Hayat,<br>S.S.P / P.D. (NFSA)</td>
														<td>National Police Bureau (M/o Interior, Islamabad)</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> ...-5152530</td>
													   </tr>
													   <tr>
														<td>5.</td>
														<td>Mr. Muhammad Aslam Khan,<br>Section Officer</td>
														<td>M/o Water &amp; Power, Islamabad</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9224881 ...-2726809</td>
													   </tr>
													   <tr>
														<td>6.</td>
														<td>Sqn
														Ldr M. Salman Khan, Assistant
														Director Procurement</td>
														<td>DP (Air), DGDP, Ministry
														of Defence Production, Rawalpindi</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9271493 ...-5883639</td>
													   </tr>
													   <tr>
														<td>7.</td>
														<td>Sqn
														Ldr Muhammad Ramzan, Assistant
														Director (P-46)</td>
														<td>-do-</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9257615</td>
													   </tr>
													   <tr>
														<td>8.</td>
														<td>Lt
														Saleem Saqib PN, Assistant
														Director (Procurment/Legal), DP (Navy)</td>
														<td>-do-</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9271473 ...-3978593</td>
													   </tr>
													   <tr>
														<td>9.</td>
														<td>S/Lt
														Zaib Un Nisa PN, AD
														(Computer ), Dy AD (Budget)</td>
														<td>-do-</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> ...-5891409</td>
													   </tr>
													   <tr>
														<td>10.</td>
														<td>Mr.
														Malahat Sher Khan, Manager
														(PR/Bills)</td>
														<td>PIDC, Karachi M/o Industries Production
														&amp; Special Initiatives</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 021-9205756 ...-3399639</td>
													   </tr>
													   <tr>
														<td>11.</td>
														<td>Mr.
														Islam Akhtar Khan, Project
														Director</td>
														<td>GTDMC-TUSDEC, Lahore</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> ...-8483787</td>
													   </tr>
													   <tr>
														<td>12.</td>
														<td>Dr.
														Masood Khan Jogezai, Deputy National Coordinator
														(MS)</td>
														<td>National Program for
														Family Planning &amp; Primary Health Care, (M/o Health, Islamabad)</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 051-9215616 ...5055372</td>
													   </tr>
													   <tr>
														<td>13.</td>
														<td>Mr.
														Rahman Shah, Section
														Officer</td>
														<td>M/o Health, Islamabad</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9208082 ...-5469822</td>
													   </tr>
													   <tr>
														<td>14.</td>
														<td>Mr.
														Sabz Ali Khan, Section
														Officer (General)</td>
														<td>Economic Affairs Division, Islamabad</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9208047 ...-5166451</td>
													   </tr>
													   <tr>
														<td>15.</td>
														<td>Mr.
														Waseem Barakullah, Assistant<br>Director</td>
														<td>Bureau of Emigration &amp;
														Overseas Employees, Islamabad</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9261016 ...-4704008</td>
													   </tr>
													   <tr>
														<td>16.</td>
														<td>Mrs. Shamim Shamshad,<br>Section Officer (P-Junior)</td>
														<td>M/o Population Welfare,
														Islamabad</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9208547 ...-5344755</td>
													   </tr>
													   <tr>
														<td>17.</td>
														<td>Mrs. Saima Rashid,<br>Accounts Officer (FM)</td>
														<td>-do-</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9206210</td>
													   </tr>
													   <tr>
														<td>18.</td>
														<td>Ms. Bushra Ikram,<br>Deputy General Manager</td>
														<td>State Life Insurance
														Corporation of Pakistan Regional Office (North) State Life Building # 9, Blue Area, Islamabad</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9204571</td>
													   </tr>
													   <tr>
														<td>19.</td>
														<td>Dr. Rubina Nayyar,<br>Manager</td>
														<td>-do-</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9204456</td>
													   </tr>
													   <tr>
														<td>20.</td>
														<td>Mr. Jamil Ahmad,<br>Deputy Director</td>
														<td>Urdu science Board, Lahore (M/o Education, Islamabad)</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 042-5758475 ...-4408460</td>
													   </tr>
													   <tr>
														<td>21.</td>
														<td>Mr. Adeel Ayub,<br>Asstt. Dir. AdmIn</td>
														<td>F-6 Rebuild Factory Pakistan Aeronautical Complex, Kamra</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 90992254 ...-5016068</td>
													   </tr>
													   <tr>
														<td>22.</td>
														<td>Mr. Umar Khattak,<br>Assistant Director (Accounts)</td>
														<td>National Housing Authority (M/o Housing &amp; Works)</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9202321 ...-4532136</td>
													   </tr>
													   <tr>
														<td>23.</td>
														<td>Mr. Khalid Zafar,<br>Accounts Officer</td>
														<td>Pakistan Sports Board (M/o Sports, Islamabad)</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9201110 ...-5124583</td>
													   </tr>
													   <tr>
														<td>24.</td>
														<td>Mr. Shad Ayaz,<br>Act. Assistant Director (P&amp;D)</td>
														<td>Pakistan Sports Board (M/o Sports, Islamabad)</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9209860</td>
													   </tr>
													   <tr>
														<td>25.</td>
														<td>Mr. Hameed Niazi,<br>Section Officer</td>
														<td>M/o Education, Islamabad</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9205005 ...-5177343</td>
													   </tr>
													   <tr>
														<td>26.</td>
														<td>Mr. Muhammad Yaqoob Awan,<br>Deputy Secretary</td>
														<td>Ministry of Interior, Islamabad</td>
														<td>PPRA Training Centre, Blue Area, Islamabad</td>
														<td>21-22. January, 2008</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9201213 ...9504681</td>
													   </tr>
													   <tr>
														<td>27.</td>
														<td>Mr. Naeem ur Rehman,<br>Accounts Officer</td>
														<td>Agribusiness Development &amp; Diversification Project (MINFAL), M/o Food Agriculture &amp;
														Livestock, Islamabad</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9210147 ...5152052</td>
													   </tr>
													   <tr>
														<td>28.</td>
														<td>Rana Muhammad Aslam,<br>Section Officer</td>
														<td>Ministry of Sports</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9210130 ...5507031</td>
													   </tr>
													   <tr>
														<td>29.</td>
														<td>Mr. Liaqat Ali Khan,<br>Joint Director</td>
														<td>M/o Textile Industry,
														Islamabad PCSI, Karachi</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 021-4324445 ...2722969</td>
													   </tr>
													   <tr>
														<td>30.</td>
														<td>Mr. Muhammad Javed Iqbal Amer,<br>Deputy Secretary</td>
														<td>Finance Division, Islamabad</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9202801</td>
													   </tr>
													   <tr>
														<td>31.</td>
														<td>Ms. Sarwat Inayat Mirza,<br>Senior Lecturer (Psychology)</td>
														<td>Central Jail Staff
														Training Institute, (M/o Interior), Lahore</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 042-9200670 ...4024712</td>
													   </tr>
													   <tr>
														<td>32.</td>
														<td>Mr. Faisal Sultan,<br>Procurement Officer</td>
														<td>Utility Stores Corporation
														(USC) (M/o Industries),
														Islamabad</td>
														<td>-do-</td>
														<td>-do-</td>
														<td><i class="fas fa-phone text-success mr-1"></i> 9210993 ...6445909</td>
													   </tr>
													  </tbody>
												</table>
											</div>
										</div>
										<div class="modal-footer">
											<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
										</div>
									</div>
								</div>
							</div>

							<h6 class="col-12 col-sm-6 col-md-4 mb-0 pt-2 font-weight-medium">
								<a href="https://ppra.org.pk/nip/nip2009.htm" target="_blank"><i
										class="far fa-hand-point-right text-primary mr-2"></i> Year 2009</a>
								<hr>
							</h6>
							<h6 class="col-12 col-sm-6 col-md-4 mb-0 pt-2 font-weight-medium">
								<a href="https://ppra.org.pk/nip/nip2010.htm" target="_blank"><i
										class="far fa-hand-point-right text-primary mr-2"></i> Year 2010</a>
								<hr>
							</h6>
							<h6 class="col-12 col-sm-6 col-md-4 mb-0 pt-2 font-weight-medium">
								<a href="https://ppra.org.pk/nip/nip2011.htm" target="_blank"><i
										class="far fa-hand-point-right text-primary mr-2"></i> Year 2011</a>
								<hr>
							</h6>
							<h6 class="col-12 col-sm-6 col-md-4 mb-0 pt-2 font-weight-medium">
								<a href="https://ppra.org.pk/nip/nip2012.htm" target="_blank"><i
										class="far fa-hand-point-right text-primary mr-2"></i> Year 2012</a>
								<hr>
							</h6>
							<h6 class="col-12 col-sm-6 col-md-4 mb-0 pt-2 font-weight-medium">
								<a href="https://ppra.org.pk/nip/nip2013.htm" target="_blank"><i
										class="far fa-hand-point-right text-primary mr-2"></i> Year 2013</a>
								<hr>
							</h6>
							<h6 class="col-12 col-sm-6 col-md-4 mb-0 pt-2 font-weight-medium">
								<a href="https://ppra.org.pk/nip/nip2014.htm" target="_blank"><i
										class="far fa-hand-point-right text-primary mr-2"></i> Year 2014</a>
								<hr>
							</h6>
							<h6 class="col-12 col-sm-6 col-md-4 mb-0 pt-2 font-weight-medium">
								<a href="https://ppra.org.pk/nip/nip2015.htm" target="_blank"><i
										class="far fa-hand-point-right text-primary mr-2"></i> Year 2015</a>
								<hr>
							</h6>
						</div>
					</div>
				</div>

				<!---International Links--->
				<div class="tab-pane fade" id="international-training-links" role="tabpanel"
					aria-labelledby="international-training-links-tab">
					<h3 class="pb-3">International Training Links</h3>
					<h6 class="well font-weight-medium">
						<a href="https://www.procurementlearning.org/aim_mooc.html" target="_blank"><i
								class="far fa-hand-point-right text-primary mr-2"></i> Certificate program in Public
							Procurement (MOOC)</a>
					</h6>
					<h6 class="well font-weight-medium">
						<a href="http://www.ripainternational.co.uk/Training-courses" target="_blank"><i
								class="far fa-hand-point-right text-primary mr-2"></i> The Chartered Institute of
							Purchasing and Supply: CIPS</a>
					</h6>
					<h6 class="well font-weight-medium">
						<a href="https://www.cips.org/" target="_blank"><i
								class="far fa-hand-point-right text-primary mr-2"></i> International Public Procurement
							(Mar)</a>
					</h6>
					<h6 class="well font-weight-medium">
						<a href="http://www.ili.org/training/.../402-2014-international-public-procurement.html"
							target="_blank"><i class="far fa-hand-point-right text-primary mr-2"></i> Procurement
							Management ITC-ILO</a>
					</h6>
					<h6 class="well font-weight-medium">
						<a href="https://www.itcilo.org/about" target="_blank"><i
								class="far fa-hand-point-right text-primary mr-2"></i> Procurement Training | UNDP</a>
					</h6>
				</div>

				<!---Contact us--->
				<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
					<div class="clear"></div>
					<h3 class="pb-3">Contact us</h3>
					<p>
						<i class="fas fa-map-marker-alt text-success mr-1"></i>1st Floor FBC Building,<br>
						<span class="ml-3">Sector G-5/2, Islamabad-Pakistan</span>
					</p>
					<p>
						<i class="fas fa-phone text-success mr-2"></i> 051-9245635<br>
						<i class="fas fa-fax text-success mr-2"></i> 051-9224823<br>
						<i class="far fa-envelope text-success mr-2"></i> <a href="mailto:nip@ppra.org.pk">nip@ppra.org.pk</a>
					</p>					
					<div class="clear"></div>
				</div>
			</div>
		</div>
	</div>
</section>
<div class="clear"></div>