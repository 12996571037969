<div class="clear"></div>
<section class="banner">
	<div class="container py-4 d-flex justify-content-between align-items-center">
		<h1 class="mb-0">Public Procurement Rules</h1>
		<div class="input-group" style="max-width:300px;">
			<input type="text" class="form-control" (keyup)="Onempty()" [(ngModel)]="searchkeys" id="search" placeholder="search...">
			<div class="input-group-append" id="button-addon4">
			  <button class="btn btn-outline-success" type="button" (click)="Search()"><em class="fas fa-search fa-fw"></em></button>
			  <button class="btn btn-outline-secondary" type="button" (click)="ClearSearch()"><em class="fas fa-backspace fa-fw"></em></button>
			</div>
		</div>
	</div>
</section>
<section class="container">
	<div class="row">
		<div class="col-md-4">
			<div class="bg-light h-100 p-4">
				<div class="list-group">
					<a [routerLink]="'/documents/pp-rules'" class="list-group-item list-group-item-action active">Public Procurement Rules 2004</a>
					<!--a href="#" class="list-group-item list-group-item-action">Regulation 2009</a>
					<a href="#" class="list-group-item list-group-item-action">Regulation 2010</a>
					<a href="#" class="list-group-item list-group-item-action">Regulation 2011</a>
					<a href="#" class="list-group-item list-group-item-action">Regulation 2012</a>
					<a href="#" class="list-group-item list-group-item-action">Regulation 2013</a>
					<a href="#" class="list-group-item list-group-item-action">Regulation 2014</a>
					<a href="#" class="list-group-item list-group-item-action">Regulation 2015</a-->
				</div>
			</div>
		</div>
		<div class="col-md-8 py-4">
			<div class="d-flex justify-content-between">
				<div>
					<h3 class="text-black">Public Procurement Rules 2004</h3>
				</div>
				<p class="text-nowrap">
					<!-- <a class="text-black font-weight-medium mr-3 cursor-pointer" (click)="getPDF()">
						<i class="far fa-file-pdf text-danger mr-1 cursor-pointer" ></i> Download PDF
					</a> -->
					<a href="./assets/docs/rules.pdf" target="_blank" class="text-black font-weight-medium mr-3 cursor-pointer">
						<i class="far fa-file-pdf text-danger mr-1 cursor-pointer" ></i> PPRA Rules
					</a>
					<a href="./assets/docs/notif3.pdf" target="_blank" class="text-black font-weight-medium mr-3 cursor-pointer">
						<i class="far fa-file-pdf text-danger mr-1 cursor-pointer" ></i> PPRA Rules Notification 
					</a>
					<a class="text-black font-weight-medium cursor-pointer" (click)="print()">
						<i class="fas fa-print text-primary mr-1 cursor-pointer"></i> Print
					</a>
				</p>
			</div>
			<div id="pprclause">
				<h5 class="text-primary font-weight-semi-bold"> Notification</h5>
				<p><strong>
					<ngx-highlight-words
					[textToHighlight]="'S.R.O. 432(I)/2004.-'"
					[searchWords]="[SearchWords]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></strong>
					<ngx-highlight-words
					[textToHighlight]="'In exercise of the powers conferred by section 26 of the'"
					[searchWords]="[SearchWords]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words>   {{this.userUtilService?.selectedEnvironment?.Name}} 
					<ngx-highlight-words
					[textToHighlight]="'Public Procurement Regulatory Authority Ordinance, 2002 (XXII of 2002), the'"
					[searchWords]="[SearchWords]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words> {{this.userUtilService?.selectedEnvironment?.name}} 
					<ngx-highlight-words
					[textToHighlight]="'Government is pleased to make the following rules, namely:-'"
					[searchWords]="[SearchWords]"
					highlightClassName="bg-Searchable">
					</ngx-highlight-words></p>
				<ul style="list-style-type: none;">
					<div *ngFor="let pprarules of publicTenders">
						<li><strong class="font-weight-semi-bold text-black">
							{{pprarules.ruleNo}}: 
							<ngx-highlight-words
								[textToHighlight]="pprarules.heading"
								[searchWords]="[SearchWords]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words>
						</strong>
							<span><ngx-highlight-words
								[textToHighlight]="pprarules.definition"
								[searchWords]="[SearchWords]"
								highlightClassName="bg-Searchable">
								</ngx-highlight-words></span>
							<div class="p-2" *ngFor="let ppraclause of pprarules.ppraRulesSubClause">
								<strong class="text-success"> 
									<ngx-highlight-words
									[textToHighlight]="ppraclause.subClause"
									[searchWords]="[SearchWords]"
									highlightClassName="bg-Searchable">
									</ngx-highlight-words>.
								</strong>
									<ngx-highlight-words
									[textToHighlight]="ppraclause.definition"
									[searchWords]="[SearchWords]"
									highlightClassName="bg-Searchable">
									</ngx-highlight-words>
							
							</div>
						</li>
					</div>
				</ul>
			</div>
			<div *ngIf="noRecordFound == true" class="d-flex justify-content-between pl-5">
				<strong>No Record Found . </strong>
			</div>
		</div>
	</div>
</section>

<div class="clear"></div>