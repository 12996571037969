<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>Evaluation Results</h1>
	</div>
</section>
<div class="clear p-4"></div>
<div class="container">
    <h6 class="font-weight-medium">Organization Name:  {{this.Organization}}</h6>
    <div class="clear p-2"></div>
    <div class="row">
        <div class="col-lg-3">
            <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">EV</span>
                </div>
                <input type="text" id="date" class="form-control" [(ngModel)]="EVValue">
            </div>
        </div>
        <div class="col-lg-2">
            <button type="submit" (click)="onSelectValueSearch()" class="btn btn-primary">Search</button>
            <button  type="Clear" (click)="EVNoClear()" class="btn btn-secondary ml-2">Clear</button>
         </div>
    </div>
    	
    
    <p class="text-right">Total Results ({{totalRecords}})</p>
    <div class="table-responsive">
        <table class="table border">
            <thead class="table-success">
                <tr>
                    <th scope="col">Evaluation No<a (click)="sort('EvaluationNo','desc')"><img  src="../assets/images/arrow_up.gif"></a> <a (click)="sort('EvaluationNo','asc')"><img src="../assets/images/arrow_down.gif"></a></th>
                    <th scope="col">Evaluation Result Title</th>
                    <th scope="col" class="text-center">Download</th>
                    <th scope="col">Uploaded Date<a (click)="sort('adate','desc')"><img  src="../assets/images/arrow_up.gif"></a> <a (click)="sort('adate','asc')"><img src="../assets/images/arrow_down.gif"></a></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of Records; let i = index">
                    <td class="font-weight-bold">EV{{data?.EvaluationNo}}</td>
                    <td>
                        <strong class="font-weight-semi-bold">{{data?.CategoryName}}</strong><br>
                        {{data?.EvaluationType}}<br>
                        {{data?.Title}}<br>
                        {{data?.eNo}} <br>  
                        {{data?.tNo}}
                    </td>
                    <td class="text-center">
                        <a *ngIf="data?.urlattachmentID==null" [href]="data?.URL" target="_blank" ><i class="fas fa-file-download fa-fw fa-lg"></i></a>
                        <a *ngIf="data?.urlattachmentID!=null" (click)="getAttachmentByID(data?.urlattachmentID,data?.urlattachmentGUID)"><i class="fas fa-file-download fa-fw fa-lg text-success"></i></a>
                    </td>      
                          
                    <td>{{data?.adate | date}}</td>
                </tr>    
                <strong *ngIf="Records.length == '0'">No record found.</strong>        
            </tbody>
        </table>
        <div class="clear clearfix py-1"></div>
        <!-- <p class="font-weight-bold">Total Results ( 5 )</p> -->
        
        <!-- <p class="font-weight-bold">Total Records ({{totalRecords}})</p> -->
        <small class="font_12">10 Records Per Page</small> 
        <small class="font_12"> | Total Pages<strong>  {{this.totalPages}}</strong></small> 
        <nav aria-label="..." class="arial-pagination">
        
            <div class="container mt-5">
                <div class="row">
                    <div class="col-md-3">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" [class.disabled]="this.PageNo === 1">
                                <a class="page-link" (click)="previousPage()">Previous</a>
                            </li>
                            <li class="page-item" *ngFor="let pageNumber of pages">
                                <a class="page-link" (click)="changePage(pageNumber)" [class.active]="pageNumber === this.PageNo">{{pageNumber}}</a> 
                            </li>
                            <li class="page-item" [class.disabled]="this.PageNo=== this.totalPages">
                                <a class="page-link" (click)="nextPage()">Next</a>
                            </li>
                        </ul>
                    </div>
                  <div class="col-md-2">
                    <div class="input-group input-group-sm">
                      <input type="number" class="form-control" placeholder="Page No."   (keyup)="onEnterKeyPressed($event)" [(ngModel)]="this.PN" aria-label="Enter your text" aria-describedby="button-addon">
                      <div class="input-group-append input-group-sm">
                        <button class="btn btn-outline-secondary"  (click)="selectedPage()" type="button" id="button-addon"><span style="color: blue; ">Go</span></button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
           
        </nav>  

    </div>
</div>