<div class="form-row">
    <div class="col-md-4 col-sm-6">
        <a [routerLink]="'/tenders/paymentlist'" class="banner_widgets success">
            <i class="icon"><img src="../assets/images/active-tenders.png"></i>
            Procurement Plans
            <h3>{{dashboardDataModel?.legencyPlansCount}}</h3>
        </a>
    </div>
    <div class="col-md-4 col-sm-6">
        <a [routerLink]="'/tenders/activetenders'" class="banner_widgets danger">
            <i class="icon"><img src="../assets/images/attachments.png"></i>
            Tenders
            <h3>{{dashboardDataModel?.legencyTendersCount}}</h3>
        </a>
    </div>
    <div class="col-md-4 col-sm-6">
        <a [routerLink]="'/tenders/evaluationtlist'" class="banner_widgets warning">
            <i class="icon"><img src="../assets/images/corrigendum.png"></i>
            Evaluation Reports
            <h3>{{dashboardDataModel?.legencyEvaluationsCount}}</h3>
        </a>
    </div>
    <div class="col-md-4 col-sm-6">
        <a [routerLink]="'/tenders/grclist'" class="banner_widgets brown">
            <i class="icon"><img src="../assets/images/evaluationicon.png"></i>
            Grievance
            <h3>{{dashboardDataModel?.legencyGrievanceCount}}</h3>
        </a>
    </div>
    <div class="col-md-4 col-sm-6">
        <a [routerLink]="'/tenders/procurementContracts'" class="banner_widgets primary">
            <i class="icon"><img src="../assets/images/new.png"></i>
            Procurement Contract
            <h3>{{dashboardDataModel?.legencyContractCount}}</h3>
        </a>
    </div>
    <div class="col-md-4 col-sm-6">
        <a [routerLink]="'/tenders'" class="banner_widgets info">
            <i class="icon"><img src="../assets/images/attachments.png"></i>
            EPADS Tenders
            <h3>{{dashboardDataModel?.ePADSTendersCounts}}</h3>
        </a>
    </div>
    <div class="col-md-4 col-sm-4">
        <a [routerLink]="'/other/procurement-plan'" class="banner_widgets secondary">
            <i class="icon"><img src="../assets/images/active-tenders.png"></i>
            EPADS Procurement Plans
            <h3>{{dashboardDataModel?.ePADSPlansCounts}}</h3>
        </a>
    </div>
    <div class="col-md-4 col-sm-4">
        <a [routerLink]="'/other/evaluation-results'" class="banner_widgets success">
            <i class="icon"><img src="../assets/images/corrigendum.png"></i>
            EPADS Evaluation Reports
            <h3>{{dashboardDataModel?.ePADSEvalutaionsCounts}}</h3>
        </a>
    </div>
    <!---div class="col-md-4 col-sm-6">
        <a [routerLink]="'/tenders/paymentlist'" class="banner_widgets danger">
            <i class="icon"><img src="../assets/images/evaluationicon.png"></i>
            EPADS Grievance
            <h3>{{dashboardDataModel?.evaluationStatCount}}</h3>
        </a>
    </div--->
    <div class="col-md-4 col-sm-6">
        <a [routerLink]="'/other/procurement-contracts'" class="banner_widgets warning">
            <i class="icon"><img src="../assets/images/new.png"></i>
            EPADS Contracts
            <h3>{{dashboardDataModel?.ePADSContractsCounts}}</h3>
        </a>
    </div>
</div>


