import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
@Component({
  selector: 'app-evaluation-report-punjab',
  templateUrl: './evaluation-report-punjab.component.html',
  styleUrls: ['./evaluation-report-punjab.component.scss']
})
export class EvaluationReportPunjabComponent implements OnInit {
    biddingProcessInfo
    data: any;
    mostAdvantigiousBidder: string;
    reportRecord:any;
    finalEvaluationReportForm: FormGroup;
  constructor(
    private PublicPortalService: PublicPortalService,
    private toasterService: ToastrService,
    private userUtilsService : UserUtilService,
  ) { }
  isVisible=false;
  @Output() pdfData = new EventEmitter<any>();
  @Input() viewAble:boolean;
  @Input() publishedDocumentID:number;
  @Input() fromEContract:boolean=false;
  @Input() FinalEvaluation:boolean=false;
  @Input() reportData:any;

  ngOnInit() {
    this.data=this.reportData.tableData;
    this.mostAdvantigiousBidder=this.data.length>0?this.data[0].mostAdvantigiousBidder:"";
    this.reportRecord=this.reportData.finalEvaluationRecord;
    this.getAll(this.publishedDocumentID)
}

  generatePDF() {
    // if(this.data[0].loiResponse=='Rejected' && this.FinalEvaluation){
    //     this.toasterService.error("Supplier didn't responded the LOI Yet");
    //     return;
    // }
    const element = document.getElementById('pdfContent');
    const options = {
        margin: [20, 20, 20, 20],};
        var report= html2pdf()
        .set(options)
        .from(element);
          const pdfBlob = new Blob([report.output('blob')], { type: 'application/pdf' });
          const pdfFile = new File([pdfBlob], 'FinalEvaluationReport.pdf', { type: 'application/pdf' });


          const fileList = [pdfFile] as any;
          fileList[0] = pdfFile;
        this.pdfData.emit(fileList);
  }
  downloadReport(){
    const element = document.getElementById('pdfContent');
    const options = {
        margin: [20, 20, 20, 20],
        pagebreak: { mode: 'avoid-all' },
      //  orientation: 'landscape',
        html2canvas: { scale: 2 },
        outerWidth:100,
        jsPDF: { format: 'a4', orientation: 'landscape' }
    };

        var report= html2pdf()
        .from(element).set(options).save("FinalEvaluationReport.pdf");
  }
  getAll(docId:number) {
    this.PublicPortalService.geteFinalEvaluationReport(docId,this.FinalEvaluation).subscribe((baseResponse: any) => {
      if (baseResponse.success) {
        this.data=baseResponse.data.tableData;
        this.mostAdvantigiousBidder=this.data.length>0?this.data[0].mostAdvantigiousBidder:"";
        this.reportRecord=baseResponse.data.finalEvaluationRecord;
        // this.setFormsValues(this.reportRecord)
      } else {
        if (baseResponse.errors.length > 0) {
          for (var i = 0; i < baseResponse.errors.length; i++) {
            this.toasterService.error(baseResponse.errors[i].errorMessage);
          }
          return;
        }
      }
    })
  }
}
