<footer class="footer">
	<!-- <div class="container">
		<div class="row py-4">
			<div class="col-lg-3">
				<div class="row">
					<div class="col-6">
						<a [routerLink]="['/home']" ><img [src]="this.userUtilsService.selectedEnvironment?.src" width="80" class="mb-3"></a>
						<div class="media mb-2">
							<a href="https://www.google.com/maps/place/Public+Procurement+Regulatory+Authority/@33.723411,73.096547,16z/data=!4m6!3m5!1s0x38dfc07c7d907aa5:0xccce2da1a9319462!8m2!3d33.723411!4d73.096547!16s%2Fg%2F12hpqnhb5?hl=en&entry=ttu" target="_blank"  class="text-white fa fa-map-marker mt-1 mr-2"></a>
							<div class="media-body font-weight-medium">
								<a href="https://www.google.com/maps/place/Public+Procurement+Regulatory+Authority/@33.723411,73.096547,16z/data=!4m6!3m5!1s0x38dfc07c7d907aa5:0xccce2da1a9319462!8m2!3d33.723411!4d73.096547!16s%2Fg%2F12hpqnhb5?hl=en&entry=ttu" target="_blank" class="text-white">{{this.userUtilsService.selectedEnvironment?.address}}</a>
							</div>
						</div>
						<div class="media mb-2">
							<i class="fa fa-envelope mt-1 mr-2"></i>
							<div class="media-body font-weight-medium">
								<a href="mailto:info@ppra.org.pk" class="text-white">{{this.userUtilsService.selectedEnvironment?.email}}</a>
							</div>
						</div>
						<div class="media mb-3">
							<i class="fa fa-phone mt-1 mr-2"></i>
							<div class="media-body font-weight-medium">
								{{this.userUtilsService.selectedEnvironment?.phone}}
							</div>
						</div>
					</div>
					<div class="col-6">
						<h6>Tenders</h6>
						<ul class="uilinks"> 
							<li><a [routerLink]="['/other/procurement-plan']" ariaCurrentWhenActive="page">EPADS Procurement</a></li>
							<li><a [routerLink]="['/tenders']" ariaCurrentWhenActive="page">EPADS Tenders</a></li>
							<li><a [routerLink]="['/other/evaluation-results']" ariaCurrentWhenActive="page">EPADS Evaluation Results</a></li>
							<li><a [routerLink]="['/other/tender-guidelines']">Tender Guidelines</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<h6>PPRA Links</h6>
				<div class="row g-2">
					<div class="col-6 col-sm-4">
						<ul class="uilinks">
							<li><a [routerLink]="['/other/online-query']">Online Query</a></li>
							<li><a [routerLink]="['/other/online-reply']">Query Reply</a></li>
							<li><a [routerLink]="['/other/FAQ']">Frequently Asked Questions</a></li>
							<li><a [routerLink]="['/other/register-suppliers']">Registered Suppliers</a></li>
							<li><a [routerLink]="['/other/board']">PPRA Board</a></li>
							<li><a [routerLink]="['/other/procurement-contracts']" ariaCurrentWhenActive="page">EPADS Contracts</a></li>
						</ul>
					</div>
					<div class="col-6 col-sm-4">
						<ul class="uilinks">
							<li><a [routerLink]="['/other/active-de-listed-black-listed-firms']">Archive De-Listed Black Listed Firms</a></li>
							<li><a [routerLink]="['/other/pepra-active-black-listed-firms']">Active Black Listed Firms</a></li>
							<li><a [routerLink]="['/other/procurement-plan']">Procurement Planning</a></li>
							<li><a [routerLink]="['/tenders/grclist']">Grievances Redressal Committee</a></li>
							<li><a [routerLink]="['/other/law-acts']">Misc. Laws and Acts</a></li>
						</ul>
					</div>
					<div class="col-6 col-md-4">
						<ul class="uilinks">
							<li><a [routerLink]="['/documents/pp-rules']" >PPRA rules</a></li>
							<li><a [routerLink]="['documents/ordinance']" >Ordinance</a></li>
							<li><a [routerLink]="['/documents/regulations']" >Regulations</a></li>
							<li><a [routerLink]="['/other/policy-guidelines']">Policy Guidelines</a></li>
							<li><a [routerLink]="['/other/downloads']">Downloads<br>(SRO, Manuals, Forms)</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-lg-3">
				<div class="row">
					<div class="col-6">
						<h6 class="pb-2">Follow {{this.userUtilsService.selectedEnvironment?.Name}} PPRA</h6>
						<a href="https://www.facebook.com/profile.php?id=100095697124318" target="_blank"><i class="fab fa-facebook-f text_16 mr-2"></i></a>
						<a href="https://twitter.com/EPADSPPRA" target="_blank"><img src="./assets/images/twitterw.png" title="X" style="margin-top:-4px;"></a>
						<div class="clear p-2"></div>
						<a href="https://eprocure.gov.pk/#/auth/login" target="_blank">
							<i class="fa fa-user text_16 mr-2"></i> User Login
						</a>						
					</div>
					<div class="col-6">
						<h6 class="pb-2">Download App</h6>
						<a target="_blank"><img src="../assets/images/btn_glplay.png" class="mb-2" width="100"></a>
						<a target="_blank"><img src="../assets/images/btn_appstore.png" class="mb-2" width="100"></a>
					</div>
				</div>
				<div class="clear p-2"></div>
				<a [routerLink]="['/contactus']" class="btn btn-outline-light btn-block"><i class="far fa-envelope mr-1"></i> CONTACT US</a>
			</div>
		</div>
    </div> -->
    <div class="bg-success pt-3 pb-1">
    	<div class="container">
			<div class="d-flex align-items-center justify-content-between">
				<p>Copyright &copy; <a [routerLink]="['/home']"  class="text-white"> 2004-2024 Public Procurement Regulatory Authority.</a></p>
				<p><a [routerLink]="['/other/policy-guidelines']" class="text-white">Privacy Statement</a></p>
			</div>
        </div>
    </div>
</footer>