import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { GetAllPublishedDocumentDetailsByPidRequestModel, GetPublishedDocumentInfoRequestModel, TenderFilter } from 'src/app/core/models/tender-filter.model';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import b64toBlob from 'b64-to-blob';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-ppra-tenders',
  templateUrl: './ppra-tenders.component.html',
  styleUrls: ['./ppra-tenders.component.scss']
})
export class PpraTendersComponent implements OnInit,OnDestroy {
  paginationModel: PaginationModel;
  searchFilter :TenderFilter;
  getAllTendersRequestModel:GetPublishedDocumentInfoRequestModel;
  publicTenders:any;
  isViolation: boolean=false;
  totalRecords:any;
  totalPages:number;
  pages= new Array();
  downloadFile: downloadfile;
  currentDate = new Date();
  publicationDetails: any;
  documentsDetails: any;
  getAllPublishedDocumentDetailsByPidRequestModel: GetAllPublishedDocumentDetailsByPidRequestModel;
  constructor(private userUtil:UserUtilService,private portalService:PublicPortalService) { 
    this.downloadFile = new downloadfile();
    this.userUtil.isPPRAPage = true;
    this.paginationModel = new PaginationModel();
    this.searchFilter = new TenderFilter();
  }

  ngOnInit(): void {
    this.getAllPublishedDocumentDetailsByPidRequestModel = new GetAllPublishedDocumentDetailsByPidRequestModel();
  }
  ngAfterViewInit(){
    this.clear();
    this.getAllPublicTenders();
    //this.getClosingTender();
  }
  ngOnDestroy(): void {
    this.userUtil.isPPRAPage = false;
  }
  getAllPublicTenders(){
   
    this.getAllTendersRequestModel = new GetPublishedDocumentInfoRequestModel();
    this.getAllTendersRequestModel.pagination = this.paginationModel;
    this.getAllTendersRequestModel.pagination.orderByColumnName = this.searchFilter?.sortOrder;
    this.getAllTendersRequestModel.pagination.orderBy = "desc";
    this.getAllTendersRequestModel.filter = this.searchFilter;
    this.portalService.getAllPublicTenders(this.getAllTendersRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
                  let publicTenders = (baseResponse.data as any) ;
                  this.totalRecords = publicTenders?.totalRecords;
                  this.totalPages = publicTenders?.totalPages;
                  this.pages = new Array();
                  for (let i = 1; i <= this.totalPages; i++) {
                    this.pages.push(i);
                  }
                  this.publicTenders =publicTenders?.records;
                  if(this.publicTenders.find(x=>x.voilation==null) ||this.publicTenders.find(x=>x.voilation==undefined)){
                    this.isViolation=false
                  }
                  else {
                    this.isViolation=true
                  }
            }
          }
        }
      })
  }
  changePage(pageNumber){
    this.paginationModel.pageNumber = pageNumber;
    this.getAllPublicTenders();
  }
  search(){
    this.getAllPublicTenders();
  }
  clear(){
    this.pages = new Array();
    this.searchFilter.sortOrder = "Latest";
    this.searchFilter.keywords = "";
    this.searchFilter.tenderNo = "";
    this.searchFilter.dateOfAdvertisement = null;
    this.searchFilter.closingDate = null;
    this.paginationModel.pageNumber = '1';
    this.getAllPublicTenders();
  }

 
  makeDate(year,month,days){

  }
  sort(orderByColumnName, orderBy){
    this.paginationModel.orderBy = orderBy;
    this.searchFilter.sortOrder = orderByColumnName;
    this.paginationModel.orderByColumnName = this.searchFilter?.sortOrder;
    this.getAllPublicTenders();
  }
  getPublications(position: string,model:any, id: any) {
    this.getAllPublishedDocumentDetailsByPidRequestModel.Id = id;
 
    this.getAllPublishedDocumentDetailsByPidRequestModel.SupplierID=1;
    this.getAllPublishedDocumentDetailsByPidRequestModel.procurementPlansDetailID = model?.procurementPlansDetailID;
    this.getAllPublishedDocumentDetailsByPidRequestModel.loggedInUserID = 1;
    this.getAllPublishedDocumentDetailsByPidRequestModel.loggedInUserOfficeID = 1;

    this.getAllPublishedDocumentDetailbyPdIdPublication();
  }
  getAttachmentByID(Id: any) {
 
    if(Id == 0 || Id==null){
      return;
    }
    this.downloadFile.ID = Id;

    this.portalService.DownloadFile(this.downloadFile).subscribe(baseResponse => {
     
      if (baseResponse.success && baseResponse.data != null) {
        var Bytes = baseResponse.data['bytes'];
        var ContentType = baseResponse.data['contentType'];
        var FileName = baseResponse.data['fileName'];

        const blob = b64toBlob(Bytes, ContentType);
        saveAs(blob, FileName);
      }
    });
  }
  getAttachmentByGUID(Id: any,GUID:any) {
 
    if(Id == 0 || Id==null){
      return;
    }
    this.downloadFile.ID = Id;
    this.downloadFile.idsList = GUID;

    this.portalService.DownloadFileByGuid(this.downloadFile).subscribe(baseResponse => {
     
      if (baseResponse.success && baseResponse.data != null) {
        var Bytes = baseResponse.data['bytes'];
        var ContentType = baseResponse.data['contentType'];
        var FileName = baseResponse.data['fileName'];

        const blob = b64toBlob(Bytes, ContentType);
        saveAs(blob, FileName);
      }
    });
  }
  downloadDocuments(position: string, id: any) {
    
    this.getAllPublishedDocumentDetailsByPidRequestModel.Id = id;
    this.getAllPublishedDocumentDetailsByPidRequestModel.loggedInUserID =1;
    this.getAllPublishedDocumentDetailsByPidRequestModel.loggedInUserOfficeID = 1
    this.getAllPublishedDocumentDetailbyPdId();
  }
  getAllPublishedDocumentDetailbyPdId() {


    this.portalService.getAllPublishedDocumentDetailbyPdId(this.getAllPublishedDocumentDetailsByPidRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              this.documentsDetails = baseResponse.data;
            }
          }
        }
      })
  }
  getAllPublishedDocumentDetailbyPdIdPublication() {

    this.portalService.getAllPublishedDocumentDetailbyPdIdPublication(this.getAllPublishedDocumentDetailsByPidRequestModel)
      .subscribe(baseResponse => {

        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {

              this.publicationDetails = baseResponse.data;
            }
          }
        }
      })
  }
}
