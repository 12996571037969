export class DocumentTemplateSection{
    sectionID:number;
    name:string;
    description:string;
    lov_DocumentTemplateSectionTypeID:number;
    parentID:number;
    orderBy:number;
    documentTemplateID:number;
    template:string;
    isMandatory:boolean = false;
    sectionNumber:string;
    isTransectionalData:boolean = false;
    isEditable:boolean = true;
    proposalID:number;
    percentage:number=0;
    noOfLotsValue?:number=1;
    template_SectionID:number;
    minPercentage:number;
    isRevised:boolean;
    isScore:boolean;
    isAttachment:boolean;
}
export class DocumentTemplateSpecificValues{

    name:string;
    description:string;
    lov_DocumentTemplateSectionTypeID:number;
    parentID:number;
    orderBy:number;
    template:string;
    isMandatory:boolean = false;
    sectionID:number;
    documentTemplateID:number;
    isEditable:boolean = true;
    proposalID:number;
    sectionNumber:string;
}
export class DocumentSectionGroup {

    id:number;
    sectionID:string;
    name:string;
    documentTemplateId:number;
    status:number
  }