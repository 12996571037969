import { Component, OnInit } from '@angular/core';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import * as html2pdf from 'html2pdf.js'
@Component({
  selector: 'app-regulations',
  templateUrl: './regulations.component.html',
  styleUrls: ['./regulations.component.scss']
})
export class RegulationsComponent implements OnInit {
searchTerm: any;

  constructor(public userUtilsService:UserUtilService) { }

  ngOnInit(): void {
  }
  public getPDF() {
    var element = document.getElementById('pprclause'); 
    html2pdf(element);
  }
  ClearSearch(){
    this.searchTerm = '' 
  }

  print(){

 window.print()
    // //let myWindow = window.open('', 'my div', 'height=400,width=600');
    // var element = document.getElementById('pprclause'); 
    // let html = "";
    // html = element.innerHTML
    // const printWindow = window.open('', '_blank');
    // if (printWindow) {
    //   printWindow.document.write(html);
    // }
    // printWindow.print();
   // myWindow.close();
  }
}
