
<diV class="grid my-3" >
    <div class="mx-1">
     <button pButton class="btn btn-sm btn-outline-primary"  (click)="generatePDF()"><i class="fas fa-download mr-1"></i>Generate PDF</button>
    </div>

 </diV>
<main id="pdfContent">
    <h4 style="text-align: center;"><strong>8.9. Contract Form</strong></h4>
    <p style="font-style: oblique;">[To be signed & stamped by the Bidder and reproduced on the letter head. To be attached with Technical Bid]</p>
    <p>THIS AGREEMENT made on the {{data?.day}} day of {{data?.month}} 20{{data?.year}} between <span style="font-style: oblique; color:red">{{data?.procuringAgencyName}} </span> of
        <span style="font-style: oblique; color:red">{{data?.countryName}}</span> (hereinafter called “the Procuring Agency”)
        on the one part and<span style="font-style: oblique; color:red">{{data?.supplierName}}</span>  of <span style="font-style: oblique; color:red">{{data?.nameAndAddressOfBidder}}</span>
         (hereinafter called “the Supplier”) on the other part:</p>

    <p>WHEREAS the Procuring Agency invited Bids for certain goods and ancillary services, viz.,<span style="font-style: oblique; color:red"> {{data?.needOfProcurement}} </span>
        and has accepted a Bid by the Supplier for the supply of those goods and services in the sum of <span style="font-style: oblique; color:red">{{data?.evaluatedCost}}</span>
        (hereinafter called “the Contract Price”).</p>

    <p>NOW THIS AGREEMENT WITNESSETH AS FOLLOWS:</p>

            <div style="margin: 0;">
                <ol class="custom-list"  style="list-style-position: outside;">
                    <li>
                        In this Agreement words and expressions shall have the same meanings as are
                         respectively assigned to them in the Conditions of Contract referred to.
                    </li>
                <br>
                    <li >
                        The following documents shall be deemed to form and be read and construed as part of this Agreement, viz.:
                        <ol type="a">
                            <li >the Bid Form and the Price Schedule submitted by the Bidder;</li>
                            <li>the Schedule of Requirements;</li>
                            <li>the Technical Specifications;</li>
                            <li>the General Conditions of Contract;</li>
                            <li>the Special Conditions of Contract; and</li>
                            <li>the Procuring Agency’s Notification of Award.</li>
                            <li>Contract agreement</li>
                            <li>Complete Bidding document</li>
                        </ol>
                    </li>
                    <br>
                    <li>
                        In consideration of the payments to be made by the Procuring Agency to the Supplier as hereinafter mentioned,
                        the Supplier hereby covenants with the Procuring Agency to provide the goods and services and to rectify
                        defects therein in conformity with all respects in accordance with the provisions of the Contract.
                    </li>
                    <br>
                    <li>
                        The Procuring Agency hereby covenants to pay the Supplier in consideration of the
                        provision of the goods and services and the rectification of defects therein,
                        the Contract Price or such other sum as may become payable under the provisions of
                        the contract at the times and in the manner prescribed by the contract.
                    </li>
                    <br>
                </ol>
                <p style="vertical-align: sub;">
                    IN WITNESS whereof the parties hereto have caused this Agreement to be executed
                    in accordance with their respective laws the day and year mentioned above.
                </p>
                <br>
                <p>Signed, sealed, delivered _______________ by _____________________ the (for the Procuring Agency)
                   Signed, sealed, delivered by 	 the 	 (for the Supplier)</p>

                <p> <span style="font-style: oblique;">[The Procuring Agency should formulate Contract Form in accordance with PPR-14
                    keeping in view its requirements, nature of procurement i.e. Bulk/Framework,
                    item wise/package wise and form of contract to be adopted (i.e. DDP, CIF, C&F, FOR, FOP etc. if applicable).
                    However, for a standard procurement/contract, contents of a generalized Contract Form may be as provided above.]</span></p>
            </div>
            <div>

                <strong >It is auto generated Contract and does not need any signatures:</strong> <br><br>
        </div>
</main>
