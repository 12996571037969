<div class="clear"></div>
<section class="banner">
    <div class="container py-4">
        <h1>EPADS Registered Suppliers</h1>
    </div>
</section>
<div class="clear py-3"></div>
<section class="container">
    <div class="form-row">
        <div class="col-sm-6 col-lg-3 form-group">
            <label for="date">Search By Supplier Name:</label>
            <div class="input-group">
                <input type="text" id="date" class="form-control" [(ngModel)]="SupplierName">
                <div class="input-group-append">
                    <button class="btn btn-success" type="button" id="button-city"><i class="fas fa-search" (click)="onSupplierNameValue()"></i></button>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-lg-4 form-group">
            <label>Search By City:</label>
            <div class="media">
                <div class="media-body">
                    <app-search-dropdown [items]="DropdownRecordsCity" [size]="'small'" [img]="'product'"
                        [label]="'Name'" [uid]="'ID'" [(ngModel)]="selectedCityValue">
                    </app-search-dropdown>
                </div>
                <button class="btn btn-success" style="border-radius:0 .25rem .25rem 0;" type="button" id="button-city">
                    <i class="fas fa-search" style="margin:2px 0px;" (click)="onCitySelectedValue()"></i>
                </button>
            </div>
        </div>
        <div class="col-sm-6 col-lg-4 form-group">
            <label>Search By Business Type:</label>
            <div class="media">
                <div class="media-body">
                    <app-search-dropdown [items]="DropdownRecordsBussinessTypes" [size]="'small'" [img]="'product'"
                        [label]="'DisplayName'" [uid]="'ID'" [(ngModel)]="selectedValue">
                    </app-search-dropdown>
                </div>
                <button class="btn btn-success" style="border-radius:0 .25rem .25rem 0;" type="button" id="button-business">
                    <i class="fas fa-search" style="margin:2px 0px;" (click)="onBussinessSelectedValue()"></i>
                </button>
            </div>
        </div>
        <div class="col-sm-3 col-lg-1 mt-4 form-group">
            <button class="btn btn-secondary w-100 mt-1" type="button" id="button-business" (click)="clear()">Clear</button>
        </div>        
    </div>
    <div class="clear py-1"></div>
    <p class="font-weight-bold text-right">Total Results ({{totalRecords}})</p>
    <div class="table-responsive">
        <table class=" table table-striped border" id="table_id">
            <thead class="table-success">
                <tr>
                    <th scope="col" class="text-nowrap">Sr No.</th>
                    <th scope="col">
                        <div class="sortTh text-nowrap">
                            Supplier Name 
                            <span class="sorted_Icons">
                                <a (click)="sort('SupplierName','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                                <a (click)="sort('SupplierName','asc')"><img src="../assets/images/arrow_down.gif"></a>
                            </span>
                        </div>
                    </th>
                    <th scope="col">
                        <div class="sortTh text-nowrap">
                            City 
                            <span class="sorted_Icons">
                                <a (click)="sort('LocationName','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                                <a (click)="sort('LocationName','asc')"><img src="../assets/images/arrow_down.gif"></a>
                            </span>
                        </div>
                    </th>
                    <th scope="col">
                        <div class="sortTh text-nowrap">
                            Bussiness Type
                            <span class="sorted_Icons">
                                <a (click)="sort('BussniessIndustry','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                                <a (click)="sort('BussniessIndustry','asc')"><img src="../assets/images/arrow_down.gif"></a>
                            </span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of Records; let i = index">
                    <td>
                        <strong>{{i+1}}</strong><br>
                    </td>
                    <td>
                        {{data?.SupplierName}}
                    </td>
                    <td>
                        {{data?.LocationName}}
                    </td>
                    <td>
                        {{data?.BussniessIndustry}}<br>
                    </td>
                </tr>
                <strong *ngIf="Records?.length == '0'">No record found.</strong>
            </tbody>
        </table>
        <div class="clear clearfix py-1"></div>
    </div>
    <small class="font_12">10 Records Per Page</small>
    <small class="font_12"> | Total Pages<strong> {{this.totalPages}}</strong></small>
    
    <nav aria-label="..." class="arial-pagination">
        <div class="d-flex mt-3">
            <ul class="pagination justify-content-center">
                <li class="page-item" [class.disabled]="this.PageNo === 1">
                    <a class="page-link" (click)="previousPage()">Previous</a>
                </li>
                <li class="page-item" *ngFor="let pageNumber of pages">
                    <a class="page-link" (click)="changePage(pageNumber)"
                        [class.active]="pageNumber === this.PageNo">{{pageNumber}}</a>
                </li>
                <li class="page-item" [class.disabled]="this.PageNo=== this.totalPages">
                    <a class="page-link" (click)="nextPage()">Next</a>
                </li>
            </ul>
        </div>
        <div class="input-group input-group-sm ml-4" style="max-width:215px;">
            <input type="number" class="form-control" placeholder="Page No."
                (keyup)="onEnterKeyPressed($event)" [(ngModel)]="this.PN"
                aria-label="Enter your text" aria-describedby="button-addon">
            <div class="input-group-append input-group-sm">
                <button class="btn btn-outline-secondary" (click)="selectedPage()" type="button" id="button-addon">Go</button>
            </div>
        </div>
    </nav>
    <p>List of registered suppliers with PPRA as given below is provided for the general information. Others suppliers
        can apply for registration if meet the basic requirements set out by the PPRA.</p>
    <a href="https://eprocure.gov.pk/#/supplier/registration" target="_blank"
        class="btn btn-rounded btn-outline-primary">Please Click here to get Registered.</a>
</section>
<div class="clear py-3"></div>