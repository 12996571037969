<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>Procurement Guide Lines</h1>
	</div>
</section>
<div class="clear py-4"></div>
<section class="container">
    <h3 class="pb-3">Tender Guide Lines</h3>
    <h5 class="font-weight-semi-bold pb-2">DESIRABLE MINIMUM INFORMATION TO BE PROVIDED IN TENDER NOTICE</h5>
    <ol class="font-weight-semi-bold text-primary">
        <li class="mb-2 pb-1">Name of procuring agency.</li>
        <li class="mb-2 pb-1">Tender number (for identification)</li>
        <li class="mb-2 pb-1">Procurement Title (indicating type and quantity).</li>
        <li class="mb-2 pb-1">Contact person (for seeking bidding documents).</li>
        <li class="mb-2 pb-1">Last date for obtaining bidding documents and its price (if any).</li>
        <li class="mb-2 pb-1">Closing time and date as well as place for receiving bids.</li>
        <li class="mb-2 pb-1">Time and Place of public opening of bids (Bids must be opened on the closing  date).</li>
        <li class="mb-2 pb-1">Amount of bid security (Fixed amount of bid security not exceeding five percent of the estimated value of procurement determined by the procuring agency).</li>
        <li class="mb-2 pb-2">Time period for performance of contract.</li>
    </ol>
    <p class="font-weight-semi-bold text_18">
        <a href="https://www.ppra.org.pk/sbd.asp" data-toggle="modal" data-target="#sbd">PPRA National Standard Bidding Documents.</a>
    </p>
    <h5 class="font-weight-semi-bold pb-2">Standard formats for procurement advertisements.</h5>
    <ul class="font-weight-medium">
        <li class="text_16 mb-1"><a href="./assets/docs/s1.doc" class="text-primary">Goods, Works and Non-consulting Services.</a></li>
        <li class="text_16 mb-1"><a href="./assets/docs//s2.doc" class="text-primary">Request for Expression of Interest (EOI)</a></li>
        <li class="text_16"><a href="./assets/docs//s3.doc" class="text-primary">Proposals for Prequalification</a></li>
    </ul>
</section>
<div class="clear py-4"></div>
<!-- Modal -->
<div class="modal fade" id="sbd" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="sbdLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="sbdLabel">PPRA STANDARD BIDDING DOCUMENTS (SBDs)</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p class="font-weight-semi-bold">In pursuance of Section 27 of PPRA Ordinance, 2002 read with Rule 23 of Public Procurement Rules, 2004, Public Procurement Regulatory Authority has been developed  following Standard Bidding Documents (SBDs).</p>
                <p><a href="./assets/docs/sro700.pdf" target="_blank" class="text-primary font-weight-medium">PPRA National Standard Bidding Document (Therapeutic Goods -Pharmaceuticals) (S.R.O 700(I)/2022).</a></p>
                <p><a href="./assets/docs/sro370.pdf" target="_blank" class="text-primary font-weight-medium">PPRA National Standard Bidding Documents (General Goods, Consultancy Services and Closed Framework Agreements) (S.R.O 370(I)/2022).</a></p>
                <ul>
                    <li class="mb-1"><a href="./assets/docs/sbdgoods.pdf" target="_blank" class="text-body font-weight-medium">PPRA National Standard Bidding Documents (General Goods, Consultancy Services and Closed Framework Agreements) (S.R.O 370(I)/2022).</a> <a href="./assets/docs/sbdgoods.doc" class="text-body font-weight-medium">[MS Word]</a></li>
                    <li class="mb-1"><a href="./assets/docs/sbdrfp.pdf" target="_blank" class="text-body font-weight-medium">National Request for Proposal Document for Procurement of Consultancy Services</a> <a href="./assets/docs/sbdrfp.doc" class="text-body font-weight-medium">[MS Word]</a></li>
                    <li class="mb-1"><a href="./assets/docs/sbdcf.pdf" target="_blank" class="text-body font-weight-medium">National Standard Procurement Document for Procurement of Good through Closed Framework Agreements</a> <a href="./assets/docs/sbdcf.doc" class="text-body font-weight-medium">[MS Word]</a></li>
                    <li class="mb-1"><a href="./assets/docs/sbdtg.pdf" target="_blank" class="text-body font-weight-medium">National Standard Bidding Document for Procurement of Therapeutic Goods (Pharmaceuticals)</a> <a href="./assets/docs/sbdtg.doc" class="text-body font-weight-medium">[MS Word]</a></li>
                    <li class="mb-1"><a href="./assets/docs/sbdinfo.pdf" target="_blank" class="text-body font-weight-medium">National Standard Bidding Document for Procurement of Information System (Supply and Installation)</a> <a href="./assets/docs/sbdinfo.doc" class="text-body font-weight-medium">[MS Word]</a></li>
                </ul>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
  