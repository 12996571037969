import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import b64toBlob from 'b64-to-blob';
import { listType } from 'src/app/core/enumerations/enum-ListType';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import { ActiveTendersSearchRequestModel } from 'src/app/core/models/activeTendersRequestModel';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { payRequestModel } from 'src/app/core/models/payRequestModel';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import { saveAs } from 'file-saver';
import { GetAllPublishedDocumentDetailsByPidRequestModel } from 'src/app/core/models/tender-filter.model';

@Component({
  selector: 'app-archive-de-listed-black-listed-firms',
  templateUrl: './archive-de-listed-black-listed-firms.component.html',
  styleUrls: ['./archive-de-listed-black-listed-firms.component.scss']
})
export class ArchiveDeListedBlackListedFirmsComponent implements OnInit {
  payRequestModel: payRequestModel;
  paginationModel: PaginationModel;
  downloadFile: downloadfile;
  dataForm: FormGroup;
  dataModel: ActiveTendersSearchRequestModel;
  loggedInUser: any;
  selectedSort: any;
  Records: any;
  totalRecords: any;
  totalPages: number;
  pages = new Array();
  PageNo: number = 0;
  PN: number;
  ID: number = 0;
  Sector: any;
  DropdownRecords: any
  selectedValue: any
  selectedValueWorth: any
  isSelected: boolean;
  selectedStateID: any;
  ClosingDate: any;
  TenderNumber: any;
  WorthRecord = [
    { id: -2, name: 'Worth More Than 50 Million' },
    { id: -1, name: 'Worth Less Than 50 Million' }
];
  isGuid: boolean;
  constructor(private service: PublicPortalService
   ) {
    this.dataModel = new ActiveTendersSearchRequestModel()
  }

  ngOnInit(): void {
    this.paginationModel = new PaginationModel();
    this.payRequestModel = new payRequestModel();
    this.PageNo = Number(this.paginationModel.pageNumber);
    this.GetPayDetailList();
  }
  
  GetPayDetailList() {

    this.payRequestModel.Type = listType.Delisted;
    this.payRequestModel.ID = 0;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              let data = (baseResponse.data as any);
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }
              this.Records = data?.records;
            }
          }
        }
      })
  }
  

  changePage(pageNumber) {

    this.paginationModel.pageNumber = pageNumber;
    this.PageNo = Number(pageNumber);
    this.PN = this.PageNo;
    this.GetPayDetailList();
  }

  nextPage() {
    if (Number(this.paginationModel.pageNumber) < this.totalPages) {
      this.changePage(Number(this.paginationModel.pageNumber) + 1);
    }
  }

  previousPage() {
    if (Number(this.paginationModel.pageNumber) > 1) {
      this.changePage(Number(this.paginationModel.pageNumber) - 1);
    }
  }

  selectedPage() {
    if(this.PN==null || this.PN==0){
      this.PN=1
    }
    this.paginationModel.pageNumber = String(this.PN);
    this.PageNo = this.PN;
    this.GetPayDetailList();
  }

  onEnterKeyPressed(event: KeyboardEvent): void {
    // Check if the key pressed is Enter

    if (event.key === 'Enter') {
      // Call your function here
      this.selectedPage()
    }
  }
  sort(orderByColumnName, orderBy){
    this.paginationModel.orderBy = orderBy;
    this.payRequestModel.pagination.orderByColumnName = orderByColumnName;
    this.paginationModel.orderByColumnName = orderByColumnName
    this.GetPayDetailList()  
  }
}