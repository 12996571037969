import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnumMicroServices } from '../../enumerations/enum-micro-services';
import { BaseResponseDropdown } from '../../models/base-response-dropdown.model';
import { BaseResponse } from '../../models/base-response.model';
import { GetAllPublishedDocumentDetailsByPidRequestModel, GetClosingTenderRequestModel, GetPPRAOrganizationNameRequestModel, GetPublishedDocumentInfoByTypeRequestModel, GetPublishedDocumentInfoRequestModel } from '../../models/tender-filter.model';
import { downloadfile } from '../../models/UtilModel/downloadfile.model';
import { HttpUtilsService } from '../common/http-utils.service';
import { UserUtilService } from '../common/user-util.service';
import { getAllPublicVoilationsRequestModel } from '../../models/getAllPublicVoilationsRequestModel';
import { payRequestModel } from '../../models/payRequestModel';
import { ActiveBlackListedRequestModel } from '../../models/active-black-listed-request-model.model';
import { BaseRequestModel } from '../../models/BaseModel/BaseRequestModel';
import { getprocurementplanbyidexportRequestModel } from '../../models/UtilModel/getprocurementplanbyidexportRequestModel';
import { GetProDetailRequestModel } from '../../models/UtilModel/GetProDetailRequestModel';
import { ProcurementActivityReportRequestmodel } from '../../models/ProcurementActivityReportRequestmodel';
import { DocumentTemplateSection } from '../../models/DocumenttemplateSection';
import { SBDReportRequestModel } from '../../models/SBDReportRequestModel';

@Injectable({
  providedIn: 'root'
})
export class PublicPortalService {


  closetender(getClosingTenderRequestModel: GetClosingTenderRequestModel) {
    throw new Error('Method not implemented.');
  }
  version: string = "1.0.0";
  service: string = "publicportal";
  microServiceName: string = EnumMicroServices.WebPortal;
  officeId = 31640;
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService,private userUtilService:UserUtilService) {
         this.officeId = this.userUtilService?.selectedEnvironment?.officeId;

   }


  getAllPublicTenders(requestModel: GetPublishedDocumentInfoRequestModel): Observable<BaseResponseDropdown> {

    requestModel.loggedInUserID = 1;
    requestModel.loggedInUserOfficeID = this.officeId;

    var req = JSON.stringify(requestModel);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getallpublictenders", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  getPPRAPlanByOrganization(requestModel: GetPPRAOrganizationNameRequestModel): Observable<BaseResponseDropdown> {

    requestModel.loggedInUserID = 1;
    requestModel.loggedInUserOfficeID = this.officeId;

    var req = JSON.stringify(requestModel);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getppraplanbyorganization", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  getallpublictendersdocumentsbytype(requestModel: GetPublishedDocumentInfoByTypeRequestModel): Observable<BaseResponseDropdown> {

    requestModel.loggedInUserID = 1;
    requestModel.loggedInUserOfficeID = this.officeId;

    var req = JSON.stringify(requestModel);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getallpublictendersdocumentsbytype", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }


  getClosingDate(requestModel: GetClosingTenderRequestModel): Observable<BaseResponseDropdown> {

    requestModel.loggedInUserID = 1;
    requestModel.loggedInUserOfficeID = this.officeId;

    var req = JSON.stringify(requestModel);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "closetender", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }

  getRulesAndRegulatoins(requestModel: GetPublishedDocumentInfoRequestModel): Observable<BaseResponseDropdown> {

    requestModel.loggedInUserID = 1;
    requestModel.loggedInUserOfficeID = this.officeId;
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    var req = JSON.stringify(requestModel);

    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getallppraruleswithclause", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }

  getAllPublishedDocumentDetailbyPdId(request: GetAllPublishedDocumentDetailsByPidRequestModel): Observable<BaseResponseDropdown> {

    // request.loggedInUserOfficeID = 1;
    // request.loggedInUserID = 1;
    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getallpublisheddocumentdetailbypdid", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  getAllPublishedDocumentDetailbyPdIdPublication(request: GetAllPublishedDocumentDetailsByPidRequestModel): Observable<BaseResponseDropdown> {

    // request.loggedInUserOfficeID = 1;
    // request.loggedInUserID = 1;
    var req = JSON.stringify(request);

    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getallpublisheddocumentdetailbypdidpublication", this.service, this.version), req,
      { headers: this.httpUtils.getHTTPHeaders() }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  Getprocurementactivityreportwebportal(request: ProcurementActivityReportRequestmodel): Observable<BaseResponseDropdown> {

    // request.loggedInUserOfficeID = 1;
    // request.loggedInUserID = 1;
    var req = JSON.stringify(request);

    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "procurementactivityreportwebportal", this.service, this.version), req,
      { headers: this.httpUtils.getHTTPHeaders() }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  DownloadFile(model: downloadfile): Observable<BaseResponse> {
    let req = {
      loggedInUserOfficeID: this.officeId,
      loggedInUserID: 1,
      ID: model.ID
    }
    return this.http.post(environment.getAPIPath(EnumMicroServices.documentmanagementsystem, "dmspublicapi", "downloadportalfile", "dmspublicapi", this.version), req,
      { headers: this.httpUtils.getHTTPHeaders() }).pipe(
        map((res: BaseResponse) => res));
  }
  DownloadFileByGuid(model: downloadfile): Observable<BaseResponse> {
    let req = {
      loggedInUserOfficeID: this.officeId,
      loggedInUserID: 1,
      ID: model.ID,
      idsList: model.idsList
    }
    var reqs = JSON.stringify(req);
    return this.http.post(environment.getAPIPath(EnumMicroServices.documentmanagementsystem, "dmspublicapi", "downloadportalfilebyguid", "dmspublicapi", this.version), reqs,
      { headers: this.httpUtils.getHTTPHeaders() }).pipe(
        map((res: BaseResponse) => res));
  }
  getDashboardData(): Observable<BaseResponseDropdown> {

    let request = {
      loggedInUserID: 1,
      loggedInUserOfficeID: this.officeId
    }

    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getdashboarddata", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  getAllBlackListedSuppliers(): Observable<BaseResponseDropdown> {

    let request = {
      loggedInUserID: 1,
      loggedInUserOfficeID: this.officeId
    }

    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getblacklistedsuppliers", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  getAllHighLights(): Observable<BaseResponseDropdown> {

    let request = {
      loggedInUserID: 1,
      loggedInUserOfficeID: this.officeId,
    }

    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getallhighlights", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  getallpublicviolations(request: getAllPublicVoilationsRequestModel): Observable<BaseResponseDropdown> {
    request.loggedInUserID = 1;
    request.loggedInUserOfficeID = this.officeId;
    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getallpublicviolations", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }

  getTablueToken(): Observable<BaseResponse> {

    let request = {
      loggedInUserID: 1,
      loggedInUserOfficeID: 1
    }

    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46YWRtaW4='

    });
    return this.http.post("https://devapi.eprocure.gov.pk/usermanagement/1.0.0/api/v1/user/tokenfortableau", req,
      { headers: headers }).pipe(
        map((res: BaseResponse) => res));
  }

  GetPayDetailList(request: payRequestModel): Observable<BaseResponseDropdown> {
    request.loggedInUserID = 1;
    request.loggedInUserOfficeID = this.officeId;
    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getpaydata", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  GetPayDetailListTenderEIOPQ(request: payRequestModel): Observable<BaseResponseDropdown> {
    request.loggedInUserID = 1;
    request.loggedInUserOfficeID = this.officeId;
    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getpayrecordsdata", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }

  GetPPRRASupplierCount(): Observable<BaseResponseDropdown> {
    let request = {
      loggedInUserID: 1,
      loggedInUserOfficeID: this.officeId,
    }
    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getpprasuppliercount", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }

  Getpprablacklistsupplier(request: ActiveBlackListedRequestModel): Observable<BaseResponseDropdown> {
    request.loggedInUserID = 1;
    request.loggedInUserOfficeID = this.officeId;
    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getpprablacklistsupplier", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  getpprablacklistedfile(request: ActiveBlackListedRequestModel): Observable<BaseResponseDropdown> {
    request.loggedInUserID = 1;
    request.loggedInUserOfficeID = this.officeId;
    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getpprablacklistedfile", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  GetDepartmentname(request: BaseRequestModel): Observable<BaseResponseDropdown> {
    request.loggedInUserID = 1;
    request.loggedInUserOfficeID = this.officeId;
    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getdepartmentname", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  getprocurementdetail(request: GetProDetailRequestModel): Observable<BaseResponseDropdown> {
    request.loggedInUserID = 1;
    request.loggedInUserOfficeID = this.officeId;
    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getprocurementdetail", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  getprocurementplanbyidexport(request: getprocurementplanbyidexportRequestModel): Observable<BaseResponseDropdown> {
    request.loggedInUserID = 1;
    request.loggedInUserOfficeID = this.officeId;
    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getprocurementplanbyidexport", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  GetPortalNewsAndAnnouncements(): Observable<BaseResponseDropdown> {

    let request = {
      loggedInUserID: 1,
      loggedInUserOfficeID: this.officeId,
    }

    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getportalnewsandannouncements", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  getNoOfLots(procurementPlanDetailID:number,publishedDocumentID:number): Observable<BaseResponseDropdown> {

    let request = {
      loggedInUserID: 1,
      loggedInUserOfficeID: this.officeId,
      procurementPlanDetailID: procurementPlanDetailID,
			publishedDocumentID:publishedDocumentID
    }

    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getnooflotlookupswebportal", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  getSection(sectionObj: DocumentTemplateSection): Observable<BaseResponseDropdown> {

    let request = {
      loggedInUserID: 1,
      loggedInUserOfficeID: this.officeId,
      sectionID: sectionObj.sectionID,
			parentID: sectionObj.parentID,
			documentTemplateID: sectionObj.documentTemplateID,
			isTransectionalData: sectionObj.isTransectionalData,
			lotNo: sectionObj.noOfLotsValue,
    }

    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getsectionwebportal", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  geteFinalEvaluationReport(publishedDocumentID: number,finalEvaluation:boolean,supplierID?:number,lotNo?:number): Observable<BaseResponseDropdown> {

    let request = {
      loggedInUserID: 1,
      loggedInUserOfficeID: this.officeId,
      publishedDocumentID: publishedDocumentID,
      finalEvaluation:finalEvaluation,
      supplierID:supplierID,
      lotNo:lotNo
    }

    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getfinalevaluationreportwebportal", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  geteContractReport(publishedDocumentID: number,supplierID:number): Observable<BaseResponseDropdown> {

    let request = {
      loggedInUserID: 1,
      loggedInUserOfficeID: this.officeId,
			publishedDocumentID: publishedDocumentID,
      supplierID:supplierID
    }

    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "getecontractreportwebportal", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
  genReport(request:SBDReportRequestModel) : Observable<BaseResponseDropdown> {
    request.loggedInUserID= 1,
    request.loggedInUserOfficeID= this.officeId
    var req = JSON.stringify(request);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Basic YWRtaW46cHByYTEy',
      'OfficeDetail': this.userUtilService.selectedEnvironmentName

    });
    return this.http.post(environment.getAPIPath(this.microServiceName, this.service, "sbdreportwebportal", this.service, this.version), req,
      { headers: headers }).pipe(
        map((res: BaseResponseDropdown) => res));
  }
}