import { NgModule ,CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TendersComponent } from './tenders/tenders.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PpraTendersComponent } from './ppra-tenders/ppra-tenders.component';
import { PpraTechEvaluationComponent } from './ppra-tech-evaluation/ppra-tech-evaluation.component';
import { FinancialEvaluationComponent } from './financial-evaluation/financial-evaluation.component';
import { PpraPlansComponent } from './ppra-plans/ppra-plans.component';
import { PpraContractsComponent } from './ppra-contracts/ppra-contracts.component';
import { PaymentlistComponent } from './paymentlist/paymentlist.component';
import { EvaluationlistComponent } from './evaluationlist/evaluationlist.component';
import { GrclistComponent } from './grclist/grclist.component';
import { EvaluationResultsComponent } from './evaluationlist/evaluation-results/evaluation-results.component';
import { PpracontractsComponent } from './ppracontracts/ppracontracts.component';
import { ContractsDetailComponent } from './ppracontracts/contracts-detail/contracts-detail.component';
import { ActiveTendersComponent } from './active-tenders/active-tenders.component';
import { OrganizationTendersComponent } from './organization-tenders/organization-tenders.component';
import { SectorsTendersComponent } from './sectors-tenders/sectors-tenders.component';
import { TendersHistoryComponent } from './tenders-history/tenders-history.component';
import { OrganizationsTendersHistoryComponent } from './organizations-tenders-history/organizations-tenders-history.component';
import { SectorTendersHistoryComponent } from './sector-tenders-history/sector-tenders-history.component';
import { SearchDropdownComponent } from './search-dropdown/search-dropdown.component';
import { ExpressionofInterestComponent } from './expressionof-interest/expressionof-interest.component';
import { PrequalificationNoticeComponent } from './prequalification-notice/prequalification-notice.component';
import { RequestforProposalComponent } from './requestfor-proposal/requestfor-proposal.component';
import { SalesAuctionDisposalComponent } from './sales-auction-disposal/sales-auction-disposal.component';
import { StandardBiddingDocumentComponent } from './standard-bidding-document/standard-bidding-document.component';
import { EvaluationExportPDFComponent } from './Reports/evaluationExportPDF/evaluationExportPDF.component';
import { ContractAwardPerforma2Component } from './Reports/contract-award-performa2/contract-award-performa2.component';
import { ContractAwardKpkComponent } from './Reports/contract-award-kpk/contract-award-kpk.component';
import { ContractAwardPpraComponent } from './Reports/contract-award-ppra/contract-award-ppra.component';
import { ContractAwardPunjabComponent } from './Reports/contract-award-punjab/contract-award-punjab.component';
import { ContractAwardSppraComponent } from './Reports/contract-award-sppra/contract-award-sppra.component';
import { EvaluationReportFppraComponent } from './Reports/evaluation-report-fppra/evaluation-report-fppra.component';
import { EvaluationReportKpkComponent } from './Reports/evaluation-report-kpk/evaluation-report-kpk.component';
import { EvaluationReportPunjabComponent } from './Reports/evaluation-report-punjab/evaluation-report-punjab.component';
import { EvaluationReportSppraComponent } from './Reports/evaluation-report-sppra/evaluation-report-sppra.component';



@NgModule({
  declarations: [
    TendersComponent,
    PpraTendersComponent,
    PpraTechEvaluationComponent,
    FinancialEvaluationComponent,
    PpraPlansComponent,
    PpraContractsComponent,
    PaymentlistComponent,
    EvaluationlistComponent,
    GrclistComponent,
    EvaluationResultsComponent,
    PpracontractsComponent,
    ContractsDetailComponent,
    ActiveTendersComponent,
    OrganizationTendersComponent,
    SectorsTendersComponent,
    TendersHistoryComponent,
    OrganizationsTendersHistoryComponent,
    SectorTendersHistoryComponent,
    SearchDropdownComponent,
    ExpressionofInterestComponent,
    PrequalificationNoticeComponent,
    RequestforProposalComponent,
    SalesAuctionDisposalComponent,
    StandardBiddingDocumentComponent,
    EvaluationExportPDFComponent,
    ContractAwardPerforma2Component,
    ContractAwardKpkComponent,
    ContractAwardPpraComponent,
    ContractAwardPunjabComponent,
    ContractAwardSppraComponent,
    EvaluationReportFppraComponent,
    EvaluationReportKpkComponent,
    EvaluationReportPunjabComponent,
    EvaluationReportSppraComponent
  ],
  imports: [
    FormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: TendersComponent,
        
      },
      {
        path: 'ppratenders',
        component: PpraTendersComponent,
      },
      {
        path: 'ppracontracts',
        component: PpraContractsComponent,
      },
      {
        path: 'ppraplans',
        component: PpraPlansComponent,
      },
      {
        path: 'ppratechevaluation',
        component: PpraTechEvaluationComponent,
      },
      {
        path: 'pprafinancialevaluation',
        component: FinancialEvaluationComponent,
      },
      {
        path: 'paymentlist',
        component: PaymentlistComponent,
      },
      {
        path: 'evaluationtlist',
        component: EvaluationlistComponent,
      },
      {
        path: 'grclist',
        component: GrclistComponent,
      },
      {
        path: 'evalualtionDetaillist/:id',
        component: EvaluationResultsComponent,
      },
      {
        path: 'procurementContracts',
        component: PpracontractsComponent,
      },
      {
        path: 'procurementContractDetail/:id',
        component: ContractsDetailComponent,
      },
      {
        path: 'activetenders',
        component: ActiveTendersComponent,
      }, 
      {
        path: 'activetenders/:id',
        component: ActiveTendersComponent,
      },
      {
        path: 'sectorwisetenders',
        component: SectorsTendersComponent,
      },
      {
        path: 'organizationwisetenders',
        component: OrganizationTendersComponent,
      },
      {
        path: 'tendershistory',
        component: TendersHistoryComponent,
      },
      {
        path: 'tendershistory/:id',
        component: TendersHistoryComponent,
      },
      {
        path: 'sectorwisetendershistory',
        component: SectorTendersHistoryComponent,
      },
      {
        path: 'organizationwisetendershistory',
        component: OrganizationsTendersHistoryComponent,
      },
      {
        path: 'Epadtenders',
        component: TendersComponent,
      },
      {
        path: 'EOITenders',
        component: ExpressionofInterestComponent,
      },
      {
        path: 'PQTenders',
        component: PrequalificationNoticeComponent,
      },
      {
        path: 'RFPTenders',
        component: RequestforProposalComponent,
      },{
        path: 'SADTenders',
        component: SalesAuctionDisposalComponent,
      },
    ]),
    
    CommonModule
  ],
  exports:[SearchDropdownComponent]
})
export class TendersModule { }
