<div class="clear"></div>
<section class="banner">
    <div class="container py-4">
        <h1>Tender History</h1>
    </div>
</section>
<div class="clear p-4"></div>
<div class="container">
    <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-semi-bold mb-0">Organizations Tender History</h3>
        <div>
            <label class="mr-3">View By:</label>
            <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                <a href="#/tenders/tendershistory" class="btn btn-link">All</a>
                <a href="#tenders/organizationwisetendershistory" class="btn btn-link">Organizations</a>
                <a href="#tenders/sectorwisetendershistory" class="btn btn-link">Sectors</a>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-3">
        
        <h6 class="mb-0 mb-3" style="font-style: italic; color: red;">Only 40 days old tenders from the closing date will be shown.</h6> 
        
    </div>
    <div class="row align-items-center">
        <label class="col-md-2" for="OrganizationName">Organization Name:</label>
        <div class="col-md-4">
            <app-search-dropdown [items]="DropdownRecords" [size]="'small'" [img]="'product'" [label]="'CategoryName'" [uid]="'CategoryID'"
                [(ngModel)]="selectedValue">
            </app-search-dropdown>
        </div>
        <div class="col">
           <button type="submit" (click)="onSelectedValue()" class="btn btn-primary mb-2">Search</button>
           <button  type="Clear"  (click)="clear()" class="btn btn-primary mb-2 ml-2">Clear</button>
        </div>
    </div>
    <div class="clear p-2"></div>
    <div class="container">
        <!-- <h6 class="font-weight-medium">Organization Name: {{this.Organization}}</h6> -->
        <div class="clear p-2"></div>
        <p class="font-weight-bold text-right">Total Results ({{totalRecords}})</p>
        <div class="table-responsive">
            <table class="table border">
                <thead class="table-success">
                    <tr>
                        <th scope="col">Sr No.</th>
                        <th scope="col">Organization Name<a (click)="sort('CategoryName','desc')"><img  src="../assets/images/arrow_up.gif"></a> <a (click)="sort('CategoryName','asc')"><img src="../assets/images/arrow_down.gif"></a></th>
                        <th scope="col">Total Tenders</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of Records; let i = index">
                        <th>{{i+1}}</th>
                        <td>
                            <a *ngIf="data?.TotalResult>0" routerLink="#" (click)="navigate(data.CategoryID,data?.CategoryName)" class="font-weight-semi-bold" style="color: blue; text-decoration: underline;">{{data?.CategoryName}}</a>
                           <a *ngIf="data?.TotalResult==0"   class="text-black font-weight-semi-bold">{{data?.CategoryName}}</a> 
                        </td>
                        <td>
                            {{data?.TotalResult}}<br>
                        </td>
                    </tr>
                    <strong *ngIf="Records?.length == '0'">No record found.</strong>
                </tbody>
            </table>
            <div class="clear clearfix py-1"></div>
            <small class="font_12">10 Records Per Page</small>
            <small class="font_12"> | Total Pages<strong> {{this.totalPages}}</strong></small>
            <nav aria-label="..." class="arial-pagination">
                <div class="d-flex">
                    <ul class="pagination justify-content-center mr-4">
                        <li class="page-item" [class.disabled]="this.PageNo === 1">
                            <a class="page-link" (click)="previousPage()">Previous</a>
                        </li>
                        <li class="page-item" *ngFor="let pageNumber of pages">
                            <a class="page-link" (click)="changePage(pageNumber)"
                                [class.active]="pageNumber === this.PageNo">{{pageNumber}}</a>
                        </li>
                        <li class="page-item" [class.disabled]="this.PageNo=== this.totalPages">
                            <a class="page-link" (click)="nextPage()">Next</a>
                        </li>
                    </ul>
                    <div class="input-group input-group-sm">
                        <input type="number" class="form-control" placeholder="Page No."
                            (keyup)="onEnterKeyPressed($event)" [(ngModel)]="this.PN"
                            aria-label="Enter your text" aria-describedby="button-addon">
                        <div class="input-group-append input-group-sm">
                            <button class="btn btn-outline-secondary" (click)="selectedPage()" type="button" id="button-addon">Go</button>
                        </div>
                    </div>
                </div>
                <div class="container mt-5">
                    <div class="row">
                        <div class="col-md-3">
                            
                        </div>
                        <div class="col-md-2">
                            
                        </div>
                    </div>
                </div>

            </nav>

        </div>
    </div>