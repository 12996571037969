<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>{{this.userUtilsService.selectedEnvironment?.Letter}}PPRA Board</h1>
	</div>
</section>
<div class="clear py-3"></div>
<section class="container">
    <p>General directions and administration of the Authority and its affairs vest in a Board which may exercise all powers, perform all functions and do all acts and things which may be exercised, performed or done by the Authority. The Board consists of the following:</p>
    <div class="table-responsive">
        <table class="table border table-striped">
            <thead class="table-success">
                <tr>
                    <th>Sr. No</th>
                    <th>Name &amp; Designation</th>
                    <th>Status</th>
                    <th>Telephone No.</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>Secretary, Finance Division,</td>
                    <td>Chairman</td>
                    <td>051-920-2373(O)<br>Fax: 051-920-1857</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Secretary, Ministry of Industries &amp; Production,</td>
                    <td>Member</td>
                    <td>051-921-0192(O)<br>051-921-1709(O)<br>Fax: 051-9207748</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>Secretary, Defence Production Division,</td>
                    <td>Member</td>
                    <td>051-927-0930(O)<br>051-561-32434(O)<br>Fax: 051-927-0944</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>Secretary, Ministry of Water &amp; Power</td>
                    <td>Member</td>
                    <td>051-920-2335<br>051-921-1852(O)<br>051-920-6460(O)<br>Fax: 051-920-6272</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>Secretary, Ministry of Housing &amp; Works</td>
                    <td>Member</td>
                    <td>051-921-4283(O)<br>051-920-3151(O)<br>Fax: 051-9202952</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>Secretary, Ministry of Communications</td>
                    <td>Member</td>
                    <td>051-922-0190(O)<br>051-920-1252(O)<br>Fax: 051-922-1300</td>
                </tr>
                <tr>
                    <td>7</td>
                    <td>Private Member</td>
                    <td>Member</td>
                    <td>Vacant</td>
                </tr>
                <tr>
                    <td>8</td>
                    <td>Private Member</td>
                    <td>Member</td>
                    <td>Vacant</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>Private Member</td>
                    <td>Member</td>
                    <td>Vacant</td>
                </tr>
                <tr>
                    <td>10</td>
                    <td>Managing Director, Public Procurement Regulatory Authority</td>
                    <td>Member/Secretary</td>
                    <td>051-922-4824(O)<br>Fax: 051-922-4823</td>
                </tr>
            </tbody>
        </table>
    </div>
    <p class="font-weight-bold"><a target="_blank" href="./assets/docs/ppra2.doc">Appointment of Private Members to the Board of Public Procurement Regulatory Authority (PPRA).</a></p>
</section>
<div class="clear py-3"></div>