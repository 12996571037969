import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './modules/shared/shared.module';
import { HomeModule } from './modules/home/home.module';
import { OthersModule } from './modules/others/others.module';
import { DocumentsModule } from './modules/documents/documents.module';
import { IntercepterService } from './core/services/common/intercepter.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { UserUtilService } from './core/services/common/user-util.service';
import { ActiveTendersComponent } from './modules/tenders/active-tenders/active-tenders.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent
    
     ],
  imports: [
    HttpClientModule,
    OthersModule,
    BrowserModule,
    HomeModule,
    SharedModule,
    AppRoutingModule,
    NgbModule,
    DocumentsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 6000,
      progressBar: true,
      preventDuplicates: true,
      positionClass: 'toast-bottom-center'
    }),
  ],
  exports : [
    NgxSpinnerModule,
  ],
  providers: [
    UserUtilService,
  IntercepterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IntercepterService,
      multi: true
    },
],
  bootstrap: [AppComponent]
})
export class AppModule { }
