import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import * as $ from 'jquery';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {

  activePageMenu: string;
  constructor(public userUtilsService:UserUtilService,
    private elementRef: ElementRef,
    private router: Router) { }

  ngOnInit(): void {

   this.router.events.pipe(
    filter(event => event instanceof NavigationEnd)
  ).subscribe((event: NavigationEnd) => {
    // Get the URL segment after the last slash
    const urlSegment = event.url.split('/').pop();
    
    
    // Map the URL segment to the corresponding menu name
    //this.activePageMenu=urlSegment;
    //this.activePageMenu = this.mapUrlSegmentToMenuName(urlSegment);
  });  
}
clearsessionstroage(){
  sessionStorage.clear();
}
}
