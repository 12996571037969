 
<button pButton class="btn btn-sm btn-outline-primary" *ngIf="!fromEContract" (click)="downloadReport()"><i class="fas fa-download mr-1"></i>Download Report</button>
<div id="pdfContent" style="padding: 10px;" class="mt-3">
    <h3 style="text-align: center; font-weight: bold;">
       <u> <span>{{evaluationHeading}} EVALUATION REPORT</span>  <br/>
    (As Per Rule 35 of PP Rules, 2004)
</u>
    </h3>
    <div>
        <ol class="custom-list grid mt-3">
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Name of Procuring Agency:</span>
                    <span class="md:col-8 text-wrap" style="border-bottom:#000 1px;">{{reportRecord?.nameOfProcuringAgency}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Method of Procurement:</span>
                    <span class="md:col-8" style="border-bottom:#000 1px;">{{reportRecord?.procurementMethod}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Title of Procurement:</span>
                    <span class="md:col-8 text-wrap" style="border-bottom:#000 1px;">{{reportRecord?.procurementTitle}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Tender Inquiry No.:</span>
                    <span class="md:col-8 text-wrap" style="border-bottom:#000 1px;">{{reportRecord?.tenderInquiryNo}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">PPRA Ref. No. (TSE):</span>
                    <span class="md:col-8 text-wrap" style="border-bottom:#000 1px;">{{reportRecord?.ppraRefNo}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Date & Time of Bid Closing:</span>
                    <span class="md:col-8 text-wrap" style="border-bottom:#000 1px;">{{reportRecord?.closingDate}} {{ reportRecord?.closingTime }}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Date & Time of Bid Opening:</span>
                    <span class="md:col-8 text-wrap" style="border-bottom:#000 1px ;">{{reportRecord?.bidOpeningDate}} {{reportRecord?.bidOpeningTime }}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">No of Bids Received:</span>
                    <span class="md:col-8 text-wrap" style="border-bottom:#000 1px;">{{reportRecord?.bidsReceived}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Criteria for Bid Evaluation:</span>
                    <span class="md:col-8 text-wrap" style="border-bottom:#000 1px;">{{reportRecord?.criteriaBidEvaluation}} </span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Details of Bid(s) Evaluation:</span>
                    <span class="md:col-8 text-wrap" style="border-bottom:#000 1px;">{{reportRecord?.detailsBidEvaluation}}</span>
                </div>
            </li>
            <table aria-describedby="firstTable" style="margin: 20px 0px 20px -16px;">
                <tbody>
                    <tr>
                        <th scope="col" rowspan="2">Name of Bidder</th>
                        <th scope="col" colspan="2" *ngIf="FinalEvaluation">Marks</th>
                        <th scope="col" *ngIf="!FinalEvaluation"><strong>Technical Marks</strong><br><small>(if applicable)</small></th>
                        <!-- <th rowspan="2" colspan="2">Technical & Financial</th> -->
                        <th scope="col" rowspan="2" *ngIf="FinalEvaluation">Evaluated Cost</th>
                        <th scope="col" rowspan="2" >Rule/Regulation/SBD*/Policy/ Basis for Rejection / Acceptance as per Rule 35 of PP Rules, 2004.</th>
                    </tr>
                    <tr *ngIf="FinalEvaluation">
                        <td><strong>Technical</strong><br><small>(if applicable)</small></td>
                        <td><strong>Financial</strong><br><small>(if applicable)</small></td>
                    </tr>
                    <tr></tr>
                        <tr   *ngFor="let item of data">
                            <td>{{item?.nameOFBidder}}</td>
                            <!-- <td *ngIf="reportRecord?.procurementProcedure=='Single Stage Single Envelope'" colspan="2">{{item.marks}}</td> -->
                            <td class="text-wrap word-break" *ngIf="reportRecord?.procurementProcedure!='Single Stage Single Envelope' && FinalEvaluation">{{item.technicalOrignalScore}}</td>
                            <td class="text-wrap word-break" *ngIf="reportRecord?.procurementProcedure!='Single Stage Single Envelope' && FinalEvaluation">{{item.financialSectionMemberMarks}}</td>
                            <td class="text-wrap word-break" *ngIf="reportRecord?.procurementProcedure=='Single Stage Single Envelope' && FinalEvaluation">N/a</td>
                            <td class="text-wrap word-break" *ngIf="reportRecord?.procurementProcedure=='Single Stage Single Envelope' && FinalEvaluation" >
                              <label *ngIf="reportRecord?.criteriaBidEvaluation=='Single Stage Single Envelope(Least Cost Based)'">N/a</label>
                              <label *ngIf="reportRecord?.criteriaBidEvaluation!='Single Stage Single Envelope(Least Cost Based)'">{{item.marks}}</label>
                            </td>
                            <td class="text-wrap word-break" *ngIf="!FinalEvaluation">{{item?.technicalOrignalScore}}</td>
                            <td class="text-wrap word-break" *ngIf="FinalEvaluation">
                               <label *ngIf="item.isShortListed">{{item?.evaluatedCost | number}}</label>
                               <label *ngIf="!item.isShortListed">0</label>
                            </td>
                            <td class="text-wrap" style="width: 200px;">{{item?.loiResponse}} <label *ngIf="reportRecord?.itemWise">({{item.nameOFBidder}} found lowest for {{item.totalItems}} Items. Evaluated Cost = {{item.itemsCost}} PKR)</label> </td>
                        </tr>
                </tbody>
            </table>
            <div class="grid w-full mb-3" *ngIf="!reportRecord?.itemWise && FinalEvaluation===true">
                <strong class="md:col-4">Most Advantageous Bidder:</strong>
                <span class="md:col-8" style="border-bottom:#000 1px;">{{mostAdvantigiousBidder}}</span>
            </div>
            <li *ngIf="!reportRecord?.itemWise">
                <span>Any other additional / supporting information, the procuring agency may like to share.</span>
            </li>
        </ol>
    </div>
    <div class="breakhere" *ngIf="reportRecord?.itemWise">
            <h3 style="text-align: center;" *ngIf="isCenter"> <u> FINANCIAL COMPARATIVE STATEMENT </u></h3>
            <h3 *ngIf="!isCenter"> <u> FINANCIAL COMPARATIVE STATEMENT </u></h3>

            <table aria-describedby="secondTable" >
            <thead>
                <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Item Name</th>
                    <th scope="col" style="width: 50px;">Specification (if any)</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Evaluated Cost</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of itemwiseTableData;index as i">
                    <td  class="text-wrap word-break">{{i+1}}</td>
                    <td  class="text-wrap" style="width: 150px;">{{item.criterionTitle}}</td>
                    <td  class="text-wrap word-break">{{item.specification}}</td>
                    <td  class="text-wrap word-break">{{item.quantity}}</td>
                    <td class="text-wrap" style="width: 150px;">
                        <ul style="list-style-type: lower-alpha;">
                            <li *ngFor="let supplierRecord of item.supplierData ;index as i">
                               {{supplierRecord.unitPrice}} {{supplierRecord.supplierName}}
                            </li>
                            <br>
                        </ul>
                    </td>
                    <td class="text-wrap" style="width: 150px;">
                        <ul style="list-style-type: lower-alpha;">
                            <li *ngFor="let supplierRecord of item.supplierData ;index as i">
                               {{supplierRecord.price}} <span *ngIf="i===0">(Most Advantageous) </span>{{supplierRecord.supplierName}}

                            </li>
                            <br>
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>
        <div>
            <h4>Lowest Evaluated Bidder</h4>
            <ol>
                <li *ngFor="let item of lowestBidderList">{{item.nameOFBidder}} found lowest for {{item.totalItems}} Items</li>
            </ol>
        </div>
    </div>
    <div>
        <strong >This is system Generated Report:</strong><br><br>
        <em><strong>*Standard Bidding Documents (SBD).</strong></em>
    </div>
</div>
