export enum EnumMicroServices {
    WebPortal = "websiteportal",
    UserManagement = "usermanagement",
    UserRegistration = "userregistration",
    SacManagement = "sacmanagement",
    SupplierManagement = 'suppliermanagement',
    WorkLoadManagement = 'workloadmanagement',
    ContractManagement ='contractmanagement',
    OTP = 'otp',
    notification = 'notificationmanagement',
    cms='configurationmanagement',
    documentcreation='documentcreation',
    workflowmanagement='workflowmanagement',
    documentmanagementsystem='documentmanagementsystem',
    globalsearch = 'globalsearch',
    report = 'report',
    dashboard='dashboard'
}
