<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>Evaluation Results</h1>
	</div>
</section>
<div class="clear p-4"></div>
<div class="container">
    <h5 class="font-weight-medium mb-2">Announcement of Evaluation Reports as PP Rule-35</h5>
    <h6><a href="https://www.ppra.org.pk/doc/tecelv.pdf" target="_blank" class="font-weight-medium"><i class="far fa-file-pdf fa-fw"></i> Technical Evaluation Report Sample</a></h6>
    <h6><a href="https://www.ppra.org.pk/doc/elv.pdf" target="_blank" class="font-weight-medium"><i class="far fa-file-pdf fa-fw"></i> Final Evaluation Report Sample</a></h6>
    <div class="clear p-2"></div>
    <div class="row align-items-center">
        <label class="col-md-2" for="OrganizationName">Organization Name:</label>
        <div class="col-md-4">
            <app-search-dropdown [items]="DropdownRecords" [size]="'small'" [img]="'product'" [label]="'OrganizationName'" [uid]="'OrganizationName'"
                [(ngModel)]="selectedValue">
            </app-search-dropdown>
            <!-- <select class="form-control" id="OrganizationName" [(ngModel)]="selectedValue">
                <option  *ngFor="let record of DropdownRecords"  [value]="record?.OrganizationName">{{record?.OrganizationName}}</option>
            </select> -->
        </div>
        <div class="col">
           <button type="submit" (click)="onSelectedValue()" class="btn btn-primary mb-2">Search</button>
           <button  type="Clear"  (click)="clear()" class="btn btn-primary mb-2 ml-2">Clear</button>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table border">
            <thead class="table-success">
                <tr>
                    <th scope="col" width="70">Sr NO.</th>
                    <th scope="col">Organization Name<a (click)="sort('OrganizationName','desc')"><img  src="../assets/images/arrow_up.gif"></a> <a (click)="sort('OrganizationName','asc')"><img src="../assets/images/arrow_down.gif"></a></th>
                    <th scope="col" width="120" class="text-center">Total Results</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of Records; let i = index">
                    <td>
                        <strong>{{i+1}}</strong><br>
                    </td>
                    <td>
                        <a routerLink="#" (click)="navigate(data.OrgID)" style="color: blue; text-decoration: underline;"><strong>{{data?.OrganizationName}}</strong></a>
                    </td>
                    <td class="text-center">{{data?.TotalResult}}</td>               
                </tr> 
                <strong *ngIf="Records.length == '0'">No record found.</strong>           
            </tbody>
        </table>
        <div class="clear clearfix py-1"></div>
        <!-- <p class="font-weight-bold">Total Results ( 5 )</p> -->
        
        <p class="font-weight-bold">Total Records ({{totalRecords}})</p>
        <small class="font_12">10 Records Per Page</small> 
        <small class="font_12"> | Total Pages<strong>  {{this.totalPages}}</strong></small> 
        <nav aria-label="..." class="arial-pagination">
        
            <div class="container mt-5">
                <div class="row">
                    <div class="col-md-3">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" [class.disabled]="this.PageNo === 1">
                                <a class="page-link" (click)="previousPage()">Previous</a>
                            </li>
                            <li class="page-item" *ngFor="let pageNumber of pages">
                                <a class="page-link" (click)="changePage(pageNumber)" [class.active]="pageNumber === this.PageNo">{{pageNumber}}</a> 
                            </li>
                            <li class="page-item" [class.disabled]="this.PageNo=== this.totalPages">
                                <a class="page-link" (click)="nextPage()">Next</a>
                            </li>
                        </ul>
                    </div>
                  <div class="col-md-2">
                    <div class="input-group input-group-sm">
                      <input type="number" class="form-control" placeholder="Page No." (keyup)="onEnterKeyPressed($event)"  [(ngModel)]="this.PN" aria-label="Enter your text" aria-describedby="button-addon">
                      <div class="input-group-append input-group-sm">
                        <button class="btn btn-outline-secondary"  (click)="selectedPage()" type="button" id="button-addon"><span style="color: blue; ">Go</span></button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
           
        </nav>  

    </div>

</div>

<div class="clear p-4"></div>