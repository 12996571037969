import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { GetAllPublishedDocumentDetailsByPidRequestModel, GetPublishedDocumentInfoRequestModel, TenderFilter } from 'src/app/core/models/tender-filter.model';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import b64toBlob from 'b64-to-blob';
import { saveAs } from 'file-saver';
import { BaseRequestModel } from 'src/app/core/models/BaseModel/BaseRequestModel';
import { getprocurementplanbyidexportRequestModel } from 'src/app/core/models/UtilModel/getprocurementplanbyidexportRequestModel';
import { GetProDetailRequestModel } from 'src/app/core/models/UtilModel/GetProDetailRequestModel';

@Component({
  selector: 'app-procurement-plan',
  templateUrl: './procurement-plan.component.html',
  styleUrls: ['./procurement-plan.component.scss']
})
export class ProcurementPlanComponent implements OnInit {
  paginationModel: PaginationModel;
  searchFilter :TenderFilter;
  getAllTendersRequestModel:GetPublishedDocumentInfoRequestModel;
  GetProDetailRequestModel:GetProDetailRequestModel;
  getDepartmentRequest:BaseRequestModel;
  publicTenders:any;
  isViolation: boolean=false;
  totalRecords:any;
  totalPages:number;
  pages= new Array();
  downloadFile: downloadfile;
  currentDate = new Date();
  publicationDetails: any;
  documentsDetails: any;
  getAllPublishedDocumentDetailsByPidRequestModel: GetAllPublishedDocumentDetailsByPidRequestModel;
  getprocurementplanbyidexportRequestModel: getprocurementplanbyidexportRequestModel;
  PN: any;
  PageNo: number=0;
  departmentNameValue: null;
  selectedValue: any;
  departmentNames: any;
  PlanActivitiesData: any;
  @ViewChild('content') contentElement: ElementRef;
  PlanID: null;
  PlanName: null;
  constructor(private userUtil:UserUtilService,private portalService:PublicPortalService) { 
    this.userUtil.isPPRAPage = false;
  }

  ngOnInit(): void {
    this.paginationModel = new PaginationModel();
    this.GetProDetailRequestModel=new GetProDetailRequestModel();
    this.getprocurementplanbyidexportRequestModel = new getprocurementplanbyidexportRequestModel();
    this.getDepartmentRequest=new BaseRequestModel(); 
    this.GetAllProcurementPlans();
    this.GetDepartmentname();
    this.PageNo = Number(this.paginationModel.pageNumber); 
  }
  
  ngOnDestroy(): void {
    this.userUtil.isPPRAPage = false;
  }
  getprocurementplanbyidexport(PlanID:number){
    this.PlanActivitiesData=[]
    this.getprocurementplanbyidexportRequestModel = new getprocurementplanbyidexportRequestModel();
    this.getprocurementplanbyidexportRequestModel.planID=PlanID;
    this.portalService.getprocurementplanbyidexport(this.getprocurementplanbyidexportRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
                  let departments = (baseResponse.data as any) ;
                  this.PlanActivitiesData =departments;
            }
          }
        }
      })
  }
  GetAllProcurementPlans(){
    this.GetProDetailRequestModel = new GetProDetailRequestModel();
    this.GetProDetailRequestModel.Pagination = this.paginationModel;
    this.GetProDetailRequestModel.Pagination.pageSize="10"
    if(this.departmentNameValue!=null){
      this.selectedValue=this.departmentNameValue==null?null:this.departmentNames.filter(x=>x.departmentName==this.departmentNameValue)[0].id;
      this.GetProDetailRequestModel.DepartmentName = this.selectedValue.toString()
    }else{
      this.GetProDetailRequestModel.DepartmentName = null
    }
    this.GetProDetailRequestModel.PlanID = this.PlanID|| null;
      this.GetProDetailRequestModel.PlanName =  this.PlanName || null;
    this.portalService.getprocurementdetail(this.GetProDetailRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
                  let departments = (baseResponse.data as any) ;
                  this.publicTenders =departments.records;
                  this.totalPages = departments?.totalPages;
                  this.pages = new Array();
                  for (let i = 1; i <= this.totalPages; i++) {
                  this.pages.push(i);
                  }
            }
          }
        }
      })
  }
  OnSelectGetAllProcurementPlans(){
    this.paginationModel = new PaginationModel();
    this.GetProDetailRequestModel.Pagination = this.paginationModel;
    this.GetProDetailRequestModel.Pagination.pageSize="10"
    if(this.departmentNameValue!=null){
      this.selectedValue=this.departmentNameValue==null?null:this.departmentNames.filter(x=>x.departmentName==this.departmentNameValue)[0].id;
      this.GetProDetailRequestModel.DepartmentName = this.selectedValue.toString()
    }else{
      this.GetProDetailRequestModel.DepartmentName = null
    }
    this.GetProDetailRequestModel.PlanID = this.PlanID|| null;
      this.GetProDetailRequestModel.PlanName =  this.PlanName || null;
    this.portalService.getprocurementdetail(this.GetProDetailRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
                  let departments = (baseResponse.data as any) ;
                  this.publicTenders =departments.records;
            }
          }
        }
      })
  }
  GetDepartmentname(){
    this.getDepartmentRequest = new BaseRequestModel();
    this.portalService.GetDepartmentname(this.getDepartmentRequest)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
                  let departments = (baseResponse.data as any) ;
                  this.departmentNames =departments;
            }
          }
        }
      })
  }
  search(){
    this.publicTenders=[]
    this.OnSelectGetAllProcurementPlans();
  }
  clear(){
    location.reload()
  }
  print(){
    var element = document.getElementById('content');
    let html = "";
    html = element.innerHTML;
    const printWindow = window.open('', '');
    if (printWindow) {
      printWindow.document.body.innerHTML = html;
      printWindow.onload = function() {
          printWindow.print();
      };
  } else {
      // Handle window opening failure
  }
  }
 
  sort(orderByColumnName, orderBy){
    this.paginationModel.orderBy = orderBy;
    this.paginationModel.orderByColumnName = orderByColumnName//this.searchFilter?.sortOrder;
    this.GetAllProcurementPlans();
  }
  getAttachmentByID(Id: any) {
 
    if(Id == 0 || Id==null){
      return;
    }
    this.downloadFile.ID = Id;
    this.portalService.DownloadFile(this.downloadFile).subscribe(baseResponse => {
      if (baseResponse.success && baseResponse.data != null) {
        var Bytes = baseResponse.data['bytes'];
        var ContentType = baseResponse.data['contentType'];
        var FileName = baseResponse.data['fileName'];
        const blob = b64toBlob(Bytes, ContentType);
        saveAs(blob, FileName);
      }
    });
  }
  getAttachmentByGUID(Id: any,GUID:any) {
    if(Id == 0 || Id==null){
      return;
    }
    this.downloadFile.ID = Id;
    this.downloadFile.idsList = GUID;
    this.portalService.DownloadFileByGuid(this.downloadFile).subscribe(baseResponse => {
      if (baseResponse.success && baseResponse.data != null) {
        var Bytes = baseResponse.data['bytes'];
        var ContentType = baseResponse.data['contentType'];
        var FileName = baseResponse.data['fileName'];
        const blob = b64toBlob(Bytes, ContentType);
        saveAs(blob, FileName);
      }
    });
  }
  changePage(pageNumber) {

    this.paginationModel.pageNumber = pageNumber;
    this.PageNo = Number(pageNumber);
    this.PN = this.PageNo;
    this.GetAllProcurementPlans();
  }

  nextPage() {
    if (Number(this.paginationModel.pageNumber) < this.totalPages) {
      this.changePage(Number(this.paginationModel.pageNumber) + 1);
    }
  }

  previousPage() {
    if (Number(this.paginationModel.pageNumber) > 1) {
      this.changePage(Number(this.paginationModel.pageNumber) - 1);
    }
  }

  selectedPage() {
    if(this.PN==null || this.PN==0){
      this.PN=1
    }
    this.paginationModel.pageNumber = String(this.PN);
    this.PageNo = this.PN;
    this.GetAllProcurementPlans();
  }

  onEnterKeyPressed(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.selectedPage()
    }
  }
}
