<section class="ppra-body">
    <div class="fix-header">
        <div class="date-time">{{currentDate | date}}</div>
        <img src="../assets/images/ppra-header.jpg">
    </div>
    <div class="wrraper">
        <h1 class="page-title">Published Contracts</h1>
        <div class="clear clearfix py-1"></div>
        <!-- <p class="font-weight-bold">Organization Name: &nbsp; <em>1 Engineer Battalion</em></p> -->
        
        <table>
            <thead class="table-success">
                <th class="text-primary text-nowrap">Evaluation No</th>
                <th class="text-primary text-nowrap">Evaluation Result Title</th>
                <th class="text-primary text-center">Download</th>
                <th class="text-primary text-nowrap">Uploaded Date</th>
            </thead>
            <tbody>
                <tr>
                    <tr *ngFor="let tenders of publicTenders">
                        <th class="text-nowrap">{{tenders?.tenderNumbers}}</th>
                        <td>
                            <strong>{{tenders?.name}}</strong><br>
                            <!-- Services Required<br>Ballistics Protection Material/ Items..<br>5001/IT-3026/2023-24/DESCOM/FOB/Proc -->
                        </td>
                        <td class="text-center">
                            <a (click)="getAttachmentByGUID(tenders?.documentId,tenders?.documentGUID)"   class="btn btn-rounded alert-primary m-1 btn-sm shadow-none font-weight-medium py-2 px-3"><img src="../assets/images/download_icon.gif"></a>
                            <!-- <a (click)="getPublications('bottom-right',tenders,tenders.publishedDocumentID)"  data-toggle="modal" data-target="#downloadModal"><img src="../assets/images/download_icon.gif"></a> -->
                        </td>
                        <td>{{tenders?.uploadedDate | date}}</td>
                        <!-- <td>{{tenders?.lastSubmissionDate | date}}</td> -->
                </tr>
            </tbody>
        </table>
        <div class="clear clearfix py-1"></div>
        <!-- <p class="font-weight-bold">Total Results ( 5 )</p> -->
        <p class="font-weight-bold">Total Active Tenders ({{totalRecords}})</p>
        <p class="arial">
            <small class="font_12">10 Records Per Page</small><br>
            <a (click)="changePage(pageNumber)"  *ngFor="let pageNumber of pages"    [ngClass]="{'active': pageNumber==this.paginationModel.pageNumber}"  class="font-weight-bold" >{{pageNumber}}&nbsp;</a> 
            <!-- <a href="#" class="font-weight-bold">></a> -->
        </p>
        <div class="ppra-footer">
            <p>Send mail to <a href="mailto:info@ppra.org.pk">info@ppra.org.pk</a> with questions or comments about this web site.</p>
            <p>Copyright Â© 2004-2019 Public Procurement Regulatory Authority</p>
            <img src="../assets/images/line.gif">
        </div>
    </div>
</section>
