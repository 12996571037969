<section class="ppra-body">
    <div class="fix-header">
        <div class="date-time">{{currentDate | date}}</div>
        <img src="../assets/images/ppra-header.jpg">
    </div>
    <div class="wrraper">
        <h1 class="page-title">Public Tenders</h1>        
        <!-- <p class="text-right"><strong>View By:</strong> &nbsp; <a href="#">All</a> &nbsp; <a href="#">Organizations</a> &nbsp; <a href="#">Sectors</a></p> -->
        <p>Please Click on image <img src="../assets/images/download_icon.gif"> to Download/View.<br>
        <!-- <em class="text-danger font-weight-bold font_18">Only 10 days old tenders from the closing date will be shown.</em></p> -->
        <div class="clear clearfix py-1"></div>
        <p class="d-flex align-items-center mb-0">
            <strong class="font_18 font_italic">Tender Closing Date :</strong>
            <input class="font_14 ml-5 mr-1" type="date" [(ngModel)]="searchFilter.closingDate" 
            [value]="searchFilter.closingDate" class="form-control" id="datend" placeholder="" style="width:200px; height:24px;">
            <!-- <span class="font_14 ml-5 mr-1">
                <label>Month </label>
                <select name="month" size="1" style="font-family: Arial; font-size: smaller; color: rgb(0,64,0)">
                    <option selected="" value="1">January</option>
                    <option value="2">Feburay</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                </select>
            </span>
            <span class="font_14 mr-1">
                <label>Day </label>
                <select name="day" size="1" style="font-family: Arial; font-size: smaller; color: rgb(0,64,0)">
                    <option selected="" value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                </select>
            </span>
            <span class="font_14 mr-1">
                <label>Year </label>
                <select name="year" size="1" style="font-family: Arial; font-size: smaller; color: rgb(0,64,0)">
                    <option selected="" value="23">2023</option>
                    <option value="22">2022</option>
                </select>
            </span> -->
            <button (click)="search()" value="Search" name="submit" class="ml-2 button_sm">Search </button>
        </p>
        <p class="flex align-items-center mb-0">
            <strong class="font_18 font_italic">PPRA Ref No:</strong>
            <span class="mx-3">
                <label class="font_18 font_italic">F </label>
                <input [(ngModel)]="searchFilter.tenderNo" [value]="searchFilter.tenderNo" type="text" name="tenderno" maxlength="65" size="10" style="height:20px;">
               
            </span>
            <!-- <span class="font_14 ml-5 mr-1">
                <label class="font_18 font_italic">City </label>
                <select name="cityid" size="1" style="font-family: Arial; font-size: smaller; color: rgb(0,64,0)">
                    <option selected="" value="218">Zhob</option>
                    <option selected="" value="200">Zahidan, Iran</option>
                    <option selected="" value="193">Yangon, Burma (Myanmar)</option>
                    <option selected="" value="91">Waziristan</option>
                    <option selected="" value="106">Washington DC, USA</option>
                    <option selected="" value="321">Warsaw, Poland</option>
                    <option selected="" value="69">Wah Cantt</option>
                    <option selected="" value="308">Vienna, Austria</option>
                    <option selected="" value="114">Vehari</option>
                    <option selected="" value="132">Umerkot</option>
                    <option selected="" value="178">Turkey, Ankara</option>
                    <option selected="" value="157">Turbat</option>
                    <option selected="" value="331">Tripoli, Libya</option>
                    <option selected="" value="175">Toronto, Canada</option>
                    <option selected="" value="319">Tolti</option>
                    <option selected="" value="207">Tokyo, Japan</option>
                    <option selected="" value="312">Toba Tek Singh</option>
                    <option selected="" value="179">Timargara</option>
                    <option selected="" value="138">Thatta</option>
                    <option selected="" value="129">Thal</option>
                    <option selected="" value="155">Tehran, Iran</option>
                    <option selected="" value="77">Taxila</option>
                    <option selected="" value="166">Tashkent, Uzbekistan</option>
                    <option selected="" value="61">Tarbela</option>
                    <option selected="" value="149">Tank</option>
                    <option selected="" value="279">Tangir</option>
                    <option selected="" value="109">Tando Jam</option>
                    <option selected="" value="235">Sydney, Australia</option>
                    <option selected="" value="79">Swat</option>
                    <option selected="" value="152">Swabi</option>
                    <option selected="" value="13">Sukkur</option>
                    <option selected="" value="296">Sujawal</option>
                    <option selected="" value="234">Sui</option>
                    <option selected="" value="65">Skardu</option>
                    <option selected="" value="196">Singapore</option>
                    <option selected="" value="127">Sibi</option>
                    <option selected="" value="5">Sialkot</option>
                    <option selected="" value="146">Shorkot</option>
                    <option selected="" value="244">Shikarpur</option>
                    <option selected="" value="281">Shigar</option>
                    <option selected="" value="57">Sheikhupura</option>
                    <option selected="" value="306">Shanghai, China</option>
                    <option selected="" value="254">Seoul, Korea</option>
                    <option selected="" value="310">Senegal, Dakar</option>
                    <option selected="" value="184">Scotland, UK</option>
                    <option selected="" value="64">Sargodha</option>
                    <option selected="" value="221">Sao Paulo, Brazil</option>
                    <option selected="" value="162">Santiago, Chile</option>
                    <option selected="" value="270">Sanjwal</option>
                    <option selected="" value="145">Sanghar</option>
                    <option selected="" value="84">Sahiwal</option>
                    <option selected="" value="292">Sadiqabad</option>
                    <option selected="" value="161">Rome, Italy</option>
                    <option selected="" value="158">Riyadh, Saudi Arabia</option>
                    <option selected="" value="60">Risalpur</option>
                    <option selected="" value="282">Renala Khurd</option>
                    <option selected="" value="304">Rawat</option>
                    <option selected="" value="6">Rawalpindi</option>
                    <option selected="" value="142">Rawalakot</option>
                    <option selected="" value="210">Rajanpur</option>
                    <option selected="" value="83">Rahim Yar Khan</option>
                    <option selected="" value="182">Rabat, Morocco</option>
                    <option selected="" value="14">Quetta</option>
                    <option selected="" value="231">Pretoria, South Africa</option>
                    <option selected="" value="274">Prague, Czech Republic</option>
                    <option selected="" value="237">Pishin</option>
                    <option selected="" value="264">Pindi Bhattian</option>
                    <option selected="" value="153">Phnom Penh, Cambodia</option>
                    <option selected="" value="16">Peshawar</option>
                    <option selected="" value="263">Pasni</option>
                    <option selected="" value="108">Paris France</option>
                    <option selected="" value="135">Pano Aqil</option>
                    <option selected="" value="213">Panjgur</option>
                    <option selected="" value="307">Pakpattan</option>
                    <option selected="" value="180">Ottawa, Canada</option>
                    <option selected="" value="239">Oslo Norway</option>
                    <option selected="" value="220">Osaka, Japan</option>
                    <option selected="" value="121">Ormara</option>
                    <option selected="" value="230">Orakzai</option>
                    <option selected="" value="252">Ontario, Canada</option>
                    <option selected="" value="104">Okara</option>
                    <option selected="" value="291">Nushki</option>
                    <option selected="" value="101">Nowshera</option>
                    <option selected="" value="246">Niger, Niamey</option>
                    <option selected="" value="133">New York, USA</option>
                    <option selected="" value="168">New Delhi, India</option>
                    <option selected="" value="118">Nawabshah</option>
                    <option selected="" value="199">Naushahero Feroze</option>
                    <option selected="" value="301">Nasirabad</option>
                    <option selected="" value="242">Narowal</option>
                    <option selected="" value="236">Nankana Sahab</option>
                    <option selected="" value="268">Nairobi, Kenya</option>
                    <option selected="" value="289">Nagar</option>
                    <option selected="" value="75">Muzaffargarh</option>
                    <option selected="" value="70">Muzaffarabad</option>
                    <option selected="" value="202">Muscat, Oman</option>
                    <option selected="" value="11">Murree</option>
                    <option selected="" value="300">Muridke</option>
                    <option selected="" value="7">Multan</option>
                    <option selected="" value="278">Moscow, Russia</option>
                    <option selected="" value="295">Montreal, Canada</option>
                    <option selected="" value="189">Mohmand Agency</option>
                    <option selected="" value="112">Moenjodaro</option>
                    <option selected="" value="241">Mithi</option>
                    <option selected="" value="82">Mirpurkhas</option>
                    <option selected="" value="58">Mirpur</option>
                    <option selected="" value="187">Miranshah</option>
                    <option selected="" value="232">Minsk, Belarus</option>
                    <option selected="" value="80">Mingora</option>
                    <option selected="" value="73">Mianwali</option>
                    <option selected="" value="195">Mian Channu</option>
                    <option selected="" value="325">Mazar-e-Sharif, Afghanistan</option>
                    <option selected="" value="317">Mauritius</option>
                    <option selected="" value="298">Mastung</option>
                    <option selected="" value="251">Mashhad, Iran</option>
                    <option selected="" value="103">Mardan</option>
                    <option selected="" value="120">Mansehra</option>
                    <option selected="" value="59">Mangla</option>
                    <option selected="" value="122">Manchester UK</option>
                    <option selected="" value="174">Manama,  Bahrain</option>
                    <option selected="" value="260">Male, Maldives</option>
                    <option selected="" value="115">Malakand</option>
                    <option selected="" value="140">Makran</option>
                    <option selected="" value="141">Mailsi</option>
                    <option selected="" value="257">Madinah, Saudi Arabia</option>
                    <option selected="" value="93">M B Din</option>
                    <option selected="" value="250">Los Angeles, USA</option>
                    <option selected="" value="124">Loralai</option>
                    <option selected="" value="110">London UK</option>
                    <option selected="" value="208">Lodhran</option>
                    <option selected="" value="214">Layyah</option>
                    <option selected="" value="134">Lasbela</option>
                    <option selected="" value="81">Larkana</option>
                    <option selected="" value="128">Landi Kotal</option>
                    <option selected="" value="96">Lakki</option>
                    <option selected="" value="92">Lakhra</option>
                    <option selected="" value="2">Lahore</option>
                    <option selected="" value="248">Kyiv, Ukraine</option>
                    <option selected="" value="181">Kuwait</option>
                    <option selected="" value="201">Kurram</option>
                    <option selected="" value="151">Kualalumpur, Malaysia</option>
                    <option selected="" value="63">Kotri</option>
                    <option selected="" value="245">Kotli</option>
                    <option selected="" value="322">Kohistan</option>
                    <option selected="" value="89">Kohat</option>
                    <option selected="" value="324">Kigali, Rwanda</option>
                    <option selected="" value="206">Khyber Agency</option>
                    <option selected="" value="99">Khuzdar</option>
                    <option selected="" value="78">Khushab</option>
                    <option selected="" value="280">Kharmong</option>
                    <option selected="" value="130">Kharian</option>
                    <option selected="" value="267">Khanpur</option>
                    <option selected="" value="131">Khanewal</option>
                    <option selected="" value="102">Khairpur</option>
                    <option selected="" value="253">Kathmandu, Nepal</option>
                    <option selected="" value="154">Kasur</option>
                    <option selected="" value="256">Kaskelen, Kazakhstan</option>
                    <option selected="" value="212">Kashmore</option>
                    <option selected="" value="198">Karak</option>
                    <option selected="" value="3">Karachi</option>
                    <option selected="" value="276">Kandahar, Afghanistan</option>
                    <option selected="" value="105">Kamra</option>
                    <option selected="" value="328">Kampala, Uganda</option>
                    <option selected="" value="194">Kamber Shahdadkot</option>
                    <option selected="" value="285">Kallar Kahar</option>
                    <option selected="" value="318">Kalabagh</option>
                    <option selected="" value="159">Kabul, Afghanistan</option>
                    <option selected="" value="288">Kabirwala</option>
                    <option selected="" value="329">Johannesburg, South Africa</option>
                    <option selected="" value="66">Jhelum</option>
                    <option selected="" value="94">Jhang</option>
                    <option selected="" value="176">Jeddah, KSA</option>
                    <option selected="" value="170">Jebel Al Weibdeh, Amman</option>
                    <option selected="" value="116">Jauharabad</option>
                    <option selected="" value="68">Jamshoro</option>
                    <option selected="" value="123">Jamaica, USA</option>
                    <option selected="" value="313">Jalalabad, Afghanistan</option>
                    <option selected="" value="203">Jakarta, Indonesia</option>
                    <option selected="" value="314">Jaglot</option>
                    <option selected="" value="147">Jacobabad</option>
                    <option selected="" value="1">Islamabad</option>
                    <option selected="" value="8">Hyderabad</option>
                    <option selected="" value="136">Hunza</option>
                    <option selected="" value="283">Hujra</option>
                    <option selected="" value="227">Houston, USA</option>
                    <option selected="" value="277">Hong Kong</option>
                    <option selected="" value="144">Havelian</option>
                    <option selected="" value="303">Havana, Cuba</option>
                    <option selected="" value="17">Hasanabadal</option>
                    <option selected="" value="287">Haroonabad</option>
                    <option selected="" value="74">Haripur</option>
                    <option selected="" value="165">Harare, Zimbabwe</option>
                    <option selected="" value="309">Hanoi, Vietnam</option>
                    <option selected="" value="95">Hangu</option>
                    <option selected="" value="173">Hague, Holland</option>
                    <option selected="" value="137">Hafizabad</option>
                    <option selected="" value="98">Gwadar</option>
                    <option selected="" value="12">Gujrat</option>
                    <option selected="" value="76">Gujranwala</option>
                    <option selected="" value="10">Gujar Khan</option>
                    <option selected="" value="62">Guddu</option>
                    <option selected="" value="72">Gilgit</option>
                    <option selected="" value="117">Ghotki</option>
                    <option selected="" value="87">Ghizar</option>
                    <option selected="" value="113">Ghanche</option>
                    <option selected="" value="320">Geneva, Switzerland</option>
                    <option selected="" value="316">Galati, Romania</option>
                    <option selected="" value="315">Gakkhar</option>
                    <option selected="" value="233">Frankfurt Germany</option>
                    <option selected="" value="311">Fateh Jang</option>
                    <option selected="" value="4">Faisalabad</option>
                    <option selected="" value="302">Dushanbe, Tajikistan</option>
                    <option selected="" value="183">Dubai, UAE</option>
                    <option selected="" value="164">Doha, Qatar</option>
                    <option selected="" value="225">Dir Upper</option>
                    <option selected="" value="226">Dir Lower</option>
                    <option selected="" value="297">Diamer</option>
                    <option selected="" value="167">Dhaka, Bangladesh</option>
                    <option selected="" value="269">Dera Nawab Sahib</option>
                    <option selected="" value="326">Dera Nawab</option>
                    <option selected="" value="219">Dera Murad Jamali</option>
                    <option selected="" value="265">Dera Allah Yar</option>
                    <option selected="" value="284">Depalpur</option>
                    <option selected="" value="259">Daska</option>
                    <option selected="" value="139">Dargai</option>
                    <option selected="" value="323">Dar-es-Salaam, Tanzania</option>
                    <option selected="" value="294">Damascus, Syria</option>
                    <option selected="" value="100">Dadu</option>
                    <option selected="" value="18">D.I.Khan</option>
                    <option selected="" value="19">D.G.Khan</option>
                    <option selected="" value="188">Copenhagen, Denmark</option>
                    <option selected="" value="169">Colombo, Sirlanka</option>
                    <option selected="" value="126">Chunian</option>
                    <option selected="" value="156">Chitral</option>
                    <option selected="" value="209">Chistian</option>
                    <option selected="" value="238">Chiniot</option>
                    <option selected="" value="88">Chilas</option>
                    <option selected="" value="228">Chicago, USA</option>
                    <option selected="" value="125">Cherat</option>
                    <option selected="" value="90">Chashma</option>
                    <option selected="" value="205">Charsadda</option>
                    <option selected="" value="67">Chakwal</option>
                    <option selected="" value="293">Chaghai</option>
                    <option selected="" value="223">Casablanca, Morocco</option>
                    <option selected="" value="240">Cario, Egypt</option>
                    <option selected="" value="185">Canberra, Australia</option>
                    <option selected="" value="191">Burewala</option>
                    <option selected="" value="286">Burewala</option>
                    <option selected="" value="224">Buenos Aires, Argentina</option>
                    <option selected="" value="171">Budapest, Hungary</option>
                    <option selected="" value="275">Bucharest, Romania</option>
                    <option selected="" value="258">Brussels, Belgium</option>
                    <option selected="" value="215">Brunei, Darussalam</option>
                    <option selected="" value="243">Brasilia, Brazil</option>
                    <option selected="" value="197">Bradford, UK</option>
                    <option selected="" value="271">Bishkek, Kyrgyzstan</option>
                    <option selected="" value="177">Birmingham, UK</option>
                    <option selected="" value="222">Bhimber, AJK</option>
                    <option selected="" value="111">Bhakkar</option>
                    <option selected="" value="119">Besham</option>
                    <option selected="" value="229">Berlin, Germany</option>
                    <option selected="" value="273">Belgrade, Serbia</option>
                    <option selected="" value="249">Beirut, Lebanon</option>
                    <option selected="" value="150">Beijing, China</option>
                    <option selected="" value="290">Battagram</option>
                    <option selected="" value="216">Batkhella</option>
                    <option selected="" value="186">Barcelona, Spain</option>
                    <option selected="" value="97">Bannu</option>
                    <option selected="" value="163">Bangkok, Thailand</option>
                    <option selected="" value="172">Baku,  Azerbaijan</option>
                    <option selected="" value="148">Bajaur</option>
                    <option selected="" value="85">Bahawalpur</option>
                    <option selected="" value="190">Bahawalnagar</option>
                    <option selected="" value="255">Baghdad, Iraq</option>
                    <option selected="" value="192">Bagh AJK</option>
                    <option selected="" value="107">Badin</option>
                    <option selected="" value="15">Attock</option>
                    <option selected="" value="261">Athens, Greece</option>
                    <option selected="" value="71">Astore</option>
                    <option selected="" value="204">Ashgabat, Turkmenistan</option>
                    <option selected="" value="217">Amman, Jordan</option>
                    <option selected="" value="262">Almaty Kazakhstan</option>
                    <option selected="" value="266">Alipur</option>
                    <option selected="" value="247">Addis Ababa, Ethiopia</option>
                    <option selected="" value="330">Accra, Ghana</option>
                    <option selected="" value="305">Abuja, Nigeria</option>
                    <option selected="" value="160">Abu Dhabi, UAE</option>
                    <option selected="" value="327">Abidjan, Ivory Coast</option>
                    <option selected="" value="9">Abbottabad</option>
                </select>
            </span> -->
            <button (click)="search()" value="Search" name="submit" class="ml-2 button_sm">Search</button>
        </p>
        <button (click)="clear()" value="Search" name="submit" class="ml-2 button_sm">clear</button>

        <div class="clear clearfix py-1"></div>
        
        <table>
            <thead class="table-success">
                <th class="text-primary text-nowrap">Tender No <a (click)="sort('TenderNo','desc')"><img  src="../assets/images/arrow_up.gif"></a> <a (click)="sort('TenderNo','asc')"><img src="../assets/images/arrow_down.gif"></a></th>
                <!-- <th class="text-primary text-nowrap">PPRA Tender No</th> -->
                <th class="text-primary text-nowrap">Tender Details</th>
                <th class="text-primary text-center">Download</th>
                <th class="text-primary text-nowrap">Advertised Date <a (click)="sort('PublishedDate','desc')"><img  src="../assets/images/arrow_up.gif"></a> <a (click)="sort('ClosingDate','asc')"><img src="../assets/images/arrow_down.gif"></a></th>
                <th class="text-primary text-nowrap">Closing Date <a (click)="sort('ClosingDate','desc')"><img  src="../assets/images/arrow_up.gif"></a> <a (click)="sort('ClosingDate','asc')"><img src="../assets/images/arrow_down.gif"></a></th>
            </thead>
            <tbody>
                <tr *ngFor="let tenders of publicTenders">
                    <th class="text-nowrap">{{tenders?.tenderNumbers}}</th>
                    <!-- <th class="text-nowrap">{{tenders?.ppraTenderNumber}}</th> -->
                    <td>
                        <strong>{{tenders?.name}}</strong><br>
                        <!-- Services Required<br>Ballistics Protection Material/ Items..<br>5001/IT-3026/2023-24/DESCOM/FOB/Proc -->
                    </td>
                    <td class="text-center">
                        <a (click)="downloadDocuments('',tenders?. publishedDocumentID)"  data-toggle="modal" data-target="#downloadModal" class="btn btn-rounded alert-primary m-1 btn-sm shadow-none font-weight-medium py-2 px-3"><img src="../assets/images/download_icon.gif"></a>
                        <!-- <a (click)="getPublications('bottom-right',tenders,tenders.publishedDocumentID)"  data-toggle="modal" data-target="#downloadModal"><img src="../assets/images/download_icon.gif"></a> -->
                    </td>
                    <td>{{tenders?.publishDate | date}}</td>
                    <td>{{tenders?.lastSubmissionDate | date}}</td>
                </tr>               
            </tbody>
        </table>
        <div class="clear clearfix py-1"></div>
        <p class="font-weight-bold">Total Active Tenders ({{totalRecords}})</p>
        <p class="arial">
            <small class="font_12">10 Records Per Page</small><br>
            <a (click)="changePage(pageNumber)"  *ngFor="let pageNumber of pages"    [ngClass]="{'active': pageNumber==this.paginationModel.pageNumber}"  class="font-weight-bold" >{{pageNumber}}&nbsp;</a> 
            <!-- <a href="#" class="font-weight-bold">></a> -->
        </p>
        <div class="ppra-footer">
            <p>Send mail to <a href="mailto:info@ppra.org.pk">info@ppra.org.pk</a> with questions or comments about this web site.</p>
            <p>Copyright Â© 2004-2019 Public Procurement Regulatory Authority</p>
            <img src="../assets/images/line.gif">
        </div>
    </div>
</section>

<!-- Download Modal -->
<!-- <div id="downloadModal" tabindex="-1" role="dialog" aria-labelledby="downloadModalLabel" aria-hidden="true" class="w3-modal">
    <div class="w3-modal-content">
        <h5 class="w3-modal-head">
            PA Pulished Documents
            <button type="button" class="close" data-dismiss="modal" aria-label="close">
                <span aria-hidden="true">&times;</span>
            </button>
        </h5>
    <div class="w3-container">                
        <div class="table-responsive">
            <table class="table table-striped border">
                <thead class="table-success">
                    <tr>
                        <th scope="col">Sr. No</th>
                        <th scope="col">Publication Name</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of publicationDetails; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ item?.documentTemplateName }}</td>
                        <td>
                            <a (click)="getAttachmentByID(item?.dmS_FileID)" class="btn btn-sm btn-outline-primary">
                                <i class="fas fa-download mr-1"></i> Download
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    </div>
</div> -->
<div class="clear"></div>
<div class="modal fade" id="downloadModal" tabindex="-1" role="dialog" aria-labelledby="downloadModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Download Files</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="table-responsive">
					<table class="table table-striped border">
						<thead class="table-success">
							<tr>
								<th scope="col">Document Name</th>
								<th scope="col">Action</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of documentsDetails">
								<td>{{item?.documentTemplateName}}</td>
								<td><a (click)="getAttachmentByGUID(item?.dmS_FileID,item?.dmS_FileGUID)" class="btn btn-sm btn-outline-primary btn-rounded"><i class="fas fa-download mr-1"></i> Download</a></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-outline-danger py-2" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<div class="modal fade" id="publicationModal" tabindex="-1" role="dialog" aria-labelledby="publicationModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">PA Pulished Documents</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="table-responsive">
					<table class="table table-striped border">
						<thead class="table-success">
							<tr>
								<th scope="col">Sr. No</th>
								<th scope="col">Publication Name</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of publicationDetails; let i = index">
								<td>{{ i + 1 }}</td>
								<td>{{ item?.documentTemplateName }}</td>
								<td>
									<a (click)="getAttachmentByGUID(item?.dmS_FileID,item?.dmS_FileGUID)" class="btn btn-sm btn-outline-primary">
										<i class="fas fa-download mr-1"></i> Download
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-outline-danger py-2" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
