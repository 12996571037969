<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>
			e-Pak Acquisition & Disposal System EPADS-Trainings<br>
			<span class="text-success">UAN: 051-111-137-237</span>
		</h1>
	</div>
</section>
<section class="container">
	<div class="row">
		<div class="col-md-4 col-sm-6">
			<div class="bg-light h-100 p-4">
				<div class="nav flex-column list-group" id="training-tab" role="tablist" aria-orientation="vertical">
					<a class="nav-link list-group-item list-group-item-action active" id="home-tab" data-toggle="pill" href="#home" role="tab" aria-controls="home" aria-selected="true">Home</a>
					<a class="nav-link list-group-item list-group-item-action" id="resource-persons-tab" data-toggle="pill" href="#resource-persons" role="tab" aria-controls="resource-persons" aria-selected="false">Resource Persons</a>
					<a class="nav-link list-group-item list-group-item-action" id="training-modules-tab" data-toggle="pill" href="#training-modules" role="tab" aria-controls="training-modules" aria-selected="false">Training Modules</a>
					<a class="nav-link list-group-item list-group-item-action" id="picture-gallery-tab" data-toggle="pill" href="#picture-gallery" role="tab" aria-controls="picture-gallery" aria-selected="false">Picture Gallery</a>
					<a class="nav-link list-group-item list-group-item-action" id="contact-tab" data-toggle="pill" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Contact Us</a>
				</div>				
			</div>
		</div>
		<div class="col-md-8 col-sm-7 py-4">
			<div class="tab-content" id="training-tabContent">
				<!---home--->
				<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
					<h3 class="pb-2">Introduction</h3>
					<p>Public Procurement Regulatory Authority (PPRA). Hereinafter called as the Authority established under the PPRA Ordinance, 2002, as a measure to improve governance, management, transparency, accountability and quality of public procurement, and to ensure an effective Monitoring & Evaluation System, invites the attention of suppliers for goods, works and services to register themselves with the upcoming e-Procurement System (i.e. E-Pak Acquisition & Disposal System hereinafter referred as E-PADS), encompassing the complete procurement cycle staring form planning to the close out of the contracts. After commissioning of the E-PADS, the Authority shall ensure that all the public procurement and disposal activities be initially, the system is being piloted in the all Federal Ministries and attached departments.</p>
                    <h5 class="font-weight-semi-bold">The key features of the e-Procurement (EPADS)</h5>
                    <ul>
                        <li>e-Procurement Planning</li>
                        <li>Encrypted Tender Submission System</li>
                        <li>e-Evaluation System Grievance Redressal System</li>
                        <li>e-Contract Award System</li>
                        <li>e-Payment System</li>
                        <li>e-Catalogue Management Supplier Relationship Management</li>
                    </ul>
                    <p>For the pilot implementation of e-Pak Acquisition & Disposal System (EPADS) modules, {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA was conducted the training of Ministry of National Health Services, Regulation & Coordination and Ministry of Federal Education and Professional Training and Federal Government Procuring Agencies.</p>
                    <div class="row align-items-center">
						<p class="font-weight-medium col-lg-7">Sindh Chief Minister Justice Maqbool Baqar launched E-procurement system and spoke on the occasion. The launching ceremony held at a local hotel on 29th December, 2023 and was attended by Law Minister/Chairman SPPRA Mr. Omar Soomro, MD Federal PPRA Mr. Maqbool Ahmad Gondal and MD SPPRA Mr. Zubair Pervaiz</p>
						<div class="col-lg-5">
							<video width="100%" height="240" controls>
								<source src="https://www.ppra.org.pk/epads/sepads.mp4" type="video/mp4">
								Your browser does not support the video tag.
							</video>
						</div>
					</div>					
					<h6 class="font-weight-bold">From 8th September, 2022 to 13th February, 2024</h6>					
					<div class="table-responsive">
                        <table class="table border">
                            <thead class="table-success">
                                <tr>
                                  <th scope="col">Description</th>
                                  <th scope="col">No of Training Session</th>
                                  <th scope="col">No of Participants</th>
                                </tr>
                              </thead>
                            
                            <tbody>
                            <tr>
                                <td><a target="_blank" href="https://www.ppra.org.pk/epads/l1.pdf">Procuring Agencies Level-1</a></td>
                                <td><strong>128</strong></td>
                                <td><strong>2030</strong></td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" href="https://www.ppra.org.pk/epads/l1.pdf">Procuring Agencies</a>
                                    <a target="_blank" href="https://www.ppra.org.pk/epads/l2.pdf">Level-2</a>
                                 </td>
                                <td><strong>68</strong></td>
                                <td><strong>282</strong></td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" href="https://www.ppra.org.pk/epads/l1.pdf">Procuring Agencies</a>
                                    <a target="_blank" href="https://www.ppra.org.pk/epads/l3.pdf">Level-3</a>
                                </td>
                                <td><strong>69</strong></td>
                                <td><strong>1972</strong></td>
                            </tr>
                            <tr>
                                <td>
                                    <a target="_blank" href="https://www.ppra.org.pk/epads/l4.pdf">Procuring Agencies</a>
                                    <a target="_blank" href="https://www.ppra.org.pk/epads/l2.pdf">Level-4</a>
                                </td>
                                <td><strong>27</strong></td>
                                <td><strong>412</strong></td>
                            </tr>
                            <tr>
                                <td><a target="_blank" href="https://www.ppra.org.pk/epads/pprapunjab_o.pdf">PPRA Punjab (Officers)</a></td>
                                <td><strong>6</strong></td>
                                <td><strong>10</strong></td>
                            </tr>
                            <tr>
                                <td><a target="_blank" href="https://www.ppra.org.pk/epads/kpkppra_o.pdf">KPK PPRA (Officers)</a></td>
                                <td><strong>2</strong></td>
                                <td><strong>12</strong></td>
                            </tr>
                            <tr>
                                <td><a target="_blank" href="https://www.ppra.org.pk/epads/pprapunjab_s.pdf">PPRA Punjab (Suppliers)</a></td>
                                <td><strong>18</strong></td>
                                <td><strong>582</strong></td>
                            </tr>
                            <tr>
                                <td><a target="_blank" href="https://www.ppra.org.pk/epads/ex_tra.pdf">Exclusive Training Program</a></td>
                                <td><strong>4</strong></td>
                                <td><strong>141</strong></td>
                            </tr>
                            <tr>
                                <td><a target="_blank" href="https://www.ppra.org.pk/epads/fppra_s1.pdf">Sindh PPRA Suppliers Level-1</a></td>
                                <td><strong>5</strong></td>
                                <td><strong>28</strong></td>
                            </tr>
                            <tr>
                                <td><a target="_blank" href="https://www.ppra.org.pk/epads/fppra_s2.pdf">Sindh PPRA Suppliers Level-2</a></td>
                                <td><strong>3</strong></td>
                                <td><strong>44</strong></td>
                            </tr>
							<tr>
                                <td><a target="_blank" href="https://www.ppra.org.pk/epads/fppra_s2.pdf">Federal PPRA Suppliers Level-1</a></td>
                                <td><strong>17</strong></td>
                                <td><strong>124</strong></td>
                            </tr>
							<tr>
                                <td><a target="_blank" href="https://www.ppra.org.pk/epads/fppra_s2.pdf">Federal PPRA Suppliers Level-2</a></td>
                                <td><strong>3</strong></td>
                                <td><strong>40</strong></td>
                            </tr>
                            <tr>
                                <th>Total</th>
                                <th>358</th>                                
                                <th>6040</th>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    <p><a target="_blank" href="https://www.ppra.org.pk/epads/mann.asp"><img border="0" src="https://www.ppra.org.pk/epads/images/manual.png"><img border="0" src="https://www.ppra.org.pk/epads/images/video.png"></a></p>
				</div>
				
				<!---Resource Persons--->
				<div class="tab-pane fade" id="resource-persons" role="tabpanel" aria-labelledby="resource-persons-tab">
					<h3 class="pb-3">Resource Persons</h3>

                    <h5 class="font-weight-semi-bold">NAME & DESIGNATION</h5>
                    <div class="row">
						<div class="col-sm-6 pb-4 mb-2">
                            <div class="well h-100 m-0 bg-light">
                                <p class="mb-0"><strong>Mr. Rizwan Mehmood,<br><em>Director (MIS), {{this.userUtilsService.selectedEnvironment?.Letter}}PPRA</em></strong><br>                        
                                    Ph: +92 51 9205728<br>E-mail: <a href="mailto:rizwan@ppra.org.pk">rizwan@ppra.org.pk</a></p>
                            </div>
                        </div>
                        <div class="col-sm-6 pb-4 mb-2">
                            <div class="well h-100 m-0 bg-light">
                                <p class="mb-0"><strong>Ms Wardha Rizwan,<br><em>Principal Business Analyst, PMU-FPPRA</em></strong><br>                        
                                    Ph: +92 51 9205726<br>E-mail: <a href="mailto:wardharizwan@ppra.org.pk">wardharizwan@ppra.org.pk</a></p>
                            </div>
                        </div>
                    </div>
				</div>

                <!---Training Modules--->
				<div class="tab-pane fade" id="training-modules" role="tabpanel" aria-labelledby="training-modules-tab">
					<h3 class="pb-3">EPADS Training Modules</h3>
                    <h5 class="text-primary">Level 1:</h5>
                    <h6 class="font-weight-semi-bold">User Access Management System</h6>
                    <ul>
                        <li>Users Creation</li>
                        <li>Roles and Groups</li>
                    </ul>
                    <div class="clear p-1"></div>
                    <h6 class="font-weight-semi-bold">Supplier Management System</h6>
                    <ul>
                        <li>User Access Management System</li>
                        <li>Users Creation</li>
                        <li>Roles and Groups</li>
                        <li>Supplier Management System</li>
                        <li>Supplier Self Registration</li>
                        <li>OTP Verification</li>
                        <li>Beneficial Owner</li>
                        <li>Business Profile</li>
                        <li>Registration Bodies</li>
                        <li>Bank Details</li>
                        <li>Supporting Documents</li>
                        <li>PPRA Registration</li>
                        <li>Board Members</li>
                        <li>Shareholder</li>
                        <li>Addresses</li>
                    </ul>
                    <div class="clear p-1"></div>
                    <h6 class="font-weight-semi-bold">Constitution of Committee</h6>
                    <h6 class="font-weight-semi-bold">Procurement Plan</h6>
                    <h6 class="font-weight-semi-bold">Procurement Activities</h6>
                    <ul>
                        <li>Petty Cash</li>
                        <li>Request for Quotation (RFQ)</li>
                    </ul>
                    <div class="clear p-2"></div>
                    <h5 class="text-primary">Level 2:</h5>
                    <h6 class="font-weight-semi-bold">Procurement Plan</h6>
                    <h6 class="font-weight-semi-bold">Procedure for Selection of Contractors</h6>
                    <ul>
                        <li>Single stage one envelope procedure.</li>
                        <li>Single stage two envelope procedure.</li>
                        <li>Two stage bidding procedure.</li>
                        <li>Two stage two envelope procedure</li>
                    </ul>
                    <div class="clear p-1"></div>
                    <h6 class="font-weight-semi-bold">Evaluation Results</h6>
                    <h6 class="font-weight-semi-bold">Contract Award</h6>
                    <div class="clear p-2"></div>
                    <h5 class="text-primary">Level 3:</h5>
                    <h6 class="font-weight-semi-bold">Pre-Qualification Process</h6>
                    <ul>
                        <li>Execution of Pre-Qualification</li>
                        <li>Tender Execution for Pre-Qualified Bidders</li>
                    </ul>
                    <div class="clear p-1"></div>
                    <h6 class="font-weight-semi-bold">Hiring of Consultancy Services Process</h6>
                    <ul>
                        <strong>Methods for Selection of Consultants</strong>
                        <li class="pl-4"> Quality Based Selection.</li>
                        <li class="pl-4"> Quality and Cost Based Selection</li>
                        <li class="pl-4"> Least Cost</li>
                        <li class="pl-4"> Single Source or Direct Selection</li>
                        <li class="pl-4"> Fixed Budget</li>
                        <li class="pl-4"> Work Flow Approval Mechanism Process</li>
                    </ul>
                    <div class="clear p-1"></div>
                    <h6 class="font-weight-semi-bold">Workflow Approval Mechanism Process   ring of Consultancy Services Process</h6>
                    <ul>
                        <li>Creation of Workflow.</li>
                        <li>Creation of Approval Mechanism.</li>
                    </ul>
                    <div class="clear p-2"></div>
                    <h5 class="text-primary">Level 4:</h5>
                    <h6 class="font-weight-semi-bold">Document Template</h6>
                    <ul>
                        <li>Create Bidding Document.</li>
                        <li>Create Bid Document Sections</li>
                    </ul>
                    <div class="clear p-1"></div>
                    <h6 class="font-weight-semi-bold">Procurement Plan Activity.</h6>
                    <ul>
                        <li>Requisition Management.</li>
                        <li>Create Activity.</li>
                        <li>Adopt Bidding Document.</li>
                        <li>Evaluation Criteria</li>
                        <li>Publish Activity for E-Submission (by supplier)</li>
                    </ul>
                    <div class="clear p-1"></div>
                    <h6 class="font-weight-semi-bold">E-Submission</h6>
                    <h6 class="font-weight-semi-bold">E-Evaluation</h6>
                    <ul>
                        <li>Select Committee Member for E-Evaluation</li>
                        <li>Committee Member will Evaluate Suppliers</li>
                    </ul>
                    <h6 class="font-weight-semi-bold">Letter of Credit (LOI)</h6>
                    <h6 class="font-weight-semi-bold">E-Contract</h6>
                    <div class="clear p-2"></div>
                    <h5 class="text-primary">Level 5:</h5>
                    <h6 class="font-weight-semi-bold">Work Load</h6>
                    <ul>
                        <li>Create Task.</li>
                        <li>Associate Task.</li>
                    </ul>
                    <h6 class="font-weight-semi-bold">Dynamic Search</h6>
                    <h6 class="font-weight-semi-bold">Transactional Reports</h6>
                    <h6 class="font-weight-semi-bold">Users Groups</h6>
                    <h6 class="font-weight-semi-bold">User Account Closure</h6>
                    <div class="clear p-2"></div>
                    <h5 class="text-primary">Level 6:</h5>
                    <h6 class="font-weight-semi-bold">Alternative Methods</h6>
                    <ul>
                        <li>Emergency Procurement</li>
						<li>Purchase of Motor Vehicle</li>
						<li>Negotiated Tendering</li>
						<li>Forced Account</li>
						<li>Direct Contracting with State Owned Entities</li>
                    </ul>
					<h6 class="font-weight-semi-bold">Unsolicited Proposals</h6>
					<h6 class="font-weight-semi-bold">Framework Agreement</h6>
                    <ul>
                        <li>Closed Framework Agreement.</li>
						<li>Open Framework Agreement.</li>
                    </ul>
				</div>
				
				<!---Picture Gallery--->
				<div class="tab-pane fade" id="picture-gallery" role="tabpanel" aria-labelledby="picture-gallery-tab">
					<h3>EPADS Training Pictures Gallery</h3>
					<h5 class="pb-3 font-weight-semi-bold">
						Visit of World Bank Delegation at<br>
						Ministry of National Health Services, Regulation and<br>
						Ministry of Federal Education and Professional Training
					</h5>
					<div class="row">
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
							<img src="https://www.ppra.org.pk/epads/tra/101.jpg" class="gallery_pic" data-toggle="modal" data-target="#DecPic15">
							<!-- Modal -->
							<div class="modal fade" id="DecPic15" tabindex="-1" role="dialog" aria-labelledby="DecPicLabel" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/101.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
							<img src="https://www.ppra.org.pk/epads/tra/102.jpg" class="gallery_pic" data-toggle="modal" data-target="#JanPic16">
							<!-- Modal -->
							<div class="modal fade" id="JanPic16" tabindex="-1" role="dialog" aria-labelledby="JanPicLabel" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/102.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
							<img src="https://www.ppra.org.pk/epads/tra/103.jpg" class="gallery_pic" data-toggle="modal" data-target="#MarPic17">
							<!-- Modal -->
							<div class="modal fade" id="MarPic17" tabindex="-1" role="dialog" aria-labelledby="MarPicLabel" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/103.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
							<img src="https://www.ppra.org.pk/epads/tra/104.jpg" class="gallery_pic" data-toggle="modal" data-target="#AprPic16">
							<!-- Modal -->
							<div class="modal fade" id="AprPic16" tabindex="-1" role="dialog" aria-labelledby="AprPicLabel" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/104.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
							<img src="https://www.ppra.org.pk/epads/tra/105.jpg" class="gallery_pic" data-toggle="modal" data-target="#Apr27Pic16">
							<!-- Modal -->
							<div class="modal fade" id="Apr27Pic16" tabindex="-1" role="dialog" aria-labelledby="Apr27PicLabel" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/105.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
							<img src="https://www.ppra.org.pk/epads/tra/106.jpg" class="gallery_pic" data-toggle="modal" data-target="#JunPic16">
							<!-- Modal -->
							<div class="modal fade" id="JunPic16" tabindex="-1" role="dialog" aria-labelledby="JunPicLabel" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/106.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/108.jpg" class="gallery_pic" data-toggle="modal" data-target="#JulyPic16">
							<!-- Modal -->
							<div class="modal fade" id="JulyPic16" tabindex="-1" role="dialog" aria-labelledby="JulyPicLabel" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/108.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<h5 class="py-3 font-weight-semi-bold">EPDAS Trainings</h5>
					<div class="row">
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/110.jpg" class="gallery_pic" data-toggle="modal" data-target="#AugPic16">
							<!-- Modal -->
							<div class="modal fade" id="AugPic16" tabindex="-1" role="dialog" aria-labelledby="AugPicLabel" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/110.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/1111.jpg" class="gallery_pic" data-toggle="modal" data-target="#Aug25Pic16">
							<!-- Modal -->
							<div class="modal fade" id="Aug25Pic16" tabindex="-1" role="dialog" aria-labelledby="Aug25PicLabel" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/1111.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/112.jpg" class="gallery_pic" data-toggle="modal" data-target="#SepPic16">
							<!-- Modal -->
							<div class="modal fade" id="SepPic16" tabindex="-1" role="dialog" aria-labelledby="SepPicLabel" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/112.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/113.jpg" class="gallery_pic" data-toggle="modal" data-target="#Sep21Pic16">
							<!-- Modal -->
							<div class="modal fade" id="Sep21Pic16" tabindex="-1" role="dialog" aria-labelledby="Sep21PicLabel" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/113.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/114.jpg" class="gallery_pic" data-toggle="modal" data-target="#NovPic16">
							<!-- Modal -->
							<div class="modal fade" id="NovPic16" tabindex="-1" role="dialog" aria-labelledby="NovPicLabel" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/114.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/115.jpg" class="gallery_pic" data-toggle="modal" data-target="#training115">
							<!-- Modal -->
							<div class="modal fade" id="training115" tabindex="-1" role="dialog" aria-labelledby="Label" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/115.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/116.jpg" class="gallery_pic" data-toggle="modal" data-target="#training116">
							<!-- Modal -->
							<div class="modal fade" id="training116" tabindex="-1" role="dialog" aria-labelledby="Label" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/116.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/117.jpg" class="gallery_pic" data-toggle="modal" data-target="#training117">
							<!-- Modal -->
							<div class="modal fade" id="training117" tabindex="-1" role="dialog" aria-labelledby="Label" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/117.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/118.jpg" class="gallery_pic" data-toggle="modal" data-target="#training118">
							<!-- Modal -->
							<div class="modal fade" id="training118" tabindex="-1" role="dialog" aria-labelledby="Label" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/118.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/1119.jpg" class="gallery_pic" data-toggle="modal" data-target="#training1119">
							<!-- Modal -->
							<div class="modal fade" id="training1119" tabindex="-1" role="dialog" aria-labelledby="Label" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/1119.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/120.jpg" class="gallery_pic" data-toggle="modal" data-target="#training120">
							<!-- Modal -->
							<div class="modal fade" id="training120" tabindex="-1" role="dialog" aria-labelledby="Label" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/120.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/121.jpg" class="gallery_pic" data-toggle="modal" data-target="#training121">
							<!-- Modal -->
							<div class="modal fade" id="training121" tabindex="-1" role="dialog" aria-labelledby="Label" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/121.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/01.jpg" class="gallery_pic" data-toggle="modal" data-target="#training01">
							<!-- Modal -->
							<div class="modal fade" id="training01" tabindex="-1" role="dialog" aria-labelledby="Label" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/01.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/02.jpg" class="gallery_pic" data-toggle="modal" data-target="#training02">
							<!-- Modal -->
							<div class="modal fade" id="training02" tabindex="-1" role="dialog" aria-labelledby="Label" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/02.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/03.jpg" class="gallery_pic" data-toggle="modal" data-target="#training03">
							<!-- Modal -->
							<div class="modal fade" id="training03" tabindex="-1" role="dialog" aria-labelledby="Label" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/03.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/04.jpg" class="gallery_pic" data-toggle="modal" data-target="#training04">
							<!-- Modal -->
							<div class="modal fade" id="training04" tabindex="-1" role="dialog" aria-labelledby="Label" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/04.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/05.jpg" class="gallery_pic" data-toggle="modal" data-target="#training05">
							<!-- Modal -->
							<div class="modal fade" id="training05" tabindex="-1" role="dialog" aria-labelledby="Label" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/05.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/06.jpg" class="gallery_pic" data-toggle="modal" data-target="#training06">
							<!-- Modal -->
							<div class="modal fade" id="training06" tabindex="-1" role="dialog" aria-labelledby="Label" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/06.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 col-md-3 col-lg-4 border text-center py-3">
                            <img src="https://www.ppra.org.pk/epads/tra/07.jpg" class="gallery_pic" data-toggle="modal" data-target="#training07">
							<!-- Modal -->
							<div class="modal fade" id="training07" tabindex="-1" role="dialog" aria-labelledby="Label" aria-hidden="true">
								<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<button type="button" class="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body text-center">
											<img src="https://www.ppra.org.pk/epads/tra/07.jpg" class="img-center">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
								
				<!---Contact us--->
				<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
					<h3 class="pb-3">Contact us</h3>
					<div class="row">
						<div class="col-lg-7">
							<h6 class="font-weight-semi-bold">For Nomination and Participation</h6>
							<h6>Dr. Faiza Nayyer</h6>
							<p class="text-black-50 font-weight-medium Roboto">Director (HR)-PPRA</p>
							<p>
								<i class="fas fa-map-marker-alt text-success mr-1"></i>1st Floor FBC Building,<br>
								<span class="ml-3">Sector G-5/2, Islamabad-Pakistan</span>
							</p>
							<p>
								<i class="fas fa-phone text-success mr-2"></i> 051-9224822<br>
								<i class="far fa-envelope text-success mr-2"></i><a href="mailto:faiza@ppra.org.pk">faiza@ppra.org.pk</a>
							</p>
						</div>
						<div class="col-lg-5">
							<h6 class="font-weight-semi-bold">For Training Queries</h6>
							<p>
								<i class="fas fa-phone text-success mr-2"></i> 051-9205726<br>
								<i class="fas fa-fax text-success mr-2"></i> 051-9219149<br>
								<i class="far fa-envelope text-success mr-2"></i><a href="mailto:training@ppra.org.pk">training@ppra.org.pk</a>
							</p>
						</div>
					</div>
				</div>
			</div>			
		</div>
	</div>
</section>
<div class="clear"></div>