<header class="page-header">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-md-6 col-lg-7">
				<a href="index.html" title="{{this.userUtilsService.selectedEnvironment?.Name}} PPRA" class="logo">
					<img [src]="this.userUtilsService.selectedEnvironment?.src" height="75" [alt]="this.userUtilsService.selectedEnvironment?.alt" class="mr-3">
					<h1>{{this.userUtilsService.selectedEnvironment?.Name}} Public Procurement Regulatory Authority<br><small class="text-body">Government of {{this.userUtilsService.selectedEnvironment.key == 1 ? 'Pakistan': this.userUtilsService.selectedEnvironment?.Name}}</small></h1>
					<h5 class="text-body text-right d-none d-lg-block"><br>سندھ پبلک پروکیورمنٹ ریگولیٹری اتھارٹی
حکومت سندھ</h5>
				</a>
			</div> 
			<div class="col-md-6 col-lg-5">
				<ul class="account_section">
					<li class="text_12">{{CurentDate | date}}</li>
					<li>UAN: <a href="tel:051111137237" class="text-dark font-weight-bold">051-111-137-237</a></li>
					<!-- <li><a href="https://www.facebook.com/profile.php?id=100095697124318" target="_blank" class="text-primary" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
					<li><a href="https://twitter.com/EPADSPPRA" target="_blank" class="text-primary"><img src="./assets/images/twitterx.png" title="X"></a></li> -->
					<!-- <li class="pr-0"><a [routerLink]="['/contactus']" class="text-dark" title="Contact us"><i class="far fa-envelope"></i></a></li> -->
					<li class="clear d-block"></li>
					<li class="register"><a [href]="this.userUtilsService.selectedEnvironment?.authRegister" target="_blank">Register as a Supplier</a></li>
					<li class="login ml-2"><a [href]="this.userUtilsService.selectedEnvironment?.authLogin" target="_blank">EPADS Login</a></li>
					<!-- <li class="font-weight-semi-bold ml-2 mt-2"><a href="https://ppra.org.pk/login2.asp" target="_blank">Login for Online Submission</a></li> -->
					<!---li class="login dropdown">
						<a href="#" class="dropdown-toggle" role="button" id="dropdownlogin" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Login</a>
						<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownlogin">
							<a class="dropdown-item" href="#" target="_blank"><i class="fas fa-user mr-2"></i> Admin Login</a>
							<a class="dropdown-item" href="#" target="_blank"><i class="far fa-user mr-2"></i> Supplier Login</a>
							<a class="dropdown-item" href="#" target="_blank"><i class="fas fa-user-tie mr-2"></i> Super Admin Login</a>
  						</div>
					</li--->
				</ul>
			</div>
		</div>
	</div>
</header>