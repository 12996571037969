<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>Request for Proposal</h1>
	</div>
</section>
<div class="clear p-4"></div>
<div class="container">
    <h6 class="mb-0 mb-3" style="font-style: italic; color: red;">Only 10 days old tenders from the closing date will be shown.</h6>
    <div class="clear p-2"></div>
    <div class="row justify-content-end align-items-center">
        <strong class="font-weight-bold col-lg-4 text-right">Total Records ({{totalRecords}})</strong>
    </div>
    <div class="clear p-2"></div>
    <div class="table-responsive">
        <table class="table border sortable">
            <thead class="table-success">
                <tr>
                    <th scope="col">Sr NO.</th>
                    <th scope="col">
                        <div class="sortTh">
                            Tender NO
                            <span class="sorted_Icons">
                                <a (click)="sort('tse','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                                <a (click)="sort('tse','asc')"><img src="../assets/images/arrow_down.gif"></a>
                            </span>
                        </div>
                    </th>
                    <th scope="col">Tender Details</th>
                    <th scope="col">Downloads</th>
                    <th scope="col">
                        <div class="sortTh">
                            Advertisement Date
                            <span class="sorted_Icons">
                                <a (click)="sort('startdate','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                                <a (click)="sort('startdate','asc')"><img src="../assets/images/arrow_down.gif"></a>
                            </span>
                        </div>
                    </th>
                    <th scope="col">
                        <div class="sortTh">
                            Closing Date
                            <span class="sorted_Icons">
                                <a (click)="sort('closing_date','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                                <a (click)="sort('closing_date','asc')"><img src="../assets/images/arrow_down.gif"></a>
                            </span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of Records; let i = index">
                    <td>
                        <strong>{{i+1}}</strong><br>
                    </td> 
                    <td>
                        <strong>{{data?.tse}}</strong><br>
                    </td>
                    <td>
                        <strong>{{data?.CategoryName}}, {{data?.state_name}} </strong><br>
                        {{data?.Title}} <br>
                        {{data?.enquiry_no}} {{data?.Title}} <br>
                        {{data?.enquiry_no}} <br>
                        {{data?.Rule}} <br>
                    </td>
                    <td *ngIf="data?.AMD==null || data?.AMD==0" class="text-center align-middle">
                        <a *ngIf="data?.urlattachmentID==null" [href]="data?.URL" target="_blank" data-toggle="tooltip" title="Download"><i class="fas fa-file-download fa-fw fa-lg"></i></a>
                        <a *ngIf="data?.urlattachmentID!=null" (click)="getAttachmentByID(data?.urlattachmentID,data?.urlattachmentGUID)"data-toggle="tooltip" title="Download"><i class="fas fa-file-download fa-fw fa-lg text-success"></i></a>
                    </td>
                    <td *ngIf="data?.AMD==1" class="text-center align-middle">
                        <a *ngIf="data?.urlattachmentID==null" [href]="data?.URL" target="_blank" data-toggle="tooltip" title="Corrigendom"><i class="fas fa-file-signature fa-fw fa-lg text-info"></i></a>
                        <a *ngIf="data?.urlattachmentID!=null" (click)="getAttachmentByID(data?.urlattachmentID,data?.urlattachmentGUID)" data-toggle="tooltip" title="Corrigendom"><i class="fas fa-file-signature fa-fw fa-lg text-info"></i></a>
                    </td> 
                    <td *ngIf="data?.AMD==2" class="text-center align-middle">
                        <a *ngIf="data?.urlattachmentID==null" [href]="data?.URL" target="_blank" data-toggle="tooltip" title="Cancelled"><i class="fas fa-regular fa-ban fa-fw fa-lg text-danger"></i></a>
                        <a *ngIf="data?.urlattachmentID!=null" (click)="getAttachmentByID(data?.urlattachmentID,data?.urlattachmentGUID)" data-toggle="tooltip" title="Cancelled"><i class="fas fa-regular fa-ban fa-fw fa-lg text-danger"></i></a>
                    </td> 
                    <td>
                        <strong>{{data?.startdate | date}}</strong><br>
                    </td>
                    <td>
                        <strong>{{data?.closing_date | date}}</strong><br>
                    </td>
               
                </tr>    
                <strong *ngIf="Records?.length == '0'">No record found.</strong>        
            </tbody>
        </table>
        <div class="clear clearfix py-1"></div> 
        
        <small class="font_12">10 Records Per Page</small> 
        <small class="font_12"> | Total Pages<strong>  {{this.totalPages}}</strong></small> 
        <nav aria-label="..." class="arial-pagination">
            <div class="d-flex">
                <ul class="pagination justify-content-center">
                    <li class="page-item" [class.disabled]="this.PageNo === 1">
                        <a class="page-link" (click)="previousPage()">Previous</a>
                    </li>
                    <li class="page-item" *ngFor="let pageNumber of pages">
                        <a class="page-link" (click)="changePage(pageNumber)" [class.active]="pageNumber === this.PageNo">{{pageNumber}}</a> 
                    </li>
                    <li class="page-item" [class.disabled]="this.PageNo=== this.totalPages">
                        <a class="page-link" (click)="nextPage()">Next</a>
                    </li>
                </ul>
                <div class="input-group input-group-sm ml-4">
                    <input type="number" class="form-control" placeholder="Page No."  (keyup)="onEnterKeyPressed($event)"  [(ngModel)]="this.PN" aria-label="Enter your text" aria-describedby="button-addon">
                    <div class="input-group-append input-group-sm">
                      <button class="btn btn-outline-secondary"  (click)="selectedPage()" type="button" id="button-addon">Go</button>
                    </div>
                </div>
            </div>           
        </nav>        
    </div>
</div>
<div class="clear p-4"></div>