import { Component, OnInit } from '@angular/core';
import b64toBlob from 'b64-to-blob';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import { getAllPublicVoilationsRequestModel } from 'src/app/core/models/getAllPublicVoilationsRequestModel';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-public-voilations',
  templateUrl: './public-voilations.component.html',
  styleUrls: ['./public-voilations.component.scss']
})
export class PublicVoilationsComponent implements OnInit {
  publicVoilations: any;
  getAllPublicVoilationsRequestModel:getAllPublicVoilationsRequestModel;
  paginationModel: PaginationModel;
  procurementPlanDetailID: number;
  downloadFile: downloadfile; 


  constructor(
    public userUtilsService:UserUtilService,
    public portalService:PublicPortalService
    ) { 
      this.procurementPlanDetailID=Number(sessionStorage.getItem('procurementPlanDetailID'))
    }

  ngOnInit(): void {
    this.paginationModel=new PaginationModel();
    this.getAllPublicVoilations();
  }

  getAllPublicVoilations(){
    this.getAllPublicVoilationsRequestModel=new getAllPublicVoilationsRequestModel();
    this.getAllPublicVoilationsRequestModel.procurementPlanDetailID=this.procurementPlanDetailID
    this.getAllPublicVoilationsRequestModel.pagination=this.paginationModel;
    this.portalService.getallpublicviolations(this.getAllPublicVoilationsRequestModel).subscribe(baseResponse => {
      if (baseResponse != null) {
        if (baseResponse.success != null) {
          if (baseResponse.success) {
                let publicVoilation = (baseResponse.data as any) ;
                this.publicVoilations =publicVoilation?.records; 
          }
        }
      }
    })
}

getAttachmentByID(Id: any) {

  if(Id == 0 || Id==null){
    return;
  }
  this.downloadFile=new downloadfile()
  this.downloadFile.ID = Id;

  this.portalService.DownloadFile(this.downloadFile).subscribe(baseResponse => {
    if (baseResponse.success && baseResponse.data != null) {
      var Bytes = baseResponse.data['bytes'];
      var ContentType = baseResponse.data['contentType'];
      var FileName = baseResponse.data['fileName'];

      const blob = b64toBlob(Bytes, ContentType);
      saveAs(blob, FileName);
    }
  });
}
getAttachmentByGUID(Id: any,GUID:any) {

  if(Id == 0 || Id==null){
    return;
  }
  this.downloadFile=new downloadfile()
  this.downloadFile.ID = Id;
  this.downloadFile.idsList = GUID;

  this.portalService.DownloadFileByGuid(this.downloadFile).subscribe(baseResponse => {
    if (baseResponse.success && baseResponse.data != null) {
      var Bytes = baseResponse.data['bytes'];
      var ContentType = baseResponse.data['contentType'];
      var FileName = baseResponse.data['fileName'];

      const blob = b64toBlob(Bytes, ContentType);
      saveAs(blob, FileName);
    }
  });
}
}
 