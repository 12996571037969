<main class="card">
    <div class="content-wrapper content-wrapper--with-bg">
        <div class="text-right">
            <button class="btn btn-success p-button" (click)="downloadReport()">Download PDF</button>
        </div>

        <div id="pdfContent" #pdfTable>
            <span class="mr-3" style="min-width:150px;" *ngIf="!wholePkg">
                <label class="d-block w-full"> No Of Lots
                    <em pTooltip="Please select" class="p-element pi pi-info-circle"></em>
                </label>
                <!-- <p-dropdown [filter]="true" [options]="noOfLots" [(ngModel)]="noOfLotsValue"
                    [ngModelOptions]="{ standalone: true }"
                    styleClass="p-element p-inputwrapper ng-pristine ng-valid ng-star-inserted ng-touched"
                    optionValue="lotNumber" optionLabel="name" placeholder="Choose Lots" (onChange)="getAllSections()">
                </p-dropdown> -->
            </span>
            <div *ngFor="let document of lstSession">
                <!-- <h4 class="my-4 mt-4"> {{document.name}}</h4> -->
                <div class="header my-4"></div>
                <div class="my-3" *ngIf="document.lov_DocumentTemplateSectionTypeID===282"
                    [innerHTML]="document.template"></div>
                <div class="my-3" *ngIf="document.isEligibilitySection">
                    <table aria-describedby="firstTable">
                        <!-- <thead>
                            <th>{{document.name}}</th>
                        </thead> -->
                        <thead>
                            <th scope="col">Name</th>
                            <th scope="col">Type </th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of document.sectionEligibility">
                                <td class="text-wrap word-break">{{data.name}}</td>
                                <td>Yes/No</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="my-3" *ngIf="document.isCheckListSection">
                    <table aria-describedby="secondTable">
                        <!-- <thead>
                            <th>{{document.name}}</th>
                        </thead> -->
                        <thead>
                            <th scope="col">Name </th>
                            <th scope="col">Type</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of document.sectionCheckList">
                                <td class="text-wrap word-break">{{data.name}}</td>
                                <td>Yes/No</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="my-3" *ngIf="document.lov_DocumentTemplateSectionTypeID==303">
                    <table aria-describedby="thirdTable">
                        <thead>
                            <th scope="col">Sr #</th>
                            <th scope="col" class="text-wrap"
                                *ngFor="let item of document.sectionDynamicTable.columnsArrary">
                                {{item.description}}
                            </th>
                        </thead>
                        <tbody *ngFor="let res of document.sectionDynamicTable.sectionDynamicTable;index as i">
                            <tr>
                                <td>{{i+1}}</td>
                                <td *ngFor="let item of document.sectionDynamicTable.columnsArrary">
                                    <label *ngIf="item.dataType!='Sub-Table'"> {{res[item.columnName]}}</label>
                                </td>
                            </tr>
                            <tr *ngIf="res.subTableInfo[0]?.length>0">
                                <td></td>
                                <td>
                                    <table aria-describedby="forthTable">
                                        <thead>
                                            <th scope="col">Sub-Table</th>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Score</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let child of res.subTableInfo[0]">
                                            <tr>
                                                <td>{{child.name}}</td>
                                                <td>{{child.ChildType}}</td>
                                                <td *ngIf="!document.isScore && showHideScore">{{child.score}}</td>
                                            </tr>
                                            <tr *ngIf="child.slabInfo?.length>0">
                                                <td></td>
                                                <td>
                                                    <table aria-describedby="fifthTable">
                                                        <thead>
                                                            <th scope="col">Number</th>
                                                        </thead>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Min</th>
                                                                <th scope="col">Max</th>
                                                                <th scope="col">Score</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let childSlab of child.slabInfo">
                                                                <td>{{childSlab.min}}</td>
                                                                <td>{{childSlab.max}}</td>
                                                                <td *ngIf="!document.isScore && showHideScore">
                                                                    {{childSlab.score}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="my-3" *ngIf="document.isEvaluationSection">
                    <table aria-describedby="sixTable">
                        <thead>
                            <tr>
                                <th scope="col">Criterion Title</th>
                                <th scope="col">Category</th>
                                <th scope="col">Evaluation Heading</th>
                                <!-- <th scope="col">Weightage</th> -->
                                <th scope="col">Type</th>
                                <th scope="col">Score</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let data of document.sectionEvaluations.sectionEvaluationDocumentModel">
                            <tr>
                                <td>{{data.criterionTitle}}</td>
                                <td>{{data.category}}</td>
                                <td>{{data.evaluationHeading}}</td>
                                <!-- <td>{{data.weightage}}</td> -->
                                <td>{{data.evaluationType}}</td>
                                <td *ngIf="!document.isScore && showHideScore">{{data.score}}</td>
                            </tr>
                            <tr *ngIf="data.slabEvaluation.length>0">
                                <td></td>
                                <td colspan="5">
                                    <table aria-describedby="sevenTable">
                                        <thead>
                                            <th scope="col">Number</th>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th scope="col">Min</th>
                                                <th scope="col">Max</th>
                                                <th scope="col">Score</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let slab of data.slabEvaluation">
                                            <tr>
                                                <td>{{slab.min}}</td>
                                                <td>{{slab.max}}</td>
                                                <td *ngIf="!document.isScore && showHideScore">{{slab.score}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr *ngIf="data.childEvaluation.length>0">
                                <td></td>
                                <td colspan="5">
                                    <table aria-describedby="eightTable">
                                        <thead>
                                            <th scope="col">Sub Evaluation</th>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Score</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let child of data.childEvaluation">
                                            <tr>
                                                <td>{{child.name}}</td>
                                                <td>{{child.type}}</td>
                                                <td *ngIf="!document.isScore && showHideScore">{{child.score}}</td>
                                            </tr>
                                            <tr *ngIf="child.slabEvaluationdocument.length>0">
                                                <td></td>
                                                <td colspan="3">
                                                    <table aria-describedby="nineTable">
                                                        <thead>
                                                            <th scope="col">Number</th>
                                                        </thead>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Min</th>
                                                                <th scope="col">Max</th>
                                                                <th scope="col">Score</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody *ngFor="let childSlab of child.slabEvaluationdocument">
                                                            <tr>
                                                                <td>{{childSlab.min}}</td>
                                                                <td>{{childSlab.max}}</td>
                                                                <td *ngIf="!document.isScore && showHideScore">
                                                                    {{childSlab.score}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div>
        </div>
    </div>
</main>