import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
@Component({
  selector: 'app-contract-award-ppra',
  templateUrl: './contract-award-ppra.component.html',
  styleUrls: ['./contract-award-ppra.component.scss']
})
export class ContractAwardPpraComponent implements OnInit {

    constructor(private PublicPortalService: PublicPortalService,
        private toasterService: ToastrService,
        private userUtilsService : UserUtilService
        ) { }
        //data: any;
        fillReport : boolean = false;
        reportForm: FormGroup;
        @Input() publishedDocumentID:number;
        @Input() viewAble:boolean;
        @Input() data:any;
        @Input() supplierID:number;
        @Input() lotNo:number;
        yesNo=[
            {id:1,name:"Yes"},
            {id:0,name:"No"},
        ]
      ngOnInit(): void {
      }
    toogleFillReport(){
      this.fillReport = !this.fillReport;
    }
      getAll(docId:number) {
        this.PublicPortalService.geteContractReport(docId,this.supplierID).subscribe((baseResponse: any) => {
          if (baseResponse.success) {
            this.data=baseResponse.data;
          } else {
            if (baseResponse.errors.length > 0) {
              for (var i = 0; i < baseResponse.errors.length; i++) {
                this.toasterService.error(baseResponse.errors[i].errorMessage);
              }
              return;
            }
          }
        })
      }
      generatePDF() {
        const element = document.getElementById('pdfContent');
            const options = {
                filename: 'Contract-Awward-II.pdf', // Replace 'your-filename' with the desired filename
                pagebreak: { mode: ['css'] },
                jsPDF: { format: 'a4', orientation: 'portrait' },
                // html2canvas: { scale: 2 },
                margin: [20, 20, 20, 20], // Adjust top and bottom margins as needed
                addPageNumbers: (currentPage, totalPage) => {
                  return currentPage + ' / ' + totalPage; // Customize the format of the page numbers as desired
                }
              };

              html2pdf().set(options).from(element).save('Contract-Awward-II.pdf');
      }
}
