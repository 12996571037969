<div class="card mb-0">
    <h5 class="card-header text-black"><i class="far fa-edit mr-1"></i> Highlights</h5>
    <div class="card-body">
        <ul *ngIf="allHighlights?.length > 0 ;else elseblock" class="highlight">
            <li *ngFor="let highlight of allHighlights" >
                <strong>{{highlight?.title}}</strong><br>
                <small><a [href]="highlight?.urlLink"  target="_blank" class="text-info">{{highlight?.description}}</a></small><br>
                <small><a *ngIf="highlight?.attachmentId>0" (click)="getAttachmentByID(highlight?.attachmentId,highlight?.fileGuid)"  target="_blank" class="text-info">Donwload File</a></small>
            </li>           
        </ul>
        <ng-template #elseblock>
            <ul class="highlight">
                <li><a href="#">Use of standard formats for advertisements becomes mandatory for</a></li>
                <li><a href="#">Regulations for Procedure of filing and disposal of Review Petition under Rule 19(3), 2021.</a></li>
                <li><a href="#">PPRA Standard Bidding Documents (Trial Version) for Procurement of General Goods, Closed Frame Work Agreements and Consultancy Services.</a></li>
            </ul>
        </ng-template>
       
    </div>
</div>  