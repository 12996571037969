export class BaseRequest {
    Schema?: string;
    auth?: object;
    schemaName?: string;
    userID?: number;
    userTypeID?: number;
    ministryID?: string;
    ModuleID?: number;
    roleID?: number;
    moduleIDS?: string;

    loggedInUserID: number;
    loggedInUserOfficeID: number;
    Data?: any;
    Data1?: any;
    Data2?: any;
    Data3?: any;
    Data4?: any;
}
