<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>Redressal of Grievences</h1>
	</div>
</section>
<div class="clear p-4"></div>
<div class="container">
    <p>Rule 48 of the Public Procurement Rules, 2004 provides that in case a bidder feeling aggrieved by any act of the procuring agency after the submission of his bid may lodge a written complaint concerning his grievances within fifteen days of the bid evaluation report. Rule 48 is given below:-</p>
    <h6 class="font-weight-semi-bold mb-2">Rule 48: Redressal of grievances by the procuring agency.-</h6>
    <ol class="font-weight-semi-bold mb-2">
        <li>The procuring agency shall constitute a committee comprising of odd number of persons, with proper powers and authorizations, to address the complaints of bidders that may occur prior to the entry into force of the procurement contract.</li>
        <li>Any bidder feeling aggrieved by any act of the procuring agency after the submission of his bid may lodge a written complaint concerning his grievances not later than fifteen days after the announcement of the bid evaluation report under rule 35.</li>
        <li>The committee shall investigate and decide upon the complaint within fifteen days of the receipt of the complaint.</li>
        <li>Mere fact of lodging of a complaint shall not warrant suspension of the procurement process.</li>
        <li>Any bidder not satisfied with the decision of the committee of the procuring agency may lodge an appeal in the relevant court of jurisdiction.</li>
    </ol>
    <p>In order to ensure the compliance of PP Rule 48, PPRA has required the federal procuring agencies to provide information on their grievances redressal committees on a <a href="https://www.ppra.org.pk/doc/rg.pdf" target="_blank" class="font-weight-semi-bold">format (for specimen click here)</a> in a specified manner. The lists of committees formed by various procuring agencies on name designation, address and contact details. Aggrieved bidders use this information to approach relevant procuring agencey.</p>
    <h5 class="font-weight-medium mb-2">Redressal of Grievances Committees</h5>

    <div class="row align-items-center">
        <label class="col-md-2" for="OrganizationName">Organization Name:</label>
        <div class="col-md-4">
            <!-- <select class="form-control" id="OrganizationName" [(ngModel)]="selectedValue">
                <option  *ngFor="let record of DropdownRecords"  [value]="record?.CategoryName">{{record?.CategoryName}}</option>
            </select> -->
            <app-search-dropdown [items]="DropdownRecords" [size]="'small'" [img]="'product'" [label]="'CategoryName'" [uid]="'CategoryID'"
                [(ngModel)]="selectedValue">
            </app-search-dropdown>
        </div>
        <div class="col">
           <button type="submit" (click)="onSelectedValueSearch()" class="btn btn-primary mb-2">Search</button>
           <button  type="Clear"  (click)="clear()" class="btn btn-primary mb-2 ml-2">Clear</button>
        </div>
    </div>

    <p class="font-weight-bold text-right">Total Records ({{totalRecords}})</p>
    <div class="table-responsive">        
        <table class="table border">
            <thead class="table-success">
                <tr>
                    <th scope="col">Sr NO.</th>
                    <th scope="col">
                        <div class="sortTh">
                            Organizations
                            <span class="sorted_Icons">
                                <a (click)="sort('CategoryName','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                                <a (click)="sort('CategoryName','asc')"><img src="../assets/images/arrow_down.gif"></a>
                            </span>
                        </div>
                    </th>
                    <th scope="col">Downloads</th>
                    <!-- <th scope="col">Sequence No</th> -->
                    <!-- <th scope="col">URL</th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of Records; let i = index">
                    <td width="70">
                        <strong>{{i+1}}</strong>
                    </td>
                    <td>
                        <strong>{{data?.CategoryName}}</strong>
                    </td>
                    <td >
                        <a *ngIf="data?.urlattachmentID==null" [href]="data?.URL" target="_blank" ><i class="fas fa-file-download fa-fw fa-lg"></i></a>
                        <a *ngIf="data?.urlattachmentID!=null" (click)="getAttachmentByID(data?.urlattachmentID,data?.urlattachmentGUID)"><i class="fas fa-file-download fa-fw fa-lg text-success"></i></a>
                    </td>    
                </tr>  
                <strong *ngIf="Records?.length == '0'">No record found.</strong>          
            </tbody>
        </table>
        <div class="clear clearfix py-1"></div>
        <small class="font_12">10 Records Per Page</small> 
        <small  class="font_12"> | Total Pages<strong>  {{this.totalPages}}</strong></small> 
        <nav aria-label="..." class="arial-pagination" >
            <div class="d-flex">
                <ul class="pagination justify-content-center mr-4">
                    <li class="page-item" [class.disabled]="this.PageNo === 1">
                        <a class="page-link" (click)="previousPage()">Previous</a>
                    </li>
                    <li class="page-item" *ngFor="let pageNumber of pages">
                        <a class="page-link" (click)="changePage(pageNumber)" [class.active]="pageNumber === this.PageNo">{{pageNumber}}</a> 
                    </li>
                    <li class="page-item" [class.disabled]="this.PageNo=== this.totalPages">
                        <a class="page-link" (click)="nextPage()">Next</a>
                    </li>
                </ul>
                <div class="input-group input-group-sm">
                    <input type="number" class="form-control" placeholder="Page No." (keyup)="onEnterKeyPressed($event)"  [(ngModel)]="this.PN" aria-label="Enter your text" aria-describedby="button-addon">
                    <div class="input-group-append input-group-sm">
                      <button class="btn btn-outline-secondary"  (click)="selectedPage()" type="button" id="button-addon">Go</button>
                    </div>
                </div>
            </div>           
        </nav>
    </div>
    
</div>
<div class="clear p-4"></div>