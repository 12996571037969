import { Component, OnInit } from '@angular/core';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { GetAllPublishedDocumentDetailsByPidRequestModel, GetPublishedDocumentInfoRequestModel, GetRulesRequestModel, TenderFilter } from 'src/app/core/models/tender-filter.model';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import * as html2pdf from 'html2pdf.js'
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
@Component({
  selector: 'app-pp-rules',
  templateUrl: './pp-rules.component.html',
  styleUrls: ['./pp-rules.component.scss']
})
export class PpRulesComponent implements OnInit {
  paginationModel: PaginationModel;
  publicTenders:any;
  searchFilter :TenderFilter;
  selectedSort:any;
  downloadFile: downloadfile;
  publicationDetails: any;
  documentsDetails: any;
  getAllTendersRequestModel:GetPublishedDocumentInfoRequestModel;
  GetRulesRequestModel:GetRulesRequestModel;
  getAllPublishedDocumentDetailsByPidRequestModel: GetAllPublishedDocumentDetailsByPidRequestModel;
  contentsearch: string;
  SearchWords: any;
  example : string = 'NOW, THEREFORE, in pursuance of the Proclamation of Emergency of the fourteenth day of October, 1999, and the Provisional Constitution Order No.1 of 1999, read with the Provisional Constitution (Amendment) Order No.9 of 1999, and in exercise of all powers enabling him in that behalf, the President of the Islamic Republic of Pakistan is pleased to make and promulgate the following Ordinance:-'
  searchkeys: any = "";
  isSearch: boolean=false;
  noRecordFound: boolean=false;
  constructor(private portalService:PublicPortalService,
    public userUtilService:UserUtilService) { }

  ngOnInit(): void {
    this.downloadFile = new downloadfile();
    this.paginationModel = new PaginationModel();
    this.searchFilter = new TenderFilter();
    this.getAllPublishedDocumentDetailsByPidRequestModel = new GetAllPublishedDocumentDetailsByPidRequestModel();
    this.getRulesAndRegulatoins();

  }

  Search(){
    this.getRulesAndRegulatoins()
    this.SearchWords = this.searchkeys
    this.isSearch = true
  }

  ClearSearch(){
    this.searchkeys = ''
    this.SearchWords = ''
    this.getRulesAndRegulatoins()
  }

  Onempty(){
    if(this.searchkeys.length == 0){
      this.SearchWords = ''
      this.getRulesAndRegulatoins()
    }
  }

  getRulesAndRegulatoins(){
    this.getAllTendersRequestModel = new GetPublishedDocumentInfoRequestModel();
    if((this.searchkeys.length <3 && this.searchkeys!='') && this.isSearch){
      return
    }else{
      this.getAllTendersRequestModel.search = this.searchkeys
    }
    this.portalService.getRulesAndRegulatoins(this.getAllTendersRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
                  let publicTenders = (baseResponse.data as any);
                  
                    this.publicTenders = publicTenders;
                    if(baseResponse.data ==null)
                    {
                      this.noRecordFound = true
                    }
            }
          }
        }
      })
  }

  viewMoreRulesAndRegulations(){
    if(this.publicTenders != null && this.publicTenders.length >= 10){
      let pageSize = + this.paginationModel.pageSize 
      pageSize = pageSize + 5;
      this.paginationModel.pageSize += 5;
      this.getRulesAndRegulatoins();
    }
    else{
      return;
    }
  }

  clear(){
    this.searchFilter.sortOrder = "Latest";
    this.searchFilter.keywords = "";
    this.searchFilter.tenderNo = "";
    this.searchFilter.dateOfAdvertisement = null;
    this.searchFilter.closingDate = null;
    // this.getRulesAndRegulatoins();
  }
  public getPDF() {
    var element = document.getElementById('pprclause'); 
    html2pdf(element);
  }

  print(){

 window.print()
    // //let myWindow = window.open('', 'my div', 'height=400,width=600');
    // var element = document.getElementById('pprclause'); 
    // let html = "";
    // html = element.innerHTML
    // const printWindow = window.open('', '_blank');
    // if (printWindow) {
    //   printWindow.document.write(html);
    // }
    // printWindow.print();
   // myWindow.close();
  }
}
