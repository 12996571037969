import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { listType } from 'src/app/core/enumerations/enum-ListType';
import { ActiveTendersSearchRequestModel } from 'src/app/core/models/activeTendersRequestModel';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { payRequestModel } from 'src/app/core/models/payRequestModel';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';

@Component({
  selector: 'app-sector-tenders-history',
  templateUrl: './sector-tenders-history.component.html',
  styleUrls: ['./sector-tenders-history.component.scss']
})
export class SectorTendersHistoryComponent implements OnInit {
  payRequestModel:payRequestModel;
  paginationModel: PaginationModel;
  dataForm: FormGroup;
  dataModel: ActiveTendersSearchRequestModel;
  loggedInUser : any;
  selectedSort:any;
  Records:any;
  totalRecords:any;
  totalPages:number;
  pages= new Array();
  PageNo:number=0;
  PN:number;
  selectedValue: any;
  isSelected: boolean;
  selectedID: any;
  DropdownRecords: any;
  selectedValueID: any;

  constructor(private service:PublicPortalService
    ,private router: Router) {
    this.dataModel= new ActiveTendersSearchRequestModel()
   }

  ngOnInit(): void {
    this.paginationModel = new PaginationModel();
    this.payRequestModel=new payRequestModel();

    this.PageNo=Number(this.paginationModel.pageNumber);
    this.GetPayDropDown();
    this.GetPayDetailList();
  }
  search(){

  }
  navigate(id, name)
  {
    this.router.navigateByUrl(`/tenders/tendershistory/${id}`);
    sessionStorage.setItem(listType.Sector,'Sector')
    sessionStorage.setItem('Organization Name','Organization Name')
    sessionStorage.setItem('historyName',name)
    sessionStorage.setItem('sectorID',id)
    sessionStorage.removeItem('orgID')
  }
  // clear(){
  //   this.GetPayDropDown();
  // }
  GetPayDetailList(){
    //his.clear()

    this.payRequestModel.Type = listType.SectorHistory;
    this.payRequestModel.ID = -5;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize="10"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
                 
                  let data = (baseResponse.data as any) ;
                  this.totalPages = data?.totalPages;
                  this.totalRecords = data?.totalRecords;
                  this.pages = new Array();
                  for (let i = 1; i <= this.totalPages; i++) {
                    this.pages.push(i);
                  }
                  
                  this.Records =data?.records;
                           

            }
          }
        }
      })
  }

  changePage(pageNumber){
    this.paginationModel.pageNumber = pageNumber;
    this.PageNo=Number(pageNumber);
    this.PN=this.PageNo;
    this.GetPayDetailList();
  }

  nextPage() {
    if (Number(this.paginationModel.pageNumber) < this.totalPages) {
        this.changePage(Number(this.paginationModel.pageNumber) + 1);
      }
  }

  previousPage() {
    if (Number(this.paginationModel.pageNumber) > 1) {
      this.changePage(Number(this.paginationModel.pageNumber) - 1);
    }
  }

  selectedPage()
  {
    if(this.PN==null || this.PN==0){
      this.PN=1
    }
    this.paginationModel.pageNumber =String(this.PN);
    this.PageNo=this.PN;
    this.GetPayDetailList();
  }

  onEnterKeyPressed(event: KeyboardEvent): void {
    // Check if the key pressed is Enter
    
    if (event.key === 'Enter') {
      // Call your function here
      this.selectedPage()
    }
  }
  clear(){
    location.reload()
  }
  GetPayDropDown(){
    this.payRequestModel.Type = listType.SectorDropDown;
    this.payRequestModel.ID = 0;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize="5000"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
                  let data = (baseResponse.data as any) ;
                  this.DropdownRecords =data?.records;
            }
          }
        }
      })
  }
  onSelectedValue(){
    this.paginationModel=new PaginationModel()
     this.payRequestModel.Type = listType.SectorHistoryDropDown;
     this.selectedValueID=this.selectedValue==null?null:this.DropdownRecords.filter(x=>x.SectorName==this.selectedValue)[0].SectorID;
     this.payRequestModel.ID=this.selectedValueID
     this.payRequestModel.pagination = this.paginationModel;
     this.payRequestModel.pagination.pageSize="10"
     this.service.GetPayDetailList(this.payRequestModel)
       .subscribe(baseResponse => {
         if (baseResponse != null) {
           if (baseResponse.success != null) {
             if (baseResponse.success) {
                  this.isSelected=true;
                   let data = (baseResponse.data as any) ;
                   this.Records =data?.records
                   this.totalRecords =   this.Records.length;
                   this.totalPages = data?.totalPages;
                   this.pages = new Array();
                   for (let i = 1; i <= this.totalPages; i++) {
                     this.pages.push(i);
                   }
             }
           }
         }
       })
   }
   sort(orderByColumnName, orderBy){
    this.paginationModel.orderBy = orderBy;
    this.payRequestModel.pagination.orderByColumnName = orderByColumnName;
    this.paginationModel.orderByColumnName = orderByColumnName
    this.GetPayDetailList();
  }
}

