import { Injectable } from '@angular/core';
import { PPRAEnvironment } from '../../models/environment.model';

@Injectable({
  providedIn: 'root'
})
export class UserUtilService {
  

  isPPRAPage = false;
  ppraEnvironment:PPRAEnvironment = PPRAEnvironment.Federal; //Default Federal
  ppraEnvironmentUrlKeyValue =  
  [
    {Letter:"F", Href:"", Name:"Federal", key:PPRAEnvironment.Federal, src:"assets/images/layout/ppra-logo.png", alt:"Federal", phone:"051-9205728, 26", email:"info@ppra.org.pk", address:"Red Zone, Islamabad", officeId:1233, authLogin:"https://eprocure.gov.pk/#/auth/login", authRegister:"https://eprocure.gov.pk/#/supplier/registration"},
    {Letter:"P", Href:"", Name:"Punjab", key:PPRAEnvironment.Punjab, src:"assets/images/layout/punjab-ppra.svg", alt:"Punjab PPRA", phone:"051-9205728, 26", email:"info@ppra.org.pk", address:"Red Zone, Islamabad", officeId:1233, authLogin:"https://eprocure.gov.pk/#/auth/login", authRegister:"https://eprocure.gov.pk/#/supplier/registration"},
    {Letter:"B", Href:"", Name:"Balochistan", key:PPRAEnvironment.Balochistan, src:"assets/images/layout/bppra-logo.png" , alt:"Balochistan", phone:"051-9205728, 26", email:"info@ppra.org.pk", address:"Red Zone, Islamabad", officeId:1233, authLogin:"https://eprocure.gov.pk/#/auth/login", authRegister:"https://eprocure.gov.pk/#/supplier/registration"},
    {Letter:"G", Href:"", Name:"GB", key: PPRAEnvironment.GB, src:"assets/images/layout/gbppra.png", alt:"GB", phone:"051-9205728, 26", email:"info@ppra.org.pk", address:"Red Zone, Islamabad", officeId:1233, authLogin:"https://eprocure.gov.pk/#/auth/login", authRegister:"https://eprocure.gov.pk/#/supplier/registration"},
    {Letter:"S", Href:"", Name:"Sindh", key:PPRAEnvironment.Sindh, src:"assets/images/layout/sindh_logo.png", alt:"Sindh", phone:"92-21-99205356", email:"info@pprasindh.org.pk", address:"Sindh Public Procurement Regulatory Authority Barrack 8 Secretariat 4A Court Road Karachi", officeId:31640, authLogin:"https://sindh.eprocure.gov.pk/#/auth/login", authRegister:"https://sindh.eprocure.gov.pk/#/supplier/registration"},
    {Letter:"K", Href:"", Name:"KPK", key:PPRAEnvironment.KPK, src:"assets/images/layout/kp-ppra.png", alt:"KPK", phone:"051-9205728, 26", email:"info@ppra.org.pk", address:"Red Zone, Islamabad", officeId:1233, authLogin:"https://eprocure.gov.pk/#/auth/login", authRegister:"https://eprocure.gov.pk/#/supplier/registration"},
    {Letter:"A", Href:"", Name:"AJK", key:PPRAEnvironment.AJK, src:"assets/images/layout/ajk-ppra.png", alt:"AJK", phone:"051-9205728, 26", email:"info@ppra.org.pk", address:"Red Zone, Islamabad", officeId:1233, authLogin:"https://eprocure.gov.pk/#/auth/login", authRegister:"https://eprocure.gov.pk/#/supplier/registration"},
 ];

  selectedEnvironment :any;
  selectedEnvironmentName:any;

  constructor() { 
  
    
      let url = window?.location?.hostname?.toLocaleLowerCase();
      this.setPPRAEnvironment(url);
    
  }


  public setPPRAEnvironment(url){

    if(url.includes("portalpunjab"))
    {url = "punjab.eprocure";}
    else if (url.includes("portalsindh"))
    {url = "sindh.eprocure";}
    else if (url.includes("portalgb"))
    {url = "gb.eprocure";}
    else if (url.includes("portalajk"))
    {url = "ajk.eprocure";}
    else if (url.includes("portalbppra"))
    {url = "bppra.eprocure";}
    else if (url.includes("portalkp"))
    {
      url = "kpk.eprocure";
    }
    else if (url.includes("portal.eprocure.gov.pk"))
    {
      url = "portal.eprocure";
    }
    else
    {url = "default"; }
    switch(url)
    {
        case "punjab.eprocure": this.selectedEnvironmentName = "Punjab-PPRA-Dev"; this.ppraEnvironment = PPRAEnvironment.Punjab ; this.selectedEnvironment = this.ppraEnvironmentUrlKeyValue?.find(x=>x.key == PPRAEnvironment.Punjab) ; break;
        case "sindh.eprocure":  this.selectedEnvironmentName = "Sindh-PPRA-Dev"; this.ppraEnvironment = PPRAEnvironment.Sindh ; this.selectedEnvironment = this.ppraEnvironmentUrlKeyValue?.find(x=>x.key == PPRAEnvironment.Sindh) ; break;
        case "gb.eprocure":     this.selectedEnvironmentName = "GB-PPRA-Dev"; this.ppraEnvironment = PPRAEnvironment.GB ; this.selectedEnvironment = this.ppraEnvironmentUrlKeyValue?.find(x=>x.key == PPRAEnvironment.GB) ; break;
        case "ajk.eprocure":    this.selectedEnvironmentName = "AJK-PPRA-Dev"; this.ppraEnvironment = PPRAEnvironment.AJK ;  this.selectedEnvironment = this.ppraEnvironmentUrlKeyValue?.find(x=>x.key == PPRAEnvironment.AJK) ; break;
        case "bppra.eprocure":  this.selectedEnvironmentName = "B-PPRA-Dev"; this.ppraEnvironment = PPRAEnvironment.Balochistan ; this.selectedEnvironment = this.ppraEnvironmentUrlKeyValue?.find(x=>x.key == PPRAEnvironment.Balochistan) ; break;
        case "kpk.eprocure":    this.selectedEnvironmentName = "KPK-PPRA-Dev"; this.ppraEnvironment = PPRAEnvironment.KPK ; this.selectedEnvironment = this.ppraEnvironmentUrlKeyValue?.find(x=>x.key == PPRAEnvironment.KPK) ; break;
        case "portal.eprocure": this.selectedEnvironmentName = "F-PPRA-Dev"; this.ppraEnvironment = PPRAEnvironment.Federal ; this.selectedEnvironment = this.ppraEnvironmentUrlKeyValue?.find(x=>x.key == PPRAEnvironment.Federal) ; break;
        default :               this.selectedEnvironmentName = "F-PPRA-Dev"; this.ppraEnvironment = PPRAEnvironment.Federal ; this.selectedEnvironment = this.ppraEnvironmentUrlKeyValue?.find(x=>x.key == PPRAEnvironment.Federal) ; break;
    }

  }
}
