import { EnumMicroServices } from "src/app/core/enumerations/enum-micro-services";
import { Environment } from "src/app/core/models/environment.model";


export const environment: Environment = {
    production: true,//set false for local
    im: true,//set false for local
    loignResponseKey: "loginResponse",
    tokenResponseKey: "tokenResponse",
    loginSecretKey: "SecretResponse",
    authorization: {
        secret: "Y1I2RXBUaHVYT3Z4REpWUXgwYWd6TW05MElVYToxaldESGpHYzF2ZndVUHlsM0RFVV84VnhMMTRh",
        resetPasswordSecret: "YWRtaW46cHByYTEy"
    },
    Server :"",
    ApiUrl:"",
    imHostUrl: "https://Apiprd.eprocure.gov.pk",
    microServices: [
        {
            name: EnumMicroServices.UserManagement,
            hostUrl: 'http://43.225.143.42:30002',//it must be localhost's url for dev environment
            localUrl: 'http://43.225.143.42:30002',// temporary change this veriable should remove for production
            apiVersion: 'v1',
            contextVersion: '1.0.0'
        },
        {
            name: EnumMicroServices.WebPortal,
            hostUrl: 'http://10.101.0.6:30013/',//it must be localhost's url for dev environment
            localUrl: 'http://10.101.0.6:30013',// temporary change this veriable should remove for production
            apiVersion: 'v1',
            contextVersion: '1.0.0'
        }
        , {
            name: EnumMicroServices.UserRegistration,
            hostUrl: 'http://43.225.143.42:30002',
            localUrl: 'http://43.225.143.42:30002',
            apiVersion: 'v1',
            contextVersion: '1.0.0'
        }
        , {
            name: EnumMicroServices.SupplierManagement,
            hostUrl: 'http://43.225.143.42:30004',
            localUrl: 'http://43.225.143.42:30004',
            apiVersion: 'v1',
            contextVersion: '1.0.0'
        }
        , {
            name: EnumMicroServices.SacManagement,
            hostUrl: 'http://43.225.143.42:30003',
            localUrl: 'http://43.225.143.42:30003',
            apiVersion: 'v1',
            contextVersion: '1.0.0'
        }
        , {
            name: EnumMicroServices.ContractManagement,
            hostUrl: 'http://43.225.143.42:30003',
            localUrl: 'http://43.225.143.42:30003',
            apiVersion: 'v1',
            contextVersion: '1.0.0'
        }
        , {
            name: EnumMicroServices.WorkLoadManagement,
            hostUrl: 'http://43.225.143.42:30005',
            localUrl: 'http://43.225.143.42:30005',
            apiVersion: 'v1',
            contextVersion: '1.0.0'
        }
        ,
        {
            name: EnumMicroServices.OTP,
            hostUrl: 'http://10.2.150.35:8243',
            localUrl: '',
            apiVersion: 'v1',
            contextVersion: '1.0.0'
        }
         ,
        {
            name: EnumMicroServices.notification,
            hostUrl: 'http://10.2.150.35:8243',
            localUrl: '',
            apiVersion: 'v1',
            contextVersion: '1.0.0'
        }
        ,
        {
            name: EnumMicroServices.cms,
            hostUrl: 'http://43.225.143.42:30006',
            localUrl: 'http://43.225.143.42:30006',
            apiVersion: 'v1',
            contextVersion: '1.0.0'
        },
        {
            name: EnumMicroServices.documentcreation,
            hostUrl: 'http://43.225.143.42:30007',
            localUrl: 'http://43.225.143.42:30007',
            apiVersion: 'v1',
            contextVersion: '1.0.0'
        },
        {
            name: EnumMicroServices.documentmanagementsystem,
            hostUrl: 'http://43.225.143.42:30008',
            localUrl: 'http://43.225.143.42:30008',
            apiVersion: 'v1',
            contextVersion: '1.0.0'
        },
        {
           name: EnumMicroServices.workflowmanagement,
           hostUrl: 'http://43.225.143.42:30009',
           localUrl: 'http://43.225.143.42:30009',
           apiVersion: 'v1',
           contextVersion: '1.0.0'
        },
        {
            name: EnumMicroServices.globalsearch,
            hostUrl: 'http://43.225.143.42:30010',
            localUrl: 'http://localhost:53270',
            apiVersion: 'v1',
            contextVersion: '1.0.0'
        },
        {
             name: EnumMicroServices.report,
            hostUrl: 'http://43.225.143.42:30011',
            localUrl: 'http://localhost:53271',
            apiVersion: 'v1',
            contextVersion: '1.0.0'
       }
    ],
    getAPIPath: (microServiceName: string, controllerName: string, actionName: string, service: string, version: string = "1.0.0"): string => {
        let url: string = '';
        microServiceName = microServiceName.toLowerCase();
        controllerName = controllerName.toLowerCase();
        actionName = actionName.toLowerCase();
        service = service.toLowerCase();

        let mService = environment.microServices.find(x => x.name == microServiceName);
        if (mService) {
            if (!environment.im) {
                url = `${environment.production ? mService.hostUrl : mService.localUrl}/api/${mService.apiVersion}/${controllerName}/${actionName}`;
            } else {
                if (service != "") {
                    if (service == "registration") {
                        url = `${environment.imHostUrl}/${microServiceName}/${mService.contextVersion}/api/${mService.apiVersion}/${controllerName}/${actionName}`
                    }else if(service == 'workloadmanagement'){
                        url = `${environment.imHostUrl}/${microServiceName}/${mService.contextVersion}/api/${mService.apiVersion}/${controllerName}/${actionName}`
                    }
                    else if(service == 'workflowmanagement'){
                        url = `${environment.imHostUrl}/${microServiceName}/${mService.contextVersion}/api/${mService.apiVersion}/${controllerName}/${actionName}`
                    }
                    else {
                        url = `${environment.imHostUrl}/${microServiceName}/${service}/${mService.contextVersion}/api/${mService.apiVersion}/${controllerName}/${actionName}`
                    }
                } else { //temporary
                    url = `${environment.imHostUrl}/${microServiceName}/${mService.contextVersion}/api/${mService.apiVersion}/${controllerName}/${actionName}`
                }
            }
        } else {
            console.error(`Invalid argument :  Not found ${microServiceName} service!`)
        }

        return url;
    }

}
