import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { listType } from 'src/app/core/enumerations/enum-ListType';
import { ActiveTendersSearchRequestModel } from 'src/app/core/models/activeTendersRequestModel';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { payRequestModel } from 'src/app/core/models/payRequestModel';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
@Component({
  selector: 'app-register-suppliers',
  templateUrl: './register-suppliers.component.html',
  styleUrls: ['./register-suppliers.component.scss']
})
export class RegisterSuppliersComponent implements OnInit {
  payRequestModel: payRequestModel;
  paginationModel: PaginationModel;
  dataForm: FormGroup;
  dataModel: ActiveTendersSearchRequestModel;
  loggedInUser: any;
  selectedSort: any;
  Records: any;
  totalRecords: any;
  totalPages: number;
  pages = new Array();
  PageNo: number = 0;
  PN: number;
  Organization: any;
  DropdownRecords: any;
  selectedValue: 0;
  isSelected: boolean = false;
  selectedID: any;
  DropdownRecordsCity: any;
  DropdownRecordsBussinessTypes: any;
  selectedCityValue: 0;
  SupplierName:string
  constructor(private service: PublicPortalService
    , private router: Router) {
    this.dataModel = new ActiveTendersSearchRequestModel()
  }

  ngOnInit(): void {
    this.paginationModel = new PaginationModel();
    this.payRequestModel = new payRequestModel();
    this.selectedCityValue = 0
    this.selectedValue = 0
    this.PageNo = Number(this.paginationModel.pageNumber);
    this.GetPayDropDownForCity()
    this.GetPayDropDownForBussinessIndustry()
    this.GetPayDetailList()
  }
  GetPayDetailList() {

    this.payRequestModel.Type = listType.EPADSAllSuppliers;
    this.payRequestModel.ID = 0;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              this.isSelected = false
              let data = (baseResponse.data as any);
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }
              this.Records = data?.records;
            }
          }
        }
      })
  }
  changePage(pageNumber) {

    this.paginationModel.pageNumber = pageNumber;
    this.PageNo = Number(pageNumber);
    this.PN = this.PageNo;
    if(this.payRequestModel.Type==listType.EPADSSuppliers){
      this.onCitySelectedValue();
    }
    else if(this.payRequestModel.Type==listType.EPADSAllSuppliers){
      this.GetPayDetailList()
    }
    else if (this.payRequestModel.Type==listType.EPADSSuppliers){
      this.onBussinessSelectedValue();
    }
    else if(this.payRequestModel.Type==this.SupplierName){
      this.onSupplierNameValue()
    }
  }

  nextPage() {
    if (Number(this.paginationModel.pageNumber) < this.totalPages) {
      this.changePage(Number(this.paginationModel.pageNumber) + 1);
    }
  }

  previousPage() {
    if (Number(this.paginationModel.pageNumber) > 1) {
      this.changePage(Number(this.paginationModel.pageNumber) - 1);
    }
  }

  selectedPage() {
    if(this.PN==null || this.PN==0){
      this.PN=1
    }
    this.paginationModel.pageNumber = String(this.PN);
    this.PageNo = this.PN;
    if(this.payRequestModel.Type==listType.EPADSSuppliers){
      this.onCitySelectedValue();
    }
    else if(this.payRequestModel.Type==listType.EPADSAllSuppliers){
      this.GetPayDetailList()
    }
    else if (this.payRequestModel.Type==listType.EPADSSuppliers){
      this.onBussinessSelectedValue();
    }
    else if(this.payRequestModel.Type==this.SupplierName){
      this.onSupplierNameValue()
    }
  }

  onEnterKeyPressed(event: KeyboardEvent): void {
    // Check if the key pressed is Enter

    if (event.key === 'Enter') {
      // Call your function here
      this.selectedPage()
    }
  }
  GetPayDropDownForCity() {
    this.payRequestModel.Type = listType.EPADSCities;
    this.payRequestModel.ID = 0;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "5000"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              let data = (baseResponse.data as any);
              this.selectedCityValue = 0
              this.DropdownRecordsCity = data?.records
            }
          }
        }
      })
  }
  GetPayDropDownForBussinessIndustry() {
    this.payRequestModel.Type = listType.EPADSBussinessTypes;
    this.payRequestModel.ID = 0;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "5000"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              let data = (baseResponse.data as any);
              this.DropdownRecordsBussinessTypes = data?.records;

            }
          }
        }
      })
  }
  onSupplierNameValue() {
    this.payRequestModel.Type = this.SupplierName
    this.payRequestModel.ID = -20
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              this.isSelected = true;
              let data = (baseResponse.data as any);
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }
              this.Records = data?.records;
              this.SupplierName=null
            }
          }
        }
      })
  }
  onCitySelectedValue() {
    this.payRequestModel.Type = listType.EPADSSuppliers;
    this.payRequestModel.ID = this.DropdownRecordsCity.filter(x => x.Name == this.selectedCityValue)[0].ID;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              this.isSelected = true;
              let data = (baseResponse.data as any);
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }
              this.Records = data?.records;
             // this.data()
            }
          }
        }
      })
  }
  onBussinessSelectedValue() {
    this.payRequestModel.Type = listType.EPADSSuppliersByIndustry;
    this.payRequestModel.ID = this.DropdownRecordsBussinessTypes.filter(x => x.DisplayName == this.selectedValue)[0].ID;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              this.isSelected = true;
              let data = (baseResponse.data as any);
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }
              this.Records = data?.records;
            }
          }
        }
      })
  }
  sort(orderByColumnName, orderBy){
    this.paginationModel.orderBy = orderBy;
    this.payRequestModel.pagination.orderByColumnName = orderByColumnName;
    this.paginationModel.orderByColumnName = orderByColumnName
    if(this.payRequestModel.Type==listType.EPADSSuppliers){
      this.onCitySelectedValue();
    }
    else if(this.payRequestModel.Type==listType.EPADSAllSuppliers){
      this.GetPayDetailList()
    }
    else if (this.payRequestModel.Type==listType.EPADSSuppliers){
      this.onBussinessSelectedValue();
    }
    else if(this.payRequestModel.Type==this.SupplierName){
      this.onSupplierNameValue()
    }
  }
  clear(){
    this.GetPayDropDownForCity()
    this.GetPayDropDownForBussinessIndustry()
    this.SupplierName=null
    this.GetPayDetailList()
  }
}
