import { Component, OnInit } from '@angular/core';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dashboardDataModel:any;

  constructor(private portalService:PublicPortalService) { }

  ngOnInit(): void {
    this.getDashboardData();
  }
  getDashboardData(){
    
    this.portalService.getDashboardData()
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
                 this.dashboardDataModel = baseResponse.data;
            }
          }

        }

      })
  }
}
