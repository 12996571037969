import { Component, OnInit } from '@angular/core';
import { listType } from 'src/app/core/enumerations/enum-ListType';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { payRequestModel } from 'src/app/core/models/payRequestModel';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import { saveAs } from 'file-saver';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import b64toBlob from 'b64-to-blob';

@Component({
  selector: 'app-grclist',
  templateUrl: './grclist.component.html',
  styleUrls: ['./grclist.component.scss']
})
export class GrclistComponent implements OnInit {

  payRequestModel:payRequestModel;
  paginationModel: PaginationModel;
  downloadFile: downloadfile;
  loggedInUser : any;
  selectedSort:any;
  Records:any;
  totalRecords:any;
  totalPages:number;
  pages= new Array();
  PageNo:number=0;
  PN:number;
  DropdownRecords:any;
  selectedValue: any="";
  isSelected:boolean=false;
  selectedValueName: any;
  selectedValueID: any;
  constructor(private service:PublicPortalService) { }

  ngOnInit(): void {
    this.paginationModel = new PaginationModel();
    this.payRequestModel=new payRequestModel();
    this.downloadFile = new downloadfile();
    this.PageNo=Number(this.paginationModel.pageNumber);
    this.GetPayDropDown();
    this.GetPayDetailList();
  }

  GetPayDetailList(){
    
    this.payRequestModel.Type = listType.Grievance;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize="10"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
                  this.isSelected=false
                  let data = (baseResponse.data as any) ;
                  this.totalPages = data?.totalPages;
                  this.totalRecords = data?.totalRecords;
                  this.pages = new Array();
                  for (let i = 1; i <= this.totalPages; i++) {
                    this.pages.push(i);
                  }
                  
                  this.Records =data?.records;
            }
          }
        }
      })
  }
  GetPayDropDown(){
    this.payRequestModel.Type = listType.GrievanceDropdown;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize="5000"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
                 
                  let data = (baseResponse.data as any) ;
                  this.DropdownRecords =data?.records;
            }
          }
        }
      })
  }
  onSelectedValueSearch(){

        this.paginationModel=new PaginationModel()
        this.PageNo=Number(this.paginationModel.pageNumber);
        this.PN=null
        this.payRequestModel.Type = listType.GrievanceByID;
        if(this.selectedValue!=null){
          this.selectedValueID=this.selectedValue==null?null:this.DropdownRecords.filter(x=>x.CategoryName==this.selectedValue)[0].CategoryID;
          this.payRequestModel.ID = this.selectedValueID
        }
        else{
          this.payRequestModel.ID=0
        }
        this.payRequestModel.City =  null;
        this.payRequestModel.TenderNumber =  null;
        this.payRequestModel.Date = null;
        this.payRequestModel.pagination = this.paginationModel;
        this.payRequestModel.pagination.pageSize="10"
        this.service.GetPayDetailList(this.payRequestModel)
          .subscribe(baseResponse => {
            if (baseResponse != null) {
              if (baseResponse.success != null) {
                if (baseResponse.success) {
                     this.isSelected=true;
                      let data = (baseResponse.data as any) ;
                      this.Records =data?.records
                      this.totalRecords =   this.Records.length;
                      this.totalPages = data?.totalPages;
                      this.pages = new Array();
                      for (let i = 1; i <= this.totalPages; i++) {
                        this.pages.push(i);
                      }
                }
              }
            }
          })   
  }
  onSelectedValue(){
        this.payRequestModel.Type = listType.GrievanceByID;
        if(this.selectedValue!=null){
          this.selectedValueID=this.selectedValue==null?null:this.DropdownRecords.filter(x=>x.CategoryName==this.selectedValue)[0].CategoryID;
          this.payRequestModel.ID = this.selectedValueID
        }
        else{
          this.payRequestModel.ID=0
        }
        this.payRequestModel.City =  null;
        this.payRequestModel.TenderNumber =  null;
        this.payRequestModel.Date = null;
        this.payRequestModel.pagination = this.paginationModel;
        this.payRequestModel.pagination.pageSize="10"
        this.service.GetPayDetailList(this.payRequestModel)
          .subscribe(baseResponse => {
            if (baseResponse != null) {
              if (baseResponse.success != null) {
                if (baseResponse.success) {
                     this.isSelected=true;
                      let data = (baseResponse.data as any) ;
                      this.Records =data?.records
                      this.totalRecords =   this.Records.length;
                      this.totalPages = data?.totalPages;
                      this.pages = new Array();
                      for (let i = 1; i <= this.totalPages; i++) {
                        this.pages.push(i);
                      }
                }
              }
            }
          })   
  }


  changePage(pageNumber){
    this.paginationModel.pageNumber = pageNumber;
    this.PageNo=Number(pageNumber);
    this.PN=this.PageNo;
    if(this.payRequestModel.Type==listType.Grievance){
      this.Records=[]
      this.GetPayDetailList();
    }else if(this.payRequestModel.Type==listType.GrievanceByID){
      this.onSelectedValue()
      this.Records=[]
    }
    
  }

  nextPage() {
    if (Number(this.paginationModel.pageNumber) < this.totalPages) {
        this.changePage(Number(this.paginationModel.pageNumber) + 1);
      }
  }

  previousPage() {
    if (Number(this.paginationModel.pageNumber) > 1) {
      this.changePage(Number(this.paginationModel.pageNumber) - 1);
    }
  }

  selectedPage()
  {
    if(this.PN==null || this.PN==0){
      this.PN=1
    }
    this.paginationModel.pageNumber =String(this.PN);
    this.PageNo=this.PN; 
    if(this.payRequestModel.Type==listType.Grievance){
      this.GetPayDetailList();
    }else if(this.payRequestModel.Type==listType.GrievanceByID){
      this.onSelectedValue()
    }
  }

  onEnterKeyPressed(event: KeyboardEvent): void {
    // Check if the key pressed is Enter
    
    if (event.key === 'Enter') {
      // Call your function here
      this.selectedPage()
    }
  }
  getAttachmentByID(Id: any,Guid:any) {
    if(Id == 0 || Id==null){
      return;
    }
    this.downloadFile.ID = Id;
    this.downloadFile.idsList = Guid;

    this.service.DownloadFileByGuid(this.downloadFile).subscribe(baseResponse => {
      if (baseResponse.success && baseResponse.data != null) {
        var Bytes = baseResponse.data['bytes'];
        var ContentType = baseResponse.data['contentType'];
        var FileName = baseResponse.data['fileName'];

        const blob = b64toBlob(Bytes, ContentType);
        saveAs(blob, FileName);
      }
    });
  }
  sort(orderByColumnName, orderBy){
    this.paginationModel.orderBy = orderBy;
    this.payRequestModel.pagination.orderByColumnName = orderByColumnName;
    this.paginationModel.orderByColumnName = orderByColumnName
    if(this.payRequestModel.Type==listType.Grievance){
      this.GetPayDetailList();
    }else if(this.payRequestModel.Type==listType.GrievanceByID){
      this.onSelectedValue()
    }
  }
  clear(){
    location.reload()
    // this.GetPayDropDown();
    // this.GetPayDetailList();
    // this.onSelectedValueSearch();
  }
}
