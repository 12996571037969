<div class="flex">
 <button pButton class="btn btn-sm btn-outline-primary" *ngIf="!fromEContract" (click)="downloadReport()"><i class="fas fa-download mr-1"></i>Download Report</button>
</div>
<main id="pdfContent" class="mt-6 my-5">
   <div class=" mt-2 my-5">
    <h4 style="text-align: center; text-decoration: underline;"><strong>PERFORMA FOR EVALUATION OF REPORT TENDER NO: {{reportRecord?.tenderNo}}</strong></h4>
   </div>
    <table aria-describedby="firstTable">
        <tr>
            <!---th scope="col" rowspan="2">Sr No.</th>
            <th scope="col" rowspan="2">Tender No</th--->
            <th scope="col" rowspan="2">Allocation of funds</th>
            <th scope="col" rowspan="2">Date of Advertisement of tender or letter inviting bids from prequalified bidder</th>
            <th scope="col" rowspan="2">No. of Tender Document Sold</th>
            <th scope="col" rowspan="2">No. of tender submitted</th>
            <th scope="col" rowspan="2">Closing date of tender</th>
            <th scope="col" rowspan="2">Opening date of tender</th>
            <th scope="col" rowspan="2">Details/No. of bidder participated in bidding </th>
            <th scope="col" colspan="2">Name of the qualified bidder and disqualification</th>
            <th scope="col" rowspan="2">Reasons for disqualification</th>
            <th scope="col" rowspan="2">Financial bid opening date</th>
            <th scope="col" rowspan="2">Seniority according to evaluation</th>
        </tr>
        <tr>
            <th scope="col">Qualified</th>
            <th scope="col">Disqualified</th>
        </tr>
            <tbody>
                <tr>
                    <td><strong>1</strong></td>
                    <td><strong>2</strong></td>
                    <td><strong>3</strong></td>
                    <td><strong>4</strong></td>
                    <td><strong>5</strong></td>
                    <td><strong>6</strong></td>
                    <td><strong>7</strong></td>
                    <td><strong>8</strong></td>
                    <td><strong>9</strong></td>
                    <td><strong>10</strong></td>
                    <td><strong>11</strong></td>
                    <td><strong>12</strong></td>
                    <!-- <td><strong>13</strong></td>
                    <td><strong>13</strong></td> -->
                </tr>
                    <tr>
                        <!-- <td></td>
                        <td></td> -->
                        <td > {{reportRecord?.contractAndPrice | number}}</td>
                        <td >{{reportRecord?.dateOfAdvertisement}}</td>
                        <td >{{reportRecord?.documentSold}}</td>
                        <td >{{reportRecord?.bidsReceived}}</td>
                        <td >{{reportRecord?.closingDate}}</td>
                        <td >{{reportRecord?.bidOpeningDate}}</td>
                        <td >{{reportRecord?.bidsReceived}}</td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                        <td >{{reportRecord?.financialOpenDate}}</td>
                        <td ></td>


                    </tr>
                    <tr *ngFor="let item of data">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><strong>{{item?.quallified}}</strong></td>
                        <td><strong>{{item?.disqaulified}}</strong></td>
                        <td>{{item?.disqualifyRemakrs}}</td>
                        <td></td>
                        <td>{{item?.position}}</td>
                    </tr>
            </tbody>
    </table>
</main>
