<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>Blacklisted Supplier</h1>
	</div>
</section>
<section class="container">
	<div class="row">
		<table class="table table-striped table-dark " *ngIf="blackListedSuppliers?.length > 0; else elseBlock">
            <thead class="table-success">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Mobile No</th>
              
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let supplier of blackListedSuppliers">
                <th scope="row">{{supplier?.supplierBusinessName}}</th>
                <td>{{supplier?.email}}</td>
                <td>{{supplier?.mobileNumber}}</td>
              
              </tr>
              
            </tbody>
          </table>
          <ng-template #elseBlock><h4>No Record Found</h4></ng-template>
          
	</div>
</section>
<div class="clear"></div>