import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './modules/home/home/home.component';

const routes: Routes = [
  {
    path: '',
    component:AppComponent,
  },
  {
    path: 'home',
    loadChildren: () =>
      import('../app/modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'contactus',
    loadChildren: () =>
      import('../app/modules/shared/shared.module').then((m) => m.SharedModule),
  },
  {
    path: 'tenders',
    loadChildren: () =>
      import('../app/modules/tenders/tenders.module').then((m) => m.TendersModule),
  },
  {
    path: 'other',
    loadChildren: () =>
      import('../app/modules/others/others.module').then((m) => m.OthersModule),
  },
  {
    path: 'documents',
    loadChildren: () =>
      import('../app/modules/documents/documents.module').then((m) => m.DocumentsModule),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('../app/modules/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'app-dashboard',
    loadChildren: () =>
      import('../app/modules/app-dashboard/app-dashboard.module').then((m) => m.AppDashboardModule),
  },
  
];


@NgModule({
  imports: [
      RouterModule.forRoot(routes, { onSameUrlNavigation: "reload",useHash: true, scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabled',
      relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}