import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { BlacklististedSupplierComponent } from './blacklististed-supplier/blacklististed-supplier.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { DashboardComponent } from './dashboard/dashboard.component';



@NgModule({
  declarations: [
    
    HomeComponent,
         BlacklististedSupplierComponent,
         HighlightsComponent,
         DashboardComponent
  ],
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'blacklisted',
        component: BlacklististedSupplierComponent,
      },
    ]),
    CommonModule
  ]
})
export class HomeModule { }
