<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>EPADS Evaluation Results</h1>
	</div>
</section>
<div class="clear py-3"></div>
<section class="container">
    <h3 class="pb-2">Announcement of Evaluation Reports as PP Rule-35</h3>
    <a href="./assets/docs/tecelv.pdf" target="_blank" class="btn btn-rounded btn-outline-primary mr-3"><i class="far fa-file-pdf mr-1"></i> Technical Evaluation Report Sample</a> 
    <a href="./assets/docs/elv.pdf" target="_blank" class="btn btn-rounded btn-outline-secondary"><i class="far fa-file-pdf mr-1"></i> Final Evaluation Report Sample</a>
    <div class="clear py-2"></div>

    <div class="row align-items-center">
        <div class="form-group col-lg-3">
            <label for="tenderno">Evaluation No</label>
            <input type="text" [(ngModel)]="searchFilter.tenderNo" [value]="searchFilter.tenderNo" class="form-control" id="tenderno" placeholder="">
        </div>
        <div class="form-group col-lg-3">
            <label for="keyword">Evaluation Title</label>
            <input type="text" [(ngModel)]="searchFilter.keywords" [value]="searchFilter.keywords" class="form-control" id="keyword" placeholder="">
        </div> 
        <div class="form-group col-lg-3">
            <label for="keyword">Department Name</label>
            <app-search-dropdown [items]="departmentNames" [size]="'small'" [img]="'product'" [label]="'departmentName'" [uid]="'id'"
            [(ngModel)]="departmentNameValue">
            </app-search-dropdown>
        </div>
        <div class=" col-lg-3 mt-2">
            <button (click)="search()" class="btn btn-success mr-2">Search</button>
            <button (click)="clear()" class="btn btn-secondary">Clear</button>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table border table-striped">
            <thead class="table-success">
                <th class="text-primary text-nowrap">
                    <div class="sortTh text-nowrap">
                        Evaluation No
                        <span class="sorted_Icons">
                            <a (click)="sort('tenderNumbers','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                            <a (click)="sort('tenderNumbers','asc')"><img src="../assets/images/arrow_down.gif"></a>
                        </span>
                    </div>
                </th>
                <th class="text-primary text-nowrap">
                    <div class="sortTh text-nowrap">
                        Evaluation Title
                        <span class="sorted_Icons">
                            <a (click)="sort('name','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                            <a (click)="sort('name','asc')"><img src="../assets/images/arrow_down.gif"></a>
                        </span>
                    </div>
                </th>
                <th>
                    <div class="sortTh">
                        Department Name
                        <span class="sorted_Icons">
                            <a (click)="sort('departmentName','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                            <a (click)="sort('departmentName','asc')"><img src="../assets/images/arrow_down.gif"></a>
                        </span>
                    </div>
                </th>
                <th class="text-primary text-center">Download</th>
                <th class="text-primary text-nowrap">
                    <div class="sortTh text-nowrap">
                        Uploaded Date
                        <span class="sorted_Icons">
                            <a (click)="sort('uploadedDate','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                            <a (click)="sort('uploadedDate','asc')"><img src="../assets/images/arrow_down.gif"></a>
                        </span>
                    </div>
                </th>
            </thead>
            <tbody>
                <tr *ngFor="let tenders of publicTenders">
                    <th class="text-nowrap">{{tenders?.tenderNumbers}}</th>
                    <th class="font-weight-medium text-dark">{{tenders?.name}}</th>
                    <th class="font-weight-medium text-dark">{{tenders?.departmentName}}</th>
                    <td class="text-center"><a (click)="getAttachmentByGUID(tenders?.documentId,tenders?.documentGUID)" class="fas fa-file-download fa-fw fa-lg text-success"></a></td>
                    <td>{{tenders?.uploadedDate | date}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="clear clearfix py-1"></div>
        <!-- <p class="font-weight-bold">Total Active Tenders ({{totalRecords}})</p>
        <p class="arial">
            <small class="font_12">10 Records Per Page</small><br>
            <a (click)="changePage(pageNumber)"  *ngFor="let pageNumber of pages"    [ngClass]="{'active': pageNumber==this.paginationModel.pageNumber}"  class="font-weight-bold" >{{pageNumber}}&nbsp;</a> 
          
        </p> -->
        <small class="font_12">10 Records Per Page</small> 
        <small class="font_12"> | Total Pages<strong>  {{this.totalPages}}</strong></small> 
        <nav aria-label="..." class="arial-pagination">
            <div class="d-flex">
                <ul class="pagination justify-content-center mr-4">
                    <li class="page-item" [class.disabled]="this.PageNo === 1">
                        <a class="page-link" (click)="previousPage()">Previous</a>
                    </li>
                    <li class="page-item" *ngFor="let pageNumber of pages">
                        <a class="page-link" (click)="changePage(pageNumber)" [class.active]="pageNumber === this.PageNo">{{pageNumber}}</a> 
                    </li>
                    <li class="page-item" [class.disabled]="this.PageNo=== this.totalPages">
                        <a class="page-link" (click)="nextPage()">Next</a>
                    </li>
                </ul>
                <div class="input-group input-group-sm">
                    <input type="number" class="form-control" placeholder="Page No." (keyup)="onEnterKeyPressed($event)" [(ngModel)]="this.PN" aria-label="Enter your text" aria-describedby="button-addon">
                    <div class="input-group-append input-group-sm">
                      <button class="btn btn-outline-secondary" (click)="selectedPage()" type="button" id="button-addon">Go</button>
                    </div>
                </div>
            </div>
        </nav> 
</section>
<div class="clear py-3"></div>