import { Component, OnInit } from '@angular/core';
import { listType } from 'src/app/core/enumerations/enum-ListType';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { payRequestModel } from 'src/app/core/models/payRequestModel';
import { ActiveBlackListedRequestModel } from 'src/app/core/models/active-black-listed-request-model.model';
@Component({
  selector: 'app-active-black-listed-firms',
  templateUrl: './active-black-listed-firms.component.html',
  styleUrls: ['./active-black-listed-firms.component.scss']
})
export class ActiveBlackListedFirmsComponent implements OnInit {

  payRequestModel: ActiveBlackListedRequestModel;
  paginationModel: PaginationModel;
  // dataForm: FormGroup;
  // dataModel: ActiveTendersSearchRequestModel;
  loggedInUser: any;
  selectedSort: any;
  Records: any;
  totalRecords: any;
  totalPages: number;
  pages = new Array();
  PageNo: number = 0;
  PN: number;
  ID: number = 0;
  Sector: any;
  DropdownRecords: any
  selectedValue: any
  isSelected: boolean;
  selectedStateID: any;
  ClosingDate: any;
  TenderNumber: any;

  constructor(public userUtilsService:UserUtilService, private service: PublicPortalService,) { }

  ngOnInit(): void {
    this.paginationModel = new PaginationModel();
    this.payRequestModel = new ActiveBlackListedRequestModel();
    this.PageNo = Number(this.paginationModel.pageNumber);
    this.GetPayDetailList()
  }
  GetPayDetailList() {


    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.service.Getpprablacklistsupplier(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {

              let data = (baseResponse.data as any);
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }

              this.Records = data?.records;
              // this.clear()

            }
          }
        }
      })
  }
  changePage(pageNumber) {

    this.paginationModel.pageNumber = pageNumber;
    this.PageNo = Number(pageNumber);
    this.PN = this.PageNo;
    this.GetPayDetailList();
  }
  previousPage() {
    if (Number(this.paginationModel.pageNumber) > 1) {
      this.changePage(Number(this.paginationModel.pageNumber) - 1);
    }
  }
  nextPage() {
    if (Number(this.paginationModel.pageNumber) < this.totalPages) {
      this.changePage(Number(this.paginationModel.pageNumber) + 1);
    }
  }
  
  selectedPage() {
    if(this.PN==null || this.PN==0){
      this.PN=1
    }
    this.paginationModel.pageNumber = String(this.PN);
    this.PageNo = this.PN;
    this.GetPayDetailList();
  }

  onEnterKeyPressed(event: KeyboardEvent): void {
    // Check if the key pressed is Enter

    if (event.key === 'Enter') {
      // Call your function here
      this.selectedPage()
    }
  }
}
