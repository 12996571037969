import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import b64toBlob from 'b64-to-blob';
import { listType } from 'src/app/core/enumerations/enum-ListType';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { payRequestModel } from 'src/app/core/models/payRequestModel';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-evaluation-results',
  templateUrl: './evaluation-results.component.html',
  styleUrls: ['./evaluation-results.component.scss']
})
export class EvaluationResultsComponent implements OnInit {

  payRequestModel:payRequestModel;
  paginationModel: PaginationModel;
  downloadFile: downloadfile;
  loggedInUser : any;
  selectedSort:any;
  Records:any;
  totalRecords:any;
  totalPages:number;
  pages= new Array();
  PageNo:number;
  ID:number=0;
  Organization:any;
  PN:number;
  EVValue: any=null;
  OrignalRecord: any;
  isSelected: boolean;
  constructor(
    private service:PublicPortalService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.paginationModel = new PaginationModel();
    this.payRequestModel=new payRequestModel();
    this.downloadFile = new downloadfile();
    this.PageNo=Number(this.paginationModel.pageNumber);
    this.route.params.subscribe(params => {
      // Extract the ID from the route parameters
      const id = params['id'];
      // Store the ID in another variable
      this.ID = id;
    });
    this.GetPayDetailList();
  }

  GetPayDetailList(){

    this.payRequestModel.Type = listType.Evaluations;
    this.payRequestModel.ID = this.ID;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize="10"
    //this.payRequestModel.TenderNumber = this.EVValue || null;

    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
                 
                  let data = (baseResponse.data as any) ;
                  this.totalPages = data?.totalPages;
                  this.totalRecords = data?.totalRecords;
                  this.pages = new Array();
                  for (let i = 1; i <= this.totalPages; i++) {
                    this.pages.push(i);
                  }
                  
                  this.Records =data?.records;
                  this.OrignalRecord = data?.records;
                  this.Organization=data?.records[0].CategoryName;
                }
              }
        }
      })
  }
  onSelectValueSearch(){
    this.Records=[] 
    this.paginationModel = new PaginationModel();
    this.PageNo=Number(this.paginationModel.pageNumber)
    this.onSelectedValue()
  }
  onSelectedValue() {
      this.payRequestModel.Type = listType.Evaluations;
      this.payRequestModel.ID = this.ID;
      this.payRequestModel.City=null
      this.payRequestModel.TenderNumber =this.EVValue || null;
      this.payRequestModel.TenderWorth = null;
      this.payRequestModel.Sector=null
      this.payRequestModel.Organization = null
      this.payRequestModel.Date = null
      this.payRequestModel.pagination = this.paginationModel;
      this.payRequestModel.pagination.pageSize = "10"
      this.service.GetPayDetailList(this.payRequestModel)
        .subscribe(baseResponse => {
          if (baseResponse != null) {
            if (baseResponse.success != null) {
              if (baseResponse.success) {
                this.isSelected = true;
                let data = (baseResponse.data as any);
                this.Records = data?.records
                this.totalPages = data?.totalPages;
                this.totalRecords = data?.totalRecords;
                this.totalPages = data?.totalPages;
                this.pages = new Array();
                for (let i = 1; i <= this.totalPages; i++) {
                  this.pages.push(i);
                }
              }
            }
          }
        }
        )
  }
  EVNoSearch(){
    if(this.EVValue != null){
      this.Records =this.OrignalRecord.filter(x=>x.EvaluationNo==this.EVValue);
    }

  }
  EVNoClear(){
    location.reload()
  }

  changePage(pageNumber){
    
    this.paginationModel.pageNumber = pageNumber;
    this.PageNo=Number(pageNumber);
    this.PN=this.PageNo;
    this.GetPayDetailList();
  }

  nextPage() {
    if (Number(this.paginationModel.pageNumber) < this.totalPages) {
        this.changePage(Number(this.paginationModel.pageNumber) + 1);
      }
  }

  previousPage() {
    if (Number(this.paginationModel.pageNumber) > 1) {
      this.changePage(Number(this.paginationModel.pageNumber) - 1);
    }
  }

  selectedPage()
  {
    if(this.PN==null || this.PN==0){
      this.PN=1
    }
    this.paginationModel.pageNumber =String(this.PN);
    this.PageNo=this.PN;
    this.GetPayDetailList();
  }

  onEnterKeyPressed(event: KeyboardEvent): void {
    // Check if the key pressed is Enter
    
    if (event.key === 'Enter') {
      // Call your function here
      this.selectedPage()
    }
  }
  getAttachmentByID(Id: any,Guid:any) {
    if(Id == 0 || Id==null){
      return;
    }
    this.downloadFile.ID = Id;
    this.downloadFile.idsList = Guid;

    this.service.DownloadFileByGuid(this.downloadFile).subscribe(baseResponse => {
      if (baseResponse.success && baseResponse.data != null) {
        var Bytes = baseResponse.data['bytes'];
        var ContentType = baseResponse.data['contentType'];
        var FileName = baseResponse.data['fileName'];

        const blob = b64toBlob(Bytes, ContentType);
        saveAs(blob, FileName);
      }
    });
  }
  sort(orderByColumnName, orderBy){
    this.paginationModel.orderBy = orderBy;
    this.payRequestModel.pagination.orderByColumnName = orderByColumnName;
    this.paginationModel.orderByColumnName = orderByColumnName
    this.GetPayDetailList();
  }
}
