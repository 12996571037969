import { Component, OnInit } from '@angular/core';
import b64toBlob from 'b64-to-blob';
import { listType } from 'src/app/core/enumerations/enum-ListType';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { payRequestModel } from 'src/app/core/models/payRequestModel';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-paymentlist',
  templateUrl: './paymentlist.component.html',
  styleUrls: ['./paymentlist.component.scss']
})
export class PaymentlistComponent implements OnInit {

  payRequestModel: payRequestModel;
  downloadFile: downloadfile;
  paginationModel: PaginationModel;
  loggedInUser: any;
  selectedSort: any;
  Records: any;
  totalRecords: any;
  totalPages: number;
  pages = new Array();
  PageNo: number = 0;
  PN: number;
  isSelected: boolean;
  selectedValue: any;
  DropdownRecords: any;
  shown = false;
  selectedValueID: any;
  financialList: { ID: number; Name: string; }[];
  finalcialselectedValue: any;

  constructor(private service: PublicPortalService) { }

  ngOnInit(): void {
    this.financialList = [
      { ID: 1, Name: "2020-2021" },
      { ID: 2, Name: "2021-2022" },
      { ID: 3, Name: "2022-2023" },
      { ID: 4, Name: "2023-2024" },
      { ID: 5, Name: "2024-2025" },
      // Add more items as needed
    ];
    this.paginationModel = new PaginationModel();
    this.payRequestModel = new payRequestModel();
    this.downloadFile = new downloadfile();
    this.PageNo = Number(this.paginationModel.pageNumber);
    this.GetPayDropDown();
    this.GetPayDetailList();

  }
  clear() {
    location.reload()
  }
  GetPayDetailList() {
    this.payRequestModel.Type = listType.Plans;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              let data = (baseResponse.data as any);
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }

              this.Records = data?.records;


            }
          }
        }
      })
  }

  changePage(pageNumber){
    this.paginationModel.pageNumber = pageNumber;
    this.PageNo=Number(pageNumber);
    this.PN=this.PageNo;
    if(this.payRequestModel.Type==listType.Plans){
      this.Records=[]
      this.GetPayDetailList();
    }else if(this.payRequestModel.Type==listType.plansbyid){
      this.onSelectedValueSearch()
      this.Records=[]
    }
    
  }

  nextPage() {
    if (Number(this.paginationModel.pageNumber) < this.totalPages) {
      this.changePage(Number(this.paginationModel.pageNumber) + 1);
    }
  }

  previousPage() {
    if (Number(this.paginationModel.pageNumber) > 1) {
      this.changePage(Number(this.paginationModel.pageNumber) - 1);
    }
  }

  selectedPage()
  {
    if(this.PN==null || this.PN==0){
      this.PN=1
    }
    this.paginationModel.pageNumber =String(this.PN);
    this.PageNo=this.PN; 
    if(this.payRequestModel.Type==listType.Plans){
      this.GetPayDetailList();
    }else if(this.payRequestModel.Type==listType.plansbyid){
      this.onSelectedValue()
    }
  }

  onEnterKeyPressed(event: KeyboardEvent): void {
    // Check if the key pressed is Enter

    if (event.key === 'Enter') {
      // Call your function here
      this.selectedPage()
    }
  }

  getAttachmentByID(Id: any, Guid: any) {
    if (Id == 0 || Id == null) {
      return;
    }
    this.downloadFile.ID = Id;
    this.downloadFile.idsList = Guid;

    this.service.DownloadFileByGuid(this.downloadFile).subscribe(baseResponse => {
      if (baseResponse.success && baseResponse.data != null) {
        var Bytes = baseResponse.data['bytes'];
        var ContentType = baseResponse.data['contentType'];
        var FileName = baseResponse.data['fileName'];

        const blob = b64toBlob(Bytes, ContentType);
        saveAs(blob, FileName);
      }
    });
  }

  onSelectedValue() {
      this.payRequestModel.Type = listType.plansbyid;
      if (this.selectedValue != null) {
        this.selectedValueID = this.selectedValue == null ? null : this.DropdownRecords.filter(x => x.CategoryName == this.selectedValue)[0].CategoryID;
        this.payRequestModel.ID = this.selectedValueID
      }
      else {
        this.payRequestModel.ID = 0
      }
      this.payRequestModel.City =  null;
      this.payRequestModel.TenderNumber =   null;
      this.payRequestModel.TenderNumber =  this.finalcialselectedValue || null;
      this.payRequestModel.Date = null;
      this.payRequestModel.pagination = this.paginationModel;
      this.payRequestModel.pagination.pageSize = "10"
      this.service.GetPayDetailList(this.payRequestModel)
        .subscribe(baseResponse => {
          if (baseResponse != null) {
            if (baseResponse.success != null) {
              if (baseResponse.success) {
                this.isSelected = true;
                let data = (baseResponse.data as any);
                this.Records = data?.records
                
                this.totalRecords = data?.totalRecords;
                this.totalPages = data?.totalPages;
                this.pages = new Array();
                for (let i = 1; i <= this.totalPages; i++) {
                  this.pages.push(i);
                }
              }
            }
          }
        })
    }
  onSelectedValueSearch() {
    //this.paginationModel = new PaginationModel()
    this.PageNo = Number(this.paginationModel.pageNumber);
    this.PN = null
      this.payRequestModel.Type = listType.plansbyid;
      if (this.selectedValue != null) {
        this.selectedValueID = this.selectedValue == null ? null : this.DropdownRecords.filter(x => x.CategoryName == this.selectedValue)[0].CategoryID;
        this.payRequestModel.ID = this.selectedValueID
      }
      else {
        this.payRequestModel.ID = 0
      }
      this.payRequestModel.City =  null;
      this.payRequestModel.TenderNumber =  this.finalcialselectedValue || null;
      this.payRequestModel.Date = null;
      this.payRequestModel.pagination = this.paginationModel;
      this.payRequestModel.pagination.pageSize = "10"
      this.service.GetPayDetailList(this.payRequestModel)
        .subscribe(baseResponse => {
          if (baseResponse != null) {
            if (baseResponse.success != null) {
              if (baseResponse.success) {
                this.isSelected = true;
                let data = (baseResponse.data as any);
                this.Records = data?.records
                
                this.totalRecords = data?.totalRecords;
                this.totalPages = data?.totalPages;
                this.pages = new Array();
                for (let i = 1; i <= this.totalPages; i++) {
                  this.pages.push(i);
                }
              }
            }
          }
        })
  }
  GetPayDropDown() {
    this.payRequestModel.Type = listType.PlansDropDown;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "5000"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {

              let data = (baseResponse.data as any);
              this.DropdownRecords = data?.records;
            }
          }
        }
      })
  }
  sort(orderByColumnName, orderBy) {
    this.paginationModel.orderBy = orderBy;
    this.payRequestModel.pagination.orderByColumnName = orderByColumnName;
    this.paginationModel.orderByColumnName = orderByColumnName
    if(this.payRequestModel.Type==listType.Plans){
      this.GetPayDetailList();
    }else if(this.payRequestModel.Type==listType.plansbyid){
      this.onSelectedValue()
    }
  }
}
