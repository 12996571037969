<div class="clear"></div>
<section class="container">
	<div class="row">
		<div class="col-md-4">
			<div class="bg-light h-100 p-4">
				<h5 class="d-flex justify-content-between align-items-center">
					<strong class="font-weight-medium">
						Tender Search Filters <i class="fas fa-filter text-info ml-1"></i>
					</strong>
				</h5>
				<div class="clear p-1"></div>
				<div>
					<div class="form-group">
						<label for="keyword">Procurement Name</label>
						<input type="text" [(ngModel)]="searchFilter.keywords" [value]="searchFilter.keywords" class="form-control" id="keyword" placeholder="">
					</div>
					<div class="form-group">
						<label for="keyword">Department Name</label> 
						<!-- <input type="text" [(ngModel)]="searchFilter.departmentName" [value]="searchFilter.departmentName" class="form-control" id="keyword" placeholder=""> -->
						<app-search-dropdown [items]="departmentNames" [size]="'small'" [img]="'product'" [label]="'departmentName'" [uid]="'id'"
                		[(ngModel)]="departmentNameValue">
            			</app-search-dropdown>
					</div>
					<div class="form-group">
						<label for="sort">Procurement Status</label>
						<app-search-dropdown [items]="activityStatus" [size]="'small'" [img]="'product'" [label]="'name'" [uid]="'id'"
                		[(ngModel)]="activityStatusValue">
            			</app-search-dropdown>
					</div>
					<div class="form-group">
						<label for="tenderno">Tender No</label>
						<input type="text" [(ngModel)]="searchFilter.tenderNo" [value]="searchFilter.tenderNo" class="form-control" id="tenderno" placeholder="">
					</div>
					<div class="form-group">
						<label for="datstart">Published Date</label>
						<input type="date" [(ngModel)]="searchFilter.dateOfAdvertisement" [value]="searchFilter.dateOfAdvertisement" class="form-control" id="datstart" placeholder="">
					</div>
					<div class="form-group">	
						<label for="datend">Closing Date</label>
						<input type="date" [(ngModel)]="searchFilter.closingDate" [value]="searchFilter.closingDate" class="form-control" id="datend" placeholder="">
					</div>
					<div class="form-group">
						<label for="name">Tender Worth</label>
						<app-search-dropdown [items]="WorthRecord" [size]="'small'" [img]="'product'" [label]="'name'" [uid]="'id'"
                		[(ngModel)]="selectedWorthValue">
            			</app-search-dropdown>
						<!-- <select class="form-control" [(ngModel)]="searchFilter.selectedWorth" placeholder="Select" >
							<option *ngFor="let record of WorthRecord" [value]="record?.id">{{record?.name}}</option>
						</select> -->
					</div>
					<div class="d-flex justify-content-between">
						<button (click)="search()" class="btn btn-success">Search</button>
						<button (click)="reset()" class="btn btn-secondary">Clear</button>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-8 py-4">
			<div class="d-flex align-items-center justify-content-between">
				<h5 class="font-weight-medium mb-0">Public Tenders</h5>
				<div class="form-inline d-flex justify-content-between">
					<div class="form-group">
						<label for="sort">Sort By:</label>
						<select (change)="sortChange()" [(ngModel)]="searchFilter.sortOrder" class="form-control form-control-sm ml-2">
							<!-- <option [selected]="true" [value]="'Latest'">Latest</option> -->
							<option [value]="'ClosingDate'">Closing Date</option>
							<option [value]="'PublishedDate'">Published Date</option>
						</select>
					</div>
				</div> 
			</div>
			<div class="text-lg-right font-weight-bold">
			Total Records:	({{TotalRecords}})
			</div>
			<!-- <div class="text-lg-left text-danger font-weight-bold">
			Note:	{{SortText}}
			</div> -->
			<div class="clear p-3"></div>
			<section *ngFor="let tenders of publicTenders" class="card">
				<div class="card-body">
					<span [ngClass]="getStatusClassPIPE(tenders?.statusName)"></span>
					<div class="row d-flex align-items-center">
						<div class="col-lg-5 mb-2">
							<h6 class="font-weight-medium text-secondary mt-3">{{tenders?.tenderNumbers}}</h6>
							<h6 class="mb-3">{{tenders?.name}}</h6>
							<h6>Department Name: <strong class="font-weight-semi-bold">{{tenders?.departmentName}}</strong></h6><br>
							Status: <strong [ngClass]="getStatusClass(tenders?.statusName)">{{tenders?.statusName}}</strong><br>
							Location: <strong class="text-dark">{{tenders?.location}}</strong>
						</div>
						<div class="col-lg-7 mb-2 text-lg-right">							
							<span class="d-inline-block mr-2">Published Date: <strong class="font-weight-semi-bold">{{tenders?.publishDate | date : 'MMM d, y, h:mm a'}}</strong></span><br>
							<span class="d-inline-block mr-2">Closing Date: <strong class="font-weight-semi-bold">{{tenders?.lastSubmissionDate | date : 'MMM d, y, h:mm a'}} </strong></span><br>
							<span class="d-inline-block mr-2">Bid Opening Date : <strong class="font-weight-semi-bold">{{tenders?.bidOpeningDate | date : 'MMM d, y, h:mm a' }}</strong></span><br>
							<span class="d-inline-block mr-2">Validity Date: <strong class="font-weight-semi-bold">{{tenders?.bidValidityDate  | date }}</strong></span><br>
							</div>
						<div class="col-lg-12">
							<a  (click)="downloadDocuments('',tenders?. publishedDocumentID)" data-toggle="modal" data-target="#downloadModal" class="btn btn-rounded alert-info m-1 btn-sm shadow-none font-weight-medium py-2 px-3"><i class="fas fa-download mr-1"></i> View/Download</a>
							<!-- <a (click)="downloadDocumentsESubmission('',tenders?. publishedDocumentID)" data-toggle="modal" data-target="#downloadModalESubmission" class="btn btn-rounded alert-info m-1 btn-sm shadow-none font-weight-medium py-2 px-3"><i class="fas fa-download mr-1"></i> Download SBD/SPD2</a> -->
							<a (click)="getPublications('bottom-right',tenders,tenders.publishedDocumentID)"  data-toggle="modal" data-target="#publicationModal" class="btn btn-rounded alert-primary m-1 btn-sm shadow-none font-weight-medium py-2 px-3"><i class="far fa-file-alt mr-1"></i> PA Published Documents</a>
							<a *ngIf="tenders?.voilation!=null" (click)="getViolations(tenders.procurementPlansDetailID)"  data-toggle="modal" data-target="#publicationModal" class="btn btn-rounded alert-primary m-1 btn-sm shadow-none font-weight-medium py-2 px-3"><i class="far fa-file-alt mr-1"></i> Infirmities/Violations</a>
							<!--a (click)="getAllOCDC(tenders.procurementPlansDetailID,tenders.publishedDocumentID)" data-toggle="modal" data-target="#ocdcModal" class="btn btn-rounded alert-primary m-1 btn-sm shadow-none font-weight-medium py-2 px-3"><i class="far fa-file-alt mr-1"></i> OCDS</a--->
						</div>
					</div>
				</div>
			</section>
			<div *ngIf="publicTenders?.length < 1 && viewMoreLength">
				<h4 class="text-danger">No Record Found</h4>
			</div>			
			<!-- <button *ngIf="publicTenders?.length > 0 && viewMoreLength" type="button" (click)="viewMore()" class="btn btn-success btn-200">View More</button> -->
			<small class="font_12">10 Records Per Page</small> 
			<small class="font_12"> | Total Pages<strong>  {{this.totalPages}}</strong></small> 
			<nav aria-label="..." class="arial-pagination">
				<ul class="pagination justify-content-center m-0">
					<li class="page-item" [class.disabled]="this.PageNo === 1">
						<a class="page-link" (click)="previousPage()">Previous</a>
					</li>
					<li class="page-item" *ngFor="let pageNumber of pages">
						<a class="page-link" (click)="changePage(pageNumber)" [class.active]="pageNumber === this.PageNo">{{pageNumber}}</a> 
					</li>
					<li class="page-item" [class.disabled]="this.PageNo=== this.totalPages">
						<a class="page-link" (click)="nextPage()">Next</a>
					</li>
				</ul>
				<div class="input-group input-group-sm ml-3 w-25">
					<input type="number" class="form-control" placeholder="Page No."  (keyup)="onEnterKeyPressed($event)"  [(ngModel)]="this.PN" aria-label="Enter your text" aria-describedby="button-addon">
					<div class="input-group-append input-group-sm">
					  <button class="btn btn-outline-secondary"  (click)="selectedPage()" type="button" id="button-addon">Go</button>
					</div>
				</div>
			</nav>  
		</div>
	</div>
</section>
<div class="clear"></div>
<div class="modal fade" id="downloadModal" tabindex="-1" role="dialog" aria-labelledby="downloadModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">View/Download Tender</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="table-responsive">
					<table class="table table-striped border">
						<thead class="table-success">
							<tr>
								<th scope="col">Document Name</th>
								<th scope="col">Action</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of documentsDetails">
								<td>{{item?.documentTemplateName}}</td>
								<td *ngIf="item?.bidSubmissionType==0" ><a (click)="getAttachmentByID(item?.dmS_FileID,item?.dmS_FileGUID)" class="btn btn-sm btn-outline-primary btn-rounded"><i class="fas fa-download mr-1"></i> Download</a></td>
								<td *ngIf="item?.bidSubmissionType==1"><a data-dismiss="modal" (click)="downloadDocumentsESubmission(item?.dmS_FileID,item?.dmS_FileGUID)" data-toggle="modal" data-target="#downloadModalESubmission" class="btn btn-sm btn-outline-primary btn-rounded"><i class="fas fa-eye mr-1"></i>  View</a></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-outline-danger py-2" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<div class="modal fade" id="publicationModal" tabindex="-1" role="dialog" aria-labelledby="publicationModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">PA Published Documents</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="table-responsive">
					<table class="table table-striped border">
						<thead class="table-success">
							<tr>
								<th scope="col">Sr. No</th>
								<th scope="col">Publication Name</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of publicationDetails; let i = index">
								<td>{{ i + 1 }}</td>
								<td>{{ item?.documentTemplateName }}</td>
								<td *ngIf="item?.bidSubmissionType==1 && (item?.meetingNotes!='Upload and Publish Contract' && item?.meetingNotes!='Upload and Publish Final Evaluation Report' && item?.meetingNotes!='Upload and Publish Technical Evaluation Report')"><a (click)="getAttachmentByID(item?.dmS_FileID,item?.dmS_FileGUID)" class="btn btn-sm btn-outline-primary"><i class="fas fa-download mr-1"></i> Download</a></td>
								<td *ngIf="item?.bidSubmissionType==1 && (item?.meetingNotes=='Upload and Publish Contract')"><a (click)="downloadPAPublishedDocumentsESubmission(item?.publishedDocumentID,item?.supplierID,'Award of Contract- Contract',item?.noOfLot)" data-toggle="modal" data-target="#downloadModalPAPublishedESubmissionContarct" class="btn btn-sm btn-outline-primary"><i class="fas fa-eye mr-1"></i> View</a></td>
								<td *ngIf="item?.bidSubmissionType==1 && (item?.meetingNotes=='Upload and Publish Final Evaluation Report')"><a (click)="downloadPAPublishedDocumentsESubmission(item?.publishedDocumentID,item?.supplierID,'Final Evaluation- Final Evaluation Report',item?.noOfLot)" data-toggle="modal" data-target="#downloadModalPAPublishedESubmissionFinancial" class="btn btn-sm btn-outline-primary"><i class="fas fa-eye mr-1"></i> View</a></td>
								<td *ngIf="item?.bidSubmissionType==1 && (item?.meetingNotes=='Upload and Publish Technical Evaluation Report')"><a (click)="downloadPAPublishedDocumentsESubmission(item?.publishedDocumentID,item?.supplierID,'Technical Evaluation- Technical Evaluation Report',item?.noOfLot)" data-toggle="modal" data-target="#downloadModalPAPublishedESubmissionFinancial" class="btn btn-sm btn-outline-primary"><i class="fas fa-eye mr-1"></i> View</a></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-outline-danger py-2" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<div class="modal fade" id="ocdcModal" tabindex="-1" role="dialog" aria-labelledby="ocdcModalModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header">
				
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="table-responsive">
					<table class="table table-striped border">
						<tbody>
							<tr *ngFor="let item of OCDC; let i = index">
								<td>{{ item | json }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-outline-danger py-2" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="downloadModalESubmission" tabindex="-1" role="dialog" aria-labelledby="downloadModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">View Tender</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<app-standard-bidding-document [documentTemplateID]="tR_DocumentTemplateID" [procurementPlanDetailID]="procurementPlanDetailID" [publishedDocument]="publishedDocumentID" [wholePkg]="wholePkg" [lotNo]="lotNo" *ngIf="VeiwReport"></app-standard-bidding-document>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-outline-danger py-2" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="downloadModalPAPublishedESubmissionContarct" tabindex="-1" role="dialog" aria-labelledby="downloadModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">{{ReportHeader}}</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<!-- <app-evaluationExportPDF [FinalEvaluation]="finalEvaluation" [viewAble]="true" [publishedDocumentID]="publishedDocumentID" [supplierID]="supplierID" [fromSupplier]="true" *ngIf="VeiwReportPAPublished" [lotNo]="lotNo"></app-evaluationExportPDF> -->
				<app-contract-award-performa2 [publishedDocumentID]="publishedDocumentID" [viewAble]="true" [supplierID]="supplierID" *ngIf="eContractViewPDF" [fromSupplier]="true" [lotNo]="lotNo"></app-contract-award-performa2>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-outline-danger py-2" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<div *ngIf="VeiwReportPAPublished" class="modal fade" id="downloadModalPAPublishedESubmissionFinancial" tabindex="-1" role="dialog" aria-labelledby="downloadModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">{{ReportHeader}}</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<app-evaluationExportPDF [FinalEvaluation]="finalEvaluation" [viewAble]="true" [publishedDocumentID]="publishedDocumentID" [supplierID]="supplierID" [fromSupplier]="true" *ngIf="VeiwReportPAPublished" [lotNo]="lotNo"></app-evaluationExportPDF>
				<!-- <app-contract-award-performa2 [publishedDocumentID]="publishedDocumentID" [viewAble]="true" [supplierID]="supplierID" *ngIf="eContractViewPDF" [fromSupplier]="true" [lotNo]="lotNo"></app-contract-award-performa2> -->
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-outline-danger py-2" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>

