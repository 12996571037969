<app-header *ngIf="!userUtil.isPPRAPage"></app-header>
<app-navigation-bar *ngIf="!userUtil.isPPRAPage"></app-navigation-bar>
<ngx-spinner style="z-index:10000;" bdColor="rgba(0, 0, 0, 0.8)" color="#28a745" size="medium"
    type="ball-clip-rotate-pulse" [fullScreen]="true">
    <p style="color: #28a745"> Please wait... </p>
    </ngx-spinner>
<div class="clear"></div>
<router-outlet></router-outlet>
<div class="clear"></div>
<app-footer *ngIf="!userUtil.isPPRAPage"></app-footer>


