export interface Environment {
    production: boolean;
    im: boolean;
    //isBackendAPI: boolean;
    loignResponseKey: string;
    tokenResponseKey: string;
    Server:string;
    ApiUrl:string;
    loginSecretKey: string;
    authorization: { secret: string, resetPasswordSecret: string };
    imHostUrl: string;
    microServices: { name: string, hostUrl: string, localUrl?:string, apiVersion: string, contextVersion: string}[];
    getAPIPath(microServiceName: string, controller: string, method: string, service: string, version?: string): string;
}

export enum PPRAEnvironment {
    Federal = 1,
    Punjab = 2,
    KPK = 3,
    Sindh = 4,
    AJK = 5,
    GB = 6,
    Balochistan = 7
  }
  
