<section class="ppra-body">
    <div class="fix-header">
        <div class="date-time">{{currentDate | date}}</div>
        <img src="../assets/images/ppra-header.jpg">
    </div>
    <div class="wrraper">
        <h1 class="page-title">PPRA Plans</h1>
        <div class="clear clearfix py-1"></div>
        <!-- <p class="font-weight-bold">Organization Name: &nbsp; <em>1 Engineer Battalion</em></p> -->
        
        <table>
            <thead class="table-success">
                <th class="text-primary text-nowrap">Ministry Name</th>
                <th class="text-primary text-nowrap">Office Name</th>
                <th class="text-primary text-nowrap">Plan Name</th>
                <th class="text-primary text-nowrap">Year</th>
            </thead>
            <tbody>
                <tr *ngFor="let plans of organisationPlans">
                   
                    <td>
                        {{plans?.MainMinistryName}}
                    </td>
                    <td>
                        {{plans?.MainOfficeName}}
                    </td>
                    <td>
                        {{plans?.Title}}
                    </td>
                    <td>
                        {{plans?.Years}}
                    </td>
                  
                </tr>
              
            </tbody>
        </table>
        <div class="clear clearfix py-1"></div>
        <p class="font-weight-bold">Total Result ({{totalRecords}})</p>
        <p class="arial">
            <small class="font_12">10 Records Per Page</small><br>
            <a (click)="changePage(pageNumber)"  *ngFor="let pageNumber of pages"    [ngClass]="{'active': pageNumber==this.paginationModel.pageNumber}"  class="font-weight-bold" >{{pageNumber}}&nbsp;</a> 
            <!-- <a href="#" class="font-weight-bold">></a> -->
        </p>
        <div class="ppra-footer">
            <p>Send mail to <a href="mailto:info@ppra.org.pk">info@ppra.org.pk</a> with questions or comments about this web site.</p>
            <p>Copyright Â© 2004-2019 Public Procurement Regulatory Authority</p>
            <img src="../assets/images/line.gif">
        </div>
    </div>
</section>