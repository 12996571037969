<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>Active Tenders</h1>
	</div>
</section>
<div class="clear p-4"></div>
<div class="container">
    <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-semi-bold mb-0">Sectors Active Tenders</h3>
        <div>
            <label class="mr-3">View By:</label>
            <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">            
                <a href="#/tenders/activetenders" class="btn btn-link">All</a>
                <a href="#tenders/organizationwisetenders" class="btn btn-link">Organizations</a>
                <a href="#tenders/sectorwisetenders" class="btn btn-link">Sectors</a>
            </div>
        </div>
    </div>
    <h6 class="mb-0 mb-3" style="font-style: italic; color: red;">Only 10 days old tenders from the closing date will be shown.</h6>
   
    <div class="row align-items-center">
        <label class="col-md-2" for="OrganizationName">Sector Name:</label>
        <div class="col-md-4">
            <!-- <select class="form-control" id="OrganizationName" [(ngModel)]="selectedValue">
                <option  *ngFor="let record of DropdownRecords"  [value]="record?.SectorName">{{record?.SectorName}}</option>
            </select> -->
            <app-search-dropdown [items]="DropdownRecords" [size]="'small'" [img]="'product'" [label]="'SectorName'" [uid]="'SectorName'"
            [(ngModel)]="selectedValue">
        </app-search-dropdown>
        </div>
        <div class="col">
           <button type="submit" (click)="onSelectedValue()" class="btn btn-primary mb-2">Search</button>
           <button  type="Clear"  (click)="GetPayDetailList()" class="btn btn-primary mb-2 ml-2">Clear</button>
        </div>
    </div>
    <div class="clear p-2"></div>
    <p class="font-weight-bold text-right">Total Records ({{totalRecords}})</p>
    <div class="table-responsive">
        <table class="table border">
            <thead class="table-success">
                <tr>
                    <th scope="col">Sr NO.</th>
                    <th scope="col">Sector Name<a (click)="sort('SectorName','desc')"><img  src="../assets/images/arrow_up.gif"></a> <a (click)="sort('SectorName','asc')"><img src="../assets/images/arrow_down.gif"></a></th>
                    <th scope="col">Total Tenders</th>
                    <!-- <th scope="col">Fiscal Year</th> -->
                    <!-- <th scope="col">URL</th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of Records; let i = index">
                    <td>
                        <strong>{{i+1}}</strong><br>
                    </td>
                    <td>
                        <a *ngIf="data?.TotalResult>0" routerLink="#" (click)="navigate(data.SectorID, data?.SectorName)" class="font-weight-semi-bold" style="color: blue; text-decoration: underline;">{{data?.SectorName}}</a>
                       <a *ngIf="data?.TotalResult==0"   class="text-black font-weight-semi-bold">{{data?.SectorName}}</a> 
                    </td>
                    <td>
                        {{data?.TotalResult}}<br>
                    </td>
                </tr>    
                <strong *ngIf="Records.length == '0'">No record found.</strong>        
            </tbody>
        </table>
        <div class="clear clearfix py-1"></div>
        <!-- <p class="font-weight-bold">Total Results ( 5 )</p> -->       
        
        <small class="font_12">10 Records Per Page</small> 
        <small class="font_12"> | Total Pages<strong>  {{this.totalPages}}</strong></small> 
        <nav aria-label="..." class="arial-pagination">
        
            <div class="container mt-5">
                <div class="row">
                    <div class="col-md-3">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" [class.disabled]="this.PageNo === 1">
                                <a class="page-link" (click)="previousPage()">Previous</a>
                            </li>
                            <li class="page-item" *ngFor="let pageNumber of pages">
                                <a class="page-link" (click)="changePage(pageNumber)" [class.active]="pageNumber === this.PageNo">{{pageNumber}}</a> 
                            </li>
                            <li class="page-item" [class.disabled]="this.PageNo=== this.totalPages">
                                <a class="page-link" (click)="nextPage()">Next</a>
                            </li>
                        </ul>
                    </div>
                  <div class="col-md-2">
                    <div class="input-group input-group-sm">
                      <input type="number" class="form-control" placeholder="Page No."  (keyup)="onEnterKeyPressed($event)"  [(ngModel)]="this.PN" aria-label="Enter your text" aria-describedby="button-addon">
                      <div class="input-group-append input-group-sm">
                        <button class="btn btn-outline-secondary"  (click)="selectedPage()" type="button" id="button-addon"><span style="color: blue; ">Go</span></button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
           
        </nav>        
    </div>
    <!-- <p class="arial-pagination">
        <a (click)="changePage(pageNumber)"  *ngFor="let pageNumber of pages"    [ngClass]="{'active': pageNumber==this.PageNo}">{{pageNumber}}&nbsp;</a> 
    </p> -->
</div>
<div class="clear p-4"></div>