import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import * as html2pdf from 'html2pdf.js'
@Component({
  selector: 'app-ordinance',
  templateUrl: './ordinance.component.html',
  styleUrls: ['./ordinance.component.scss']
})
export class OrdinanceComponent implements OnInit {
  @ViewChild('content') contentElement: ElementRef;
  contextText : string 


  searchTerm: string = '';
  constructor(public userUtilsService: UserUtilService) { }

  ngOnInit(): void {
  }
  public getPDF() {
    var element = document.getElementById('pprclause');
    html2pdf(element);
  }

  searchAndHighlight(): void {
    this.contextText = this.contentElement.nativeElement.textContent;
    //const html= content.innerHTML;
    //this.contextText = this.getTextNodes(html);
    
  }
  ClearSearch(){
    this.searchTerm = ''
  }
  

  highlightResult() {
    const contentDiv = document.getElementById('content');
    if (!contentDiv) return; // Ensure the content element exists
   
    const searchText = this.searchTerm.trim();
    if (!searchText) {
      //this.removeHighlights(contentDiv);
      return;
    }
   
    const textNodes = this.getTextNodes(contentDiv);
    const regex = new RegExp(searchText, 'gi');
   
    textNodes.forEach(node => {
      const text = node.nodeValue || '';
      const highlightedText = text.replace(regex, match => `<span class="highlight">${match}</span>`);
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = highlightedText;
   
      while (tempDiv.firstChild) {
        node.parentNode?.insertBefore(tempDiv.firstChild, node);
      }
      node.parentNode?.removeChild(node);
    });
  }
   
  getTextNodes(element: HTMLElement): Node[] {
    const walker = document.createTreeWalker(element, NodeFilter.SHOW_TEXT, null);
    const textNodes: Node[] = [];
    let node: Node | null;
   
    while (node = walker.nextNode()) {
      textNodes.push(node);
    }
   
    return textNodes;
  }

print(){

  window.print()
  // //let myWindow = window.open('', 'my div', 'height=400,width=600');
  // var element = document.getElementById('pprclause'); 
  // let html = "";
  // html = element.innerHTML
  // const printWindow = window.open('', '_blank');
  // if (printWindow) {
  //   printWindow.document.write(html);
  // }
  // printWindow.print();
  // myWindow.close();
}
}
