<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>Misc. Laws and Acts</h1>
	</div>
</section>
<div class="clear py-3"></div>
<section class="container">
    <h3 class="pb-3">Laws and Acts</h3>
    <div class="row g-2">
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.secp.gov.pk/corporatelaws/pdf/AMLOrd_2009.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Anti Money Laundering Ordinance, 2009
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="https://www.ppra.org.pk/doc/anti-t-act.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Anti Terrorism Act (ATA), 1997
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="https://www.ppra.org.pk/doc/anti-t-ord.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Anti Terrorism Ordinance, Amended (2009)
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.secp.gov.pk/corporatelaws/pdf/1997_1.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Central Depositaries Act 1997
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.ipo.gov.pk/copyright/downloads/copyright_ordinance_1962__update.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Copyright Ordinance 1962
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.secp.gov.pk/corporatelaws/pdf/appointment_legal_advisors_act.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Pakistan Companies Act 1974
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.crcp.org.pk/PDF%20Files/consumer%20law%20final.PDF" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Consumer Laws
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.ipo.gov.pk/Copyright/Downloads/copyright_rules_update.pdf%3E%3Cb%3E%20%3Cfont%20color=" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Copyright Rules
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.fbr.gov.pk/newcu/CustomsAct/2009-2010/CustomsACt1969June302009.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Pakistan Customs Act 1969
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.cbr.gov.pk/newcu/sros/operative/import/2001sro450.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Customs Rules 2001
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="#" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Designs Ordinance 2000
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.ipo.gov.pk/Designs/Downloads/IntegratedCircuitsOrdinance2000.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Integrated Circuits Ordinance 2000
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.fbr.gov.pk/legal/2009Finance(Amendment)Ordinance.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Sales, Finance Ordinance 2009.
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.fbr.gov.pk/newdt/ITaxRules/incometaxrule2008.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Income Tax Rules 2008
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.fbr.gov.pk/newdt/ITordinance/ITOrdinance2009/ITaxOrdinance2001ut28-10-2009.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Income Tax Ordinance 2001 (updates 28-10-2009)
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.secp.gov.pk/corporatelaws/pdf/aug_28_00.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Insurance Ordinance 2000
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.ipo.gov.pk/UploadedFiles/IPOinDec20051213.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Intellectual Property Ordinance
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.labourunity.org/labourlaws.htm" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Labor laws Pakistan-child labor laws
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.ipo.gov.pk/patent/Downloads/PatentsOrdinance2000_Amendmentsfinal.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Patent Ordinance 2000
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.ljcp.gov.pk/Menu%20Items/NJPMC/ordinance/ordinance%20of%20njpmc.htm" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Law and Justice Commission Ordinance
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.secp.gov.pk/corporatelaws/pdf/takeover2002_sep08.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Listed Companies Ordinance 2002
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.ipo.gov.pk/Patent/Downloads/PatentsRules2003.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Patent Rules 2003
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.cbr.gov.pk/newst/Sales%20Tax%20Act%201990/AmendeOctober2009/staxact1990.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Sales Tax Act 1990
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.secp.gov.pk/corporatelaws/pdf/secpact1997_sep08.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Securities and Exchange Commission of Pakistan Act 1997
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.secp.gov.pk/corporatelaws/pdf/secord1969_sep08.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Securities and Exchange Ordinance 1969
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.cbr.gov.pk/newst/Rules/The%20Sales%20Tax%20Rules%202006.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    The Sales Tax Rules 2006
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.cbr.gov.pk/newst/Rules/The%20Sales%20Tax%20Special%20Procedure%20(Withholding)%20Rules%202007.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    The Sales Tax Special Procedure (Withholding) Rules 2007
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.cbr.gov.pk/newst/Rules/The%20Sales%20Tax%20Special%20Procedures%20Rules%202007.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    The Sales Tax Special Procedures Rules 2007
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.ipo.gov.pk/Trademark/Downloads/TM-rules%202004.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Trademark Rules 2004
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.ipo.gov.pk/Trademark/Downloads/TradeMark_Ordinance.pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Trademark Ordinance 2001
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.ipo.gov.pk/Trademark/Downloads/TradeMarkAct_1940.doc">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Trademark Act 1940
                </a>
            </h6>
        </div>
        <div class="col-lg-6">
            <h6 class="well font-weight-medium">
                <a href="http://www.fbr.gov.pk/newst/VATLaws/Vetted%20FINAL%20VAT_Act,_2010-Federal_(27-02-10).pdf" target="_blank">
                    <i class="far fa-hand-point-right text-primary mr-2"></i> 
                    Value Added Tax Act, 2010-Federal (27-02-10)
                </a>
            </h6>
        </div>
    </div>
</section>
<div class="clear py-3"></div>