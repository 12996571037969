<h6>
    Procurement Name: {{procurementTitle}}<br>
    Tender Number: {{tenderNumber}}
</h6>
<button pButton class="btn btn-sm btn-outline-primary" *ngIf="!fromEContract" (click)="downloadReport()"><i class="fas fa-download mr-1"></i> Download Report</button>
<div id="pdfContent" style="padding: 10px;">
    <h3 style="text-align: center; font-weight: bold;">
       <u> <span>BID EVALUATION REPORT</span><br/></u>
    </h3>
    <div style="margin-top: 3%;">
        <ol class="custom-list grid mt-3">
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Name of Procuring Agency:</span>
                    <span class="md:col-8" style="border-bottom:#000 1px;">{{reportRecord?.nameOfProcuringAgency}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Tender Reference No:</span>
                    <span class="md:col-8" style="border-bottom:#000 1px;">{{reportRecord?.ppraRefNo}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Tender Description/Name of work/item: </span>
                    <span class="md:col-8" style="border-bottom:#000 1px;">{{reportRecord?.detailsBidEvaluation}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Method of Procurement:</span>
                    <span class="md:col-8" style="border-bottom:#000 1px;">{{reportRecord?.procurementMethod}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Tender Published:</span>
                    <span class="md:col-8" style="border-bottom:#000 1px;">{{reportRecord?.publishDate}}</span>
                    Print & Electronic Media (SPPRA ID No. & News papers names with dates)
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Total Bid documents Sold; </span>
                    <span class="md:col-8" style="border-bottom:#000 1px;">{{reportRecord?.documentSold}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Total Bids Received:</span>
                    <span class="md:col-8" style="border-bottom:#000 1px ;">{{reportRecord?.bidsReceived}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Technical Bid Opening date: (if applicable) </span>
                    <span class="md:col-8" style="border-bottom:#000 1px;">{{reportRecord?.bidOpeningDate}}</span>
                    (Provide details in separate form)
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">No. of Bid technically qualified (if applicable):</span>
                    <span class="md:col-8" style="border-bottom:#000 1px;">-------------------------</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Bid(s) Rejected:</span>
                    <span class="md:col-8" style="border-bottom:#000 1px;">{{reportRecord?.bidsRejected}}</span>
                </div>
            </li>
            <li class="w-full">
                <div class="grid w-full">
                    <span class="md:col-4">Financial Bid Opening date:</span>
                    <span class="md:col-8" style="border-bottom:#000 1px;">{{reportRecord?.finBidOpeningDate}}</span>
                </div>
            </li>
            <li class="w-full mt-3">
                <div class="grid w-full">
                    <span class="md:col-4"><strong>Bid Evaluation Report: </strong> </span>
                    <span class="md:col-8" style="border-bottom:#000 1px;"></span>
                </div>
            </li>
            <table style="width:100%" aria-describedby="firstTable">
                <tr>
                    <th scope="col" rowspan="2">S No</th>
                    <th scope="col" rowspan="2">Name of Firm or Bidder</th>
                    <th scope="col" rowspan="2">Cost offered by the Bidder</th>
                    <th scope="col" rowspan="2">Ranking in terms of cost</th>
                    <th scope="col" rowspan="2">Comparison with Estimated cost</th>
                    <th scope="col" rowspan="2">Reasons for acceptance/ rejection</th>
                    <th rowspan="2" scope="col">Remarks</th>
                </tr>
                <tr></tr>
                <tr  *ngFor="let item of data; let i = index">
                    <td>{{ i + 1}}</td>
                    <td>{{item?.nameOFBidder}}</td>
                    <td>{{item?.evaluatedCost}}</td>
                    <td>{{item?.ranking}}</td>
                    <td>{{item?.estimatedCost}}</td>
                    <td>{{item?.quallified}}</td>
                    <td></td>
                </tr>
              </table>
        </ol>
    </div>

    <div style="text-align: center;margin-top: 5%; margin-bottom: 5%;">
      <p><strong>Signatures of the Members of the Committee.</strong></p>
    </div>
</div>
