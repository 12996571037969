import { Injectable } from '@angular/core';
import {  HttpHeaders } from '@angular/common/http';
import { v4 as uuid } from 'uuid';
import { UserUtilService } from './user-util.service';

@Injectable({
    providedIn: 'root'
})
export class HttpUtilsService {
    private _baseRequestModel: {
        loggedInUserID: number
        loggedInUserOfficeID: number
    }//BaseRequest

    constructor(private userUtil:UserUtilService
    ) {
    
        //let userDetail = this.userUtilsService.getUserDetails();
        //this.baseRequestModel = {
        //    loggedInUserID: userDetail?.userProfile?.userID,
        //    loggedInUserOfficeID: userDetail?.userSelectedOfficeID
        //};
    }
    set baseRequestsModel(value: { loggedInUserID, loggedInUserOfficeID }) {
        this._baseRequestModel = value;
    }
    getHTTPHeaders(): HttpHeaders {
        const sessionID = uuid();
        var userData =null;
        var headers;
        if (userData != null) {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + userData.access_token,
                'OfficeDetail':this.userUtil.selectedEnvironmentName
                // 'ApplicationSessionID' : sessionID
            });
            return headers;
        }
        else {

            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Basic YWRtaW46cHByYTEy',
                'OfficeDetail':this.userUtil.selectedEnvironmentName
            });
            return headers;
        }
    }

    getHTTPHeadersFileUpload(): HttpHeaders {
        const sessionID = uuid();
        let userData =null;
        var headers;
        if (userData != null) {
            headers = new HttpHeaders({
                "Accept": "text/plain",
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + userData.access_token,
                'OfficeDetail':this.userUtil.selectedEnvironmentName
                // 'ApplicationSessionID' : sessionID
            });
            return headers;
        }
        else {

            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Basic YWRtaW46YWRtaW4=',
                'OfficeDetail':this.userUtil.selectedEnvironmentName
            });
            return headers;
        }
    }

    
}
