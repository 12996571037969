
import { BaseRequestModel } from "./BaseModel/BaseRequestModel";
import { PaginationModel } from "./pagination-model";

export class TenderFilter {
   keywords?:string;
   departmentName?:string;
   tenderNo?:string;
   dateOfAdvertisement?:Date;
   closingDate?:Date;
   procurementEntity?:string;
   city?:string;
   sector?:string;
   organisation?:string;
   sortOrder?:string;
   selectedWorth?: string;
   activityStatus?: string;
//    constructor(){
//       this.keywords ="";
//       this.tenderNo="";
//       this.dateOfAdvertisement = new Date();
//       this.closingDate = new Date();
//    }
}
export class GetPublishedDocumentInfoRequestModel extends BaseRequestModel {
    pagination:PaginationModel;
    filter:TenderFilter;
    departmentname:string; 
    selectedWorth:string;
    search : any;
}
export class GetRulesRequestModel extends BaseRequestModel {
    search : any;
}

export class GetPublishedDocumentInfoByTypeRequestModel extends BaseRequestModel {
    pagination:PaginationModel;
    filter:TenderFilter;
    type:number; 
}

export class GetAllPublishedDocumentDetailsByPidRequestModel extends BaseRequestModel {
    Id:number;
    procurementPlansDetailID:number;
    SupplierID:number;
}

export class GetClosingTenderRequestModel extends BaseRequestModel {
    procurementPlansDetailID:number;
    pagination:PaginationModel;
}
export class GetPPRAOrganizationNameRequestModel extends BaseRequestModel{
    pagination:PaginationModel;
}
