<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>EPADS Annual Procurement Planning</h1>
	</div>
</section>
<div class="clear py-3"></div>
<section class="container">
    <h3 class="pb-2"></h3>
    <a href="./assets/docs/sample.pdf" target="_blank" class="btn btn-rounded btn-outline-primary"><i class="far fa-file-pdf mr-1"></i> Sample Performa</a>
    <div class="clear py-2"></div>

    <div class="row align-items-center">
        <div class="form-group col-lg-3">
            <label for="tenderno">Plan ID</label>
            <input type="text" [(ngModel)]="PlanID" [value]=" PlanID" class="form-control" id="tenderno" placeholder="">
        </div>
        <div class="form-group col-lg-3">
            <label for="keyword">Procurement Plan Name</label>
            <input type="text" [(ngModel)]="PlanName" [value]="PlanName" class="form-control" id="keyword" placeholder="">
        </div>
        <div class="form-group col-lg-3">
            <label for="keyword">Department Name</label>
            <app-search-dropdown [items]="departmentNames" [size]="'small'" [img]="'product'" [label]="'departmentName'" [uid]="'id'"
            [(ngModel)]="departmentNameValue">
            </app-search-dropdown>
        </div>
        <div class=" col-lg-3 mt-2">
            <button (click)="search()" class="btn btn-success mr-2">Search</button>
            <button (click)="clear()" class="btn btn-secondary">Clear</button>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table border table-striped">
            <thead class="table-success">
                <tr>
                    <th width="75">Sr. No</th>
                    <th width="75">
                        <div class="sortTh">
                            Plan ID
                            <span class="sorted_Icons">
                                <a (click)="sort('PlanID','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                                <a (click)="sort('PlanID','asc')"><img src="../assets/images/arrow_down.gif"></a>
                            </span>
                        </div>
                    </th>
                    <th>
                        <div class="sortTh">
                            Procurement Plan Name
                            <span class="sorted_Icons">
                                <a (click)="sort('PlanName','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                                <a (click)="sort('PlanName','asc')"><img src="../assets/images/arrow_down.gif"></a>
                            </span>
                        </div>
                    </th>
                    <th>
                        <div class="sortTh">
                            Department Name
                            <span class="sorted_Icons">
                                <a (click)="sort('DepartmentName','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                                <a (click)="sort('DepartmentName','asc')"><img src="../assets/images/arrow_down.gif"></a>
                            </span>
                        </div>
                    </th> 
                    <th>
                        <div class="sortTh">
                            Plan Year
                            <span class="sorted_Icons">
                                <a (click)="sort('PlanYear','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                                <a (click)="sort('PlanYear','asc')"><img src="../assets/images/arrow_down.gif"></a>
                            </span>
                        </div>
                    </th>
                    <th>Download Files</th>
                    <!-- <th>
                        <div class="sortTh">
                            Published Date
                            <span class="sorted_Icons">
                                <a (click)="sort('publishDate','desc')"><img  src="../assets/images/arrow_up.gif"></a>
                                <a (click)="sort('publishDate','asc')"><img src="../assets/images/arrow_down.gif"></a>
                            </span>
                        </div>
                    </th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let tenders of publicTenders; let i = index">
                    <th>{{i+1}}</th> 
                    <th class="text-nowrap font-weight-semi-bold">{{tenders?.PlanID}}</th>
                    <th class="text-nowrap font-weight-bold">{{tenders?.PlanName}}</th>
                    <th class="text-nowrap font-weight-bold">{{tenders?.DepartmentName}}</th>
                    <th class="text-nowrap font-weight-bold">{{tenders?.PlanYear}}</th>
                    
                    <td class="text-center">
                        <a (click)="getprocurementplanbyidexport(tenders?.PlanID)" data-toggle="modal" data-target="#downloadModal" class="btn btn-rounded alert-info m-1 btn-sm shadow-none font-weight-medium py-2 px-3"><i class="fas fa-download mr-1"></i></a>
                    </td>
                    <!-- <td>{{tenders?.publishDate | date}}</td> -->
                </tr>
            </tbody>
        </table>
    </div>
    <div class="clear clearfix py-1"></div>
        <!-- <p class="font-weight-bold">Total Active Tenders ({{totalRecords}})</p>
        <p class="arial">
            <small class="font_12">10 Records Per Page</small><br>
            <a (click)="changePage(pageNumber)"  *ngFor="let pageNumber of pages"    [ngClass]="{'active': pageNumber==this.paginationModel.pageNumber}"  class="font-weight-bold" >{{pageNumber}}&nbsp;</a> 
          
        </p> -->
        <small class="font_12">10 Records Per Page</small> 
        <small class="font_12"> | Total Pages<strong>  {{this.totalPages}}</strong></small> 
        <nav aria-label="..." class="arial-pagination">
            <div class="d-flex">
                <ul class="pagination justify-content-center mr-4">
                    <li class="page-item" [class.disabled]="this.PageNo === 1">
                        <a class="page-link" (click)="previousPage()">Previous</a>
                    </li>
                    <li class="page-item" *ngFor="let pageNumber of pages">
                        <a class="page-link" (click)="changePage(pageNumber)" [class.active]="pageNumber === this.PageNo">{{pageNumber}}</a> 
                    </li>
                    <li class="page-item" [class.disabled]="this.PageNo=== this.totalPages">
                        <a class="page-link" (click)="nextPage()">Next</a>
                    </li>
                </ul>
                <div class="input-group input-group-sm">
                    <input type="number" class="form-control" placeholder="Page No."  (keyup)="onEnterKeyPressed($event)"  [(ngModel)]="this.PN" aria-label="Enter your text" aria-describedby="button-addon">
                    <div class="input-group-append input-group-sm">
                        <button class="btn btn-outline-secondary"  (click)="selectedPage()" type="button" id="button-addon">Go</button>
                    </div>
                </div>
            </div>
        </nav>  
</section>
<div class="clear py-3"></div>
<div class="modal fade" id="downloadModal" tabindex="-1" role="dialog" aria-labelledby="downloadModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable modal-xl" role="document" >
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Procurement Plan Details</h5>
                <div class="close">
                    <a class="text-black font-weight-medium cursor-pointer text_14" (click)="print()">
                        <i class="fas fa-print text-primary cursor-pointer"></i> print
                    </a>                    
                    <button type="button" class="ml-4 bg-tranparent border-0" data-dismiss="modal" aria-label="close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
			</div>
			<div class="modal-body" id="content">
				<div class="table-responsive">
					<table class="table table-striped border">
						<thead class="table-success">
							<tr>
								<th scope="col">Name of Procurement (Description)</th>
								<th scope="col">Estimated Cost (Rs)</th>
								<th scope="col">Procurement Method**</th>
								<th scope="col">Tentative date of Procurement Notice Publication</th>
								<th scope="col">Tentative date of Award of Contract</th>
								<th scope="col">Tentative date of Completion</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of PlanActivitiesData">
								<td>{{item?.Title}}</td>
								<td>{{item?.EstimatedCost}}</td>
								<td>{{item?.ProcurementMethod}}</td>
								<td>{{item?.TentativePublishingDate| date}}</td>
								<td>{{item?.TentativeContractAwardDate| date}}</td>
								<td>{{item?.TentativeCompletionDate| date}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-outline-danger py-2" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>