import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
@Component({
  selector: 'app-evaluationExportPDF',
   templateUrl: './evaluationExportPDF.component.html',
  styleUrls: ['./evaluationExportPDF.component.scss']
})
export class EvaluationExportPDFComponent implements OnInit {
    biddingProcessInfo
    data: any;
    mostAdvantigiousBidder: string;
    reportRecord:any;
    finalEvaluationReportForm: FormGroup;
  constructor(
    private portalService: PublicPortalService,
    private toasterService: ToastrService,
    private userUtilsService : UserUtilService,
  ) { }
  isVisible=false;
  @Output() pdfData = new EventEmitter<any>();
  @Input() viewAble:boolean;
  @Input() publishedDocumentID:number;
  @Input() fromEContract:boolean=false;
  @Input() FinalEvaluation:boolean=false;
  @Input() supplierID:number;
  @Input() lotNo:number;
  @Input() fromSupplier:boolean=false;
  ngOnInit() {
    this.getAll(this.publishedDocumentID)
}
  getAll(docId:number) {
    this.data=null
    this.portalService.geteFinalEvaluationReport(docId,this.FinalEvaluation,this.supplierID,this.lotNo).subscribe((baseResponse: any) => {
      if (baseResponse.success) {
        this.data=baseResponse.data;
      } else {
        if (baseResponse.errors.length > 0) {
          for (var i = 0; i < baseResponse.errors.length; i++) {
            this.toasterService.error(baseResponse.errors[i].errorMessage);
          }
          return;
        }
      }
    })
  }
  receiveData(evt: any) {

    this.pdfData.emit(evt);
  }
}
