import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OrdinanceComponent } from './components/ordinance/ordinance.component';
import { PpRulesComponent } from './components/pp-rules/pp-rules.component';
import { RegulationsComponent } from './components/regulations/regulations.component';
import { RouterModule } from '@angular/router';
import { PublicVoilationsComponent } from './components/public-voilations/public-voilations.component';
import { HighlightDirective } from 'src/app/core/directive/highlight.directive';
import { NgxHighlightWordsModule } from 'ngx-highlight-words';



@NgModule({
  declarations: [
    OrdinanceComponent,
    PpRulesComponent,
    RegulationsComponent,
    PublicVoilationsComponent,
    HighlightDirective
  ],
  imports: [
    RouterModule.forChild([
      {
        path: 'documents/ordinance',
        component: OrdinanceComponent,
      },
      {
        path: 'documents/pp-rules',
        component: PpRulesComponent,
      },
      {
        path: 'documents/regulations',
        component: RegulationsComponent,
      },
      {
        path: 'documents/publicvoilations',
        component: PublicVoilationsComponent,
      },

    ]),
    CommonModule,
    FormsModule,
    [NgxHighlightWordsModule]
  ]
})
export class DocumentsModule { }
