import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { listType } from 'src/app/core/enumerations/enum-ListType';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import { ActiveTendersSearchRequestModel } from 'src/app/core/models/activeTendersRequestModel';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { payRequestModel } from 'src/app/core/models/payRequestModel';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import { saveAs } from 'file-saver';
import b64toBlob from 'b64-to-blob';

@Component({
  selector: 'app-tenders-history',
  templateUrl: './tenders-history.component.html',
  styleUrls: ['./tenders-history.component.scss']
})
export class TendersHistoryComponent implements OnInit, AfterViewInit {
  payRequestModel: payRequestModel;
  paginationModel: PaginationModel;
  downloadFile: downloadfile;
  dataForm: FormGroup;
  dataModel: ActiveTendersSearchRequestModel;
  loggedInUser: any;
  selectedSort: any;
  Records: any;
  totalRecords: any;
  totalPages: number;
  pages = new Array();
  PageNo: number = 0;
  PN: number;
  ID: number = 0;
  Sector: any;
  DropdownRecords: any
  selectedValue: any
  isSelected: boolean;
  selectedStateID: any;
  ClosingDate: any;
  TenderNumber: any;
  selectedValueWorth: any;
  WorthRecord = [
    { id: -2, name: 'Worth More Than 50 Million' },
    { id: -1, name: 'Worth Less Than 50 Million' }
  ];
  dropdownLable=''
  DropdownRecords1: any;
  selectedValueForFilter: any;
  uniqueSectorNames: any;
  NameofOrgSec: string;
  tempRecords: any;
  isOrg: boolean=null;
  selectedStateIDValue: any;
  SectorID: string;
  OrgID: string;
  constructor(private service: PublicPortalService,
    private route: ActivatedRoute,
    private router : Router) {
    this.dataModel = new ActiveTendersSearchRequestModel()
    this.Sector = sessionStorage.getItem('Sector')
    this.dropdownLable = sessionStorage.getItem('Organization Name')
    this.NameofOrgSec = sessionStorage.getItem('historyName') 
    this.SectorID = sessionStorage.getItem('sectorID')
    this.OrgID = sessionStorage.getItem('orgID')
  }

  ngOnInit(): void {
    this.ClosingDate = null
    this.selectedStateID =null
    this.TenderNumber = null
    this.paginationModel = new PaginationModel();
    this.payRequestModel = new payRequestModel();
    this.downloadFile = new downloadfile();
    this.PageNo = Number(this.paginationModel.pageNumber);
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.ID = id;
    });
    this.GetPayDropDown()
  }
  ngAfterViewInit() {
    this.GetPayDropDown()
    if ((this.ID != null && this.ID != undefined) && (this.Sector != "Sector")) {
      this.GetPayDetailListByID(Number(this.ID))
      this.GetPayDropDownSetorFilter(Number(this.ID))
      this.isOrg = true
    }
    else if ((this.ID != null && this.ID != undefined) && (this.Sector == "Sector")) {
      this.GetPayDetailListByIDAndSector(Number(this.ID))
      this.GetPayDropDownOrgFilter(Number(this.ID))
      this.isOrg = false
    }
    else {
      this.GetPayDetailList();
    }
  }

  sessionstroage(){
    sessionStorage.clear();
    this.NameofOrgSec =''
    this.router.navigateByUrl(`tenders/tendershistory`);
  }
  GetPayDetailList() {

    this.payRequestModel.Type = listType.TendersHistory;
    this.payRequestModel.ID = 0;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {

              let data = (baseResponse.data as any);
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }

              this.Records = data?.records;
              this.clear()

            }
          }
        }
      })
  }
  GetPayDetailListByID(ID: any) {
    
    this.payRequestModel.Type = listType.TendersHistory;
    this.payRequestModel.ID = Number(ID);
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {

              let data = (baseResponse.data as any);
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }

              this.Records = data?.records;
              this.tempRecords = this.Records

            }
          }
        }
      })
  }

  forSectorFilter(event){
    if(sessionStorage.getItem('orgID')!=null){
      this.SectorID=this.selectedValueForFilter == null ? null : this.uniqueSectorNames.filter(x => x.Name == this.selectedValueForFilter)[0].ID;
    }else{
      this.OrgID=this.selectedValueForFilter == null ? null : this.uniqueSectorNames.filter(x => x.Name == this.selectedValueForFilter)[0].ID;
    }
    
    this.onSelectedValue()
  }

  GetPayDetailListByIDAndSector(ID: any) {
  
    this.payRequestModel.Type = listType.SectorHistory;
    this.payRequestModel.ID = Number(ID);
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
             // sessionStorage.removeItem('Sector')
              let data = (baseResponse.data as any);
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }

              this.Records = data?.records;
              this.tempRecords = this.Records

            }
          }
        }
      })
  }

  changePage(pageNumber) {
    this.paginationModel.pageNumber = pageNumber;
    this.PageNo = Number(pageNumber);
    this.PN = this.PageNo;
    this.onSelectedValue()
    // if (this.payRequestModel.Type==listType.TendersHistory && this.ID==null){
    //   this.GetPayDetailList();
    // }
    // else if (this.payRequestModel.Type==listType.TendersWorthHistory ){
    //   this.onSelectedValue();
    // }else if (this.payRequestModel.Type==listType.TendersHistory){
    //   this.GetPayDetailListByID(this.ID);
    // }else if (this.payRequestModel.Type==listType.SectorHistory){
    //   this.GetPayDetailListByIDAndSector(this.ID);
    // }else if (this.payRequestModel.Type==listType.OganizationWiseHistoryFilter){
    //   this.GetPayDetailListByIDAndSector(this.ID);
    // }else if (this.payRequestModel.Type==listType.SectorWiseHistoryFilter){
    //   this.GetPayDetailListByID(this.ID);
    // }
  }

  nextPage() {
    if (Number(this.paginationModel.pageNumber) < this.totalPages) {
      this.changePage(Number(this.paginationModel.pageNumber) + 1);
    }
  }

  previousPage() {
    if (Number(this.paginationModel.pageNumber) > 1) {
      this.changePage(Number(this.paginationModel.pageNumber) - 1);
    }
  }

  selectedPage() {
    if(this.PN==null || this.PN==0){
      this.PN=1
    }
    this.paginationModel.pageNumber = String(this.PN);
    this.PageNo = this.PN;
    this.onSelectedValue()
    // if (this.payRequestModel.Type==listType.TendersHistory && this.ID==null){
    //   this.GetPayDetailList();
    // }
    // else if (this.payRequestModel.Type==listType.TendersWorthHistory ){
    //   this.onSelectedValue();
    // }else if (this.payRequestModel.Type==listType.TendersHistory){
    //   this.GetPayDetailListByID(this.ID);
    // }else if (this.payRequestModel.Type==listType.SectorHistory){
    //   this.GetPayDetailListByIDAndSector(this.ID);
    // }else if (this.payRequestModel.Type==listType.OganizationWiseHistoryFilter){
    //   this.GetPayDetailListByIDAndSector(this.ID);
    // }else if (this.payRequestModel.Type==listType.SectorWiseHistoryFilter){
    //   this.GetPayDetailListByID(this.ID);
    // }
  }

  onEnterKeyPressed(event: KeyboardEvent): void {
    // Check if the key pressed is Enter

    if (event.key === 'Enter') {
      // Call your function here
      this.selectedPage()
    }
  }
  GetPayDropDown() {
    this.payRequestModel.Type = listType.State;
    this.payRequestModel.ID = -3;
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "5000"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              let data = (baseResponse.data as any);
              this.DropdownRecords = data?.records;
              //this.selectedValue = data?.records[0].StateName;
            }
          }
        }
      })
  }
  GetPayDropDownSetorFilter(ID:any) {
    this.payRequestModel.Type = listType.SectorWiseHistoryFilter;
    this.payRequestModel.ID = Number(ID);
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "5000"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              let data = (baseResponse.data as any);
              this.uniqueSectorNames = data?.records;
            }
          }
        }
      })
  }
  GetPayDropDownOrgFilter(ID:any) {
    this.payRequestModel.Type = listType.OganizationWiseHistoryFilter;
    this.payRequestModel.ID = Number(ID);
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "5000"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              let data = (baseResponse.data as any);
              this.uniqueSectorNames = data?.records;
            }
          }
        }
      })
  }
  onSelectValueSearch(){
    this.Records=[]
    this.paginationModel = new PaginationModel();
    this.PageNo=Number(this.paginationModel.pageNumber)
    this.onSelectedValue()
  }
  onSelectedValue() {
      this.payRequestModel.Type = listType.TendersHistory;
      this.payRequestModel.ID = 0;
    if(this.selectedStateID!=null){
      this.selectedStateIDValue=this.selectedStateID==null?null:this.DropdownRecords.filter(x=>x.StateName==this.selectedStateID)[0].StateID;
      this.payRequestModel.City = this.selectedStateIDValue.toString()
    }
    else{
      this.payRequestModel.City=null
    }
    this.payRequestModel.TenderNumber = this.TenderNumber || null;
    this.payRequestModel.TenderWorth = this.selectedValueWorth || null;
    if(this.SectorID!=null){
      this.payRequestModel.Sector = this.SectorID.toString();
    }
    else{
      this.payRequestModel.Sector=null
    }
    if(this.OrgID!=null){
      this.payRequestModel.Organization = this.OrgID.toString()
    }else{
      this.payRequestModel.Organization = null
    }
    if (this.ClosingDate == "" || this.ClosingDate == null) {
      this.payRequestModel.Date = null;
    }
    else {
      this.payRequestModel.Date = new Date(this.ClosingDate)
    }
      this.payRequestModel.pagination = this.paginationModel;
      this.payRequestModel.pagination.pageSize = "10"
      this.service.GetPayDetailList(this.payRequestModel)
        .subscribe(baseResponse => {
          if (baseResponse != null) {
            if (baseResponse.success != null) {
              if (baseResponse.success) {
                this.isSelected = true;
                let data = (baseResponse.data as any);
                this.Records = data?.records
                this.totalPages = data?.totalPages;
                this.totalRecords = data?.totalRecords;
              }
            }
          }
        }
        )
  }
  onSelectedValueFilter(event){
    this.payRequestModel.Type = listType.TendersHistory;
      this.payRequestModel.ID = Number(event.ID);
      this.payRequestModel.City = this.selectedStateID || null;
      this.payRequestModel.TenderNumber = this.TenderNumber || null;
      if (this.ClosingDate == "" || this.ClosingDate == null) {
        this.payRequestModel.Date = null;
      }
      else {
        this.payRequestModel.Date = new Date(this.ClosingDate)
      }
      this.payRequestModel.pagination = this.paginationModel;
      this.payRequestModel.pagination.pageSize = "10"
      this.service.GetPayDetailList(this.payRequestModel)
        .subscribe(baseResponse => {
          if (baseResponse != null) {
            if (baseResponse.success != null) {
              if (baseResponse.success) {
                this.isSelected = true;
                let data = (baseResponse.data as any);
                this.Records = data?.records
                this.totalPages = data?.totalPages;
                this.totalRecords = data?.totalRecords;
                this.clear()
              }
            }
          }
        }
        )
  }
  clear() {
    this.ClosingDate = null
    this.selectedStateID = null
    this.TenderNumber = null
  }
  getAttachmentByID(Id: any, Guid: any) {
    if (Id == 0 || Id == null) {
      return;
    }
    this.downloadFile.ID = Id;
    this.downloadFile.idsList = Guid;

    this.service.DownloadFileByGuid(this.downloadFile).subscribe(baseResponse => {
      if (baseResponse.success && baseResponse.data != null) {
        var Bytes = baseResponse.data['bytes'];
        var ContentType = baseResponse.data['contentType'];
        var FileName = baseResponse.data['fileName'];

        const blob = b64toBlob(Bytes, ContentType);
        saveAs(blob, FileName);
      }
    });
  }
  GetPayDetailListClear() {
    this.GetPayDropDown()
    this.payRequestModel.Type = listType.TendersHistory;
    this.payRequestModel.ID = 0;
    this.payRequestModel.City = this.selectedStateID || null;
    this.payRequestModel.TenderNumber = this.TenderNumber || null;
    if (this.ClosingDate == "" || this.ClosingDate == null) {
      this.payRequestModel.Date = null;
    }
    else {
      this.payRequestModel.Date = new Date(this.ClosingDate)
    }
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.payRequestModel.pagination.orderBy = "ASC";
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {

              let data = (baseResponse.data as any);
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }
              this.Records = data?.records;
            }
          }
        }
      })
  }
  clears() {
    this.selectedValueWorth=null
    this.ClosingDate = null
    this.selectedStateID = null
    this.TenderNumber = null
    this.selectedValueForFilter = null
    this.GetPayDropDown()
    this.ID=this.ID==null?0:this.ID
    if(sessionStorage.getItem('orgID')!=null){
      this.SectorID=null
      this.GetPayDropDownSetorFilter(this.ID)
    }else{
      this.OrgID = null
      this.GetPayDropDownOrgFilter(this.ID)
    } 
    this.onSelectedValue() 
  }
  sort(orderByColumnName, orderBy){
    this.paginationModel.orderBy = orderBy;
    this.payRequestModel.pagination.orderByColumnName = orderByColumnName;
    this.paginationModel.orderByColumnName = orderByColumnName
    this.onSelectedValue()
    // if (this.payRequestModel.Type==listType.TendersHistory && this.ID==null){
    //   this.GetPayDetailList();
    // }
    // else if (this.payRequestModel.Type==listType.TendersWorthHistory ){
    //   this.onSelectedValue();
    // }else if (this.payRequestModel.Type==listType.TendersHistory){
    //   this.GetPayDetailListByID(this.ID);
    // }else if (this.payRequestModel.Type==listType.SectorHistory){
    //   this.GetPayDetailListByIDAndSector(this.ID);
    // }else if (this.payRequestModel.Type==listType.OganizationWiseHistoryFilter){
    //   this.GetPayDetailListByIDAndSector(this.ID);
    // }else if (this.payRequestModel.Type==listType.SectorWiseHistoryFilter){
    //   this.GetPayDetailListByID(this.ID);
    // }
  }
}


