import { Component, OnInit } from '@angular/core';
import b64toBlob from 'b64-to-blob';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';

import { saveAs } from 'file-saver';
@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.css']
})
export class HighlightsComponent implements OnInit {

  downloadFile: downloadfile;
  allHighlights:any;
  constructor(private portalService:PublicPortalService) { }

  ngOnInit(): void {
    
    this.downloadFile = new downloadfile();
    this.getAllHighlights();
  }
  getAllHighlights(){
    this.portalService.getAllHighLights()
    .subscribe(baseResponse => {
      if (baseResponse != null) {
        if (baseResponse.success != null) {
          if (baseResponse.success) {
                this.allHighlights = baseResponse?.data  
              
          }
        }

      }

    })
  }
  getAttachmentByID(Id: any,Guid:any) {
    if(Id == 0 || Id==null){
      return;
    }
    this.downloadFile.ID = Id;
    this.downloadFile.idsList = Guid;

    this.portalService.DownloadFileByGuid(this.downloadFile).subscribe(baseResponse => {
      if (baseResponse.success && baseResponse.data != null) {
        var Bytes = baseResponse.data['bytes'];
        var ContentType = baseResponse.data['contentType'];
        var FileName = baseResponse.data['fileName'];

        const blob = b64toBlob(Bytes, ContentType);
        saveAs(blob, FileName);
      }
    });
  }

}
