import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
@Component({
  selector: 'app-contract-award-performa2',
  templateUrl: './contract-award-performa2.component.html',
  styleUrls: ['./contract-award-performa2.component.scss']
})
export class ContractAwardPerforma2Component implements OnInit {

  constructor(private publicPortalService: PublicPortalService,
    private toasterService: ToastrService,
    ) { }
    data: any;
    @Input() publishedDocumentID:number;
    @Input() viewAble:boolean;
    @Input() supplierID:number;
    @Input() lotNo:number;
    @Input() fromSupplier:boolean=false;

  ngOnInit(): void {
    this.getAll(this.publishedDocumentID)
  }
  getAll(docId:number) {
    this.publicPortalService.geteContractReport(docId,this.supplierID).subscribe((baseResponse: any) => {
      if (baseResponse.success) {
        this.data=baseResponse.data;
      } else {
        if (baseResponse.errors.length > 0) {
          for (var i = 0; i < baseResponse.errors.length; i++) {
            this.toasterService.error(baseResponse.errors[i].errorMessage);
          }
          return;
        }
      }
    })
  }
}
