import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { FooterComponent } from './footer/footer.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    HeaderComponent,
    NavigationBarComponent,
    FooterComponent,
    ContactUsComponent
  ],
  
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: ContactUsComponent,
      }
    ]),
    CommonModule
  ],
  exports:[
    HeaderComponent,
    NavigationBarComponent,
    FooterComponent
  ]
})
export class SharedModule { }
