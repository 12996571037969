<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>Blacklisted/Debarred Firms-Pakistan</h1>
	</div>
</section>
<div class="clear py-3"></div>
<div class="container">
<div class="table-responsive">
    <table class="table border">
        <thead class="table-success">
            <tr>
                <th scope="col">Sr NO.</th>
                <th scope="col">Supplier Name</th>
                <!-- <th scope="col">Tender Details</th>
                <th scope="col">Downloads</th>
                <th scope="col">Advertisement Date</th>
                <th scope="col">Closing Date</th> -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of Records; let i = index">
                <td>
                    <strong>{{i+1}}</strong><br>
                </td> 
                <td>
                    <strong>{{data?.supplierBusinessName}}</strong><br>
                </td>
                <!-- <td>
                    <strong>{{data?.CategoryName}}, {{data?.state_name}} </strong><br>
                    {{data?.Title}} <br>
                    {{data?.note}} <br>
                    {{data?.enquiry_no}} <br>
                </td>
                <td>
                    <a [href]="data?.URL" target="_blank" ><i class="fas fa-file-download fa-fw fa-lg"></i></a>
                </td>
                <td>
                    <strong>{{data?.start_date | date}}</strong><br>
                </td>
                <td>
                    <strong>{{data?.closing_date | date}}</strong><br>
                </td> -->
           
            </tr>    
            <strong *ngIf="Records?.length == '0'">No record found.</strong>        
        </tbody>
    </table>
    <div class="clear clearfix py-1"></div> 
    
    <small class="font_12">10 Records Per Page</small> 
    <small class="font_12"> | Total Pages<strong>  {{this.totalPages}}</strong></small> 
    <nav aria-label="..." class="arial-pagination">
    
        <div class="container mt-5">
            <div class="row">
                <div class="col-md-3">
                    <ul class="pagination justify-content-center">
                        <li class="page-item" [class.disabled]="this.PageNo === 1">
                            <a class="page-link" (click)="previousPage()">Previous</a>
                        </li>
                        <li class="page-item" *ngFor="let pageNumber of pages">
                            <a class="page-link" (click)="changePage(pageNumber)" [class.active]="pageNumber === this.PageNo">{{pageNumber}}</a> 
                        </li>
                        <li class="page-item" [class.disabled]="this.PageNo=== this.totalPages">
                            <a class="page-link" (click)="nextPage()">Next</a>
                        </li>
                    </ul>
                </div>
              <div class="col-md-2">
                <div class="input-group input-group-sm">
                  <input type="number" class="form-control" placeholder="Page No."  (keyup)="onEnterKeyPressed($event)"  [(ngModel)]="this.PN" aria-label="Enter your text" aria-describedby="button-addon">
                  <div class="input-group-append input-group-sm">
                    <button class="btn btn-outline-secondary"  (click)="selectedPage()" type="button" id="button-addon"><span style="color: blue; ">Go</span></button>
                  </div>
                </div>
              </div>
            </div>
        </div>
       
    </nav>        
</div>
</div>
<!-- <section class="container">
    <p class="font-weight-bold">Rule 19 of the Public Procurement Rules, 2004 (amended vide S.R.O. No. 834(I)/2021 dated 28th June, 2021) requires that</p>
    <p>The procuring agency shall devise a comprehensive mechanism for blacklisting and debarment of bidders for a specified time in accordance with regulations made by the Authority, and the bidder or the bidders shall be declared as-</p>
    <ul type="a">
        <li class="mb-2">blacklisted and henceforth cross debarred for participation in any public procurement or disposal proceedings for the period of not more than ten years, if corrupt and fraudulent practice as defined in these rules is established against the bidder or the bidders in pursuance of blacklisting proceedings;</li>
        <li class="mb-2">blacklisted and henceforth cross debarred for participation in respective category of public procurement or disposal proceedings for a period of not more than three years, if the bidder fails to perform his contractual obligations during the execution of contract or breaches the contract due to his capacity and capability to perform or otherwise. However, procuring agency shall initiate such blacklisting or debarment proceedings after exhausting the forum of arbitration, provided that such provision exists in the conditions of contract, and if such failure or breach is covered in the respective dispute settlement clauses of the contract, and</li>
        <li class="mb-2">blacklisted and henceforth cross debarred for participation in respective category of public procurement or disposal proceedings for a period of not more than six months, if the bidder fails to abide with a bid securing declaration, however without being indulged in any corrupt and fraudulent practice.</li>
        <li class="mb-2">The bidder may file the review petition before the Authority within thirty days of communication of such blacklisting or barring action after depositing the prescribed fee and in accordance with procedure issued by the Authority, and the Authority shall evaluate the case and decide within ninety days of filing of review petition. The decision of the Authority shall be considered as final</li>
    </ul>
    <p class="font-weight-bold">In observance of the above said Rule, various procuring agencies reported the firms which they have blacklisted/debarred permanently or temporarily to PPRA, as per details given below.</p>
    <h4 class="font-weight-semi-bold">Related Links:</h4>
    <ul>
        <li class="mb-2"><a target="_blank" href="http://www.pprasindh.gov.pk/blacklistfirms.php" class="font-weight-bold">Sindh PPRA Blacklisted Firms.</a></li>
        <li class="mb-2"><a target="_blank" href="http://eproc.punjab.gov.pk/blacklist.aspx" class="font-weight-bold">PPRA Punjab Blacklisted Firms.</a></li>
        <li class="mb-2"><a target="_blank" href="http://www.kppra.gov.pk/index.php/blacklistedfirms/bf_user_view" class="font-weight-bold">PPRA Kyber Pakhtunkhwa Blacklisted Firms.</a></li>
        <li class="mb-2"><a target="_blank" href="http://www.worldbank.org/debarr" class="font-weight-bold">World Bank Debarred Firms.</a></li>
        <li class="mb-2"><a target="_blank" href="https://lnadbg4.adb.org/oga0009p.nsf/sancALLPublic?OpenView&count=999" class="font-weight-bold">Asian Development Bank Debarred Firms.</a></li>
    </ul>
</section> -->
<div class="clear py-3"></div>