import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class IntercepterService implements HttpInterceptor {
  private totalRequests = 0;
  constructor( private spinner: NgxSpinnerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.totalRequests++;
    this.spinner.show();
   
    return next.handle(req).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.spinner.hide();
        }
      }),
      tap(
        event => {
          if (event instanceof HttpResponse) {
            // if (req.url != "/assets/i18n/en.json" && req.url != "/assets/i18n/ur.json") {
            //   if (event.body.tokenHasExpired) {
            //     setTimeout(()=>{                           //<<<---using ()=> syntax
            //       this.toast.error("Session has been timeout.");
            //  }, 3000);  
                   
            //     this.router.navigate(['/login']);
            //   }
            // }
            setTimeout(() => {
              this.spinner.hide();
            }, 1000);
          }
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            if(error.status == 401)
            {
              // this.toast.info("Session expired. Please re-login.")
              // setTimeout(() => {
              //   this.router.navigate(['/auth/login']);
              // }, 3000);

            }
          }
          this.spinner.hide();
          console.error(error.status);
          console.error(error.message);
        }
      )
    )

  }
}
