<diV class="grid my-3" >
    <div class="mx-1">
     <button pButton class="btn btn-sm btn-outline-primary"  (click)="generatePDF()"><i class="fas fa-download mr-1"></i>Generate PDF</button>
    </div>
 </diV>

 <div id="pdfContent" style="padding: 10px;">
     <h3 class="reportHeading">
           PUBLIC PROCUREMENT REGULATORY
     </h3>
     <h3 class="reportHeading">
         AUTHOIUTY (PPRA)
     </h3>
     <h4 class="reportHeading">
       (As Per Rule 47 of PP Rules. 2004)
     </h4>
     <h3 class="reportHeadingProforma">
         CONTRACT AWARD PROFORMA-I
     </h3>

 <h4 class="reportHeadingDescription">To Be Filled And Uploaded on PPRA Website In Respect of All
     Public Contracts of Works, Services & Goods.</h4>

     <div class="reportList">
         <ul class="styled-list">
             <li> <span class="pi pi-angle-right"></span>
                 NUMBER OF BIDDERS PRESENT AT THE TIME OF
                 OPENING OF BIDS:
                 <span > {{data?.totalBidders}} </span>
             </li>
             <li> <span class="pi pi-angle-right"></span>
                NAME AND ADDRESS OF THE SUCCESSFUL BIDDER: <span > {{data?.nameAndAddressOfBidder}} </span>
             </li>
             <li> <span class="pi pi-angle-right"></span>
                 RANKING OF SUCCESSFUL BIDDER IN EVALUATION REPORT
                 (i.e. '1st, 2nd, 3rd  EVALUATED BID). <span>{{data?.bidderPosition}} </span>
             </li>
             <li> <span class="pi pi-angle-right"></span>
                 NEED ANALYSIS (Why the procurement was necessary?) <span>{{data?.needOfProcurement}} </span>
             </li>
             <li> <span class="pi pi-angle-right"></span>
                 IN CASE EXTENSION WAS MADE IN RESPONSE TIME. WHAT WERE THE REASONS (Briefly describe) <span>{{data?.extension}} </span>
             </li>

             <li> <span class="pi pi-angle-right"></span>
                 NUMBER OF TENDER DOCUMENTS SOLD  <span>{{data?.documentSold}} </span> <br/>
                 (Attach list of Buyers)
             </li>


             <li> <span class="pi pi-angle-right"></span>
                 WHETHER QUALIFICATION CRITERIA
                 WAS INCLUDED IN BIDDING/TENDER DOCUMENTS  <span>Yes</span> <br/>
                 (If yes enclose a copy).
             </li>

             <li> <span class="pi pi-angle-right"></span>
                 WHETHER BID EVALUATION CRITERIA
                 WAS INCLUDED IN BIDDING/TENDER DOCUMENTS <span>Yes</span> <br/>
                 (If yes enclose a copy).
             </li>

             <li> <span class="pi pi-angle-right"></span>
                 WHICH METHOD OF PROCUREMENT WAS USED: - (Tick one)
                 <ol start="a">
                     <li>SINGLE STAGE - ONE ENVELOPE PROCEDURE : <span > <input *ngIf="data?.method=='SSSE'" type="checkbox" name="ssse"  checked></span> </li>
                     <li>SINGLE STAGE - TWO ENVELOPE PROCEDURE : <span> <input *ngIf="data?.method=='SSTE'" type="checkbox" name="sste" checked></span>  </li>
                     <li>TWO STAGE BIDDING PROCEDURE : <span> <input *ngIf="data?.method=='TSBP'" type="checkbox" name="tsbp" checked></span>  </li>
                     <li>TWO STAGE - TWO ENVELOPE BIDDING PROCEDURE : <span> <input *ngIf="data?.method=='TSTE'" type="checkbox" name="tste" checked></span>  </li>
                   </ol>

                   <div>
                     -    PLEASE SPECIFY IF ANY OTHER METHOD OF PROCUREMENT WAS ADOPTED WITH BRIEF REASONS (i.e EMERGENCY, DIRECT
                     CONTRACTING, NEGOTIATED TENDERING ETC.)
                   </div>

                   <div>
                     - 	  WHO IS THE APPROVING AUTHORITY  <span style="text-decoration: underline;"> {{data?.approvingAuthority}}</span>
                   </div>
             </li>
             <br>
             <br>

             <li> <span class="pi pi-angle-right"></span>
                 WHETHER APPROVAL OF COMPETENT AUTHORITY WAS OBTAINED
                 FOR USING A METHOD OTHER THAN OPEN COMPETITIVE BIDDING.
                 NUMBER OF BIDS RECEIVED <span> YES </span>

             </li>


             <li> <span class="pi pi-angle-right"></span>
                 NUMBER OF BIDS RECEIVED <span> {{data?.totalBidders}} </span>
             </li>

             <li> <span class="pi pi-angle-right"></span>
                 WHETHER THE SUCCESSFUL BIDDER WAS LOWEST BIDDER  <span> YES </span>
             </li>
             <li> <span class="pi pi-angle-right"></span>
                 WHETHER INTEGRITY PACT WAS SIGNED  <span> {{data?.integrityPack}} </span>
             </li>
         </ul>
     </div>
     <div class="breakhere">
         <h3 class="reportHeading">
             PUBLIC PROCUREMENT REGULATORY
       </h3>
       <h3 class="reportHeading">
           AUTHOIUTY (PPRA)
       </h3>
       <h4 class="reportHeading">
         (As Per Rule 47 of PP Rules. 2004)
       </h4>
       <h3 class="reportHeadingProforma">
           CONTRACT AWARD PROFORMA-II
       </h3>
       <h4 class="reportHeadingDescription">To Be Filled And Uploaded on PPRA Website In Respect of All
         Public Contracts of Works, Services & Goods.</h4>

         <div class="reportList">
             <ul class="styled-list">
                 <li> <span class="pi pi-angle-right"></span>
                     NUMBER OF BIDDERS PRESENT AT THE TIME OF
                     OPENING OF BIDS:
                     <span > {{data?.totalBidders}} </span>
                 </li>
                 <li> <span class="pi pi-angle-right"></span>
                    NAME AND ADDRESS OF THE SUCCESSFUL BIDDER: <span > {{data?.nameAndAddressOfBidder}} </span>
                 </li>
                 <li> <span class="pi pi-angle-right"></span>
                     RANKING OF SUCCESSFUL BIDDER IN EVALUATION REPORT
                     (i.e. '1st, 2nd, 3rd  EVALUATED BID). <span>{{data?.bidderPosition}} </span>
                 </li>
                 <li> <span class="pi pi-angle-right"></span>
                     NEED ANALYSIS (Why the procurement was necessary?) <span>{{data?.needOfProcurement}} </span>
                 </li>
                 <li> <span class="pi pi-angle-right"></span>
                     IN CASE EXTENSION WAS MADE IN RESPONSE TIME. WHAT WERE THE REASONS (Briefly describe)
                      <span>{{data?.extension}} </span>
                 </li>
                 <li> <span class="pi pi-angle-right"></span>
                     WHETHER NAMES OF THE BIDDERS AND THEIR PRICES WERE READ OUT ATH THE TIME OF OPENING OF BIDS
                     <span>{{data?.biddersNameROL}}</span> <br/>

                 </li>
                 <li> <span class="pi pi-angle-right"></span>
                     DATE OF CONTRACT SIGNING <span> {{data?.contractDate | date}} </span>
                    (Attach a copy of agreement)
                 </li>
                 <li> <span class="pi pi-angle-right"></span>
                     CONTRACT AWARD PRICE <span>{{data?.contractPrice}}</span> <br/>
                     (Attach copy of the bid evaluation report)
                 </li>
                 <li> <span class="pi pi-angle-right"></span>
                    ANY COMPLAINTS RECEIVED <span>{{data?.complaints}}</span> <br/>
                     (If yes result thereof).
                 </li>
                 <li> <span class="pi pi-angle-right"></span>
                     ANY DEVIATION FROM SPECIFICATION GIVEN IN THE TENDER NOTICE/DOCUMENTS <span>{{data?.deviationSpecification}}</span> <br/>
                     (If yes give details).
                 </li>
                 <li> <span class="pi pi-angle-right"></span>
                     DEVIATION FROM QUALIFICATION CRITERIA   <span>{{data?.deviationQualification}}</span> <br/>
                     (If yes result thereof).
                 </li>
                 <li> <span class="pi pi-angle-right"></span>
                     SPECIAL CONDITIONS, IF ANY   <span>{{data?.specialCondition}}</span> <br/>
                     (Give Brief Description).
                 </li>
             </ul>
         </div>

     </div>
     <div class="breakhere">
         <app-evaluationExportPDF [FinalEvaluation]="true" [viewAble]="true" [fromEContract]="true" [publishedDocumentID]="publishedDocumentID" [supplierID]="supplierID" [fromSupplier]="fromSupplier" [lotNo]="lotNo"></app-evaluationExportPDF>

     </div>


 </div>