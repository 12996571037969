import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { GetAllPublishedDocumentDetailsByPidRequestModel, GetPublishedDocumentInfoByTypeRequestModel, GetPublishedDocumentInfoRequestModel, TenderFilter } from 'src/app/core/models/tender-filter.model';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import b64toBlob from 'b64-to-blob';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-ppra-tech-evaluation',
  templateUrl: './ppra-tech-evaluation.component.html',
  styleUrls: ['./ppra-tech-evaluation.component.scss']
})
export class PpraTechEvaluationComponent implements OnInit,OnDestroy {

  
    paginationModel: PaginationModel;
    searchFilter :TenderFilter;
    GetPublishedDocumentInfoByTypeRequestModel:GetPublishedDocumentInfoByTypeRequestModel;
    publicTenders:any;
    isViolation: boolean=false;
    totalRecords:any;
    totalPages:number;
    pages= new Array();
    downloadFile: downloadfile;
    currentDate = new Date();
    publicationDetails: any;
    documentsDetails: any;
    getAllPublishedDocumentDetailsByPidRequestModel: GetAllPublishedDocumentDetailsByPidRequestModel; 
    constructor(private userUtil:UserUtilService,private portalService:PublicPortalService) { 
      this.downloadFile = new downloadfile();
      this.userUtil.isPPRAPage = true;
      this.paginationModel = new PaginationModel();
      this.searchFilter = new TenderFilter();
    }

    ngOnInit(): void {
      this.getAllPublishedDocumentDetailsByPidRequestModel = new GetAllPublishedDocumentDetailsByPidRequestModel();
    }
    ngAfterViewInit(){
      this.clear();
      this.getallpublictendersdocumentsbytype();
      //this.getClosingTender();
    }
    ngOnDestroy(): void {
      this.userUtil.isPPRAPage = false;
    }
    getallpublictendersdocumentsbytype(){
    
      this.GetPublishedDocumentInfoByTypeRequestModel = new GetPublishedDocumentInfoByTypeRequestModel();
      this.GetPublishedDocumentInfoByTypeRequestModel.pagination = this.paginationModel;
      this.GetPublishedDocumentInfoByTypeRequestModel.pagination.orderByColumnName = this.searchFilter?.sortOrder;
      this.GetPublishedDocumentInfoByTypeRequestModel.pagination.orderBy = "desc";
      this.GetPublishedDocumentInfoByTypeRequestModel.filter = this.searchFilter;
      this.GetPublishedDocumentInfoByTypeRequestModel.type=1;
      this.portalService.getallpublictendersdocumentsbytype(this.GetPublishedDocumentInfoByTypeRequestModel)
        .subscribe(baseResponse => {
          if (baseResponse != null) {
            if (baseResponse.success != null) {
              if (baseResponse.success) {
                    let publicTenders = (baseResponse.data as any) ;
                  
                  
                    this.totalRecords = publicTenders?.totalRecords;
                    this.totalPages = publicTenders?.totalPages;
                    this.pages = new Array();
                    for (let i = 1; i <= this.totalPages; i++) {
                      this.pages.push(i);
                    }
                    this.publicTenders =publicTenders?.records;
                    if(this.publicTenders.find(x=>x.voilation==null) ||this.publicTenders.find(x=>x.voilation==undefined)){
                      this.isViolation=false
                    }
                    else {
                      this.isViolation=true
                    }
              }
            }
          }
        })
    }
    changePage(pageNumber){
      this.paginationModel.pageNumber = pageNumber;
      this.getallpublictendersdocumentsbytype();
    }
    search(){
      this.getallpublictendersdocumentsbytype();
    }
    clear(){
      this.pages = new Array();
      this.searchFilter.sortOrder = "Latest";
      this.searchFilter.keywords = "";
      this.searchFilter.tenderNo = "";
      this.searchFilter.dateOfAdvertisement = null;
      this.searchFilter.closingDate = null;
      this.paginationModel.pageNumber = '1';
      this.getallpublictendersdocumentsbytype();
    }
    makeDate(year,month,days){

    }
    sort(orderByColumnName, orderBy){
      this.paginationModel.orderBy = orderBy;
      this.searchFilter.sortOrder = orderByColumnName;
      this.paginationModel.orderByColumnName = this.searchFilter?.sortOrder;
      this.getallpublictendersdocumentsbytype();
    }
    getPublications(position: string,model:any, id: any) {
      this.getAllPublishedDocumentDetailsByPidRequestModel.Id = id;
  
      this.getAllPublishedDocumentDetailsByPidRequestModel.SupplierID=1;
      this.getAllPublishedDocumentDetailsByPidRequestModel.procurementPlansDetailID = model?.procurementPlansDetailID;
      this.getAllPublishedDocumentDetailsByPidRequestModel.loggedInUserID = 1;
      this.getAllPublishedDocumentDetailsByPidRequestModel.loggedInUserOfficeID = 1;

      this.getAllPublishedDocumentDetailbyPdIdPublication();
    }
    getAttachmentByID(Id: any) {
      if(Id == 0 || Id==null){
        return;
      }
      this.downloadFile.ID = Id;
      this.portalService.DownloadFile(this.downloadFile)
      .subscribe(baseResponse => {
      
        if (baseResponse.success && baseResponse.data != null) {
          var Bytes = baseResponse.data['bytes'];
          var ContentType = baseResponse.data['contentType'];
          var FileName = baseResponse.data['fileName'];

          const blob = b64toBlob(Bytes, ContentType);
          saveAs(blob, FileName);
        }
      });
    } 
    getAttachmentByGUID(Id: any,GUID:any) {
      if(Id == 0 || Id==null){
        return;
      }
      this.downloadFile.ID = Id;
      this.downloadFile.idsList = GUID;
      this.portalService.DownloadFileByGuid(this.downloadFile)
      .subscribe(baseResponse => {
      
        if (baseResponse.success && baseResponse.data != null) {
          var Bytes = baseResponse.data['bytes'];
          var ContentType = baseResponse.data['contentType'];
          var FileName = baseResponse.data['fileName'];

          const blob = b64toBlob(Bytes, ContentType);
          saveAs(blob, FileName);
        }
      });
    }
    downloadDocuments(position: string, id: any) {
      
      this.getAllPublishedDocumentDetailsByPidRequestModel.Id = id;
      this.getAllPublishedDocumentDetailsByPidRequestModel.loggedInUserID =1;
      this.getAllPublishedDocumentDetailsByPidRequestModel.loggedInUserOfficeID = 1
      this.getAllPublishedDocumentDetailbyPdId();
    }
    getAllPublishedDocumentDetailbyPdId() {


      this.portalService.getAllPublishedDocumentDetailbyPdId(this.getAllPublishedDocumentDetailsByPidRequestModel)
        .subscribe(baseResponse => {
          if (baseResponse != null) {
            if (baseResponse.success != null) {
              if (baseResponse.success) {
                this.documentsDetails = baseResponse.data;
              }
            }
          }
        })
    }
    getAllPublishedDocumentDetailbyPdIdPublication() {

      this.portalService.getAllPublishedDocumentDetailbyPdIdPublication(this.getAllPublishedDocumentDetailsByPidRequestModel)
        .subscribe(baseResponse => {

          if (baseResponse != null) {
            if (baseResponse.success != null) {
              if (baseResponse.success) {

                this.publicationDetails = baseResponse.data;
              }
            }
          }
        })
    }

}
