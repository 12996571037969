import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserUtilService } from './core/services/common/user-util.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ppra-portal';
  constructor(private router: Router,public userUtil:UserUtilService) { }
  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });
}
}
