<div class="clear"></div>
<section class="banner">
    <div class="container py-4">
        <h1>Annual Procurement Planning</h1>
    </div>
</section>
<div class="clear p-4"></div>
<div class="container">
    <div class="row">
        <div class="col-lg-3">
            <h3 class="font-weight-semi-bold">Procurement Plan 2023-24</h3>
            <h6>
                <a href="./assets/docs/ProcurmentPlan-Sample.pdf" target="_blank" class="font-weight-medium">
                <i class="far fa-file-pdf fa-fw"></i> Sample Performa</a>
            </h6>
        </div>
        <div class="col-lg-9">
            <div class="form-row">
                <div class="col-md-9">
                    <div class="form-row">
                        <div class="form-group col-sm-9">
                            <label for="OrganizationName" class="font-weight-semi-bold">Procuring Agency Name</label>
                            <app-search-dropdown [items]="DropdownRecords" [size]="'small'" [img]="'product'" [label]="'CategoryName'"
                                [uid]="'CategoryID'" [(ngModel)]="selectedValue">
                            </app-search-dropdown>
                        </div>
                        <div class="form-group col-sm-3">
                            <label for="OrganizationName" class="font-weight-semi-bold">Fiscal Years</label>
                            <app-search-dropdown [items]="financialList" [size]="'small'" [img]="'product'" [label]="'Name'"
                                [uid]="'ID'" [(ngModel)]="finalcialselectedValue">
                            </app-search-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mt-4">
                    <div class="mt-1" role="group">
                        <button type="submit" (click)="onSelectedValueSearch()" class="btn btn-primary mr-2">Search</button>
                        <button type="Clear" (click)="clear()" class="btn btn-secondary">Clear</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clear p-2"></div>
    <p class="font-weight-bold text-right">Total Records ({{totalRecords}})</p>
    <div class="table-responsive">
        <table class="table border">
            <thead class="table-success">
                <tr>
                    <th scope="col">Sr NO.</th>
                    <th scope="col">
                        <div class="sortTh text-nowrap">
                            Procuring Agency Name
                            <span class="sorted_Icons">
                                <a (click)="sort('CategoryName','desc')"><img src="../assets/images/arrow_up.gif"></a>
                                <a (click)="sort('CategoryName','asc')"><img src="../assets/images/arrow_down.gif"></a>
                            </span>
                        </div>
                    </th>
                    <th scope="col">Downloads</th>
                    <th scope="col">
                        <div class="sortTh text-nowrap">
                        Fiscal Year
                        <span class="sorted_Icons">
                            <a (click)="sort('FiscalYear','desc')"><img src="../assets/images/arrow_up.gif"></a>
                            <a (click)="sort('FiscalYear','asc')"><img src="../assets/images/arrow_down.gif"></a>
                        </span>
                    </div></th>
                    <th scope="col">
                        <div class="sortTh text-nowrap">
                            Advertisement Date
                            <span class="sorted_Icons">
                                <a (click)="sort('aDate','desc')"><img src="../assets/images/arrow_up.gif"></a>
                                <a (click)="sort('aDate','asc')"><img src="../assets/images/arrow_down.gif"></a>
                            </span>
                        </div>
                    </th>
                    <!-- <th scope="col">Fiscal Year</th> -->
                    <!-- <th scope="col">URL</th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of Records; let i = index">
                    <td>
                        <strong>{{i+1}}</strong><br>
                    </td>
                    <td>
                        <strong>{{data?.CategoryName}}</strong>
                    </td>
                    <td>
                        <a *ngIf="data?.urlattachmentID==null" [href]="data?.URL" target="_blank"><i
                                class="fas fa-file-download fa-fw fa-lg"></i></a>
                        <a *ngIf="data?.urlattachmentID!=null"
                            (click)="getAttachmentByID(data?.urlattachmentID,data?.urlattachmentGUID)"><i
                                class="fas fa-file-download fa-fw fa-lg text-success"></i></a>
                    </td>
                    <td>
                        <strong>{{data?.FiscalYear}}</strong>
                    </td>
                    <td>
                        <strong>{{data?.aDate |date}}</strong><br>
                    </td>

                </tr>
                <strong *ngIf="Records?.length == '0'">No record found.</strong>
            </tbody>
        </table>
        <div class="clear clearfix py-1"></div>
        <!-- <p class="font-weight-bold">Total Results ( 5 )</p> -->

        <small class="font_12">10 Records Per Page</small>
        <small class="font_12"> | Total Pages<strong> {{this.totalPages}}</strong></small>
        <nav aria-label="..." class="arial-pagination">
            <div class="d-flex">
                <ul class="pagination justify-content-center">
                    <li class="page-item" [class.disabled]="this.PageNo === 1">
                        <a class="page-link" (click)="previousPage()">Previous</a>
                    </li>
                    <li class="page-item" *ngFor="let pageNumber of pages">
                        <a class="page-link" (click)="changePage(pageNumber)"
                            [class.active]="pageNumber === this.PageNo">{{pageNumber}}</a>
                    </li>
                    <li class="page-item" [class.disabled]="this.PageNo=== this.totalPages">
                        <a class="page-link" (click)="nextPage()">Next</a>
                    </li>
                </ul>
                <div class="input-group input-group-sm ml-4">
                    <input type="number" class="form-control" placeholder="Page No." (keyup)="onEnterKeyPressed($event)"
                        [(ngModel)]="this.PN" aria-label="Enter your text" aria-describedby="button-addon">
                    <div class="input-group-append input-group-sm">
                        <button class="btn btn-outline-secondary" (click)="selectedPage()" type="button"
                            id="button-addon">Go</button>
                    </div>
                </div>
            </div>
        </nav>
    </div>
    <!-- <p class="arial-pagination">
        <a (click)="changePage(pageNumber)"  *ngFor="let pageNumber of pages"    [ngClass]="{'active': pageNumber==this.PageNo}">{{pageNumber}}&nbsp;</a> 
    </p> -->
</div>
<div class="clear p-4"></div>