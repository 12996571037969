import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import b64toBlob from 'b64-to-blob';
import { listType } from 'src/app/core/enumerations/enum-ListType';
import { downloadfile } from 'src/app/core/models/UtilModel/downloadfile.model';
import { ActiveTendersSearchRequestModel } from 'src/app/core/models/activeTendersRequestModel';
import { PaginationModel } from 'src/app/core/models/pagination-model';
import { payRequestModel } from 'src/app/core/models/payRequestModel';
import { PublicPortalService } from 'src/app/core/services/public-portal/public-portal.service';
import { saveAs } from 'file-saver';
import { GetPayRequestModel } from 'src/app/core/models/GetPayRequestModel';

@Component({
  selector: 'app-prequalification-notice',
  templateUrl: './prequalification-notice.component.html',
  styleUrls: ['./prequalification-notice.component.scss']
})
export class PrequalificationNoticeComponent implements OnInit {
  payRequestModel: GetPayRequestModel;
  paginationModel: PaginationModel;
  downloadFile: downloadfile;
  dataForm: FormGroup;
  dataModel: ActiveTendersSearchRequestModel;
  loggedInUser: any;
  selectedSort: any;
  Records: any;
  totalRecords: any;
  totalPages: number;
  pages = new Array();
  PageNo: number = 0;
  PN: number;
  ID: number = 0;
  Sector: any;
  DropdownRecords: any
  selectedValue: any
  selectedValueWorth: any
  isSelected: boolean;
  selectedStateID: any;
  ClosingDate: any;
  TenderNumber: any;
  isGuid: boolean;
  dropdownLable: string;
  uniqueSectorNames: any;
  selectedValueForFilter: any;
  NameofOrgSec: string;
  FilteredRecord: any;
  isOrg: boolean = null;
  tempRecords: any;
  selectedStateIDValue: any;
  SectorID: string;
  OrgID: string;
  displayDropdown: boolean = false
  constructor(private service: PublicPortalService) {
    this.dataModel = new ActiveTendersSearchRequestModel()
  }

  ngOnInit(): void {
    this.ClosingDate = null
    this.selectedStateID = null
    this.TenderNumber = null
    this.paginationModel = new PaginationModel();
    this.payRequestModel = new payRequestModel();
    this.downloadFile = new downloadfile();
    this.PageNo = Number(this.paginationModel.pageNumber);
  }
  ngAfterViewInit() {
    this.GetPayDetailList();
  }
  GetPayDetailList() {

    this.payRequestModel.Type = listType.PQTenders;
    this.payRequestModel.ID = 0;
    this.payRequestModel.TypeID = '2';
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.service.GetPayDetailListTenderEIOPQ(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {

              let data = (baseResponse.data as any);
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
              this.pages = new Array();
              for (let i = 1; i <= this.totalPages; i++) {
                this.pages.push(i);
              }
              this.Records = data?.records;
            }
          }
        }
      })
  }

  changePage(pageNumber) {

    this.paginationModel.pageNumber = pageNumber;
    this.PageNo = Number(pageNumber);
    this.PN = this.PageNo;
    this.GetPayDetailList()
  }

  nextPage() {
    if (Number(this.paginationModel.pageNumber) < this.totalPages) {
      this.changePage(Number(this.paginationModel.pageNumber) + 1);
    }
  }

  previousPage() {
    if (Number(this.paginationModel.pageNumber) > 1) {
      this.changePage(Number(this.paginationModel.pageNumber) - 1);
    }
  }

  selectedPage() {
    if(this.PN==null || this.PN==0){
      this.PN=1
    }
    this.paginationModel.pageNumber = String(this.PN);
    this.PageNo = this.PN;
    this.GetPayDetailList()
  }

  onEnterKeyPressed(event: KeyboardEvent): void {
    // Check if the key pressed is Enter

    if (event.key === 'Enter') {
      // Call your function here
      this.selectedPage()
    }
  }
  onSelectValueSearch() {
    this.Records = []
    this.paginationModel = new PaginationModel();
    this.PageNo = Number(this.paginationModel.pageNumber)
    this.GetPayDetailList()
  }
  onSelectedValue() {
    this.payRequestModel.Type = listType.PQTenders;
    this.payRequestModel.ID = 0;
    this.payRequestModel.TypeID = '2';
    if (this.selectedStateID != null) {
      this.selectedStateIDValue = this.selectedStateID == null ? null : this.DropdownRecords.filter(x => x.StateName == this.selectedStateID)[0].StateID;
      this.payRequestModel.City = this.selectedStateIDValue.toString()
    }
    else {
      this.payRequestModel.City = null
    }
    this.payRequestModel.TenderNumber = this.TenderNumber || null;
    this.payRequestModel.TenderWorth = this.selectedValueWorth || null;
    if (this.SectorID != null) {
      this.payRequestModel.Sector = this.SectorID.toString();
    }
    else {
      this.payRequestModel.Sector = null
    }
    if (this.OrgID != null) {
      this.payRequestModel.Organization = this.OrgID.toString()
    } else {
      this.payRequestModel.Organization = null
    }
    if (this.ClosingDate == "" || this.ClosingDate == null) {
      this.payRequestModel.Date = null;
    }
    else {
      this.payRequestModel.Date = new Date(this.ClosingDate)
    }
    this.payRequestModel.pagination = this.paginationModel;
    this.payRequestModel.pagination.pageSize = "10"
    this.service.GetPayDetailList(this.payRequestModel)
      .subscribe(baseResponse => {
        if (baseResponse != null) {
          if (baseResponse.success != null) {
            if (baseResponse.success) {
              this.isSelected = true;
              let data = (baseResponse.data as any);
              this.Records = data?.records
              this.totalPages = data?.totalPages;
              this.totalRecords = data?.totalRecords;
            }
          }
        }
      }
      )
    //}
  }
  clear() {
    this.ClosingDate = null
    this.selectedStateID = null
    this.TenderNumber = null
    this.GetPayDetailList()
  }
  clears() {
    this.selectedValueWorth = null
    this.ClosingDate = null
    this.selectedStateID = null
    this.TenderNumber = null
    this.selectedValueForFilter = null
    this.GetPayDetailList()
  }
  getAttachmentByID(Id: any, Guid: any) {
    if (Id == 0 || Id == null) {
      return;
    }
    this.downloadFile.ID = Id;
    this.downloadFile.idsList = Guid;

    this.service.DownloadFileByGuid(this.downloadFile).subscribe(baseResponse => {
      if (baseResponse.success && baseResponse.data != null) {
        var Bytes = baseResponse.data['bytes'];
        var ContentType = baseResponse.data['contentType'];
        var FileName = baseResponse.data['fileName'];

        const blob = b64toBlob(Bytes, ContentType);
        saveAs(blob, FileName);
      }
    });
  }
  sort(orderByColumnName, orderBy) {
    this.paginationModel.orderBy = orderBy;
    this.payRequestModel.pagination.orderByColumnName = orderByColumnName;
    this.paginationModel.orderByColumnName = orderByColumnName
    this.GetPayDetailList()
  }
}