<div class="clear"></div>
<section class="banner">
    <div class="container py-4">
        <h1>Procurement Contracts</h1>
    </div>
</section>
<div class="clear p-4"></div>
<div class="container">
    <h6 class="font-weight-medium">Organization Name: {{this.Organization}}</h6>
    <div class="row">
        <div class="col-lg-3">
            <label for="date">Tender Worth</label>
            <select class="form-control" id="OrganizationName" [(ngModel)]="selectedValueWorth">
                <option *ngFor="let record of WorthRecord" [value]="record?.id">{{record?.name}}</option>
            </select>
        </div>
        <div class="col-lg-3 mt-4">
            <div class="input-group mt-1">
                <div class="input-group-prepend">
                    <span class="input-group-text">PCN</span>
                </div>
                <input type="text" id="date" class="form-control" [(ngModel)]="PCNValue">
            </div>
        </div>
        <div class="col-lg-2 mt-4">
            <button type="submit" (click)="onSelectValueSearch()" class="btn btn-primary">Search</button>
            <button type="Clear" (click)="clear()" class="btn btn-secondary ml-2">Clear</button>
        </div>
    </div>

    <div class="clear p-2"></div>
    <!-- <p class="text-right">Total Results (5)</p> -->
    <div class="table-responsive">
        <table class="table border">
            <thead class="table-success">
                <tr>
                    <th scope="col">Contract No<a (click)="sort('SequenceNo','desc')"><img
                                src="../assets/images/arrow_up.gif"></a> <a (click)="sort('SequenceNo','asc')"><img
                                src="../assets/images/arrow_down.gif"></a></th>
                    <th scope="col">Contract Title</th>
                    <th scope="col" class="text-center">Download</th>
                    <th scope="col">Uploaded Date<a (click)="sort('aDate','desc')"><img
                                src="../assets/images/arrow_up.gif"></a> <a (click)="sort('aDate','asc')"><img
                                src="../assets/images/arrow_down.gif"></a></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of Records; let i = index">
                    <td class="font-weight-bold">PCN{{data?.ConNo}}</td>
                    <td>
                        <strong class="font-weight-semi-bold">{{data?.CategoryName}}</strong><br>
                        {{data?.Title}}<br>
                        {{data?.Description}}<br>
                        {{data?.tNo}}
                        <!-- {{data?.SNo}}  <br>  -->
                        {{data?.tse}} <br>
                        <strong class="font-weight-semi-bold">Contract Worth: </strong>{{data?.ContractWorth}}<br>
                        <strong class="font-weight-semi-bold">Contract Awarded Amount:
                        </strong>{{data?.ContractAwardedAmount}}<br>
                        <strong class="font-weight-semi-bold">Successfull Bidder:
                        </strong>{{data?.SuccessfullBidder}}<br>
                        <a *ngIf="data?.ContractAwardedAmount >= 50000000" (click)="getBOI(data.SequenceNo)" data-toggle="modal" data-target="#publicationModal"
                            style="color: blue; text-decoration: underline;"><strong>Beneficial Owners Information</strong> </a>
                    </td>
                    <td class="text-center">
                        <a *ngIf="data?.urlattachmentID==null" [href]="data?.URL" target="_blank"><i
                                class="fas fa-file-download fa-fw fa-lg"></i></a>
                        <a *ngIf="data?.urlattachmentID!=null"
                            (click)="getAttachmentByID(data?.urlattachmentID,data?.urlattachmentGUID)"><i
                                class="fas fa-file-download fa-fw fa-lg text-success"></i></a>
                    </td>
                    <td>{{data?.aDate | date}}</td>
                </tr>
                <strong *ngIf="Records?.length == '0'">No record found.</strong>
            </tbody>
        </table>

        <div class="clear clearfix py-1"></div>
        <!-- <p class="font-weight-bold">Total Results ( 5 )</p> -->

        <p class="font-weight-bold">Total Contracts ({{totalRecords}})</p>
        <small class="font_12">10 Records Per Page</small>
        <small class="font_12"> | Total Pages<strong> {{this.totalPages}}</strong></small>
        <nav aria-label="..." class="arial-pagination">

            <div class="container mt-5">
                <div class="row">
                    <div class="col-md-3">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" [class.disabled]="this.PageNo === 1">
                                <a class="page-link" (click)="previousPage()">Previous</a>
                            </li>
                            <li class="page-item" *ngFor="let pageNumber of pages">
                                <a class="page-link" (click)="changePage(pageNumber)"
                                    [class.active]="pageNumber === this.PageNo">{{pageNumber}}</a>
                            </li>
                            <li class="page-item" [class.disabled]="this.PageNo=== this.totalPages">
                                <a class="page-link" (click)="nextPage()">Next</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-2">
                        <div class="input-group input-group-sm">
                            <input type="number" class="form-control" placeholder="Page No."
                                (keyup)="onEnterKeyPressed($event)" [(ngModel)]="this.PN" aria-label="Enter your text"
                                aria-describedby="button-addon">
                            <div class="input-group-append input-group-sm">
                                <button class="btn btn-outline-secondary" (click)="selectedPage()" type="button"
                                    id="button-addon"><span style="color: blue; ">Go</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </nav>

    </div>
</div>

<div class="modal fade" id="publicationModal" tabindex="-1" role="dialog" aria-labelledby="publicationModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Beneficial Owner Information of Contract</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="table-responsive">
					<table class="table table-striped border">
						<thead class="table-success">
							<tr>
								<th scope="col">PPRA Contract No</th>
								<th scope="col">Beneficial Owner Name</th>
								<th scope="col">Beneficial Owner Father Name</th>
								<th scope="col">Beneficial Owner NIC No</th>
								<th scope="col">Beneficial Owner Nationality</th>
								<th scope="col">Beneficial Owner Residential Address</th>
								<th scope="col">Beneficial Owner Email</th>
								<th scope="col">Beneficial Owner Details</th>
								<th scope="col">Remarks</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of BOIRecords">
								<td>{{ item?.ConNo }}</td>
								<td>{{ item?.bname }}</td>
								<td>{{ item?.bfname }}</td>
								<td>{{ item?.bnic }}</td>
								<td>{{ item?.bnational }}</td>
								<td>{{ item?.baddress }}</td>
								<td>{{ item?.bemail }}</td>
								<td>{{ item?.bdetail }}</td>
								<td>{{ item?.remarks }}</td>
							</tr>
						</tbody>
					</table>
                    <div *ngIf="BOIRecords?.length < 1" class="text-center py-4" >
                        No Record Found
                    </div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-outline-danger py-2" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>