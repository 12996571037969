<section class="banner">
	<div class="container">
		<div class="row">
        	<div class="col-lg-8">
            	<div id="alertslides" class="carousel slide" data-ride="carousel">
					<div class="carousel-inner">
						<div class="carousel-item active">
							<img class="d-block w-100" src="../assets/images/slide-01.png" alt="First slide">
					 		<div class="carousel-caption">
								<h5>Manage and Publish Tenders easily with e-Procurement</h5>
								<p>The {{this.userUtilsService.selectedEnvironment?.Name}} Public Procurement Regulatory Authority is an autonomous body endowed with the responsibility of prescribing regulations...</p>
							</div>
						</div>
						<div class="carousel-item">
							<img class="d-block w-100" src="../assets/images/slide-02.png" alt="2nd slide">
					  		<div class="carousel-caption">
								<h5>Manage and Publish Tenders easily with e-Procurement</h5>
								<p>The {{this.userUtilsService.selectedEnvironment?.Name}} Public Procurement Regulatory Authority is an autonomous body endowed with the responsibility of prescribing regulations...</p>
							</div>
						</div>
						<div class="carousel-item">
							<img class="d-block w-100" src="../assets/images/slide-03.png" alt="3rd slide">
					  		<div class="carousel-caption">
								<h5>Manage and Publish Tenders easily with e-Procurement</h5>
								<p>The {{this.userUtilsService.selectedEnvironment?.Name}} Public Procurement Regulatory Authority is an autonomous body endowed with the responsibility of prescribing regulations...</p>
							</div>
						</div>
				  	</div>
					<button class="carousel-control-prev d-none d-lg-block" type="button" data-target="#alertslides" data-slide="prev">
						<span class="carousel-control-prev-icon" aria-hidden="true"></span>
						<span class="sr-only">Previous</span>
					</button>
					<button class="carousel-control-next d-none d-lg-block" type="button" data-target="#alertslides" data-slide="next">
						<span class="carousel-control-next-icon" aria-hidden="true"></span>
						<span class="sr-only">Next</span>
					</button>					
				</div>
				<app-dashboard></app-dashboard>
            </div>
			<div class="col-lg-4">
				<app-highlights></app-highlights>
			</div>
			
        </div>
    </div>
</section>
<div class="clear p-3"></div>
<section class="container">
	<h2>News <span class="font-weight-light">& Announcements</span></h2>
	<div class="clear p-2"></div>
    <div class="row">
		<div class="col-lg-6">
			<div class="announcement_box">
				<img src="../assets/images/news-01.jpg">
				<a [routerLink]="['/other/procurement-plan']">
					<div>
						<span class="bar bg-warning"></span>
						<h4>eProcurement</h4>
						<p>E-procurement is the business-to-business or business-to-consumer or business-to-government</p>
					</div>
				</a>
			</div>
		</div>
		<div class="col-lg-6">
			<div class="announcement_box">
				<img src="../assets/images/news-02.jpg">
				<a [routerLink]="'/home'" target="_blank">
					<div>
						<span class="bar bg-success"></span>
						<h5>{{this.userUtilsService.selectedEnvironment?.Name}} PPRA Mobile App</h5>
						<p>Download our mobile app</p>
					</div>
				</a>
			</div>
			<div class="row">
				<div class="col-sm-6">
					<div class="announcement_box">
						<img src="../assets/images/news-03.jpg">
						<a [routerLink]="['/other/online-query']">
							<div>
								<span class="bar bg-info"></span>
								<h5>Drop your complaints</h5>
							</div>
						</a>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="announcement_box">
						<img src="../assets/images/news-04.jpg">
						<a [routerLink]="['/tenders']">
							<div>
								<span class="bar bg-danger"></span>
								<h5>Upload your tenders</h5>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<div class="clear p-2"></div>
<section class="bg-light py-5">
	<div class="container">
    	<h2>Services <span class="font-weight-light">& Information</span></h2>
        <div class="clear p-2"></div>
		<div class="row">
			<div class="col-md-6 mb-4 pb-2 wow fadeInUp">
				<a [routerLink]="['/other/procurement-plan']" class="services_widgets d-flex align-items-center justify-content-between green">
					<img src="../assets/images/procurement.png">
					EPADS Procurement Planning
					<i class="fa fa-arrow-right col-auto"></i>
				</a>
			</div>
			<div class="col-md-6 mb-4 pb-2 wow fadeInUp">
				<a [routerLink]="['/tenders']" class="services_widgets d-flex align-items-center justify-content-between blue">
					<img src="../assets/images/tender.png">
					EPADS Tenders
					<i class="fa fa-arrow-right col-auto"></i>
				</a>
			</div>
			
			<div class="col-md-6 mb-4 pb-2 wow fadeInUp">
				<a [routerLink]="['/other/evaluation-results']" class="services_widgets d-flex align-items-center justify-content-between yellow">
					<img src="../assets/images/evaluations.png">
					EPADS Evaluation Results
					<i class="fa fa-arrow-right col-auto"></i>
				</a>
			</div>
			<div class="col-md-6 mb-4 pb-2 wow fadeInUp">
				<a [routerLink]="['/other/procurement-contracts']" class="services_widgets d-flex align-items-center justify-content-between gray">
					<img src="../assets/images/contract.png">
					EPADS Contracts
					<i class="fa fa-arrow-right col-auto"></i>
				</a>
			</div>
			<div class="col-md-6 mb-4 pb-2 wow fadeInUp">
				<a [routerLink]="['/other/register-suppliers']" class="services_widgets d-flex align-items-center justify-content-between green2">
					<img src="../assets/images/suppliers.png">
					EPADS Registered Suppliers
					<i class="fa fa-arrow-right col-auto"></i>
				</a>
			</div>
			<!-- <div class="col-md-6 mb-4 pb-2 wow fadeInUp">
				<a [routerLink]="['/other/active-black-listed-firms']" class="services_widgets d-flex align-items-center justify-content-between orrange">
					<img src="../assets/images/blacklists.png">
					EPADS Active Black Listed Firms
					<i class="fa fa-arrow-right col-auto"></i>
				</a>
			</div> -->
			<div class="col-md-6 mb-4 pb-2 wow fadeInUp">
				<a [routerLink]="['/other/pepra-active-black-listed-firms']" class="services_widgets d-flex align-items-center justify-content-between orrange">
					<img src="../assets/images/blacklists.png">
					PPRA Active Black Listed Firms
					<i class="fa fa-arrow-right col-auto"></i>
				</a>
			</div>
			<div class="col-md-6 mb-4 pb-2 wow fadeInUp">
				<a [routerLink]="['/other/FAQ']" class="services_widgets d-flex align-items-center justify-content-between blue">
					<img src="../assets/images/faqs.png">
					FAQs
					<i class="fa fa-arrow-right col-auto"></i>
				</a>
			</div>
			<div class="col-md-6 mb-4 pb-2 wow fadeInUp">
				<a [routerLink]="['/documents/regulations']" class="services_widgets d-flex align-items-center justify-content-between yellow">
					<img src="../assets/images/legal-doc.png">
					Legal Documents
					<i class="fa fa-arrow-right col-auto"></i>
				</a>
			</div>
			<div class="col-md-6 mb-4 pb-2 wow fadeInUp">
				<a [routerLink]="['/documents/pp-rules']" class="services_widgets d-flex align-items-center justify-content-between green">
					<img src="../assets/images/rule.png">
					Rules & Regulations
					<i class="fa fa-arrow-right col-auto"></i>
				</a>
			</div>
			<div class="col-md-6 mb-4 pb-2 wow fadeInUp">
				<a [routerLink]="['/other/downloads']" class="services_widgets d-flex align-items-center justify-content-between green2">
					<img src="../assets/images/downloads.png">
					Downloads
					<i class="fa fa-arrow-right col-auto"></i>
				</a>
			</div>
		</div>
    </div>
</section>
<div class="clear p-4"></div>
<section class="container">
	<div class="row justify-content-center" >
		<div *ngFor="let item of arrayList" class="col-6 col-sm-4 col-md-3 col-lg-2 text-center">
			<a [href]="item?.Url" target="_blank"><img [src]="item.BytesData" class="img-center" width="180" height="180"></a>
		</div>
	</div>
	<div class="clear p-3"></div>
	<hr>
	<div class="row align-items-center">
		<div class="col-lg-7">
			<div class="border">
				<div class="row align-items-center">
					<div class="col-lg-7">
						<p class="font-weight-medium ml-2">Sindh Chief Minister Justice Maqbool Baqar launched E-procurement system and spoke on the occasion. The launching ceremony held at a local hotel on 29th December, 2023 and was attended by Law Minister/Chairman SPPRA Mr. Omar Soomro, MD Federal PPRA Mr. Maqbool Ahmad Gondal and MD SPPRA Mr. Zubair Pervaiz</p>
					</div>
					<div class="col-lg-5">
						<video width="100%" height="240" controls>
							<source src="https://www.ppra.org.pk/epads/sepads.mp4" type="video/mp4">
							Your browser does not support the video tag.
						</video>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-5">
			<h3><a target="_blank" href="https://eprocure.gov.pk/#/auth/login">e-Pak Acquisition & Disposal System (EPADS)</a></h3>
			<h4 class="text-black">EPADS UAN: <strong class="text-primary">051-111-137-237</strong></h4>
			<div class="media">
				<a target="_blank" href="https://eprocure.gov.pk/#/supplier/registration"><img src="../assets/images/evr.jpg" class="mr-3"></a>
				<div class="media-body">
					<h6><a target="_blank" href="https://eprocure.gov.pk/#/supplier/registration">Electronic Vendor Registration</a></h6>
					<p>All the Suppliers are encouraged to avail the opportunity of getting free of cost registration with the e-VR (Electronic Vendor Registration System) of the E-PADS, with the exemption of the annual subscription fee for the first year .</p>
				</div>
			</div>
		</div>
	</div>
	<hr>
	<div class="row align-items-center">
		<div class="col-lg-9">
			<div class="row align-items-center">
				<div class="col-4 col-sm-4">
					<img src="../assets/images/DGgondal.png" class="img-thumbnail">
				</div>
				<div class="col-8 col-sm-8">
					<h5 class="font-weight-medium">Mr. Maqbool Ahmad Gondal</h5>
					<p class="text-black-50 font-weight-medium Roboto">Managing Director</p>
					<p>Mr. Maqbool Ahmad Gondal is Managing Director of Public Procurement Regulatory Authority (PPRA) w.e.f 18-11-2021. He is an officer of Audit and Accounts Services having 30 years of experience of Public Sector Accounting, Auditing, Management and Tax Administration. His academic includes Master of Accounting and Finance from University of Manchester (UK); MBA Finance from National University of Modern Language (NUML); and Msc / M.Phil Physics from Quaid-e-Azam University with distinction of Chancellor's Gold Medal. He is also member of a number of national and international Accounting Bodies including Certified Fraud Examiner (CFE) from USA. He is lifetime member of Pakistan Institute of Public Financial Accountant (PIPFA).</p>
				</div>
			</div>
		</div>
		<div class="col-lg-3 text-center">
			<img src="../assets/images/visitors.png" class="img-center">
		</div>
	</div>
	<hr>
	<div class="row">
		<div class="col-lg-6">
			<div class="card">
				<div class="card-header">
					<marquee loop="true" width="100%">
						<a target="_blank" href="../assets/docs/tenderfee.pdf" class="h5 card-title mb-0 font-weight-semi-bold">All Procuring Agencies can deposit the tenders uploading fee Rs:10000/- in shape of <b>Pay Order/Demand Draft</b>., PPRA FTN No. 9010117-7 and Vendor No.30379395 (AGPR-Islamabad), Vendor No.30334836 (AGPR-Faislabad), Vendor No.50305254 (AGPR-Karachi), Vendor No.60092095 (AGPR-Quetta), Vendor No.70001748 (AGPR-Gilgit), Vendor No.80178117 (AGPR-Peshawar)</a>
					</marquee>
				</div>
				<div class="card-body">
					<h6 class="well font-weight-medium p-3 mb-3">
						<i class="far fa-hand-point-right text-primary mr-2"></i>
						<a [routerLink]="['/tenders/EOITenders']">Expression of Interest</a> <small class="ml-2">({{this.Records[0]?.EOI}})</small>
					</h6>
					<h6 class="well font-weight-medium p-3 mb-3">
						<i class="far fa-hand-point-right text-primary mr-2"></i>
						<a [routerLink]="['/tenders/RFPTenders']">Request for Proposal</a> <small class="ml-2">({{this.Records[0]?.RFP}})</small>
					</h6>
					<h6 class="well font-weight-medium p-3 mb-3">
						<i class="far fa-hand-point-right text-primary mr-2"></i>
						<a [routerLink]="['/tenders/activetenders']">Tender Notice</a> <small class="ml-2">({{this.Records[0]?.TotalActiveTenders}})</small>
					</h6>
					<h6 class="well font-weight-medium p-3 mb-3">
						<i class="far fa-hand-point-right text-primary mr-2"></i>
						<a [routerLink]="['/tenders/PQTenders']">Prequalification Notice</a> <small class="ml-2">({{this.Records[0]?.PQNotice}})</small>
					</h6>
					<h6 class="well font-weight-medium p-3 mb-3">
						<i class="far fa-hand-point-right text-primary mr-2"></i>
						<a [routerLink]="['/tenders/SADTenders']">Sales/Auction/Disposal</a> <small class="ml-2">({{this.Records[0]?.SalesAuctionDisposal}})</small>
					</h6>
					<h6 class="well font-weight-medium p-3 mb-0">
						<i class="far fa-hand-point-right text-primary mr-2"></i>
						<a href="https://ppra.org.pk/jobs.asp" target="_blank">Job Advertisements</a> <small class="ml-2">(84)</small>
					</h6>
					<div class="d-flex justify-content-between mt-3">
						<h6 class="font-weight-bold">Last Update: {{currentDate |date}}</h6>
						<h6 class="font-weight-bold"><a [routerLink]="['/tenders/activetenders']">Total Active Tenders: {{this.Records[0]?.TotalActiveTenders}}</a></h6>
					</div>
				</div>
			</div>
			<div class="row align-items-center">
				<div class="col-md-8 align-items-center">
					<div class="row align-items-center">
						<div class="col-sm-3 text-center"><a [routerLink]="['/tenders/activetenders']"><img src="../assets/images/newtenders.jpg" class="img-center"></a></div>
						<div class="col-sm-6 text-center"><a href="https://ppra.org.pk/opendata.asp" target="_blank"><img src="../assets/images/opendata.jpg" class="img-center"></a></div>
						<div class="col-sm-3 text-center"><a href="https://www.facebook.com/profile.php?id=100095697124318" target="_blank"><img src="../assets/images/facebook.jpg" class="img-center"></a></div>
					</div>
					<div class="clear py-2"></div>
					<a href="https://webmail.ppra.org.pk/interface/root" target="_blank" class="btn btn-primary w-100 font-weight-medium"><i class="far fa-envelope mr-2"></i> Only for PPRA Employees</a>
				</div>
				<div class="col-md-4 text-center">
					<a href="../assets/images/rate.png" target="_blank"><img src="../assets/images/ad-rate.gif" class="img-center"></a>
					<div class="clear py-1"></div>
					<a href="../assets/docs/pnotice.pdf" target="_blank"><img src="../assets/images/pnotice.jpg" class="img-center"></a>
				</div>
			</div>
		</div>
		<div class="col-lg-6">
			<div class="row">
				<div class="col-sm-6">
					<div class="card card-body bg-light">
						<h6 class="font-weight-bold">Important Notice for Procuring Agencies</h6>
						<hr>
						<p>For timely uploading of tenders notices on PPRA website;</p>
						<ul class="pl-3">
							<li>Send tender notices to PPRA at least two working days prior to its publication in newspaper.</li>
							OR
							<li>Obtain your own User ID/Password for direct uploading on PPRA website.  (info@ppra.org.pk)</li>
						</ul>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="card card-body bg-light">
						<h6 class="font-weight-bold">Contact for  Queries/Support</h6>
						<strong class="text-black">Tender Uploading</strong><br>
						Tel: 051-9205728, 26<br>Fax: 051-9219149<br>
						Email: <a href="mailto:info@ppra.org.pk">info@ppra.org.pk</a> <a href="mailto:rizwan@ppra.org.pk">rizwan@ppra.org.pk</a>
						<h6 class="font-weight-bold">Tender Fee Payments/Accounts & Finance</h6>
						Tel: 051-9203541<br>Fax: 051-9224823<br>
						Email: <a href="mailto:faraz@ppra.org.pk">faraz@ppra.org.pk</a>
					</div>
				</div>
			</div>
			<a href="http://www.anti-fraudhotline.com/" target="_blank"><img src="../assets/images/usaid.jpg" class="img-center mt-2" title="Anti-Fraud"></a>
		</div>
	</div>
	<!--div class="row d-flex align-items-center">
		<div class="col-md-6 wow fadeInUp">
			<h2>Statistics Today <span class="font-weight-light">For</span> Suppliers</h2>
			<div class="clear p-3"></div>
			<p class="d-flex align-items-center">
				<img src="../assets/images/ico_all.png" class="mr-3">
				All
			</p>
			<p class="d-flex align-items-center">
				<img src="../assets/images/ico_goods.png" class="mr-3">
				Goods
			</p>
			<p class="d-flex align-items-center">
				<img src="../assets/images/ico_works.png" class="mr-3">
				Works
			</p>
			<p class="d-flex align-items-center">
				<img src="../assets/images/ico_non-consultancy.png" class="mr-3">
				Non-Consultancy Services
			</p>
			<p class="d-flex align-items-center">
				<img src="../assets/images/ico_consultancy.png" class="mr-3">
				Consultancy Services
			</p>
		</div>
		<div class="col-md-6 text-center chart d-none d-lg-block">
			<img src="../assets/images/chart.png" class="img-center">
			<strong class="all">{{AllCount}}</strong>
			<strong class="non-consultancy">{{NonConsultingServicesCount}}</strong>
			<strong class="goods">{{GoodsCount}}</strong>
			<strong class="works">{{WorksCount}}</strong>
			<strong class="consultancy">{{ConsultingServicesCount}}</strong>
		</div>
	</div--->	
</section>
<div class="clear p-4"></div>
<section class="well py-5 rounded- mb-0">
	<div class="container">
		<h2>Explore</h2>
		<div class="clear p-2"></div>
		<div class="row">
			<div class="col-md-4">
				<div class="card">
					<div class="position-relative">
						<img src="../assets/images/about-sindh.jpg" class="w-100">
					</div>
					<div class="card-body">
						<h6 class="card-title text-success">About {{this.userUtilsService.selectedEnvironment?.Name}} PPRA</h6>
						<p>The {{this.userUtilsService.selectedEnvironment?.Name}} Public Procurement Regulatory Authority is an autonomous body endowed with the responsibility of prescribing regulations and procedures for public procurements by {{this.userUtilsService.selectedEnvironment?.Name}} Government owned public sector organizations with a view to improve governance, management,</p>
						<!---div class="text-right"><a [routerLink]="['/home']" class="text-success">Learn More <i class="fa fa-arrow-right ml-2"></i></a></div--->
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card">
					<div class="position-relative">
						<img src="../assets/images/train-img.png" class="w-100">
					</div>
					<div class="card-body">
						<h6 class="card-title text-primary">Trainings</h6>
						<p>Training of government officials in public procurement was started by {{this.userUtilsService.selectedEnvironment?.Name}} Public Procurement Regulatory Authority ({{this.userUtilsService.selectedEnvironment?.Letter}}PPRA) under a 3 years World Bank Capacity Building Project in 2006...</p>
						<div class="text-right"><a [routerLink]="['/other/epad-trainings']" class="text-primary">Learn More <i class="fa fa-arrow-right ml-2"></i></a></div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card">
					<div class="position-relative">
						<img src="../assets/images/policy-img.png" class="w-100">
					</div>
					<div class="card-body">
						<h6 class="card-title text-info">Policy Guidelines</h6>
						<p>Training of government officials in public procurement was started by {{this.userUtilsService.selectedEnvironment?.Name}} Public Procurement Regulatory Authority ({{this.userUtilsService.selectedEnvironment?.Letter}}PPRA) under a 3 years World Bank Capacity Building Project in 2006...</p>
						<div class="text-right"><a [routerLink]="['/other/policy-guidelines']" class="text-info">Learn More <i class="fa fa-arrow-right ml-2"></i></a></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
