import { Component, OnInit } from '@angular/core';
import { UserUtilService } from 'src/app/core/services/common/user-util.service';

@Component({
  selector: 'app-law-acts',
  templateUrl: './law-acts.component.html',
  styleUrls: ['./law-acts.component.scss']
})
export class LawActsComponent implements OnInit {

  constructor(public userUtilsService:UserUtilService) { }

  ngOnInit(): void {
  }

}
