<div class="clear"></div>
<section class="banner">
	<div class="container py-4">
		<h1>PPRA Procurement Code</h1>
	</div>
</section>
<div class="clear py-4"></div>
<section class="container">
    <div class="row">
        <div class="col-lg-4 m-auto">
            <h3 class="font-weight-semi-bold text-primary">PPRA Procurement Code Is Not Available Due To Under Approval Stage.</h3>
            <h3 class="font-weight-semi-bold text-primary">Please Visit Again,<br>Thanks</h3>
        </div>
    </div>
</section>
<div class="clear py-3"></div>